import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const PageContainer = styled(motion.div)`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: transparent;
  justify-content: space-between;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

export const MainSection = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 2rem 2rem;
  position: relative;
  z-index: 2;
  width: 100%;
  margin-top: 0;

  @media (max-width: 768px) {
    padding: 40px 1rem 2rem;
  }
`;

export const IntervalSelectionContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 40px auto 0;
`;

export const IntervalSelection = styled.div`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 4px;
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;

export const IntervalButton = styled.button`
  background: none;
  border: none;
  padding: 8px 24px;
  color: ${(props) => (props.$active ? "#fff" : "rgba(255, 255, 255, 0.6)")};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
`;

export const IntervalSlider = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: calc(50% - 4px);
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 8px;
  transform: translateX(
    ${(props) => (props.$position === "right" ? "100%" : "0")}
  );
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;

export const SavingsLabel = styled(motion.div)`
  position: absolute;
  top: -10px;
  right: -65px;
  background: linear-gradient(45deg, #8853fb, #3498db);
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  box-shadow: 0 4px 12px rgba(136, 83, 251, 0.3);
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 12px;

  @media (max-width: 1400px) {
    overflow-x: auto;
    justify-content: flex-start;
    padding: 12px 40px;
    
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(136, 83, 251, 0.2);
      border-radius: 4px;
    }
  }
`;

export const PlanCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;

  svg {
    color: #8853fb;
    filter: drop-shadow(0 0 10px rgba(136, 83, 251, 0.3));
  }
`;

export const CurrentPrice = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  white-space: nowrap;
  height: 56px;
  align-items: center;
`;

export const OriginalPrice = styled(motion.div)`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.4);
  text-decoration: line-through;
  display: flex;
  align-items: center;
  gap: 2px;
  height: 24px;
`;

export const AccessCount = styled.div`
  background: rgba(28, 28, 35, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
  text-align: center;
`;

export const AccessNumber = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #8853fb;
  margin-bottom: 4px;
`;

export const AccessPeriod = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

export const Divider = styled.div`
  width: 1px;
  height: 420px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(136, 83, 251, 0.2),
    rgba(136, 83, 251, 0.3),
    rgba(136, 83, 251, 0.2),
    transparent
  );
  margin: 0 32px;
  flex-shrink: 0;
  position: relative;

  &::before {
    content: "OR";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(28, 28, 35, 0.95);
    padding: 12px;
    border-radius: 50%;
    color: rgba(136, 83, 251, 0.8);
    font-size: 12px;
    font-weight: 500;
    border: 1px solid rgba(136, 83, 251, 0.3);
    box-shadow: 
      0 0 20px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      background: rgba(33, 33, 40, 0.95);
      border-color: rgba(136, 83, 251, 0.4);
      box-shadow: 
        0 0 30px rgba(136, 83, 251, 0.3),
        0 0 0 1px rgba(136, 83, 251, 0.2);
    }
  }
`;

export const PayAsYouGoBar = styled(motion.div)`
  background: rgba(18, 18, 24, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 28px;
  width: 280px;
  min-width: 280px;
  height: 420px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(20px);
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(255, 255, 255, 0.03),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    transform: translateY(-8px);
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 12px 40px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }
`;

export const PayAsYouGoContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PayAsYouGoTitle = styled.h3`
  font-size: 1.2rem;
  color: white;
  margin: 0;
  padding-left: 4px;
`;

export const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 8px 0;
  z-index: 100;
`;

export const PayAsYouGoDropdown = styled.div`
  width: 100%;
  background: rgba(28, 28, 35, 0.95);
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  position: relative;
`;

export const CheckMark = styled(motion.div)`
  color: #8853fb;
  display: flex;
  align-items: center;
`;

export const LandingFooter = styled.footer`
  flex-shrink: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.98)
  );
  backdrop-filter: blur(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const LanguageButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const LanguageButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover,
  &.active {
    color: rgba(136, 83, 251, 0.8);
    background: rgba(136, 83, 251, 0.1);
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const PricingContainer = styled(motion.div)`
  min-height: 100vh;
  background: linear-gradient(165deg, rgba(28, 28, 35, 0.85), rgba(18, 18, 24, 0.95));
  backdrop-filter: blur(40px);
  color: white;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px 40px;
`;

export const PricingHeader = styled.header`
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;

  h1 {
    font-size: clamp(2rem, 4vw, 3rem);
    background: linear-gradient(45deg, #8853fb, #3498db);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0 0 20px 0;
  }
`;

export const IntervalToggle = styled.div`
  display: inline-flex;
  background: rgba(136, 83, 251, 0.1);
  border-radius: 12px;
  padding: 4px;
  position: relative;
  border: 1px solid rgba(136, 83, 251, 0.2);
  margin: 20px 0;

  button {
    padding: 8px 24px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 1;
    font-size: 14px;

    &.active {
      color: white;
    }
  }

  .slider {
    position: absolute;
    top: 4px;
    left: 4px;
    height: calc(100% - 8px);
    width: calc(50% - 4px);
    background: linear-gradient(165deg, rgba(136, 83, 251, 0.2), rgba(96, 57, 176, 0.2));
    border-radius: 8px;
    transition: transform 0.3s ease;

    &.yearly {
      transform: translateX(100%);
    }
  }
`;

export const PlanGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 40px 0;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const PricingCard = styled(motion.div)`
  background: rgba(18, 18, 24, 0.7);
  border: 1px solid ${props => props.$featured ? 'rgba(136, 83, 251, 0.3)' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 24px;
  padding: 28px;
  width: 280px;
  min-width: 280px;
  height: 420px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(20px);
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${props => props.$featured ? 'rgba(136, 83, 251, 0.08)' : 'rgba(255, 255, 255, 0.03)'},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  }

  ${props => props.$featured && `
    background: rgba(28, 28, 35, 0.8);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.15);
  `}

  &:hover {
    transform: translateY(-8px);
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 12px 40px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }
`;

export const PlanName = styled.h3`
  font-size: 20px;
  color: #8853fb;
  margin: 0;
  font-weight: 500;
`;

export const PlanPrice = styled.div`
  font-size: 48px;
  font-weight: 600;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 80px;
  margin-bottom: 4px;

  .period {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: normal;
  }
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;

  svg {
    color: #8853fb;
    width: 16px;
    height: 16px;
  }
`;

export const GetStartedButton = styled(motion.button)`
  width: 100%;
  padding: 14px;
  background: rgba(28, 28, 35, 0.6);
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  margin-top: auto;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(136, 83, 251, 0.2);
    border-color: rgba(136, 83, 251, 0.4);
  }
`;

export const PricingFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(165deg, rgba(28, 28, 35, 0.95), rgba(18, 18, 24, 0.98));
  backdrop-filter: blur(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 24px;

  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-size: 14px;
    
    &:hover {
      color: #8853fb;
    }
  }
`;

export const LanguageToggle = styled.div`
  display: flex;
  gap: 8px;

  button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    border: none;
    background: rgba(136, 83, 251, 0.1);
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 12px;

    &.active {
      background: rgba(136, 83, 251, 0.2);
      color: white;
    }

    &:hover {
      background: rgba(136, 83, 251, 0.3);
    }
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  color: white;
`;

export const LogoText = styled.span`
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

export const PayAsYouGoSection = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
`;

export const PayAsYouGoCard = styled(motion.div)`
  background: linear-gradient(165deg, rgba(38, 38, 45, 0.5), rgba(28, 28, 35, 0.5));
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  backdrop-filter: blur(20px);
  max-width: 400px;
  width: 100%;
`;

export const PayAsYouGoPrice = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PriceSubtext = styled.span`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal;
`;

export const AmountDropdown = styled.div`
  position: relative;
`;

export const DropdownHeader = styled.div`
  padding: 12px 16px;
  background: rgba(136, 83, 251, 0.1);
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  
  &:hover {
    background: rgba(136, 83, 251, 0.15);
    color: white;
  }
`;

export const ChevronIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(255, 255, 255, 0.8);
  transform: ${props => props.$isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  transition: transform 0.3s ease;
`;

export const DropdownOptions = styled(motion.div)`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background: rgba(38, 38, 45, 0.95);
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  overflow: hidden;
  z-index: 10;
  backdrop-filter: blur(20px);
`;

export const DropdownOption = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  
  background: ${props => props.$isSelected ? 'rgba(136, 83, 251, 0.15)' : 'transparent'};
  
  &:hover {
    background: rgba(136, 83, 251, 0.15);
    color: white;
  }

  ${props => props.$isSelected && `
    color: white;
  `}
`;

export const Navbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(165deg, rgba(28, 28, 35, 0.85), rgba(18, 18, 24, 0.95));
  backdrop-filter: blur(40px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const IconButton = styled.button`
  width: 140px;
  height: 44px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at center,
      rgba(136, 83, 251, 0.1),
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  &:last-child {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.25),
      rgba(96, 57, 176, 0.25)
    );
    border-color: rgba(136, 83, 251, 0.4);

    &:hover {
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.3),
        rgba(96, 57, 176, 0.3)
      );
      border-color: rgba(136, 83, 251, 0.5);
      box-shadow: 
        0 8px 32px rgba(136, 83, 251, 0.2),
        0 0 0 1px rgba(136, 83, 251, 0.3);
    }
  }
`;

export const priceAnimationVariants = {
  initial: {
    opacity: 0,
    y: -20,
    scale: 0.95
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 30
    }
  },
  exit: {
    opacity: 0,
    y: 20,
    scale: 0.95,
    transition: {
      duration: 0.2
    }
  }
};

export const BackButton = styled(motion.button)`
  position: fixed;
  top: 90px;
  left: 40px;
  background: rgba(28, 28, 35, 0.6);
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 8px 16px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(136, 83, 251, 0.1);
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateX(-2px);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`; 