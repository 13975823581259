import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Check, ChevronDown } from "react-feather";
import { useTheme } from "../../context/ThemeContext";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownTrigger = styled.div`
  background: ${({ theme }) => theme.colors.glass.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  svg {
    transition: transform 0.2s ease;
    color: ${({ theme }) => theme.colors.secondary};
  }

  &.active {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surfaceHover};

    svg {
      transform: rotate(180deg);
    }
  }
`;

const DropdownContent = styled(motion.div)`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  box-shadow: ${({ theme }) => theme.colors.shadow};
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme.isDarkMode ? "rgba(18, 18, 24, 0.3)" : "rgba(136, 83, 251, 0.05)"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 4px;
    border: 2px solid
      ${({ theme }) =>
        theme.isDarkMode
          ? "rgba(18, 18, 24, 0.3)"
          : "rgba(248, 249, 252, 0.3)"};
  }
`;

const Option = styled.div`
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  .checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid
      ${({ theme, selected }) =>
        selected ? theme.colors.accent.purple.base : theme.colors.border};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background: ${({ theme, selected }) =>
      selected ? theme.colors.buttonGradient : "transparent"};

    svg {
      color: ${({ theme }) => theme.colors.text};
      width: 14px;
      height: 14px;
      opacity: ${(props) => (props.selected ? 1 : 0)};
      transform: scale(${(props) => (props.selected ? 1 : 0.5)});
      transition: all 0.2s ease;
    }
  }
`;

const SelectedDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .selected-count {
    background: ${({ theme }) => theme.colors.buttonGradient};
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.85em;
    color: ${({ theme }) => theme.colors.text};
  }
`;

const Dropdown = ({
  options,
  selectedOption,
  onSelect,
  placeholder,
  canSelectMultiple = false,
  loading = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const theme = useTheme();

  const handleSelect = (value) => {
    if (canSelectMultiple) {
      const newSelection = selectedOption.includes(value)
        ? selectedOption.filter((item) => item !== value)
        : [...selectedOption, value];
      onSelect(newSelection);
    } else {
      onSelect(value);
      setIsOpen(false);
    }
  };

  const getDisplayText = () => {
    if (
      !selectedOption ||
      (Array.isArray(selectedOption) && selectedOption.length === 0)
    ) {
      return <span className="placeholder">{placeholder}</span>;
    }

    if (canSelectMultiple) {
      return (
        <SelectedDisplay>
          <span>{placeholder}</span>
          <span className="selected-count">{selectedOption.length}</span>
        </SelectedDisplay>
      );
    }

    return (
      options.find((opt) => opt.value === selectedOption)?.label || placeholder
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownTrigger
        onClick={() => setIsOpen(!isOpen)}
        className={isOpen ? "active" : ""}
      >
        {getDisplayText()}
        <ChevronDown size={20} />
      </DropdownTrigger>

      <AnimatePresence>
        {isOpen && (
          <DropdownContent
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {options.map((option) => (
              <Option
                key={option.value}
                onClick={() => handleSelect(option.value)}
                selected={
                  canSelectMultiple
                    ? selectedOption.includes(option.value)
                    : selectedOption === option.value
                }
              >
                <span>{option.label}</span>
                <div className="checkbox">
                  <Check />
                </div>
              </Option>
            ))}
          </DropdownContent>
        )}
      </AnimatePresence>
    </DropdownContainer>
  );
};

export default Dropdown;
