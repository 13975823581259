import React, { useEffect, useState, useCallback, useMemo } from "react";
import { ChevronDown, Eye } from "react-feather";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { useTranslation } from "react-i18next";

const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 24px;
  border-radius: 12px;
  font-size: 11.5px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  letter-spacing: 0.4px;
  background: ${({ theme, $type }) =>
    $type === "status"
      ? theme.colors.accent.purple.light
      : theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
  backdrop-filter: blur(5px);
  white-space: nowrap;
  transform: translateY(-1px);
  text-transform: uppercase;

  /* Adjust colors based on type */
  ${({ theme, $type }) =>
    $type === "status"
      ? `
        background: ${theme.colors.accent.purple.light};
        color: ${theme.colors.text};
      `
      : `
        background: ${theme.colors.buttonGradient};
        color: ${theme.colors.text};
      `}
`;

const TableContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 16px;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
  height: ${(props) => props.$containerHeight}px;
  min-height: ${(props) => props.$containerHeight}px;
  max-height: ${(props) => props.$containerHeight}px;
  padding-bottom: 20px;
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: transparent;
  perspective: 1200px;
  transform-style: preserve-3d;
  height: calc(100% - 20px);
`;

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
`;

const HeaderCell = styled.th`
  background: ${({ theme }) => theme.colors.surface};
  padding: 16px;
  font-weight: 600;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  color: ${({ theme }) => theme.colors.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  transition: all 0.2s ease;
  cursor: ${(props) => (props.$isPublic ? "default" : "pointer")};
  white-space: nowrap;
  text-align: left;
  letter-spacing: 0.3px;

  &:hover {
    background: ${({ theme, $isPublic }) =>
      $isPublic ? theme.colors.surface : theme.colors.buttonGradient};
  }
`;

const HeaderContent = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.2s ease;
`;

const TableBody = styled.tbody`
  position: relative;
  z-index: 1;
`;

const StyledRow = styled(motion.tr)`
  transition: all 0.2s cubic-bezier(0.2, 0.65, 0.3, 0.9);
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  height: ${(props) => props.$rowHeight}px;
  min-height: ${(props) => props.$rowHeight}px;
  max-height: ${(props) => props.$rowHeight}px;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  &.viewed-row td:first-child {
    position: relative;
  }
`;

const TableCell = styled(motion.td)`
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;
  font-size: 13.5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  transition: all 0.2s ease;
  height: ${(props) => props.$rowHeight}px;
  min-height: ${(props) => props.$rowHeight}px;
  max-height: ${(props) => props.$rowHeight}px;
  line-height: ${(props) => props.$rowHeight}px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  position: relative;
  letter-spacing: 0.2px;

  &.column-number,
  &.column-money {
    font-family: "SF Mono", "Roboto Mono", "Consolas", monospace;
    font-feature-settings: "tnum" on, "lnum" on;
    letter-spacing: 0.3px;
    font-size: 13px;
  }

  &.column-money {
    color: ${({ theme }) => theme.colors.accent.purple.base};
    font-weight: 500;
  }

  /* Center badges within cells */
  &:nth-last-child(-n + 2) {
    text-align: center;

    ${Badge} {
      display: inline-flex;
    }
  }

  /* Viewed icon styling */
  &:first-child {
    .viewed-row & {
      position: relative;
    }
  }
`;

const ViewedIcon = styled(motion.div)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.accent.purple.base};
  font-size: 14px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const CellContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .viewed-row td:first-child & {
    margin-left: 34px;
  }
`;

const EmptyRow = styled.tr`
  height: ${(props) => props.$rowHeight}px;
  min-height: ${(props) => props.$rowHeight}px;
  max-height: ${(props) => props.$rowHeight}px;
  background: transparent !important;
  line-height: ${(props) => props.$rowHeight}px !important;
  box-sizing: border-box !important;

  td {
    height: ${(props) => props.$rowHeight}px !important;
    min-height: ${(props) => props.$rowHeight}px !important;
    max-height: ${(props) => props.$rowHeight}px !important;
    padding: 0 16px !important;
    line-height: ${(props) => props.$rowHeight}px !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    box-sizing: border-box !important;
    vertical-align: middle !important;
  }
`;

const LoadingOverlay = styled(motion.div)`
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const EmptyMessage = styled(motion.div)`
  padding: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  font-size: 13.5px;
  letter-spacing: 0.2px;
`;

const DataTable = ({
  headers,
  items,
  sortConfig,
  onSort,
  onRowClick,
  loading,
  isPublic = false,
  viewedItems = new Set(),
  isViewedPropertiesMode = false,
}) => {
  const ROW_HEIGHT = 35.5;
  const HEADER_HEIGHT = 56;
  const PAGINATION_HEIGHT = 80;
  const BOTTOM_PADDING = 20;
  const CONTAINER_HEIGHT = window.innerHeight - 180 + BOTTOM_PADDING;

  // Calculate how many rows can fit based on available height
  const AVAILABLE_HEIGHT =
    CONTAINER_HEIGHT - HEADER_HEIGHT - PAGINATION_HEIGHT - BOTTOM_PADDING;
  const CALCULATED_MAX_ROWS = Math.floor(AVAILABLE_HEIGHT / ROW_HEIGHT);

  // Use the larger of calculated rows or minimum 20 rows
  const MAX_ROWS = Math.max(CALCULATED_MAX_ROWS, 20);

  const displayItems = items.slice(0, MAX_ROWS);
  const emptyRows = MAX_ROWS - displayItems.length;

  const [shouldAnimate, setShouldAnimate] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setShouldAnimate(false);
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 50);
    return () => clearTimeout(timer);
  }, [items]);

  const getLinearDelay = (index) => {
    return index * 0.02;
  };

  const formatValue = (value, format) => {
    if (!value) return "-";

    switch (format) {
      case "USD":
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      default:
        return value;
    }
  };

  const getColumnClassName = (key) => {
    if (key.includes("_nbr") || key === "zipCode") return "column-number";
    if (key.includes("bid") || key.includes("value")) return "column-money";
    return "";
  };

  const renderCell = (item, header) => {
    const value = item[header.key];
    let formattedValue;

    if (typeof header.format === "function") {
      formattedValue = header.format(value);
    } else {
      formattedValue = formatValue(value, header.format);
    }

    return header.key === "status" || header.key === "sale_type" ? (
      <Badge $type={header.key}>{formattedValue}</Badge>
    ) : (
      formattedValue
    );
  };

  // Calculate max width for each column based on content
  const getColumnWidths = useCallback(() => {
    const widths = {};

    // Initialize with header widths
    headers.forEach((header) => {
      widths[header.key] = header.label.length * 8; // Base character width
    });

    // Compare with actual data
    items.forEach((item) => {
      headers.forEach((header) => {
        const value = item[header.key];
        let displayValue = value;

        // Format value if needed
        if (header.format === "USD") {
          displayValue = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value);
        } else if (typeof header.format === "function") {
          displayValue = header.format(value);
        }

        const valueLength = String(displayValue || "-").length * 8;
        widths[header.key] = Math.max(widths[header.key], valueLength);
      });
    });

    // Add padding
    Object.keys(widths).forEach((key) => {
      widths[key] += 32; // 16px padding on each side
    });

    return widths;
  }, [headers, items]);

  const columnWidths = useMemo(() => getColumnWidths(), [getColumnWidths]);

  const handleRowClick = (index) => {
    onRowClick?.(index);
  };

  return (
    <TableContainer $containerHeight={CONTAINER_HEIGHT}>
      <Table>
        <TableHead>
          <AnimatePresence mode="wait">
            <motion.tr
              key={`header-${items.length}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {headers.map((header) => (
                <HeaderCell
                  key={header.key}
                  onClick={() => !isPublic && onSort?.(header.key)}
                  className={getColumnClassName(header.key)}
                  $isPublic={isPublic}
                  style={{ width: columnWidths[header.key] }}
                >
                  <HeaderContent>
                    <AnimatePresence mode="wait">
                      <motion.span
                        key={`header-text-${items.length}-${header.key}`}
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ duration: 0.15 }}
                      >
                        {header.label}
                      </motion.span>
                    </AnimatePresence>
                    {!isPublic && sortConfig?.key === header.key && (
                      <motion.div
                        initial={{ rotate: 0 }}
                        animate={{
                          rotate:
                            sortConfig.direction === "ascending" ? 180 : 0,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 15,
                        }}
                      >
                        <ChevronDown size={16} />
                      </motion.div>
                    )}
                  </HeaderContent>
                </HeaderCell>
              ))}
            </motion.tr>
          </AnimatePresence>
        </TableHead>
        <TableBody>
          <AnimatePresence mode="sync">
            {shouldAnimate &&
              displayItems.map((item, index) => (
                <StyledRow
                  key={`${item.uid}-${index}`}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: getLinearDelay(index),
                    duration: 0.2,
                    ease: [0.2, 0.65, 0.3, 0.9],
                  }}
                  onClick={() => handleRowClick(index)}
                  className={
                    isViewedPropertiesMode
                      ? "viewed-row"
                      : viewedItems.has(item.uid)
                      ? "viewed-row"
                      : ""
                  }
                  $rowHeight={ROW_HEIGHT}
                >
                  {headers.map((header, colIndex) => (
                    <TableCell
                      key={`${item.uid}-${header.key}`}
                      className={getColumnClassName(header.key)}
                      style={{ width: columnWidths[header.key] }}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          delay: getLinearDelay(index) + 0.1,
                          duration: 0.2,
                        },
                      }}
                      $rowHeight={ROW_HEIGHT}
                    >
                      <div style={{ position: "relative" }}>
                        {colIndex === 0 &&
                          (isViewedPropertiesMode
                            ? true
                            : viewedItems.has(item.uid)) && (
                            <Tippy
                              content={
                                isViewedPropertiesMode
                                  ? t("lastViewed", {
                                      date: new Date(
                                        item.viewStats.lastViewed
                                      ).toLocaleDateString(),
                                      count: item.viewStats.viewCount,
                                    })
                                  : item.totalViews <= 1
                                  ? t("firstViewer")
                                  : t("viewCount", {
                                      count: item.totalViews - 1,
                                      plural:
                                        item.totalViews - 1 === 1 ? "" : "s",
                                    })
                              }
                              placement="left"
                              animation="shift-away"
                              arrow={true}
                              duration={[300, 250]}
                              delay={[400, 0]}
                              theme="modern"
                              appendTo={() => document.body}
                            >
                              <ViewedIcon>
                                <Eye size={14} />
                              </ViewedIcon>
                            </Tippy>
                          )}
                        <CellContent>{renderCell(item, header)}</CellContent>
                      </div>
                    </TableCell>
                  ))}
                </StyledRow>
              ))}
            {emptyRows > 0 &&
              Array.from({ length: emptyRows }).map((_, index) => (
                <EmptyRow key={`empty-${index}`} $rowHeight={ROW_HEIGHT}>
                  {headers.map((header, colIndex) => (
                    <td key={`empty-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </EmptyRow>
              ))}
          </AnimatePresence>
        </TableBody>
      </Table>

      <AnimatePresence>
        {loading && items.length === 0 && (
          <LoadingOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <EmptyMessage>Loading...</EmptyMessage>
          </LoadingOverlay>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait">
        {!loading && items.length === 0 && (
          <EmptyMessage
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            No items to display
          </EmptyMessage>
        )}
      </AnimatePresence>
    </TableContainer>
  );
};

export default React.memo(DataTable);
