import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { X, ExternalLink, Search, Filter, XCircle, Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { NotesContext } from "../../context/NotesContext";
import { useTheme } from "../../context/ThemeContext";
import ConfirmationModal from "../common/ConfirmationModal";
import Toast from "../common/Toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { motion, AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";
import styled from "styled-components";

const MODAL_PORTAL_CLASS = "notes-modal-portal";

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background: ${({ theme }) =>
    theme.isDarkMode ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.3)"};
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  touch-action: none;
  pointer-events: all;
  user-select: none;
`;

const Modal = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(20px);
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  width: 90vw;
  max-width: 1800px;
  height: 85vh;
  min-height: min(600px, 90vh);
  margin: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent
    );
    border-radius: 24px;
    pointer-events: none;
  }
`;

const ModalHeader = styled.div`
  padding: clamp(12px, 2vw, 20px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.surfaceHover};
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1002;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  position: relative;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  margin-right: 20px;
  min-width: max-content;

  h2 {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    font-size: clamp(20px, 2.5vw, 24px);
    font-weight: 600;
  }
`;

const TitleGradient = styled.span`
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.accent.purple.base} 0%,
    ${({ theme }) => theme.colors.accent.purple.dark} 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
`;

const NoteCount = styled.span`
  font-size: clamp(12px, 1.5vw, 14px);
  background: rgba(136, 83, 251, 0.15);
  padding: 4px 8px;
  border-radius: 6px;
  color: #8853fb;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  min-width: 0;
`;

const SearchInputWrapper = styled(motion.div)`
  flex: 1;
  min-width: 300px;
  max-width: 800px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.surface};
  border: 1.5px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  padding: 0 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: flex;
  box-sizing: border-box;
  margin: 0 auto;
  gap: 12px;

  &.expanded {
    margin-right: 24px;
    flex: 1;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.accent.purple.light};
  }

  svg {
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  input {
    height: 100%;
    padding: 0;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    width: 100%;
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus {
      outline: none;
    }
  }

  @media (max-width: 1200px) {
    min-width: 200px;
  }

  @media (max-width: 992px) {
    min-width: 150px;
  }
`;

const ClearSearchButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  color: rgba(136, 83, 251, 0.6);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    color: rgba(136, 83, 251, 0.9);
  }
`;

const FilterControls = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  transform-style: preserve-3d;
  perspective: 1000px;
  flex-shrink: 0;
  min-width: max-content;
  padding-right: 8px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  flex-shrink: 0;
`;

const FilterToggleButton = styled(motion.button)`
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  color: ${({ theme }) => theme.colors.accent.purple.base};
  width: 36px;
  height: 36px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease;
  position: relative;
  flex-shrink: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
  }

  &.active {
    background: ${({ theme }) => theme.colors.accent.purple.light};
  }
`;

const ModalContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  position: relative;
`;

const NotesGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 4px;
`;

const StyledStackedNotesContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  aspect-ratio: 1.4;
  transform-style: preserve-3d;
  perspective: 1000px;
`;

const NoteCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  backdrop-filter: blur(20px);
  box-shadow: ${({ theme }) => theme.colors.shadow};
  backface-visibility: hidden;
  transform-origin: 0% 100%;
  will-change: transform;

  &.active {
    transform: translateY(-15px) !important;
    filter: brightness(1.05) !important;
    box-shadow: ${({ theme }) => theme.colors.glow} !important;
    z-index: 10 !important;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.accent.purple.light} 0%,
      transparent 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 12px;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }
`;

const NotePropertyInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NoteHeader = styled.div`
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};
  }
`;

const ParcelNumber = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const NoteMessage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NoteContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text};
`;

const NoteTimestamp = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const NoteActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: auto;
  position: relative;
  z-index: 10;
`;

const NotesCountBadge = styled.button`
  background: ${({ theme }) => theme.colors.accent.purple.light};
  border: none;
  padding: 6px 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.accent.purple.base};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.accent.purple.light};
  }
`;

const ViewPropertyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.accent.purple.base};
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  z-index: 11;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    transform: translateY(-1px);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const LoadingMessage = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
`;

const NoNotesMessage = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
`;

const SelectTrigger = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  user-select: none;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  transform-style: preserve-3d;
  will-change: transform, box-shadow;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
    max-width: 100%;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surfaceHover};
    transform: translateY(-1px) translateZ(10px);
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }

  &.active {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};
    background: ${({ theme }) => theme.colors.surfaceHover};
  }
`;

const SelectArrow = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-right: 2px solid ${({ theme }) => theme.colors.accent.purple.base};
  border-bottom: 2px solid ${({ theme }) => theme.colors.accent.purple.base};
  transform: translateY(-70%) rotate(45deg);
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  ${SelectTrigger}.active & {
    transform: translateY(-30%) rotate(-135deg);
  }
`;

const StyledCustomSelect = styled.div`
  position: relative;
  z-index: 1003;
  min-width: 0;
  width: auto;
  background: transparent;
  border: none;
  margin-right: 0;
  transform-style: preserve-3d;

  &.date-filter {
    width: 120px;
    min-width: 120px;
    flex: 0 0 auto;
  }

  &.property-filter {
    width: 300px;
    min-width: 200px;
    flex: 0 0 auto;
  }

  @media (max-width: 1200px) {
    &.date-filter {
      width: 110px;
      min-width: 110px;
    }

    &.property-filter {
      width: 180px;
      min-width: 180px;
    }
  }
`;

const SelectOptions = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border: 1px solid ${({ theme }) => theme.colors.glass.border};
  border-radius: 12px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  z-index: 9999;
  padding: 6px 0;
  margin-top: 4px;
  width: max-content;
  min-width: 260px;
  transform-origin: top;
  will-change: transform, opacity;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme.isDarkMode ? "rgba(18, 18, 24, 0.3)" : "rgba(136, 83, 251, 0.05)"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 4px;
    border: 2px solid
      ${({ theme }) =>
        theme.isDarkMode
          ? "rgba(18, 18, 24, 0.3)"
          : "rgba(136, 83, 251, 0.05)"};
  }
`;

const SelectOption = styled.div`
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  backdrop-filter: blur(10px);
  white-space: nowrap;
  min-width: max-content;

  span {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .checkbox {
    width: 18px;
    height: 18px;
    border: 1.5px solid ${({ theme }) => theme.colors.accent.purple.light};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background: ${({ theme }) => theme.colors.accent.purple.light};
    flex-shrink: 0;

    svg {
      width: 12px;
      height: 12px;
      color: ${({ theme }) => theme.colors.accent.purple.base};
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
    color: ${({ theme }) => theme.colors.text};

    .checkbox {
      border-color: ${({ theme }) => theme.colors.accent.purple.base};
      background: ${({ theme }) => theme.colors.accent.purple.light};
    }
  }

  &.selected {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    color: ${({ theme }) => theme.colors.accent.purple.base};
    font-weight: 500;

    .checkbox {
      border-color: ${({ theme }) => theme.colors.accent.purple.base};
      background: ${({ theme }) => theme.colors.accent.purple.light};

      svg {
        opacity: 1;
      }
    }
  }
`;

const CustomSelect = ({
  value,
  onChange,
  options,
  placeholder,
  className,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [triggerRect, setTriggerRect] = useState(null);
  const selectRef = useRef(null);

  const handleTriggerClick = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      const rect = e.currentTarget.getBoundingClientRect();
      setTriggerRect(rect);
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      const portalElements =
        document.getElementsByClassName(MODAL_PORTAL_CLASS);
      for (let element of portalElements) {
        if (element.contains(event.target)) {
          return;
        }
      }
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <StyledCustomSelect className={className} ref={selectRef} theme={theme}>
      <SelectTrigger
        className={isOpen ? "active" : ""}
        onClick={handleTriggerClick}
        theme={theme}
      >
        <span>
          {options.find((opt) => opt.value === value)?.label || placeholder}
        </span>
        <SelectArrow theme={theme} />
      </SelectTrigger>

      {isOpen &&
        triggerRect &&
        ReactDOM.createPortal(
          <SelectOptions
            className={MODAL_PORTAL_CLASS}
            style={{
              position: "fixed",
              top: `${triggerRect.bottom + 8}px`,
              left: `${triggerRect.left}px`,
              minWidth: "260px",
              width: "auto",
            }}
            theme={theme}
          >
            {options.map((option) => (
              <SelectOption
                key={option.value}
                className={value === option.value ? "selected" : ""}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option.value);
                  setIsOpen(false);
                }}
                theme={theme}
              >
                <span>
                  <div className="checkbox">
                    <Check size={12} />
                  </div>
                  {option.label}
                </span>
              </SelectOption>
            ))}
          </SelectOptions>,
          document.body
        )}
    </StyledCustomSelect>
  );
};

const containerVariants = {
  hidden: {
    opacity: 0,
    scale: 0.98,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.06,
      delayChildren: 0.05,
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.15,
    },
  },
};

const noteVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
    y: 15,
    rotateX: -5,
  },
  show: {
    opacity: 1,
    scale: 1,
    y: 0,
    rotateX: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25,
      mass: 0.5,
      velocity: 2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: -10,
    rotateX: 5,
    transition: {
      duration: 0.15,
      ease: "easeInOut",
    },
  },
};

const CloseOrb = styled(motion.button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.accent.purple.base};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  box-shadow: ${({ theme }) => theme.colors.glow};
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;
  pointer-events: auto;
  color: ${({ theme }) =>
    theme.isDarkMode ? "white" : "rgba(255, 255, 255, 0.95)"};

  &:hover {
    transform: scale(1.05) translateY(-2px);
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

const NotesModal = ({ isOpen, onClose, onViewProperty }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { notes, deleteNote, fetchAllUserNotes } = useContext(NotesContext);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [toast, setToast] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const modalRef = useRef(null);

  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  // Filter states
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState("all");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Get unique properties for the filter dropdown
  const uniqueProperties = useMemo(() => {
    const properties = new Set();

    // Check if notes exists and is an array
    if (!Array.isArray(notes)) {
      return [];
    }

    notes.forEach((note) => {
      if (note?.propertyDetails?.address) {
        properties.add(note.propertyDetails.address);
      }
    });

    return Array.from(properties);
  }, [notes]);

  // Filter notes based on all criteria
  const filteredNotes = useMemo(() => {
    // Check if notes exists and is an array
    if (!Array.isArray(notes)) {
      return [];
    }

    return notes.filter((note) => {
      const matchesSearch =
        note?.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note?.propertyDetails?.address
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

      const matchesProperty =
        !selectedProperty ||
        note?.propertyDetails?.address === selectedProperty;

      const noteDate = new Date(note.createdAt);
      const today = new Date();
      const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      const monthAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

      let matchesDate = true;
      if (dateFilter === "today") {
        matchesDate = noteDate.toDateString() === today.toDateString();
      } else if (dateFilter === "week") {
        matchesDate = noteDate >= weekAgo;
      } else if (dateFilter === "month") {
        matchesDate = noteDate >= monthAgo;
      }

      return matchesSearch && matchesProperty && matchesDate;
    });
  }, [notes, searchTerm, selectedProperty, dateFilter]);

  const handleCancelDelete = () => {
    setIsConfirmOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const confirmationModal = document.querySelector(".confirmation-modal");
      const confirmationOverlay = document.querySelector(
        ".confirmation-modal-overlay"
      );
      const portalElements =
        document.getElementsByClassName(MODAL_PORTAL_CLASS);

      // If confirmation is open, only handle clicks for confirmation
      if (isConfirmOpen) {
        if (
          confirmationModal &&
          !confirmationModal.contains(event.target) &&
          confirmationOverlay &&
          confirmationOverlay.contains(event.target)
        ) {
          handleCancelDelete();
        }
        return; // Stop here if confirmation is open
      }

      // Check if click is within any portal elements
      for (let element of portalElements) {
        if (element.contains(event.target)) {
          return;
        }
      }

      // Only handle modal clicks if confirmation is not open
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, isConfirmOpen]);

  useEffect(() => {
    const loadNotes = async () => {
      if (isOpen) {
        try {
          setIsLoading(true); // Add loading state
          await fetchAllUserNotes();
        } catch (error) {
          console.error("Failed to fetch notes:", error);
          setToast({
            message: t("failedToFetchNotes"),
            type: "error",
            duration: 3000,
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadNotes();
  }, [isOpen, fetchAllUserNotes]);

  const handleViewProperty = async (propertyId, tabIndex = 0) => {
    console.log(
      "NotesModal handleViewProperty called with:",
      propertyId,
      tabIndex
    );
    try {
      await onViewProperty(propertyId, tabIndex);
    } catch (error) {
      console.error("Failed to view property:", error);
      setToast({
        message: t("failedToFetchDetails"),
        type: "error",
        duration: 3000,
      });
    }
  };

  const confirmDeleteNote = async () => {
    if (noteToDelete) {
      try {
        await deleteNote(noteToDelete._id);
        setToast({
          message: t("noteDeletedSuccessfully"),
          type: "success",
          duration: 3000,
        });
      } catch (error) {
        setToast({
          message: t("failedToDeleteNote"),
          type: "error",
          duration: 3000,
        });
      }
    }
    setIsConfirmOpen(false);
    setNoteToDelete(null);
  };

  const groupNotesByProperty = (notes) => {
    return notes.reduce((acc, note) => {
      const propertyId = note.propertyId;
      if (!acc[propertyId]) {
        acc[propertyId] = [];
      }
      acc[propertyId].push(note);
      return acc;
    }, {});
  };

  const limitNotesPerProperty = (groupedNotes, limit = 3) => {
    return Object.entries(groupedNotes).reduce((acc, [propertyId, notes]) => {
      acc[propertyId] = notes
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, limit);
      return acc;
    }, {});
  };

  const truncateText = (text, maxLength = 30) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={handleOverlayClick} theme={theme}>
        <Modal
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
          theme={theme}
        >
          <ModalHeader theme={theme}>
            <HeaderContent>
              <TitleSection>
                <h2>
                  <TitleGradient>Notes</TitleGradient>
                  <NoteCount theme={theme}>{filteredNotes.length}</NoteCount>
                </h2>
              </TitleSection>

              <HeaderControls className={isFilterOpen ? "expanded" : ""}>
                <SearchInputWrapper
                  className={isFilterOpen ? "expanded" : ""}
                  animate={{
                    flex: isFilterOpen ? 2 : 3,
                    marginRight: isFilterOpen ? "12px" : "0",
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 30,
                  }}
                  theme={theme}
                >
                  <Search size={16} />
                  <input
                    type="text"
                    placeholder={t("searchNotes")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm && (
                    <ClearSearchButton
                      onClick={() => setSearchTerm("")}
                      theme={theme}
                    >
                      <XCircle size={16} />
                    </ClearSearchButton>
                  )}
                </SearchInputWrapper>

                <AnimatePresence>
                  {isFilterOpen && (
                    <FilterControls
                      initial={{ width: 0, opacity: 0, x: 40, scaleX: 0 }}
                      animate={{
                        width: "auto",
                        opacity: 1,
                        x: 0,
                        scaleX: 1,
                      }}
                      exit={{
                        width: 0,
                        opacity: 0,
                        x: 40,
                        scaleX: 0,
                      }}
                      theme={theme}
                    >
                      <motion.div
                        initial={{ x: 40 }}
                        animate={{ x: 0 }}
                        exit={{ x: 40 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                        style={{
                          display: "flex",
                          gap: "12px",
                          minWidth: "max-content",
                        }}
                      >
                        <CustomSelect
                          className="date-filter"
                          value={dateFilter}
                          onChange={setDateFilter}
                          options={[
                            { value: "all", label: t("allTime") },
                            { value: "today", label: t("today") },
                            { value: "week", label: t("pastWeek") },
                            { value: "month", label: t("pastMonth") },
                          ]}
                          placeholder={t("filterNotes")}
                          theme={theme}
                        />

                        <CustomSelect
                          className="property-filter"
                          value={selectedProperty}
                          onChange={setSelectedProperty}
                          options={[
                            { value: "", label: t("allProperties") },
                            ...uniqueProperties.map((property) => ({
                              value: property,
                              label: truncateText(property, 40),
                            })),
                          ]}
                          placeholder={t("selectProperty")}
                          theme={theme}
                        />
                      </motion.div>
                    </FilterControls>
                  )}
                </AnimatePresence>

                <HeaderActions>
                  <Tippy
                    content={t("toggleFilters")}
                    placement="bottom"
                    animation="shift-away"
                    arrow={true}
                    duration={[300, 250]}
                    delay={[400, 0]}
                    theme="modern"
                  >
                    <FilterToggleButton
                      className={isFilterOpen ? "active" : ""}
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                      animate={{
                        backgroundColor: isFilterOpen
                          ? theme.colors.accent.purple.light
                          : theme.colors.buttonGradient,
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 25,
                      }}
                      theme={theme}
                    >
                      <Filter size={20} />
                    </FilterToggleButton>
                  </Tippy>
                </HeaderActions>
              </HeaderControls>
            </HeaderContent>
          </ModalHeader>

          <ModalContent>
            <AnimatePresence mode="wait">
              {isLoading ? (
                <LoadingMessage
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  theme={theme}
                >
                  {t("loading")}
                </LoadingMessage>
              ) : !Array.isArray(notes) || notes.length === 0 ? (
                <NoNotesMessage
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  theme={theme}
                >
                  {searchTerm || dateFilter !== "all" || selectedProperty
                    ? t("noNotesMatchFilters")
                    : t("noNotesFound")}
                </NoNotesMessage>
              ) : (
                <NotesGrid
                  key={`grid-${filteredNotes.length}-${searchTerm}-${dateFilter}-${selectedProperty}`}
                  variants={containerVariants}
                  initial="hidden"
                  animate="show"
                  exit="exit"
                  layout
                  theme={theme}
                >
                  {Object.entries(
                    limitNotesPerProperty(groupNotesByProperty(filteredNotes))
                  ).map(([propertyId, propertyNotes]) => {
                    // Calculate total notes for this property
                    const totalNotes =
                      groupNotesByProperty(filteredNotes)[propertyId]?.length ||
                      0;

                    return (
                      <StyledStackedNotesContainer
                        key={propertyId}
                        variants={noteVariants}
                        layout
                        theme={theme}
                      >
                        {propertyNotes.map((note, index) => (
                          <NoteCard
                            key={note._id}
                            className={
                              selectedNote === note._id ? "active" : ""
                            }
                            initial={false}
                            layout
                            animate={{
                              scale: selectedNote === note._id ? 1.02 : 1,
                              y: selectedNote === note._id ? -15 : 0,
                              zIndex: propertyNotes.length - index,
                            }}
                            theme={theme}
                          >
                            <NotePropertyInfo>
                              <NoteHeader>
                                <h3 title={note.propertyDetails.address}>
                                  {truncateText(
                                    note.propertyDetails.address,
                                    30
                                  )}
                                </h3>
                              </NoteHeader>
                              <ParcelNumber>
                                {t("parcelNumber")}:{" "}
                                {note.propertyDetails.parcelNumber}
                              </ParcelNumber>
                            </NotePropertyInfo>
                            <NoteMessage>
                              <NoteContent>{note.text}</NoteContent>
                              <NoteTimestamp>
                                {new Date(note.createdAt).toLocaleString()}
                              </NoteTimestamp>
                            </NoteMessage>
                            <NoteActions>
                              {totalNotes > 1 && (
                                <Tippy
                                  content={t("clickToViewAllNotes")}
                                  placement="top"
                                  animation="shift-away"
                                  arrow={true}
                                  duration={[300, 250]}
                                  delay={[400, 0]}
                                  theme="modern"
                                >
                                  <NotesCountBadge
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleViewProperty(note.propertyId, 5);
                                    }}
                                    theme={theme}
                                  >
                                    {totalNotes}{" "}
                                    {t("notes").charAt(0).toUpperCase() +
                                      t("notes").slice(1)}
                                  </NotesCountBadge>
                                </Tippy>
                              )}
                              <ViewPropertyButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleViewProperty(note.propertyId);
                                }}
                                theme={theme}
                              >
                                <ExternalLink size={16} />
                                {t("viewProperty")}
                              </ViewPropertyButton>
                            </NoteActions>
                          </NoteCard>
                        ))}
                      </StyledStackedNotesContainer>
                    );
                  })}
                </NotesGrid>
              )}
            </AnimatePresence>
          </ModalContent>
        </Modal>
        <ConfirmationModal
          isOpen={isConfirmOpen}
          message={t("areYouSureDeleteNote")}
          onConfirm={confirmDeleteNote}
          onClose={handleCancelDelete}
        />
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            duration={toast.duration}
            onClose={() => setToast(null)}
          />
        )}
      </ModalOverlay>

      <CloseOrb
        onClick={onClose}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        whileHover={{ scale: 1.05, y: -2 }}
        whileTap={{ scale: 0.95 }}
        theme={theme}
      >
        <X size={24} />
      </CloseOrb>
    </>
  );
};

export default NotesModal;
