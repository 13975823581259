import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root {
  /* Dark theme variables */
  --dark-background: linear-gradient(180deg, #16161a 0%, #121216 100%);
  --dark-text: rgba(255, 255, 255, 0.95);
  --dark-surface: linear-gradient(165deg, rgba(28, 28, 35, 0.85), rgba(18, 18, 24, 0.95));
  --dark-border: rgba(255, 255, 255, 0.07);
  --dark-shadow: 0 8px 32px rgba(0, 0, 0, 0.4), 0 2px 8px rgba(0, 0, 0, 0.2);
  --dark-secondary: rgba(255, 255, 255, 0.7);
  --dark-glow: 0 0 20px rgba(136, 83, 251, 0.2);
  
  /* Light theme variables */
  --light-background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
  --light-text: rgba(0, 0, 0, 0.95);
  --light-surface: linear-gradient(165deg, rgba(255, 255, 255, 0.85), rgba(250, 250, 250, 0.95));
  --light-border: rgba(0, 0, 0, 0.07);
  --light-shadow: 0 8px 32px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.05);
  --light-secondary: rgba(0, 0, 0, 0.7);
  --light-glow: 0 0 20px rgba(136, 83, 251, 0.1);
  
  /* Common variables */
  --primary: #8853fb;
  --primary-dark: #6039b0;
  --primary-light: rgba(136, 83, 251, 0.1);
  --glass-blur: 40px;
  
  /* Interactive States */
  --hover-background: rgba(136, 83, 251, 0.1);
  --active-background: rgba(136, 83, 251, 0.15);
  --focus-ring: 0 0 0 2px rgba(136, 83, 251, 0.3);
}

/* Global transition for theme changes */
* {
  transition: background-color 0.2s ease,
              border-color 0.2s ease,
              box-shadow 0.2s ease,
              color 0.2s ease;
}

html,
body {
  background: var(--background-color);
  height: 100%;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  letter-spacing: -0.01em;
}

/* Theme-specific styles based on data-theme attribute */
[data-theme='light'] {
  --background-color: var(--light-background);
  --text-color: var(--light-text);
  --surface-color: var(--light-surface);
  --border-color: var(--light-border);
  --shadow: var(--light-shadow);
  --secondary-color: var(--light-secondary);
  --glow: var(--light-glow);
}

[data-theme='dark'] {
  --background-color: var(--dark-background);
  --text-color: var(--dark-text);
  --surface-color: var(--dark-surface);
  --border-color: var(--dark-border);
  --shadow: var(--dark-shadow);
  --secondary-color: var(--dark-secondary);
  --glow: var(--dark-glow);
}

/* Apply the theme to the root element */
#root {
  min-height: 100vh;
  background: var(--background-color);
  color: var(--text-color);
}

.App {
  background: var(--background-color);
  min-height: 100vh;
  position: relative;
}

.App::before {
  content: '';
  position: fixed;
  inset: 0;
  background: radial-gradient(
    circle at top right,
    rgba(136, 83, 251, 0.08),
    transparent 70%
  );
  pointer-events: none;
}

/* Center text only in specific components */
.App-header,
.landing-page,
.auth-container {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  filter: drop-shadow(0 0 20px rgba(136, 83, 251, 0.2));
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: var(--surface-color);
  backdrop-filter: blur(var(--glass-blur));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
  position: relative;
  overflow: hidden;
}

.App-header::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at top right,
    rgba(136, 83, 251, 0.08),
    transparent 70%
  );
  pointer-events: none;
}

.App-link {
  color: var(--primary);
  text-decoration: none;
  transition: color 0.2s ease;
}

.App-link:hover {
  color: var(--primary-dark);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Glass Card Effect */
.glass-card {
  background: var(--surface-color);
  backdrop-filter: blur(var(--glass-blur));
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.glass-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at top right,
    rgba(136, 83, 251, 0.08),
    transparent 70%
  );
  pointer-events: none;
}

/* Interactive Elements */
.interactive-element {
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

.interactive-element:hover {
  background: var(--hover-background);
}

.interactive-element:active {
  background: var(--active-background);
}

.interactive-element:focus-visible {
  outline: none;
  box-shadow: var(--focus-ring);
}

/* DC Map fix */
#DC circle {
  display: none !important;
}

#DC path {
  fill: none !important;
  stroke: rgba(136, 83, 251, 0.4);
  stroke-width: 1.5px;
  vector-effect: non-scaling-stroke;
  filter: drop-shadow(0 0 3px rgba(136, 83, 251, 0.3));
}

/* Global text selection styles */
::selection {
  background: rgba(136, 83, 251, 0.2);
  color: white;
  text-shadow: 0 0 8px rgba(136, 83, 251, 0.5);
}

::-moz-selection {
  background: rgba(136, 83, 251, 0.2);
  color: white;
  text-shadow: 0 0 8px rgba(136, 83, 251, 0.5);
}
`;

export default GlobalStyles;
