// src/common/CTASection.js

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { keyframes } from "styled-components";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import SwarmAlive from "./SwarmAlive";

gsap.registerPlugin(ScrollTrigger);

const wobble = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(1.02) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.98) rotate(-1deg);
  }
  75% {
    transform: translate(-50%, -50%) scale(1.02) rotate(0.5deg);
  }
`;

const pulseNeon = keyframes`
  0%, 100% {
    box-shadow: 0 0 15px rgba(136, 83, 251, 0.7),
                0 0 30px rgba(136, 83, 251, 0.5),
                0 0 45px rgba(136, 83, 251, 0.3),
                inset 0 0 15px rgba(136, 83, 251, 0.7),
                inset 0 0 30px rgba(136, 83, 251, 0.5);
    border-color: rgba(136, 83, 251, 0.8);
  }
  50% {
    box-shadow: 0 0 25px rgba(136, 83, 251, 0.9),
                0 0 50px rgba(136, 83, 251, 0.7),
                0 0 75px rgba(136, 83, 251, 0.5),
                inset 0 0 25px rgba(136, 83, 251, 0.9),
                inset 0 0 50px rgba(136, 83, 251, 0.7);
    border-color: rgba(136, 83, 251, 1);
  }
`;
const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(136, 83, 251, 0.3) 0%,
    rgba(136, 83, 251, 0.1) 20%,
    transparent 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.5);
  box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
  opacity: 0.9;
  will-change: transform, opacity;
`;

const highlightFloat = keyframes`
  0%, 100% {
    transform: translate(0, 0);
    opacity: 0.3;
  }
  50% {
    transform: translate(1px, 1px);
    opacity: 0.4;
  }
`;
const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4),
    transparent 80%
  );
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: ${highlightFloat} 4s ease-in-out infinite;
`;
const SectionContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background: linear-gradient(
    180deg,
    rgba(13, 13, 13, 0) 0%,
    rgba(13, 13, 13, 0.8) 100%
  );
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: center;
  position: relative;
`;

const Title = styled.h2`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0.8) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  letter-spacing: -0.02em;
`;

const Description = styled.p`
  font-size: clamp(1.125rem, 2vw, 1.25rem);
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 3rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transform: translateY(20px);
`;

const CTAButton = styled.button`
  background: linear-gradient(135deg, #8853fb 0%, #6c3ed0 100%);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 16px 40px;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  transform: translateY(20px);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: -2px;
    background: linear-gradient(135deg, #8853fb 0%, #6c3ed0 100%);
    filter: blur(10px);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(136, 83, 251, 0.3);

    &::before {
      opacity: 0.5;
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const ParticleWrapper = styled.div`
  position: relative;
  // width: 600px;
  // height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  // animation: ${wobble} 10s ease-in-out infinite;
`;

const BubbleContainer = styled.div`
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 2px solid rgba(136, 83, 251, 0.8);
  box-shadow: 0 0 15px rgba(136, 83, 251, 0.7), 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(136, 83, 251, 0.7),
    inset 0 0 30px rgba(136, 83, 251, 0.5);
  animation: ${pulseNeon} 2s ease-in-out infinite;
  background: rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(5px);
  will-change: transform, box-shadow, border-color;
`;

const CTASection = ({ onGetStarted }) => {
  const sectionRef = useRef(null);
  const logoRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const buttonRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const section = sectionRef.current;
    const logo = logoRef.current;
    const title = titleRef.current;
    const description = descriptionRef.current;
    const button = buttonRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
        onEnter: () => setIsVisible(true),
        onLeaveBack: () => setIsVisible(false),
      },
    });

    tl.to(logo, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power3.out",
    })
      .to(
        title,
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        },
        "-=0.5"
      )
      .to(
        description,
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        },
        "-=0.5"
      )
      .to(
        button,
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power3.out",
        },
        "-=0.5"
      );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef} data-scroll-section>
      <ContentWrapper>
        <ParticleWrapper>
          <BubbleContainer>
            <BubbleSkin
              animate={{
                scale: 1.02,
                opacity: 0.9,
              }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 20,
              }}
            />
            <Highlight />
          </BubbleContainer>
          <SwarmAlive
            size="350px"
            enableHover={true}
            active={true}
            mode="normal"
            instanceId="cta-section-swarm"
          />
        </ParticleWrapper>
        <Title ref={titleRef}>Join DeedFinders Today</Title>
        <Description ref={descriptionRef}>
          Ready to streamline your tax deed and lien research? Get organized,
          stay informed, and move forward with confidence—all in one place.
        </Description>
        <CTAButton ref={buttonRef} onClick={onGetStarted}>
          Get Started
        </CTAButton>
      </ContentWrapper>
    </SectionContainer>
  );
};

export default CTASection;
