import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { AlertTriangle, CreditCard, X as CloseIcon, ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const ModalContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 480px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(255, 59, 48, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(255, 59, 48, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.15),
    rgba(255, 59, 48, 0.1)
  );
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3b30;
  border: 1px solid rgba(255, 59, 48, 0.2);
`;

const Title = styled.h2`
  font-size: 24px;
  color: #fff;
  margin: 0;
  flex: 1;
`;

const Message = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  line-height: 1.6;
  margin: 0 0 24px 0;
`;

const ActionButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.15),
    rgba(255, 59, 48, 0.1)
  );
  border: 1px solid rgba(255, 59, 48, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.2),
      rgba(255, 59, 48, 0.15)
    );
    border-color: rgba(255, 59, 48, 0.4);
    box-shadow: 
      0 8px 32px rgba(255, 59, 48, 0.2),
      0 0 0 1px rgba(255, 59, 48, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(255, 59, 48, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(255, 59, 48, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const RetryProgress = styled.div`
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.08),
    rgba(255, 59, 48, 0.05)
  );
  border: 1px solid rgba(255, 59, 48, 0.15);
  border-radius: 12px;
  padding: 16px;
  margin: 16px 0;

  h4 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    margin: 0 0 12px 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(255, 59, 48, 0.1);
  border-radius: 2px;
  overflow: hidden;
  margin: 8px 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => (props.$progress * 100)}%;
    background: linear-gradient(
      to right,
      rgba(255, 59, 48, 0.5),
      rgba(255, 59, 48, 0.8)
    );
    border-radius: 2px;
    transition: width 0.3s ease;
  }
`;

const RetryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  
  span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 24px;
`;

const SecondaryButton = styled(ActionButton)`
  background: linear-gradient(
    165deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.1)
    );
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 
      0 8px 32px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(255, 255, 255, 0.1);
  }
`;

const PaymentFailureModal = ({ 
  open, 
  onClose, 
  error, 
  nextAttempt,
  onUpdatePaymentMethod,
  onRetryPayment,
  retryCount = 0,
  maxRetries = 3
}) => {
  const { t } = useTranslation();

  if (!open) return null;

  const formatNextAttempt = (date) => {
    if (!date) return null;
    return new Date(date).toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  };

  const progress = retryCount / maxRetries;
  const retriesRemaining = maxRetries - retryCount;

  return (
    <AnimatePresence>
      {open && (
        <ModalOverlay
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <CloseButton onClick={onClose}>
              <CloseIcon size={24} />
            </CloseButton>

            <ModalHeader>
              <IconWrapper>
                <AlertTriangle size={24} />
              </IconWrapper>
              <Title>{t("paymentFailed")}</Title>
            </ModalHeader>

            <Message>
              {error?.message || t("paymentFailedDefaultMessage")}
            </Message>

            <RetryProgress>
              <h4>
                <CreditCard size={16} />
                {t("paymentRetryStatus")}
              </h4>
              <ProgressBar $progress={progress} />
              <RetryInfo>
                <span>
                  {retriesRemaining > 0
                    ? t("retriesRemaining", { count: retriesRemaining })
                    : t("noRetriesRemaining")}
                </span>
                {nextAttempt && (
                  <span>
                    {t("nextAttempt")}: {formatNextAttempt(nextAttempt)}
                  </span>
                )}
              </RetryInfo>
            </RetryProgress>

            <ButtonGroup>
              <SecondaryButton 
                onClick={onRetryPayment}
                disabled={retryCount >= maxRetries}
              >
                {t("retryNow")}
                <ArrowRight size={18} />
              </SecondaryButton>
              <ActionButton onClick={onUpdatePaymentMethod}>
                {t("updatePaymentMethod")}
                <ArrowRight size={18} />
              </ActionButton>
            </ButtonGroup>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default PaymentFailureModal; 