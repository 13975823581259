// PropertyDetails.jsx

import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Tabs, Tab } from "../../common/Tabs";
import DetailsTab from "./DetailsTab";
import SalesTab from "./SalesTab";
import FAQTab from "./FAQTab";
import OverviewTab from "./OverviewTab";
import NotesTab from "./NotesTab";
import ResourcesTab from "./ResourcesTab";
import { useTranslation } from "react-i18next";

// Styled Components

const PropertyDetailsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: transparent;
  overflow: hidden;
  position: relative;
`;

const TabContentWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.glass.border};
  margin: 0 12px 12px 12px;
`;

const TabContent = styled(motion.div)`
  height: 100%;
  color: ${({ theme }) => theme.colors.text};
  inset: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background: transparent;
  will-change: transform, opacity;
  // padding: 12px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ theme }) => `linear-gradient(
      165deg,
      ${
        theme.isDarkMode
          ? "rgba(255, 255, 255, 0.03)"
          : "rgba(255, 255, 255, 0.5)"
      },
      transparent
    )`};
    pointer-events: none;
  }
`;

// Animation variants
const contentVariants = {
  enter: (direction) => ({
    x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
    position: "absolute",
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    position: "relative",
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? "100%" : "-100%",
    opacity: 0,
    position: "absolute",
  }),
};

const PropertyDetails = ({
  property,
  tabIndex,
  setTabIndex,
  isTranslating = false,
  isPublic,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [[page, direction], setPage] = useState([0, 0]);
  const [isExiting, setIsExiting] = useState(false);
  const isMounted = useRef(true);
  const previousTab = useRef(activeTab);

  // Move faqItems definition before tabs
  const faqItems = useMemo(() => {
    if (!property) return [];

    if (property?.faq?._doc) {
      return property.faq._doc.customFAQ || property.faq._doc.faq || [];
    }
    return property?.faq?.customFAQ || property?.faq?.faq || [];
  }, [property]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (typeof tabIndex === "number" && isMounted.current) {
      const direction = tabIndex > previousTab.current ? 1 : -1;
      previousTab.current = tabIndex;
      setPage([tabIndex, direction]);
      setActiveTab(tabIndex);
    }
  }, [tabIndex]);

  const handleTabChange = (newTab) => {
    if (isTranslating) {
      return;
    }
    const direction = newTab > activeTab ? 1 : -1;
    previousTab.current = activeTab;
    setPage([newTab, direction]);
    setActiveTab(newTab);
    if (setTabIndex) {
      setTabIndex(newTab);
    }
  };

  if (!property) {
    return null;
  }

  // Define tabs after faqItems
  const tabs = [
    {
      label: "Overview",
      tooltip: "propertyOverview",
      content: (
        <OverviewTab property={property} isTranslating={isTranslating} />
      ),
    },
    {
      label: "Details",
      tooltip: "propertyDetails",
      content: <DetailsTab property={property} isTranslating={isTranslating} />,
    },
    {
      label: "Sales",
      tooltip: "salesInformation",
      content: (
        <SalesTab sales={property?.sales || []} isTranslating={isTranslating} />
      ),
    },
    {
      label: "FAQ",
      tooltip: "frequentlyAskedQuestions",
      content: <FAQTab faqItems={faqItems} isTranslating={isTranslating} />,
    },
  ];

  if (property.overview?.county && property.overview?.state) {
    tabs.push({
      label: "Resources",
      tooltip: "resources",
      content: (
        <ResourcesTab
          county={property.overview.county}
          state={property.overview.state}
          isTranslating={isTranslating}
          isActive={activeTab === 4}
          property={property}
        />
      ),
    });
  }

  tabs.push({
    label: "Notes",
    tooltip: "myPropertyNotes",
    content: (
      <NotesTab expandedData={property.overview || {}} isPublic={isPublic} />
    ),
  });

  const renderTabContent = (tab) => {
    const content = tabs[tab]?.content;
    return content;
  };

  return (
    <PropertyDetailsContainer
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Tabs activeTab={activeTab} onTabClick={handleTabChange}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            tooltip={tab.tooltip}
            disabled={isTranslating}
          />
        ))}
      </Tabs>
      <TabContentWrapper>
        <AnimatePresence
          mode="wait"
          initial={false}
          custom={direction}
          onExitComplete={() => {
            if (isMounted.current) {
              setIsExiting(false);
            }
          }}
        >
          <TabContent
            key={page}
            custom={direction}
            variants={contentVariants}
            initial="enter"
            animate="center"
            exit="exit"
            onAnimationStart={() => {
              if (isMounted.current) {
                setIsExiting(true);
              }
            }}
            onAnimationComplete={() => {
              if (isMounted.current) {
                setIsExiting(false);
              }
            }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            {renderTabContent(activeTab)}
          </TabContent>
        </AnimatePresence>
      </TabContentWrapper>
    </PropertyDetailsContainer>
  );
};

export default React.memo(PropertyDetails);
