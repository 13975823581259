import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  Users,
  UserPlus,
  UserCheck,
  UserX,
  Clock,
  X,
  Shield,
} from "react-feather";
import FriendsList from "./FriendsList";
import FriendsSearch from "./FriendsSearch";
import {
  getFriendsList,
  getPendingRequests,
  respondToRequest,
  cancelFriendRequest,
} from "./friendsApi";
import { useToast } from "../../context/ToastContext";
import { useTheme } from "../../context/ThemeContext";
import LoadingSpinner from "../common/LoadingSpinner";
import ConfirmationModal from "../common/ConfirmationModal";

const FriendsContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 24px;
  height: calc(100vh - 100px);
  padding: 24px;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => `
    0 0 0 1px ${theme.colors.glass.border},
    ${theme.colors.shadow},
    ${theme.colors.glow}
  `};
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const Sidebar = styled.div`
  background: ${({ theme }) => theme.colors.surfaceHover};
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 280px;
  min-width: 280px;
  height: 90%;
  border-radius: 12px;
`;

const MainContent = styled.div`
  padding: 24px;
  overflow-y: auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.border};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent.purple.light};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.accent.purple.base};
    }
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Tab = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: ${({ theme, $active }) =>
    $active ? theme.colors.buttonGradient : "transparent"};
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.accent.purple.light : theme.colors.border};
  border-radius: 12px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.text : theme.colors.secondary};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  .tab-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.light};
    transform: translateY(-1px);
  }

  span {
    background: ${({ theme, $active }) =>
      $active
        ? `linear-gradient(45deg, ${theme.colors.accent.purple.base}, ${theme.colors.accent.purple.dark})`
        : theme.colors.buttonGradient};
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    min-width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ $active }) => ($active ? "#fff" : "inherit")};
  }
`;

const AddFriendButton = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:hover {
    transform: translateY(-1px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const RequestsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const RequestCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  padding: 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 2px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      color: ${({ theme }) => theme.colors.text};
    }

    p {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 12px;
      margin: 0;
    }
  }

  .status-actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .message {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.secondary};
    padding: 8px 12px;
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
    margin-top: 4px;
    font-style: italic;
  }
`;

const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 11px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  white-space: nowrap;

  svg {
    width: 12px;
    height: 12px;
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

const CancelButton = styled(motion.button)`
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-color: ${({ theme }) => theme.colors.accent.purple.light};
    transform: translateY(-1px);
  }

  svg {
    width: 12px;
    height: 12px;
    color: ${({ theme }) => theme.colors.accent.error.base};
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 48px;
  color: ${({ theme }) => theme.colors.secondary};

  svg {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    stroke: ${({ theme }) => theme.colors.accent.purple.light};
  }

  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text};
    margin: 0 0 8px 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 2rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 32px;
    height: 32px;
    stroke: #8853fb;
  }
`;

const RequestButton = styled(motion.button)`
  padding: 8px 16px;
  background: ${(props) => {
    if (props.$accept) return "rgba(80, 200, 120, 0.1)";
    if (props.$cancel) return "rgba(255, 255, 255, 0.05)";
    return "rgba(255, 59, 48, 0.1)";
  }};
  border: 1px solid
    ${(props) => {
      if (props.$accept) return "rgba(80, 200, 120, 0.3)";
      if (props.$cancel) return "rgba(255, 255, 255, 0.1)";
      return "rgba(255, 59, 48, 0.3)";
    }};
  border-radius: 8px;
  color: ${(props) => {
    if (props.$accept) return "rgb(80, 200, 120)";
    if (props.$cancel) return "rgba(255, 255, 255, 0.8)";
    return "rgb(255, 59, 48)";
  }};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    transform: translateY(-1px);
    background: ${(props) => {
      if (props.$accept) return "rgba(80, 200, 120, 0.15)";
      if (props.$cancel) return "rgba(255, 255, 255, 0.08)";
      return "rgba(255, 59, 48, 0.15)";
    }};
    border-color: ${(props) => {
      if (props.$accept) return "rgba(80, 200, 120, 0.4)";
      if (props.$cancel) return "rgba(255, 255, 255, 0.15)";
      return "rgba(255, 59, 48, 0.4)";
    }};
  }
`;

const Friends = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("friends");
  const [friends, setFriends] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const { showToast } = useToast();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [friendsData, requestsData] = await Promise.all([
          getFriendsList(),
          getPendingRequests(),
        ]);

        setFriends(friendsData);
        setReceivedRequests(requestsData.received || []);
        setSentRequests(requestsData.sent || []);
      } catch (error) {
        console.error("Error loading friends data:", error);
        showToast("Failed to load friends data", "error");
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [showToast]);

  const handleAcceptRequest = async (requestId) => {
    try {
      await respondToRequest(requestId, "accepted");
      setReceivedRequests((prev) => prev.filter((r) => r._id !== requestId));
      showToast("Friend request accepted", "success");
    } catch (error) {
      showToast("Failed to accept request", "error");
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      await respondToRequest(requestId, "rejected");
      setReceivedRequests((prev) => prev.filter((r) => r._id !== requestId));
      showToast("Friend request rejected", "success");
    } catch (error) {
      showToast("Failed to reject request", "error");
    }
  };

  const handleCancelRequest = async (request) => {
    setSelectedRequest(request);
    setShowCancelConfirmation(true);
  };

  const handleConfirmCancel = async () => {
    try {
      await cancelFriendRequest(selectedRequest._id);
      setSentRequests((prev) =>
        prev.filter((r) => r._id !== selectedRequest._id)
      );
      showToast("Friend request canceled", "success");
    } catch (error) {
      showToast("Failed to cancel request", "error");
    } finally {
      setShowCancelConfirmation(false);
      setSelectedRequest(null);
    }
  };

  const handleFriendUpdate = (friendId, updates) => {
    setFriends(
      friends.map((friend) =>
        friend._id === friendId ? { ...friend, ...updates } : friend
      )
    );
  };

  const renderContent = () => {
    if (isLoading) return <LoadingSpinner />;

    switch (activeTab) {
      case "friends":
        return friends.filter((f) => f.status === "accepted").length > 0 ? (
          <FriendsList
            friends={friends.filter((f) => f.status === "accepted")}
            onFriendRemove={(friendId) =>
              setFriends(friends.filter((f) => f._id !== friendId))
            }
            onFriendUpdate={handleFriendUpdate}
          />
        ) : (
          <EmptyState>
            <Users />
            <h3>{t("noFriendsYet")}</h3>
            <p>{t("startAddingFriends")}</p>
          </EmptyState>
        );

      case "received":
        return receivedRequests.length > 0 ? (
          <RequestsContainer>
            {receivedRequests.map((request) => (
              <RequestCard
                key={request._id}
                theme={theme}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <div className="header">
                  <div className="user-info">
                    <h3>{request.from.name}</h3>
                    <p>{request.from.email}</p>
                  </div>
                  <div className="status-actions">
                    <RequestButton
                      $accept
                      onClick={() => handleAcceptRequest(request._id)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <UserCheck size={12} />
                      {t("acceptFriendRequest")}
                    </RequestButton>
                    <RequestButton
                      onClick={() => handleRejectRequest(request._id)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <UserX size={12} />
                      {t("declineFriendRequest")}
                    </RequestButton>
                  </div>
                </div>
                {request.message && (
                  <div className="message">{request.message}</div>
                )}
              </RequestCard>
            ))}
          </RequestsContainer>
        ) : (
          <EmptyState theme={theme}>
            <UserCheck />
            <h3>{t("noFriendRequests")}</h3>
            <p>{t("noFriendRequests")}</p>
          </EmptyState>
        );

      case "sent":
        return sentRequests.length > 0 ? (
          <RequestsContainer>
            {sentRequests.map((request) => (
              <RequestCard
                key={request._id}
                theme={theme}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <div className="header">
                  <div className="user-info">
                    <h3>{request.to.name}</h3>
                    <p>{request.to.email}</p>
                  </div>
                  <div className="status-actions">
                    <StatusBadge>
                      <Clock size={12} />
                      {t("friendStatus.pending")}
                    </StatusBadge>
                    <CancelButton
                      onClick={() => handleCancelRequest(request)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <X size={12} />
                      {t("cancelFriendRequest")}
                    </CancelButton>
                  </div>
                </div>
                {request.message && (
                  <div className="message">{request.message}</div>
                )}
              </RequestCard>
            ))}
          </RequestsContainer>
        ) : (
          <EmptyState theme={theme}>
            <Clock />
            <h3>{t("noFriendRequests")}</h3>
            <p>{t("noFriendRequests")}</p>
          </EmptyState>
        );

      case "blocked":
        const blockedFriends = friends.filter((f) => f.status === "blocked");
        return blockedFriends.length > 0 ? (
          <FriendsList
            friends={blockedFriends}
            onFriendRemove={(friendId) =>
              setFriends(friends.filter((f) => f._id !== friendId))
            }
            onFriendUpdate={handleFriendUpdate}
          />
        ) : (
          <EmptyState theme={theme}>
            <Shield />
            <h3>{t("noBlockedUsers")}</h3>
            <p>{t("noBlockedUsersYet")}</p>
          </EmptyState>
        );

      default:
        return null;
    }
  };

  return (
    <FriendsContainer theme={theme}>
      <Sidebar theme={theme}>
        <Header>
          <Title>
            <Users />
            {t("friends")}
          </Title>
        </Header>

        <TabsContainer>
          <Tab
            $active={activeTab === "friends"}
            onClick={() => setActiveTab("friends")}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Users size={16} />
              {t("friends")}
            </div>
            <span>{friends.filter((f) => f.status !== "blocked").length}</span>
          </Tab>
          <Tab
            $active={activeTab === "received"}
            onClick={() => setActiveTab("received")}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <UserCheck size={16} />
              {t("friendRequests")}
            </div>
            <span>{receivedRequests.length}</span>
          </Tab>
          <Tab
            $active={activeTab === "sent"}
            onClick={() => setActiveTab("sent")}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Clock size={16} />
              {t("sent")}
            </div>
            <span>{sentRequests.length}</span>
          </Tab>
          <Tab
            $active={activeTab === "blocked"}
            onClick={() => setActiveTab("blocked")}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Shield size={16} />
              {t("blocked")}
            </div>
            <span>{friends.filter((f) => f.status === "blocked").length}</span>
          </Tab>
        </TabsContainer>

        <AddFriendButton
          onClick={() => setShowSearch(true)}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <UserPlus />
          {t("sendFriendRequest")}
        </AddFriendButton>
      </Sidebar>

      <MainContent theme={theme}>
        <AnimatePresence mode="wait">{renderContent()}</AnimatePresence>
      </MainContent>

      <FriendsSearch
        show={showSearch}
        onClose={() => setShowSearch(false)}
        onFriendAdd={(friend) => setSentRequests([...sentRequests, friend])}
      />

      <ConfirmationModal
        isOpen={showCancelConfirmation}
        onClose={() => {
          setShowCancelConfirmation(false);
          setSelectedRequest(null);
        }}
        onConfirm={handleConfirmCancel}
        title={t("cancelFriendRequest")}
        message={t("removeFriendConfirmation")}
      />
    </FriendsContainer>
  );
};

export default Friends;
