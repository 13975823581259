import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const LoadingContainer = styled(motion.div)`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `linear-gradient(
        165deg,
        rgba(28, 28, 35, 0.98),
        rgba(18, 18, 24, 0.98)
      )`
      : `linear-gradient(
        165deg,
        rgba(255, 255, 255, 0.98),
        rgba(245, 245, 247, 0.98)
      )`};
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: 1000;
  perspective: 1000px;
`;

const LoadingContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  transform-style: preserve-3d;
`;

const SpinnerWrapper = styled(motion.div)`
  width: 64px;
  height: 64px;
  transform-style: preserve-3d;
  animation: float 4s ease-in-out infinite;

  @keyframes float {
    0%,
    100% {
      transform: translateY(0px) translateZ(0) rotate(0deg);
    }
    50% {
      transform: translateY(-10px) translateZ(20px) rotate(5deg);
    }
  }
`;

const Spinner = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.9)" : "rgba(0, 0, 0, 0.9)"};
  border-right-color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.15)"};
  border-bottom-color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.15)"};
  border-left-color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.9)" : "rgba(0, 0, 0, 0.9)"};
  animation: spin 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled(motion.p)`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `linear-gradient(
        165deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.8)
      )`
      : `linear-gradient(
        165deg,
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 0.8)
      )`};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 0.3px;
  transform-style: preserve-3d;
  text-shadow: ${({ theme }) =>
    theme.isDarkMode ? "0 2px 4px rgba(0, 0, 0, 0.2)" : "none"};
`;

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <AnimatePresence>
        <LoadingContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <LoadingContent>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
            <LoadingText
              initial={{ opacity: 0, y: 10, z: -50 }}
              animate={{ opacity: 1, y: 0, z: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              Preparing your experience...
            </LoadingText>
          </LoadingContent>
        </LoadingContainer>
      </AnimatePresence>
    );
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;
