import React, { useState, useEffect, useRef, useContext } from "react";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";

import {
  Send,
  UserPlus,
  X,
  MoreVertical,
  Trash2,
  MessageCircle,
  Users,
  Archive,
  Inbox,
} from "react-feather";

import {
  ChatContainer,
  ChatSearchIcon,
  ChatSearchInput,
  ChatSearchContainer,
  Sidebar,
  SearchContainer,
  SearchIcon,
  SearchInput,
  NewChatButton,
  ChatList,
  ChatItem,
  UnreadBadge,
  MainChat,
  ChatHeader,
  ChatHeaderInfo,
  ChatHeaderActions,
  IconButton,
  MessageContainer,
  InputArea,
  Input,
  EmptyStateContainer,
  UserSearchModal,
  UserSearchContent,
  UserList,
  UserItem,
  ChatOptionsMenu,
  ChatOptionItem,
  LoadingWaveContainer,
  WaveEffect,
  LoadingText,
  ParticipantsButton,
  ParticipantsModal,
  ParticipantList,
  ParticipantItem,
  ModalHeader,
  CloseButton,
  TabsContainer,
  Tab,
  ChatItemContent,
  ChatItemInfo,
  EditableInput,
  EditIndicator,
  NoResultsText,
  SearchErrorText,
  SearchHeader,
  CloseIconButton,
  UserSearchEmptyState,
} from "./styles/Chat.styles";

import { AuthContext } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { useNavigate, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import Message from "./Message";
import {
  createChatApi,
  archiveChat,
  unarchiveChat,
  updateChatTitle,
} from "./chatApi";
import ConfirmationModal from "../common/ConfirmationModal";

const Chat = ({ onShowHistory }) => {
  const { t } = useTranslation();
  const [activeChat, setActiveChat] = useState(null);
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const messageEndRef = useRef(null);
  const { user } = useContext(AuthContext);
  const { showToast } = useToast();
  const [showUserSearch, setShowUserSearch] = useState(false);
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showChatOptions, setShowChatOptions] = useState(false);
  const [searchError, setSearchError] = useState("");
  const navigate = useNavigate();
  const socket = useRef();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(true);
  const messageContainerRef = useRef(null);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteChatConfirmation, setShowDeleteChatConfirmation] =
    useState(false);
  const [chatSearchQuery, setChatSearchQuery] = useState("");
  const [filteredChats, setFilteredChats] = useState([]);
  const [messageSearchQuery, setMessageSearchQuery] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadingTimeoutRef = useRef(null);
  const [showParticipants, setShowParticipants] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const participantsModalRef = useRef(null);
  const [error, setError] = useState(null);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [editingTitle, setEditingTitle] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const location = useLocation();

  // Create the chatApi with the required parameters
  const chatApi = createChatApi({
    setChats,
    setMessages,
    setMessage,
    socket,
    showToast,
    navigate,
    setSearchResults,
    setActiveChat,
    user,
  });

  const {
    fetchChats,
    fetchMessages,
    handleSend,
    handleNewChat,
    searchUsers,
    handleUserSelect,
    handleDeleteMessage,
    handleDeleteChat: handleDeleteChatApi,
    handleUnsendMessage,
    handleChatInteraction,
    handleEditMessage,
    loadMoreMessages,
  } = chatApi;

  // Add confirm delete message handler
  const confirmDeleteMessage = async () => {
    if (messageToDelete) {
      await handleDeleteMessage(messageToDelete);
      setMessageToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  // Add confirm delete chat handler
  const confirmDeleteChat = async () => {
    if (activeChat) {
      await handleDeleteChatApi(activeChat);
      setShowDeleteChatConfirmation(false);
      setActiveChat(null);
    }
  };

  // Move all useEffects to the top level, put conditions inside
  useEffect(() => {
    const loadChats = async () => {
      try {
        const chatsData = await fetchChats();
        setChats(chatsData);
      } catch (error) {
        setError(t("failedToLoadChats"));
        console.error("Error loading chats:", error);
      }
    };

    loadChats();
  }, []);

  // Fetch messages when active chat changes
  useEffect(() => {
    const loadMessages = async () => {
      if (activeChat) {
        setCurrentPage(1); // Reset page when changing chats
        setHasMore(true); // Reset hasMore flag
        const hasMoreMessages = await fetchMessages(activeChat, 1);
        setHasMore(hasMoreMessages);
        socket.current.emit("join chat", activeChat);
      }
    };

    loadMessages();
  }, [activeChat]);

  // Scroll to bottom when messages change
  useEffect(() => {
    const handleScroll = () => {
      if (isNearBottom) {
        scrollToBottom();
      } else {
        setHasNewMessage(true);
      }
    };

    handleScroll();
  }, [messages, isNearBottom]);

  // Initialize socket connection
  useEffect(() => {
    if (user?._id) {
      socket.current = io(process.env.REACT_APP_API_URL, {
        withCredentials: true,
        transports: ["websocket"],
        path: "/socket.io",
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        auth: {
          token: document.cookie,
        },
        extraHeaders: {
          Cookie: document.cookie,
        },
      });

      socket.current.on("connect", () => {
        console.log("Socket connected in Chat component");
        socket.current.emit("user connected", user._id);

        // Join all chat rooms the user is part of
        if (Array.isArray(chats)) {
          chats.forEach((chat) => {
            console.log("Joining chat room:", chat._id);
            socket.current.emit("join chat", chat._id);
          });
        }
      });

      // Create event handlers
      const handleMessageEdit = ({ messageId, updatedMessage, chatId }) => {
        console.log("Received message edit:", {
          messageId,
          chatId,
          updatedMessage,
        });

        if (!chatId) {
          console.warn("Received message edit without chatId");
          return;
        }

        // Update messages if in active chat
        if (chatId === activeChat) {
          setMessages((prev) => {
            // Remove any potential duplicates first
            const uniqueMessages = prev.filter(
              (msg, index, self) =>
                index === self.findIndex((m) => m._id === msg._id)
            );

            // Then update the message
            return uniqueMessages.map((msg) =>
              msg._id === messageId ? updatedMessage : msg
            );
          });
        }

        // Always update chats list
        setChats((prev) =>
          prev.map((chat) => {
            if (chat._id === chatId && chat.lastMessage?._id === messageId) {
              return {
                ...chat,
                lastMessage: updatedMessage,
              };
            }
            return chat;
          })
        );
      };

      const handleMessage = (data) => {
        const { message, chat: chatId } = data;

        // Update messages if in active chat
        if (chatId === activeChat) {
          setMessages((prev) =>
            Array.isArray(prev) ? [...prev, message] : [message]
          );

          // Don't increment unread count if we're in the active chat
          socket.current.emit("message_read", { chatId });
        }

        // Always update chat preview with latest message regardless of active status
        setChats((prev) =>
          prev.map((chat) => {
            if (chat._id === chatId) {
              return {
                ...chat,
                lastMessage: message,
                // Only update unread counts if not in active chat
                unreadCounts:
                  chatId === activeChat
                    ? chat.unreadCounts
                    : {
                        ...chat.unreadCounts,
                        [user._id]: (chat.unreadCounts?.[user._id] || 0) + 1,
                      },
              };
            }
            return chat;
          })
        );
      };

      const handleUnreadUpdate = ({ chatId, totalUnread }) => {
        if (chatId) {
          setChats((prev) =>
            prev.map((chat) => {
              if (chat._id === chatId) {
                return {
                  ...chat,
                  unreadCounts: {
                    ...chat.unreadCounts,
                    [user._id]: 0,
                  },
                };
              }
              return chat;
            })
          );
        }
      };

      const handleMessageUnsent = ({ messageId, chatId }) => {
        setChats((prev) => {
          const updatedChats = prev.map((chat) => {
            if (chat._id === chatId) {
              const updatedChat = { ...chat };
              if (chat.lastMessage?._id === messageId) {
                updatedChat.lastMessage = {
                  ...chat.lastMessage,
                  _id: messageId,
                  isUnsent: true,
                  content: t("thisMessageWasUnsent"),
                  sender: chat.lastMessage.sender,
                  updatedAt: new Date().toISOString(),
                };
              }
              return updatedChat;
            }
            return chat;
          });
          return updatedChats;
        });

        if (chatId === activeChat) {
          setMessages((prev) =>
            prev.map((msg) => {
              if (msg._id === messageId) {
                return {
                  ...msg,
                  isUnsent: true,
                  content: t("thisMessageWasUnsent"),
                };
              }
              return msg;
            })
          );
        }
      };

      // Attach event listeners
      socket.current.on("message_edited", handleMessageEdit);
      socket.current.on("message received", handleMessage);
      socket.current.on("update_unread_count", handleUnreadUpdate);
      socket.current.on("message_unsent", handleMessageUnsent);

      // Clean up socket listeners
      return () => {
        if (socket.current) {
          console.log("Cleaning up socket connection");
          socket.current.off("message_edited", handleMessageEdit);
          socket.current.off("message received", handleMessage);
          socket.current.off("update_unread_count", handleUnreadUpdate);
          socket.current.off("message_unsent", handleMessageUnsent);
          socket.current.disconnect();
        }
      };
    }
  }, [user?._id, activeChat, chats]);

  // Join chat room when activeChat changes
  useEffect(() => {
    const joinChat = () => {
      if (activeChat && socket.current) {
        socket.current.emit("join chat", activeChat);
      }
    };

    joinChat();
  }, [activeChat]);

  // Handle message container scroll
  useEffect(() => {
    const handleMessageScroll = () => {
      if (messages.length > 0) {
        const container = messageContainerRef.current;
        if (!container) return;
        // ... rest of the scroll logic
      }
    };

    handleMessageScroll();
  }, [messages]);

  // Handle click outside for chat options
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showChatOptions && !event.target.closest(".chat-options")) {
        setShowChatOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showChatOptions]);

  // Update filtered chats
  useEffect(() => {
    const filtered = Array.isArray(chats)
      ? chats
          .filter((chat) => {
            const isArchived = chat.archivedBy?.some(
              (a) => a.user === user._id
            );
            return showArchived ? isArchived : !isArchived;
          })
          .filter(
            (chat) =>
              !chatSearchQuery ||
              chat.participants.some(
                (p) =>
                  p.name
                    .toLowerCase()
                    .includes(chatSearchQuery.toLowerCase()) ||
                  p.email.toLowerCase().includes(chatSearchQuery.toLowerCase())
              )
          )
      : [];

    setFilteredChats(filtered);
  }, [chats, showArchived, chatSearchQuery, user._id]);

  // Update filtered messages
  useEffect(() => {
    setFilteredMessages(Array.isArray(messages) ? messages : []);
  }, [messages]);

  // Handle scroll position
  useEffect(() => {
    const messageContainer = messageContainerRef.current;
    if (!messageContainer) return;
    // ... rest of the scroll position logic
  }, [messages]);

  // Handle click outside for participants modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        participantsModalRef.current &&
        !participantsModalRef.current.contains(event.target)
      ) {
        setShowParticipants(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleScroll = () => {
    const container = messageContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const scrollPosition = scrollHeight - scrollTop - clientHeight;
    const isNearBottom = scrollPosition < 100;

    setIsNearBottom(isNearBottom);
    setShowScrollButton(!isNearBottom);

    if (isNearBottom) {
      setHasNewMessage(false);
      if (activeChat) {
        // Emit 'message_read' event for the chat
        socket.current.emit("message_read", { chatId: activeChat });
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend(message, activeChat);
    }
  };
  const handleChatOptions = () => {
    setShowChatOptions(!showChatOptions);
  };

  const handleDeleteChat = async () => {
    setShowChatOptions(false); // Close the options menu
    setShowDeleteChatConfirmation(true); // Show confirmation modal
  };

  useEffect(() => {
    if (messages.length > 0) {
      const container = messageContainerRef.current;
      if (!container) return;

      if (isNearBottom) {
        scrollToBottom();
      } else {
        setHasNewMessage(true);
      }
    }
  }, [messages]);

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
      setHasNewMessage(false);
      setShowScrollButton(false);
    }
  };

  const handleCloseUserSearch = () => {
    setShowUserSearch(false);
    setUserSearchQuery("");
    setSearchResults([]);
    setSearchError("");
  };

  // Update the input handlers
  const handleInputFocus = () => {
    handleChatInteraction();
  };

  // Update the message container click handler
  const handleMessageContainerClick = () => {
    handleChatInteraction();
  };

  // Update the chat list click handler
  const handleChatListClick = () => {
    handleChatInteraction();
  };

  // Add this function to handle chat filtering
  const handleChatSearch = (query) => {
    setChatSearchQuery(query);
    if (!query.trim() || !Array.isArray(chats)) {
      setFilteredChats(chats || []);
      return;
    }

    const filtered = chats.filter((chat) => {
      if (!chat || !chat.participants) return false;

      // Search in participant names
      const participantNames = chat.participants
        .filter((p) => p && p._id !== user?._id)
        .map((p) => p?.name?.toLowerCase() || "")
        .filter(Boolean);

      // Search in last message
      const lastMessage = chat?.lastMessage?.content?.toLowerCase() || "";

      // Check if query matches any participant name or last message
      return (
        participantNames.some((name) => name.includes(query.toLowerCase())) ||
        lastMessage.includes(query.toLowerCase())
      );
    });

    setFilteredChats(filtered);
  };

  const handleMessageSearch = (query) => {
    setMessageSearchQuery(query);
    if (!query.trim()) {
      setFilteredMessages(Array.isArray(messages) ? messages : []);
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    if (!Array.isArray(messages)) {
      setFilteredMessages([]);
      return;
    }

    const filtered = messages.filter((msg) => {
      if (!msg || msg.isUnsent) return false;
      return msg.content?.toLowerCase().includes(query.toLowerCase());
    });

    setFilteredMessages(filtered);
  };

  // Add this new useEffect to handle scroll position
  useEffect(() => {
    const messageContainer = messageContainerRef.current;
    if (!messageContainer) return;

    const handleScroll = async () => {
      const { scrollTop } = messageContainer;

      // If we're near the top and not already loading more messages
      if (scrollTop < 100 && hasMore && !isLoadingMore) {
        // Clear any existing timeout
        if (loadingTimeoutRef.current) {
          clearTimeout(loadingTimeoutRef.current);
        }

        // Set a timeout to load more messages
        loadingTimeoutRef.current = setTimeout(async () => {
          setIsLoadingMore(true);
          const nextPage = currentPage + 1;
          const hasMoreMessages = await loadMoreMessages(activeChat, nextPage);

          setCurrentPage(nextPage);
          setHasMore(hasMoreMessages);
          setIsLoadingMore(false);
        }, 1000);
      }
    };

    messageContainer.addEventListener("scroll", handleScroll);
    return () => {
      messageContainer.removeEventListener("scroll", handleScroll);
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, [currentPage, hasMore, isLoadingMore, activeChat]);

  const handleShowParticipants = (chat) => {
    setSelectedChat(chat);
    setShowParticipants(true);
  };

  // Update the user selection handler
  const onUserSelect = async (userId) => {
    const success = await handleUserSelect(userId, activeChat);
    if (success) {
      setShowUserSearch(false);
    }
  };

  const handleArchiveChat = () => {
    setShowChatOptions(false);
    setShowArchiveConfirmation(true);
  };

  const confirmArchiveChat = async () => {
    try {
      if (isArchived) {
        await unarchiveChat(activeChat);
        showToast("Chat unarchived successfully", "success");
      } else {
        await archiveChat(activeChat);
        showToast("Chat archived successfully", "success");
      }

      // Refresh chats list
      const updatedChats = await fetchChats();
      setChats(updatedChats);
    } catch (error) {
      showToast("Failed to archive chat", "error");
    } finally {
      setShowArchiveConfirmation(false);
    }
  };

  // Update useEffect to check archived status when chat changes
  useEffect(() => {
    const checkArchivedStatus = () => {
      if (activeChat) {
        const currentChat = chats.find((c) => c._id === activeChat);
        setIsArchived(
          currentChat?.archivedBy?.some((a) => a.user === user._id) || false
        );
      }
    };

    checkArchivedStatus();
  }, [activeChat, chats, user._id]);

  // Add this function to handle title update
  const handleTitleUpdate = async (chatId, title) => {
    try {
      await updateChatTitle(chatId, title);
      setChats(
        chats.map((chat) => (chat._id === chatId ? { ...chat, title } : chat))
      );
      showToast("Chat title updated successfully", "success");
    } catch (error) {
      showToast("Failed to update chat title", "error");
    } finally {
      setEditingTitle(null);
      setNewTitle("");
    }
  };

  // Add this useEffect to handle chat activation from URL
  useEffect(() => {
    const activateChatFromUrl = async () => {
      const params = new URLSearchParams(location.search);
      const chatId = params.get("chatId");

      if (chatId) {
        try {
          // Only proceed if we have chats loaded
          if (chats.length > 0) {
            const chatExists = chats.find((c) => c._id === chatId);
            if (chatExists) {
              // Only load messages if active chat is different
              if (activeChat !== chatId) {
                await chatApi.handleChatClick(chatId);
                setActiveChat(chatId);
              }
            }
          }
        } catch (error) {
          console.error("Error activating chat:", error);
          showToast("Failed to open chat", "error");
        }
      } else {
        // If no chatId in URL, clear active chat
        setActiveChat(null);
        setMessages([]);
      }
    };

    activateChatFromUrl();
  }, [location.search, chats, activeChat]);

  // Add this function to handle chat selection
  const handleChatSelection = async (chatId) => {
    // Update URL
    navigate(`?chatId=${chatId}`, { replace: true });

    // Handle chat click directly since we're waiting for URL change to take effect
    await chatApi.handleChatClick(chatId);
    setActiveChat(chatId);
  };

  const handleViewEditHistory = (message) => {
    if (message?.editHistory) {
      onShowHistory({
        currentVersion: {
          content: message.content,
          timestamp: message.updatedAt,
        },
        history: message.editHistory,
      });
    }
  };

  return (
    <ChatContainer>
      <Sidebar onClick={handleChatListClick}>
        <SearchContainer>
          <SearchIcon size={20} />
          <SearchInput
            placeholder={t("searchChats")}
            value={chatSearchQuery}
            onChange={(e) => handleChatSearch(e.target.value)}
          />
        </SearchContainer>

        <NewChatButton
          onClick={handleNewChat}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <UserPlus />
          {t("newChat")}
        </NewChatButton>

        <TabsContainer>
          <Tab
            $active={!showArchived}
            onClick={() => setShowArchived(false)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Inbox size={16} />
              {t("inbox")}
            </div>
            <span>
              {chats.filter((c) => !c.archivedBy?.some((a) => a.user === user._id)).length}
            </span>
          </Tab>
          <Tab
            $active={showArchived}
            onClick={() => setShowArchived(true)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Archive size={16} />
              {t("archived")}
            </div>
            <span>
              {chats.filter((c) => c.archivedBy?.some((a) => a.user === user._id)).length}
            </span>
          </Tab>
        </TabsContainer>

        <ChatList onClick={handleChatListClick}>
          {Array.isArray(filteredChats) &&
            filteredChats.map((chat) => (
              <ChatItem
                key={chat._id}
                $active={activeChat === chat._id}
                onClick={() => handleChatSelection(chat._id)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <ChatItemContent>
                  <ChatItemInfo>
                    <h4
                      onDoubleClick={(e) => {
                        e.stopPropagation();
                        setEditingTitle(chat._id);
                        setNewTitle(chat.title || chat?.name || t("unnamedChat"));
                      }}
                      className="editable-title"
                    >
                      {editingTitle === chat._id ? (
                        <EditableInput
                          type="text"
                          value={newTitle}
                          onChange={(e) => setNewTitle(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleTitleUpdate(chat._id, newTitle);
                            }
                          }}
                          onBlur={() => {
                            if (newTitle.trim()) {
                              handleTitleUpdate(chat._id, newTitle);
                            } else {
                              setEditingTitle(null);
                              setNewTitle("");
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                          autoFocus
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.2 }}
                        />
                      ) : (
                        <Tippy
                          content={t("doubleClickToEdit")}
                          animation="shift-away"
                          placement="top"
                          theme="modern"
                          arrow={false}
                          delay={[200, 0]}
                        >
                          <span style={{ display: "inline-block" }}>
                            {chat.title || chat?.name || t("unnamedChat")}
                            <EditIndicator
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                            >
                              ✎
                            </EditIndicator>
                          </span>
                        </Tippy>
                      )}
                    </h4>
                    <p>{chat?.lastMessage?.content || t("noMessagesYet")}</p>
                  </ChatItemInfo>
                  {chat?.unreadCounts?.[user?._id] > 0 && (
                    <UnreadBadge>{chat.unreadCounts[user._id]}</UnreadBadge>
                  )}
                </ChatItemContent>
              </ChatItem>
            ))}
          {chatSearchQuery && (!filteredChats || filteredChats.length === 0) && (
            <NoResultsText>{t("noChatsFound")}</NoResultsText>
          )}
        </ChatList>
      </Sidebar>

      <MainChat>
        <ChatHeader>
          {activeChat && (
            <>
              <ChatHeaderInfo>
                <ParticipantsButton
                  onClick={() =>
                    handleShowParticipants(
                      chats.find((c) => c._id === activeChat)
                    )
                  }
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Users size={14} />
                  {chats.find((c) => c._id === activeChat)?.participants?.length || 0}{" "}
                  {t("participants")}
                </ParticipantsButton>
              </ChatHeaderInfo>
              <ChatSearchContainer>
                <ChatSearchIcon />
                <ChatSearchInput
                  placeholder={t("searchInConversation")}
                  value={messageSearchQuery}
                  onChange={(e) => handleMessageSearch(e.target.value)}
                />
              </ChatSearchContainer>
              <ChatHeaderActions>
                <IconButton
                  onClick={() => setShowUserSearch(true)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <UserPlus size={20} />
                </IconButton>
                <IconButton
                  onClick={handleChatOptions}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <MoreVertical size={20} />
                </IconButton>
              </ChatHeaderActions>
            </>
          )}
        </ChatHeader>

        {activeChat ? (
          <>
            <MessageContainer
              ref={messageContainerRef}
              onScroll={handleScroll}
              onClick={handleMessageContainerClick}
            >
              {isLoadingMore && (
                <LoadingWaveContainer>
                  <WaveEffect />
                  <LoadingText>{t("loadingMoreMessages")}</LoadingText>
                </LoadingWaveContainer>
              )}
              {(() => {
                const messagesToDisplay = isSearching
                  ? filteredMessages
                  : messages;
                if (!Array.isArray(messagesToDisplay)) {
                  return null;
                }

                return messagesToDisplay.map((msg, index) => {
                  if (!msg) return null;
                  return (
                    <Message
                      key={`${msg._id}-${index}`}
                      msg={msg}
                      user={user}
                      onDelete={handleDeleteMessage}
                      onUnsend={(messageId) =>
                        handleUnsendMessage(messageId, activeChat)
                      }
                      onEdit={(messageId, content) =>
                        handleEditMessage(messageId, content, activeChat)
                      }
                      onViewHistory={handleViewEditHistory}
                      showDeleteConfirmation={showDeleteConfirmation}
                      setShowDeleteConfirmation={setShowDeleteConfirmation}
                      confirmDeleteMessage={confirmDeleteMessage}
                    />
                  );
                });
              })()}
              {isSearching &&
                Array.isArray(filteredMessages) &&
                filteredMessages.length === 0 && (
                  <NoResultsText>{t("noMessagesFound")}</NoResultsText>
                )}
              <div ref={messageEndRef} />
            </MessageContainer>

            <InputArea>
              <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                onFocus={handleInputFocus}
                placeholder={t("typeMessage")}
              />
              <IconButton
                onClick={() => handleSend(message, activeChat)}
                disabled={!message.trim()}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Send size={20} />
              </IconButton>
            </InputArea>
          </>
        ) : (
          <EmptyStateContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
          >
            <MessageCircle />
            <h3>{t("welcomeToMessages")}</h3>
            <p>{t("selectChatToStart")}</p>
          </EmptyStateContainer>
        )}
      </MainChat>

      {showUserSearch && (
        <UserSearchModal
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={handleCloseUserSearch}
        >
          <UserSearchContent
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            style={{ maxWidth: "400px" }}
          >
            <SearchHeader>
              <h3>{t("addUserToChat")}</h3>
              <CloseIconButton onClick={handleCloseUserSearch}>
                <X size={20} />
              </CloseIconButton>
            </SearchHeader>
            <SearchInput
              type="text"
              placeholder={t("searchUsersByNameOrEmail")}
              value={userSearchQuery}
              onChange={(e) => {
                setUserSearchQuery(e.target.value);
                if (e.target.value.length >= 2) {
                  searchUsers(e.target.value);
                } else {
                  setSearchResults([]);
                }
                setSearchError("");
              }}
            />
            {searchError && <SearchErrorText>{searchError}</SearchErrorText>}
            <UserList>
              {searchResults.length === 0 && userSearchQuery.length >= 2 ? (
                <UserSearchEmptyState>
                  <Users size={32} />
                  <h3>{t("noUsersFound")}</h3>
                  <p>{t("trySearchingDifferent")}</p>
                </UserSearchEmptyState>
              ) : (
                searchResults.map((user) => (
                  <UserItem
                    key={user._id}
                    onClick={() => onUserSelect(user._id)}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className="user-info">
                      <span className="name">{user.name}</span>
                      <span className="email">{user.email}</span>
                    </div>
                    <UserPlus size={16} />
                  </UserItem>
                ))
              )}
            </UserList>
          </UserSearchContent>
        </UserSearchModal>
      )}

      {showChatOptions && (
        <ChatOptionsMenu
          className="chat-options"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <ChatOptionItem onClick={handleArchiveChat} $danger>
            <Archive size={16} />
            {isArchived ? t("unarchiveChat") : t("archiveChat")}
          </ChatOptionItem>
          <ChatOptionItem onClick={handleDeleteChat} $danger>
            <Trash2 size={16} />
            {t("deleteChat")}
          </ChatOptionItem>
        </ChatOptionsMenu>
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={confirmDeleteMessage}
          title={t("deleteMessage")}
          message={t("deleteMessageConfirmation")}
          confirmText={t("delete")}
        />
      )}

      {showDeleteChatConfirmation && (
        <ConfirmationModal
          isOpen={showDeleteChatConfirmation}
          onClose={() => setShowDeleteChatConfirmation(false)}
          onConfirm={confirmDeleteChat}
          title={t("deleteChat")}
          message={t("deleteChatConfirmation")}
          confirmText={t("delete")}
        />
      )}

      <AnimatePresence>
        {showParticipants && selectedChat && (
          <ParticipantsModal
            ref={participantsModalRef}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ type: "spring", damping: 25, stiffness: 300 }}
          >
            <ModalHeader>
              <h3>{t("chatParticipants")}</h3>
              <CloseButton onClick={() => setShowParticipants(false)}>
                <X size={16} />
              </CloseButton>
            </ModalHeader>
            <ParticipantList>
              {selectedChat.participants.map((participant) => (
                <ParticipantItem key={participant._id}>
                  <div className="name">{participant.name}</div>
                  <div className="email">{participant.email}</div>
                </ParticipantItem>
              ))}
            </ParticipantList>
          </ParticipantsModal>
        )}
      </AnimatePresence>

      <ConfirmationModal
        isOpen={showArchiveConfirmation}
        onClose={() => setShowArchiveConfirmation(false)}
        onConfirm={confirmArchiveChat}
        title={isArchived ? t("unarchiveChat") : t("archiveChat")}
        message={
          isArchived
            ? t("areYouSureYouWantToUnarchiveThisChatItWillReappearInYourMainChatList")
            : t("areYouSureYouWantToArchiveThisChatYouCanAlwaysUnarchiveItLater")
        }
        confirmText={isArchived ? t("unarchive") : t("archive")}
      />
    </ChatContainer>
  );
};

export default Chat;
