import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { X as CloseIcon, CheckCircle, AlertCircle, Info, AlertTriangle } from "react-feather";

const scanlineAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(16px);
  }
`;

const glowPulse = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
`;

const ToastContainer = styled(motion.div)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  min-width: 320px;
  max-width: 380px;
  z-index: 10000;
  padding: 14px 16px;
  background: ${({ theme, $type }) => {
    const base = theme.isDarkMode 
      ? "rgba(14, 14, 18, 0.85)"
      : "rgba(245, 245, 255, 0.85)";
    const accent = {
      success: "rgba(46, 204, 113, 0.1)",
      error: "rgba(231, 76, 60, 0.1)",
      warning: "rgba(243, 156, 18, 0.1)",
      info: "rgba(136, 83, 251, 0.1)"
    }[$type] || "rgba(136, 83, 251, 0.1)";
    return `linear-gradient(165deg, ${base}, ${base}), ${accent}`;
  }};
  backdrop-filter: blur(20px);
  border-radius: 12px;
  border: 1px solid ${({ theme, $type }) => {
    const colors = {
      success: theme.isDarkMode ? "rgba(46, 204, 113, 0.3)" : "rgba(46, 204, 113, 0.2)",
      error: theme.isDarkMode ? "rgba(231, 76, 60, 0.3)" : "rgba(231, 76, 60, 0.2)",
      warning: theme.isDarkMode ? "rgba(243, 156, 18, 0.3)" : "rgba(243, 156, 18, 0.2)",
      info: theme.isDarkMode ? "rgba(136, 83, 251, 0.3)" : "rgba(136, 83, 251, 0.2)"
    };
    return colors[$type] || colors.info;
  }};
  box-shadow: ${({ theme, $type }) => {
    const shadowColor = {
      success: "46, 204, 113",
      error: "231, 76, 60",
      warning: "243, 156, 18",
      info: "136, 83, 251"
    }[$type] || "136, 83, 251";
    
    return theme.isDarkMode
      ? `0 4px 16px rgba(0, 0, 0, 0.3),
         0 0 0 1px rgba(${shadowColor}, 0.2),
         0 0 20px rgba(${shadowColor}, 0.15),
         inset 0 0 20px rgba(${shadowColor}, 0.03)`
      : `0 4px 16px rgba(0, 0, 0, 0.1),
         0 0 0 1px rgba(${shadowColor}, 0.1),
         0 0 20px rgba(${shadowColor}, 0.1),
         inset 0 0 20px rgba(${shadowColor}, 0.02)`;
  }};
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ theme }) => theme.isDarkMode 
      ? `repeating-linear-gradient(
          0deg,
          transparent,
          rgba(255, 255, 255, 0.02) 1px,
          transparent 2px
        )`
      : `repeating-linear-gradient(
          0deg,
          transparent,
          rgba(0, 0, 0, 0.01) 1px,
          transparent 2px
        )`};
    opacity: 0.5;
    pointer-events: none;
    animation: ${scanlineAnimation} 8s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ theme, $type }) => {
      const gradientColor = {
        success: "46, 204, 113",
        error: "231, 76, 60",
        warning: "243, 156, 18",
        info: "136, 83, 251"
      }[$type] || "136, 83, 251";
      
      return `radial-gradient(
        circle at top right,
        rgba(${gradientColor}, ${theme.isDarkMode ? "0.15" : "0.08"}),
        transparent 70%
      )`;
    }};
    border-radius: inherit;
    pointer-events: none;
    animation: ${glowPulse} 4s ease-in-out infinite;
  }
`;

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: -4px;
    background: ${({ theme, $type }) => {
      const color = {
        success: "46, 204, 113",
        error: "231, 76, 60",
        warning: "243, 156, 18",
        info: "136, 83, 251"
      }[$type] || "136, 83, 251";
      return `radial-gradient(
        circle at center,
        rgba(${color}, ${theme.isDarkMode ? "0.15" : "0.1"}),
        transparent 70%
      )`;
    }};
    border-radius: 50%;
    animation: ${glowPulse} 2s ease-in-out infinite;
  }

  svg {
    width: 18px;
    height: 18px;
    color: ${({ theme, $type }) => {
      const colors = {
        success: theme.isDarkMode ? "rgba(46, 204, 113, 0.95)" : "rgb(46, 204, 113)",
        error: theme.isDarkMode ? "rgba(231, 76, 60, 0.95)" : "rgb(231, 76, 60)",
        warning: theme.isDarkMode ? "rgba(243, 156, 18, 0.95)" : "rgb(243, 156, 18)",
        info: theme.isDarkMode ? "rgba(136, 83, 251, 0.95)" : "rgb(136, 83, 251)"
      };
      return colors[$type] || colors.info;
    }};
    filter: drop-shadow(0 0 6px ${({ theme, $type }) => {
      const shadowColor = {
        success: "46, 204, 113",
        error: "231, 76, 60",
        warning: "243, 156, 18",
        info: "136, 83, 251"
      }[$type] || "136, 83, 251";
      return `rgba(${shadowColor}, ${theme.isDarkMode ? "0.4" : "0.3"})`;
    }});
    animation: ${glowPulse} 2s ease-in-out infinite;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-right: 4px;
  position: relative;
  z-index: 1;
  
  p {
    margin: 0;
    font-size: 13px;
    line-height: 1.5;
    color: ${({ theme }) => 
      theme.isDarkMode ? "rgba(255, 255, 255, 0.95)" : "rgba(0, 0, 0, 0.9)"};
    text-shadow: ${({ theme }) => 
      theme.isDarkMode ? "0 0 8px rgba(255, 255, 255, 0.1)" : "none"};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: ${({ theme }) => 
    theme.isDarkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;

  &:hover {
    background: ${({ theme }) => 
      theme.isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};
    color: ${({ theme }) => 
      theme.isDarkMode ? "rgba(255, 255, 255, 0.95)" : "rgba(0, 0, 0, 0.9)"};
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 0 4px ${({ theme }) => 
      theme.isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)"});
  }
`;

const UndoButton = styled.button`
  background: none;
  border: none;
  padding: 4px 8px;
  margin-top: 6px;
  font-size: 12px;
  color: ${({ theme, $type }) => {
    const colors = {
      success: theme.isDarkMode ? "rgba(46, 204, 113, 0.95)" : "rgb(46, 204, 113)",
      error: theme.isDarkMode ? "rgba(231, 76, 60, 0.95)" : "rgb(231, 76, 60)",
      warning: theme.isDarkMode ? "rgba(243, 156, 18, 0.95)" : "rgb(243, 156, 18)",
      info: theme.isDarkMode ? "rgba(136, 83, 251, 0.95)" : "rgb(136, 83, 251)"
    };
    return colors[$type] || colors.info;
  }};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
  text-shadow: 0 0 8px ${({ theme, $type }) => {
    const shadowColor = {
      success: "46, 204, 113",
      error: "231, 76, 60",
      warning: "243, 156, 18",
      info: "136, 83, 251"
    }[$type] || "136, 83, 251";
    return `rgba(${shadowColor}, ${theme.isDarkMode ? "0.4" : "0.3"})`;
  }};

  &:hover {
    background: ${({ theme, $type }) => {
      const colors = {
        success: "rgba(46, 204, 113, 0.15)",
        error: "rgba(231, 76, 60, 0.15)",
        warning: "rgba(243, 156, 18, 0.15)",
        info: "rgba(136, 83, 251, 0.15)"
      };
      return colors[$type] || colors.info;
    }};
  }
`;

const Toast = ({ message, type = "info", duration = 3000, onClose, onUndo }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 200); // Duration of exit animation
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircle />;
      case "error":
        return <AlertCircle />;
      case "warning":
        return <AlertTriangle />;
      default:
        return <Info />;
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <ToastContainer
          $type={type}
          initial={{ opacity: 0, y: 16, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 16, scale: 0.95 }}
          transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
        >
          <IconWrapper $type={type}>
            {getIcon()}
          </IconWrapper>
          <Content>
            <p>{message}</p>
            {onUndo && (
              <UndoButton $type={type} onClick={onUndo}>
                Undo
              </UndoButton>
            )}
          </Content>
          <CloseButton onClick={() => setIsVisible(false)}>
            <CloseIcon />
          </CloseButton>
        </ToastContainer>
      )}
    </AnimatePresence>
  );
};

export default Toast;
