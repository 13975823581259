import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";

const TabsContainer = styled.div`
  display: inline-flex;
  background: ${({ theme }) => theme.colors.surface};
  padding: 3px;
  border-radius: 30px;
  gap: 2px;
  margin-bottom: 12px;

  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabButton = styled.button`
  position: relative;
  padding: 6px 16px;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-width: 0;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }

  &.active {
    color: ${({ theme }) => theme.colors.text};

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 20px;
      background: ${({ theme }) => theme.colors.buttonGradient};
      z-index: -1;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const TabLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`;

const TabPanel = styled.div`
  position: relative;
  height: 100%;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${({ $isActive }) => ($isActive ? "auto" : "none")};
`;

export const Tabs = ({ children, activeTab, onTabClick }) => {
  const tabsRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const activeElement = tabsRef.current?.children[activeTab];
    if (activeElement && !isScrolling) {
      setIsScrolling(true);
      activeElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });

      const scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 300);

      return () => clearTimeout(scrollTimeout);
    }
  }, [activeTab]);

  const handleTabClick = useCallback(
    (index) => {
      if (!isScrolling) {
        onTabClick(index);
      }
    },
    [isScrolling, onTabClick]
  );

  if (!children || !Array.isArray(React.Children.toArray(children))) {
    console.error("Tabs component requires child Tab components");
    return null;
  }

  return (
    <TabsContainer ref={tabsRef}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          console.error(`Invalid tab child at index ${index}`);
          return null;
        }

        return React.cloneElement(child, {
          isActive: activeTab === index,
          onClick: () => handleTabClick(index),
        });
      })}
    </TabsContainer>
  );
};

export const Tab = ({ label, tooltip, isActive, onClick, disabled }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const translatedLabel = useMemo(() => t(label), [t, label]);
  const translatedTooltip = useMemo(
    () => (tooltip ? t(tooltip) : null),
    [t, tooltip]
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <Tippy
      content={translatedTooltip}
      animation="shift-away"
      placement="top"
      delay={[400, 0]}
      duration={[300, 250]}
      theme="modern"
      arrow={true}
      disabled={!translatedTooltip || disabled}
    >
      <TabButton
        className={`${isActive ? "active" : ""} ${disabled ? "disabled" : ""}`}
        onClick={handleClick}
        disabled={disabled}
        role="tab"
        aria-selected={isActive}
        aria-disabled={disabled}
        type="button"
      >
        <TabLabel>{translatedLabel}</TabLabel>
      </TabButton>
    </Tippy>
  );
};

export const TabContent = ({ children, isActive }) => {
  const [mounted, setMounted] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (isActive && !mounted) {
      setMounted(true);
    }
  }, [isActive]);

  if (!mounted) return null;

  return (
    <TabPanel role="tabpanel" aria-hidden={!isActive} $isActive={isActive}>
      {children}
    </TabPanel>
  );
};
