import React, { memo, useState, useRef, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Home as DashboardIcon,
  CreditCard as WalletIcon,
  Settings as SettingsIcon,
  MessageCircle as ChatIcon,
  Users as FriendsIcon,
  Sun as LightIcon,
  Moon as DarkIcon,
  Package,
} from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "../../../assets/styles/Sidebar.css";
import styled from "styled-components";
import { io } from "socket.io-client";
import { AuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../context/ThemeContext";

const NotificationBadge = styled(motion.div)`
  position: absolute;
  top: -6px;
  right: -6px;
  background: linear-gradient(165deg, #8853fb, #6039b0);
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.surface};
  font-weight: 500;
  z-index: 100;
  box-shadow: 0 2px 6px rgba(136, 83, 251, 0.3);
`;

const IconContainer = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledSidebar = styled(motion.nav)`
  width: 60px;
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(10px);
  margin: 10px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  position: relative;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  border: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
  will-change: transform;
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 10px;
  margin-bottom: auto;
`;

const NavButton = styled(motion.button)`
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.text : theme.colors.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.2s ease;
  margin: 0 auto;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ $isActive }) =>
    $isActive &&
    `
    &::after {
      content: '';
      position: absolute;
      inset: -1px;
      border-radius: 12px;
      padding: 1px;
      background: linear-gradient(135deg, rgba(136, 83, 251, 0.5), transparent);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
  `}
`;

const ActiveIndicator = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gradient};
  border-radius: 12px;
  z-index: 1;
`;

const ThemeToggleContainer = styled.div`
  margin-top: auto;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    height: 1px;
    background: ${({ theme }) => theme.colors.border};
  }
`;

const ThemeToggleButton = styled(NavButton)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Sidebar = memo(() => {
  const [unreadCount, setUnreadCount] = useState(0);
  const socket = useRef();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();

  const fetchUnreadCount = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/unread-count`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const { totalUnread } = await response.json();
        if (location.pathname !== "/home/chat") {
          setUnreadCount(totalUnread);
        }
      }
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  };

  useEffect(() => {
    fetchUnreadCount();
  }, [location.pathname]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      path: "/socket.io/",
    });

    socket.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socket.current.on("connect", () => {
      if (user?._id) {
        socket.current.emit("user connected", user._id);
      }
    });

    socket.current.on("message received", (data) => {
      if (location.pathname !== "/home/chat") {
        setUnreadCount((prev) => prev + 1);
      }
    });

    socket.current.on("update_unread_count", ({ totalUnread }) => {
      if (location.pathname !== "/home/chat") {
        setUnreadCount(totalUnread);
      }
    });

    socket.current.on("messages read", ({ chatId, count }) => {
      if (location.pathname !== "/home/chat") {
        setUnreadCount((prev) => Math.max(0, prev - count));
      }
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [user, location.pathname]);

  useEffect(() => {
    const handlePathChange = async () => {
      if (location.pathname === "/home/chat") {
        setUnreadCount(0);
      } else {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/chat/unread-count`,
            {
              credentials: "include",
            }
          );
          if (response.ok) {
            const { totalUnread } = await response.json();
            setUnreadCount(totalUnread);
          }
        } catch (error) {
          console.error("Error fetching unread count:", error);
        }
      }
    };

    handlePathChange();
  }, [location.pathname]);

  const handleNavClick = (path) => {
    if (path === "/home/chat") {
      setUnreadCount(0);
    }
    navigate(path);
  };

  const navItems = [
    {
      id: "dashboard",
      icon: DashboardIcon,
      path: "/home/buy",
      label: "Dashboard",
      tooltip: "Dashboard",
    },
    {
      id: "chat",
      icon: ChatIcon,
      path: "/home/chat",
      label: "Chat",
      tooltip: "Messages",
      notifications: unreadCount,
    },
    {
      id: "friends",
      icon: FriendsIcon,
      path: "/home/friends",
      label: "Friends",
      tooltip: "Friends",
    },
    {
      id: "wallet",
      icon: WalletIcon,
      path: "/home/wallet",
      label: t("myWallet"),
      tooltip: t("myWallet"),
    },
    {
      id: "settings",
      icon: SettingsIcon,
      path: "/home/account-settings",
      label: "Settings",
      tooltip: "Settings",
    },
  ];

  const isActive = (path) => {
    if (location.pathname === path) return true;

    if (path === "/home/wallet" && location.pathname.startsWith("/home/wallet"))
      return true;

    return false;
  };

  return (
    <StyledSidebar
      initial={{ x: -60, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <NavItems>
        {navItems.map((item) => (
          <Tippy
            key={item.id}
            content={item.tooltip}
            animation="shift-away"
            placement="right"
            duration={[300, 250]}
            delay={[400, 0]}
            theme="modern"
            arrow={true}
          >
            <NavButton
              $isActive={isActive(item.path)}
              onClick={() => handleNavClick(item.path)}
              whileHover={{
                backgroundColor: "rgba(136, 83, 251, 0.05)",
                scale: 1.02,
              }}
              whileTap={{ scale: 0.98 }}
            >
              <IconContainer>
                <item.icon size={20} />
                {item.id === "chat" &&
                  unreadCount > 0 &&
                  !isActive(item.path) && (
                    <NotificationBadge
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                    >
                      {unreadCount}
                    </NotificationBadge>
                  )}
              </IconContainer>
              {isActive(item.path) && (
                <ActiveIndicator
                  layoutId="activeIndicator"
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                />
              )}
            </NavButton>
          </Tippy>
        ))}
      </NavItems>
      <ThemeToggleContainer>
        <Tippy
          content={
            theme.isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"
          }
          animation="shift-away"
          placement="right"
          duration={[300, 250]}
          delay={[400, 0]}
          theme="modern"
          arrow={true}
        >
          <ThemeToggleButton
            onClick={theme.toggleTheme}
            whileHover={{
              backgroundColor: "rgba(136, 83, 251, 0.05)",
              scale: 1.02,
            }}
            whileTap={{ scale: 0.98 }}
          >
            {theme.isDarkMode ? <LightIcon /> : <DarkIcon />}
          </ThemeToggleButton>
        </Tippy>
      </ThemeToggleContainer>
    </StyledSidebar>
  );
});

export default Sidebar;
