// DetailsTab.jsx

import React from "react";
import { useTranslation } from "react-i18next";
import { FileText, Calendar, BookOpen, MapPin } from "react-feather";
import {
  TabDetails,
  ScrollableContent,
  DetailsGrid as BaseDetailsGrid,
  DetailItem as BaseDetailItem,
  DetailIcon as BaseDetailIcon,
  DetailContent,
  DetailLabel as BaseDetailLabel,
  DetailValue as BaseDetailValue,
} from "./TabStyles";
import styled from "styled-components";
import { LoadingText } from "../../common/LoadingAnimation";
import { useTheme } from "../../../context/ThemeContext";

const DetailsGrid = styled(BaseDetailsGrid)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const DetailItem = styled(BaseDetailItem)`
  background: ${({ theme }) =>
    theme.isDarkMode ? "rgba(38, 38, 45, 0.95)" : "rgba(255, 255, 255, 0.95)"};
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 1px solid
    ${({ theme }) =>
      theme.isDarkMode ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)"};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode
      ? "0 2px 8px rgba(0, 0, 0, 0.2)"
      : "0 2px 8px rgba(136, 83, 251, 0.08)"};
`;

const DetailIcon = styled(BaseDetailIcon)`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 10px;
`;

const DetailLabel = styled(BaseDetailLabel)`
  color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)"};
  font-size: 12px;
  margin-bottom: 4px;
`;

const DetailValue = styled(BaseDetailValue)`
  color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.9)" : "rgba(0, 0, 0, 0.9)"};
  font-size: 14px;
`;

const FullWidthDetail = styled(DetailItem)`
  grid-column: 1 / -1;
`;

// DetailsTab Component
const DetailsTab = ({ property, isTranslating }) => {
  const { t } = useTranslation();

  // Get details from property
  const details = property?.details;

  if (!details) {
    return <div>{t("noDetailsAvailable")}</div>;
  }

  const detailItems = [
    {
      icon: <FileText size={18} />,
      label: t("caseStyle"),
      value: details.case_style,
    },
    {
      icon: <FileText size={18} />,
      label: t("causeNumber"),
      value: details.cause_nbr,
    },
    {
      icon: <FileText size={18} />,
      label: t("courtNumber"),
      value: details.court_nbr,
    },
    {
      icon: <Calendar size={18} />,
      label: t("judgmentDate"),
      value: details.judgment_date,
    },
    {
      icon: <BookOpen size={18} />,
      label: t("legalDescription"),
      value: details.legal_desc_l,
      fullWidth: true,
    },
    {
      icon: <BookOpen size={18} />,
      label: t("legalDescriptionShort"),
      value: details.legal_desc_s,
      fullWidth: true,
    },
    {
      icon: <FileText size={18} />,
      label: t("propertyType"),
      value: details.prop_type,
    },
    {
      icon: <MapPin size={18} />,
      label: t("saleLocation"),
      value: details.sale_location,
      fullWidth: true,
    },
    {
      icon: <FileText size={18} />,
      label: t("saleNotes"),
      value: details.sale_notes,
    },
    {
      icon: <FileText size={18} />,
      label: t("schoolDistrict"),
      value: details.school_district,
    },
  ];

  return (
    <TabDetails>
      <ScrollableContent>
        <DetailsGrid>
          {detailItems.map((item, index) =>
            item.fullWidth ? (
              <FullWidthDetail key={index}>
                <DetailIcon>{item.icon}</DetailIcon>
                <DetailContent>
                  <DetailLabel>{item.label}</DetailLabel>
                  <DetailValue>
                    {isTranslating ? (
                      <LoadingText>
                        {item.value || t("notAvailable")}
                      </LoadingText>
                    ) : (
                      item.value || t("notAvailable")
                    )}
                  </DetailValue>
                </DetailContent>
              </FullWidthDetail>
            ) : (
              <DetailItem key={index}>
                <DetailIcon>{item.icon}</DetailIcon>
                <DetailContent>
                  <DetailLabel>{item.label}</DetailLabel>
                  <DetailValue>
                    {isTranslating ? (
                      <LoadingText>
                        {item.value || t("notAvailable")}
                      </LoadingText>
                    ) : (
                      item.value || t("notAvailable")
                    )}
                  </DetailValue>
                </DetailContent>
              </DetailItem>
            )
          )}
        </DetailsGrid>
      </ScrollableContent>
    </TabDetails>
  );
};

export default DetailsTab;
