import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useLinkReport } from "../../context/LinkReportContext";
import { useTranslation } from "react-i18next";
import { CheckCircle, X as CloseIcon } from "react-feather";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "../../context/ThemeContext";

const ModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => `linear-gradient(
    165deg,
    ${
      theme.isDarkMode ? "rgba(28, 28, 35, 0.85)" : "rgba(255, 255, 255, 0.85)"
    },
    ${theme.isDarkMode ? "rgba(18, 18, 24, 0.95)" : "rgba(248, 249, 252, 0.95)"}
  )`};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  width: 90%;
  max-width: 440px;
  border: 1px solid ${({ theme }) => theme.colors.glass.border};
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const ReportCard = styled.div`
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${(props) =>
    props.$isSubmitted ? "rotateY(180deg)" : "rotateY(0)"};
`;

const CardSide = styled.div`
  backface-visibility: hidden;
  position: ${(props) => (props.$isBack ? "absolute" : "relative")};
  inset: 0;
  transform: ${(props) => (props.$isBack ? "rotateY(180deg)" : "rotateY(0)")};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  background: ${({ theme }) =>
    theme.isDarkMode ? "rgba(38, 38, 45, 0.5)" : "rgba(255, 255, 255, 0.5)"};
  border: 1px solid ${({ theme }) => theme.colors.glass.border};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100;

  &:hover {
    background: ${({ theme }) =>
      theme.isDarkMode ? "rgba(43, 43, 50, 0.6)" : "rgba(255, 255, 255, 0.6)"};
    border-color: ${({ theme }) => theme.colors.primary};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0 0 1.5rem;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.text};
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);
`;

const ReportDetails = styled.div`
  margin-bottom: 20px;

  p {
    color: ${({ theme }) => theme.colors.text};
    margin: 8px 0;
    font-size: 14px;

    strong {
      color: ${({ theme }) => theme.colors.primary};
      margin-right: 8px;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
  }

  textarea {
    background: ${({ theme }) => `linear-gradient(
      165deg,
      ${
        theme.isDarkMode ? "rgba(38, 38, 45, 0.5)" : "rgba(255, 255, 255, 0.5)"
      },
      ${theme.isDarkMode ? "rgba(28, 28, 35, 0.5)" : "rgba(248, 249, 252, 0.5)"}
    )`};
    border: 1px solid ${({ theme }) => theme.colors.glass.border};
    border-radius: 12px;
    padding: 12px;
    color: ${({ theme }) => theme.colors.text};
    resize: vertical;
    min-height: 100px;
    font-size: 14px;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}33;
    }

    &::placeholder {
      color: ${({ theme }) =>
        theme.isDarkMode ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)"};
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 48px;
  background: ${({ theme }) => `linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  )`};
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      transform: translateY(-2px) scale(1.02);
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.2),
        rgba(96, 57, 176, 0.2)
      );
      border-color: rgba(136, 83, 251, 0.4);
      box-shadow: 
        0 8px 32px rgba(136, 83, 251, 0.2),
        0 0 0 1px rgba(136, 83, 251, 0.2);
    }

    &:active {
      transform: translateY(1px) scale(0.98);
    }
  `}
`;

const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.text};

  svg {
    color: ${({ theme }) => theme.colors.success};
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.text};
  }

  p {
    margin-bottom: 24px;
    color: ${({ theme }) =>
      theme.isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)"};
  }
`;

const ErrorMessage = styled(motion.div)`
  background: ${({ theme }) => `linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.1),
    rgba(255, 59, 48, 0.05)
  )`};
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 59, 48, 0.2);
  border-radius: 12px;
  padding: 12px 16px;
  margin-top: 16px;
  font-size: 13px;
  color: #ff3b30;
  display: flex;
  align-items: center;
  gap: 12px;
  transform-origin: top;
`;

const ReportLinkModal = ({
  isOpen,
  onClose,
  propertyId,
  reportedUrl,
  resourceType,
  resourceTitle,
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  const { submitReport, loading: isSubmitting, error } = useLinkReport();
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);

    if (!propertyId) {
      setSubmitError(t("reportLinkMissingId"));
      return;
    }

    try {
      await submitReport({
        uid: propertyId,
        url: reportedUrl,
        reason,
        resourceType,
        resourceTitle,
      });

      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting report:", error);
      setSubmitError(error.message || t("reportLinkError"));
    }
  };

  const handleClose = () => {
    onClose();
    // Reset state after animation
    setTimeout(() => {
      setSubmitted(false);
      setReason("");
    }, 300);
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          onClick={(e) => {
            if (e.target === e.currentTarget) handleClose();
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <CloseButton onClick={handleClose}>
              <CloseIcon size={16} />
            </CloseButton>
            <ReportCard $isSubmitted={submitted}>
              <CardSide>
                <Title>{t("reportLinkTitle")}</Title>
                <ReportDetails>
                  <p>
                    <strong>{t("reportLinkResourceType")}:</strong>{" "}
                    {resourceType}
                  </p>
                  <p>
                    <strong>{t("reportLinkResourceTitle")}:</strong>{" "}
                    {resourceTitle}
                  </p>
                  <p>
                    <strong>{t("reportLinkUrl")}:</strong> {reportedUrl}
                  </p>
                </ReportDetails>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <label>{t("reportLinkDescription")}</label>
                    <textarea
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      required
                      rows={4}
                      placeholder={t("reportLinkPlaceholder")}
                    />
                  </FormGroup>
                  <SubmitButton type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? t("reportLinkSubmitting")
                      : t("reportLinkSubmit")}
                  </SubmitButton>
                </Form>
              </CardSide>

              <CardSide $isBack>
                <SuccessContent>
                  <CheckCircle size={60} />
                  <h2>{t("reportLinkSuccess")}</h2>
                  <p>{t("reportLinkSuccessMessage")}</p>
                  <SubmitButton onClick={handleClose}>
                    {t("reportLinkClose")}
                  </SubmitButton>
                </SuccessContent>
              </CardSide>
            </ReportCard>

            <AnimatePresence>
              {submitError && (
                <ErrorMessage
                  initial={{ opacity: 0, y: -20, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: -20, scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  {submitError}
                </ErrorMessage>
              )}
            </AnimatePresence>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>,
    document.getElementById("modal-root")
  );
};

export default ReportLinkModal;
