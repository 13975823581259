import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../../../context/ThemeContext";
import styled from "styled-components";
import { motion } from "framer-motion";

const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  position: relative;
`;

const FieldTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(28, 28, 35, 0.7)"};
  margin-bottom: 8px;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  font-weight: 500;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
`;

const Track = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? "rgba(255, 255, 255, 0.15)"
      : "rgba(136, 83, 251, 0.15)"};
  border-radius: 50px;
  top: 50%;
  transform: translateY(-50%);
`;

const TrackFill = styled(motion.div)`
  position: absolute;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${({ theme }) =>
      theme.isDarkMode
        ? theme.colors.accent.purple.base
        : "rgba(136, 83, 251, 0.6)"},
    ${({ theme }) =>
      theme.isDarkMode
        ? theme.colors.accent.purple.dark
        : "rgba(96, 57, 176, 0.6)"}
  );
  border-radius: 50px;
  box-shadow: 0 0 10px
    ${({ theme }) =>
      theme.isDarkMode
        ? `${theme.colors.accent.purple.base}40`
        : "rgba(136, 83, 251, 0.2)"};
`;

const ThumbLeft = styled(motion.div)`
  position: absolute;
  width: 16px;
  height: 16px;
  background: linear-gradient(
    135deg,
    ${({ theme }) =>
      theme.isDarkMode
        ? theme.colors.accent.purple.base
        : "rgba(136, 83, 251, 0.8)"},
    ${({ theme }) =>
      theme.isDarkMode
        ? theme.colors.accent.purple.dark
        : "rgba(96, 57, 176, 0.8)"}
  );
  border: 2px solid
    ${({ theme }) =>
      theme.isDarkMode
        ? "rgba(255, 255, 255, 0.9)"
        : "rgba(136, 83, 251, 0.9)"};
  border-radius: 50%;
  cursor: grab;
  top: -10px;
  transform: translateX(-50%);
  z-index: 2;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode
      ? "0 2px 10px rgba(0, 0, 0, 0.3)"
      : "0 2px 8px rgba(136, 83, 251, 0.25)"};

  &:hover {
    box-shadow: 0 0 0 6px
      ${({ theme }) =>
        theme.isDarkMode
          ? `${theme.colors.accent.purple.base}20`
          : "rgba(136, 83, 251, 0.15)"};
  }

  &:active {
    cursor: grabbing;
    box-shadow: 0 0 0 8px
      ${({ theme }) =>
        theme.isDarkMode
          ? `${theme.colors.accent.purple.base}30`
          : "rgba(136, 83, 251, 0.2)"};
  }

  &::before {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: 50%;
    background: linear-gradient(
      135deg,
      ${({ theme }) =>
        theme.isDarkMode
          ? `${theme.colors.accent.purple.base}40`
          : "rgba(136, 83, 251, 0.3)"},
      transparent
    );
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const ThumbRight = styled(ThumbLeft)``;

const ThumbContainerLeft = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  left: 0px;
`;

const ThumbContainerRight = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  right: 20px;
`;

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

const ValueInput = styled.input`
  width: 80%;
  background: ${({ theme }) => theme.colors.glass.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.text};
  font-family: "SF Mono", "Roboto Mono", monospace;
  font-size: 14px;
  text-align: right;
  transition: all 0.2s ease;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surfaceHover};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surfaceHover};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }
`;

const RangeSlider = ({ label, min, max, step, initialValue, onChange }) => {
  const theme = useTheme();
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);
  const [isDragging, setIsDragging] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);
  const containerRef = useRef(null);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // Convert linear position to logarithmic value
  const positionToValue = (position) => {
    const minLog = Math.log(min);
    const maxLog = Math.log(max);
    const scale = (maxLog - minLog) / 100;
    return Math.exp(minLog + scale * position);
  };

  // Convert logarithmic value to linear position
  const valueToPosition = (value) => {
    const minLog = Math.log(min);
    const maxLog = Math.log(max);
    const scale = (maxLog - minLog) / 100;
    return (Math.log(value) - minLog) / scale;
  };

  const handleStart = (e, isMin) => {
    e.preventDefault();
    setIsDragging(true);
    setActiveThumb(isMin ? "min" : "max");
  };

  const handleMove = (clientX) => {
    if (!containerRef.current || !isDragging) return;

    const rect = containerRef.current.getBoundingClientRect();
    const percentage = Math.min(
      Math.max(((clientX - rect.left) / rect.width) * 100, 0),
      100
    );

    // Convert the percentage to a logarithmic value
    const newValue = Math.round(positionToValue(percentage));

    if (activeThumb === "min") {
      if (newValue < maxValue) setMinValue(newValue);
    } else {
      if (newValue > minValue) setMaxValue(newValue);
    }
  };

  const handleEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      setActiveThumb(null);
      onChange([minValue, maxValue]);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => handleMove(e.clientX);
    const handleTouchMove = (e) => handleMove(e.touches[0].clientX);

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchend", handleEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [isDragging, minValue, maxValue]);

  useEffect(() => {
    if (initialValue && Array.isArray(initialValue)) {
      setMinValue(initialValue[0]);
      setMaxValue(initialValue[1]);
    }
  }, [initialValue]);

  // Get percentage for positioning based on logarithmic scale
  const getPercentage = (value) => {
    return valueToPosition(value);
  };

  return (
    <RangeContainer>
      <FieldTitle>{label}</FieldTitle>
      <SliderContainer ref={containerRef}>
        <Track />
        <TrackFill
          style={{
            left: `${getPercentage(minValue)}%`,
            width: `${getPercentage(maxValue) - getPercentage(minValue)}%`,
          }}
        />
        <ThumbContainerLeft>
          <ThumbLeft
            style={{ left: `${getPercentage(minValue)}%` }}
            onMouseDown={(e) => handleStart(e, true)}
            onTouchStart={(e) => handleStart(e, true)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            animate={{
              scale: activeThumb === "min" ? 0.9 : 1,
              boxShadow:
                activeThumb === "min"
                  ? theme.isDarkMode
                    ? "0 0 0 10px rgba(136, 83, 251, 0.2)"
                    : "0 0 0 10px rgba(136, 83, 251, 0.15)"
                  : "none",
            }}
          />
        </ThumbContainerLeft>
        <ThumbContainerRight>
          <ThumbRight
            style={{ left: `${getPercentage(maxValue)}%` }}
            onMouseDown={(e) => handleStart(e, false)}
            onTouchStart={(e) => handleStart(e, false)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            animate={{
              scale: activeThumb === "max" ? 0.9 : 1,
              boxShadow:
                activeThumb === "max"
                  ? theme.isDarkMode
                    ? "0 0 0 10px rgba(136, 83, 251, 0.2)"
                    : "0 0 0 10px rgba(136, 83, 251, 0.15)"
                  : "none",
            }}
          />
        </ThumbContainerRight>
      </SliderContainer>
      <InputsContainer>
        <ValueInput
          type="text"
          value={formatter.format(minValue)}
          onChange={(e) => {
            const value = parseInt(e.target.value.replace(/\D/g, ""));
            if (!isNaN(value) && value < maxValue) {
              setMinValue(value);
              onChange([value, maxValue]);
            }
          }}
        />
        <ValueInput
          type="text"
          value={formatter.format(maxValue)}
          onChange={(e) => {
            const value = parseInt(e.target.value.replace(/\D/g, ""));
            if (!isNaN(value) && value > minValue) {
              setMaxValue(value);
              onChange([minValue, value]);
            }
          }}
        />
      </InputsContainer>
    </RangeContainer>
  );
};

export default RangeSlider;
