import { io } from "socket.io-client";

const API_URL = process.env.REACT_APP_API_URL;

export const archiveChat = async (chatId) => {
  try {
    const response = await fetch(
      `${API_URL}/api/chat/chats/${chatId}/archive`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to archive chat");
    }

    return await response.json();
  } catch (error) {
    console.error("Error archiving chat:", error);
    throw error;
  }
};

export const unarchiveChat = async (chatId) => {
  try {
    const response = await fetch(
      `${API_URL}/api/chat/chats/${chatId}/unarchive`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to unarchive chat");
    }

    return await response.json();
  } catch (error) {
    console.error("Error unarchiving chat:", error);
    throw error;
  }
};

export const createChatApi = ({
  setChats,
  setMessages,
  setMessage,
  socket,
  showToast,
  navigate,
  setSearchResults,
  setActiveChat,
  user,
}) => {
  // Initialize socket if not already initialized
  if (!socket.current) {
    socket.current = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket"],
      path: "/socket.io",
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      auth: {
        token: document.cookie,
      },
      extraHeaders: {
        Cookie: document.cookie,
      },
    });

    socket.current.on("connect_error", (error) => {
      console.error("Socket connection error in chatApi:", error);
    });

    socket.current.on("connect", () => {
      if (user?._id) {
        socket.current.emit("user connected", user._id);
      }
    });
  }

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchChats = async () => {
    try {
      const response = await fetch(`${API_URL}/api/chat/chats`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch chats");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching chats:", error);
      throw error;
    }
  };

  const fetchMessages = async (chatId, page = 1, limit = 20) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${chatId}/messages?page=${page}&limit=${limit}`,
        {
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const messageArray = data.messages || [];

        setMessages((prev) => {
          const prevMessages = Array.isArray(prev) ? prev : [];
          const combinedMessages = page === 1 
            ? messageArray 
            : [...messageArray, ...prevMessages];
          
          const uniqueMessages = combinedMessages.filter((msg, index, self) =>
            index === self.findIndex(m => m._id === msg._id)
          );
          
          return uniqueMessages;
        });

        return data.hasMore;
      }
      setMessages([]);
      return false;
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessages([]);
      return false;
    }
  };

  const loadMoreMessages = async (chatId, page) => {
    return await fetchMessages(chatId, page);
  };

  const handleSend = async (message, activeChat) => {
    const messageContent = String(message || "").trim();
    if (!messageContent || !activeChat) return;

    // Create a temporary message object with pending status
    const tempMessage = {
      _id: Date.now().toString(), // Temporary ID
      content: messageContent,
      sender: user,
      createdAt: new Date(),
      status: "sending",
      isTemporary: true
    };

    // Add message to UI immediately with pending status
    setMessages((prev) => [...prev, tempMessage]);
    setMessage(""); // Clear the input

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${activeChat}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ content: messageContent }),
        }
      );

      if (response.ok) {
        const newMessage = await response.json();

        // Replace temporary message with actual message
        setMessages((prev) => 
          prev.map(msg => msg._id === tempMessage._id ? newMessage : msg)
        );

        // Emit the new message to socket with active chat info
        if (socket.current) {
          socket.current.emit("new message", {
            message: newMessage,
            chat: activeChat,
            isActive: true // Indicate that sender is actively in the chat
          });
        }

        // Update chat list with new message
        setChats((prev) =>
          prev.map((chat) => {
            if (chat._id === activeChat) {
              return {
                ...chat,
                lastMessage: newMessage,
              };
            }
            return chat;
          })
        );
      } else {
        const error = await response.json();
        console.error("Error response:", error);

        // Update temporary message to show not delivered status
        setMessages((prev) => 
          prev.map(msg => {
            if (msg._id === tempMessage._id) {
              return {
                ...msg,
                status: "not_delivered",
                error: error.error || "Failed to send message"
              };
            }
            return msg;
          })
        );

        if (error.error === "Message not delivered - User is blocked") {
          showToast("Message not delivered - User is blocked", "error");
        } else if (error.error === "User not authenticated") {
          navigate("/");
        } else {
          showToast(error.error || "Failed to send message", "error");
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      
      // Update temporary message to show error status
      setMessages((prev) => 
        prev.map(msg => {
          if (msg._id === tempMessage._id) {
            return {
              ...msg,
              status: "not_delivered",
              error: "Failed to send message"
            };
          }
          return msg;
        })
      );
      
      showToast("Failed to send message", "error");
    }
  };

  const handleNewChat = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            name: "New Chat",
            participants: [], // Add logic to select participants
          }),
        }
      );

      if (response.ok) {
        const newChat = await response.json();
        setChats((prev) => [...prev, newChat]);
        setActiveChat(newChat._id);
      }
    } catch (error) {
      console.error("Error creating new chat:", error);
    }
  };

  const searchUsers = async (query) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/users/search?q=${query}`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
      }
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  const handleUserSelect = async (userId, activeChat) => {
    try {
      if (!activeChat) {
        // If no active chat, create a new one
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat/chats`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              name: "New Chat",
              participants: [userId],
            }),
          }
        );

        if (response.ok) {
          const newChat = await response.json();
          setChats((prev) => [...prev, newChat]);
          setActiveChat(newChat._id);
          return true;
        }
      } else {
        // Add user to existing chat
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat/chats/${activeChat}/users`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ userId }),
          }
        );

        if (response.ok) {
          // Get the populated chat data
          const updatedChat = await response.json();

          // Update chats list with the populated chat data
          setChats((prev) =>
            prev.map((chat) => (chat._id === activeChat ? updatedChat : chat))
          );

          showToast("User added to chat successfully", "success");
          return true;
        }
        showToast("Failed to add user to chat", "error");
      }
      return false;
    } catch (error) {
      console.error("Error managing chat:", error);
      showToast("Failed to add user to chat", "error");
      return false;
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/messages/${messageId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (response.ok) {
        setMessages((prev) => prev.filter((msg) => msg._id !== messageId));
        showToast("Message deleted for you", "success");
        return true;
      } else {
        const error = await response.json();
        showToast(error.error || "Failed to delete message", "error");
        return false;
      }
    } catch (error) {
      console.error("Error deleting message:", error);
      showToast("Failed to delete message", "error");
      return false;
    }
  };

  const handleDeleteChat = async (activeChat) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${activeChat}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (response.ok) {
        setChats((prev) => prev.filter((chat) => chat._id !== activeChat));
        setActiveChat(null);
        setMessages([]); // Clear messages
        showToast("Chat deleted successfully", "success");
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error deleting chat:", error);
      showToast("Failed to delete chat", "error");
      return false;
    }
  };

  const handleChatClick = async (chatId) => {
    try {
      // Clear unread messages
      const clearUnreadResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${chatId}/clear-unread`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (clearUnreadResponse.ok) {
        setChats((prev) =>
          prev.map((chat) => {
            if (chat._id === chatId) {
              return {
                ...chat,
                unreadCounts: {
                  ...chat.unreadCounts,
                  [user._id]: 0,
                },
              };
            }
            return chat;
          })
        );
      }

      // Fetch messages
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${chatId}/messages`,
        {
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const messageArray = data.messages || [];
        setMessages(messageArray);
        return true;
      }
      
      setMessages([]);
      return false;
    } catch (error) {
      console.error("Error handling chat click:", error);
      setMessages([]);
      return false;
    }
  };

  const handleUnsendMessage = async (messageId, activeChat) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/messages/${messageId}/unsend`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (response.ok) {
        setMessages((prev) =>
          prev.map((msg) => {
            if (msg._id === messageId) {
              return {
                ...msg,
                isUnsent: true,
                content: "This message was unsent",
              };
            }
            return msg;
          })
        );

        // Manually update the chats state if the unsent message is the last message
        setChats((prevChats) =>
          prevChats.map((chat) => {
            if (
              chat._id === activeChat &&
              chat.lastMessage?._id === messageId
            ) {
              return {
                ...chat,
                lastMessage: {
                  ...chat.lastMessage,
                  isUnsent: true,
                  content: "This message was unsent",
                },
              };
            }
            return chat;
          })
        );

        // Emit the event to other clients
        socket.current.emit("message_unsent", {
          messageId,
          chatId: activeChat,
        });

        showToast("Message unsent successfully", "success");
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error unsending message:", error);
      showToast("Failed to unsend message", "error");
      return false;
    }
  };

  const handleChatInteraction = async (activeChat) => {
    if (activeChat) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat/chats/${activeChat}/clear-unread`,
          {
            method: "POST",
            credentials: "include",
          }
        );

        if (response.ok) {
          setChats((prev) =>
            prev.map((chat) => {
              if (chat._id === activeChat) {
                return {
                  ...chat,
                  unreadCounts: {
                    ...chat.unreadCounts,
                    [user._id]: 0,
                  },
                };
              }
              return chat;
            })
          );
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error clearing unread count:", error);
        return false;
      }
    }
  };

  const handleEditMessage = async (messageId, newContent, activeChat) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/messages/${messageId}/edit`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ content: newContent }),
        }
      );

      if (response.ok) {
        const updatedMessage = await response.json();

        setMessages((prev) =>
          prev.map((msg) => (msg._id === messageId ? updatedMessage : msg))
        );

        socket.current.emit("message_edited", {
          messageId,
          updatedMessage,
          chatId: activeChat,
        });

        showToast("Message updated successfully", "success");
        return true;
      } else {
        const error = await response.json();
        showToast(error.error || "Failed to edit message", "error");
        return false;
      }
    } catch (error) {
      console.error("Error editing message:", error);
      showToast("Failed to edit message", "error");
      return false;
    }
  };

  return {
    fetchChats,
    fetchMessages,
    loadMoreMessages,
    handleSend,
    handleNewChat,
    searchUsers,
    handleUserSelect,
    handleDeleteMessage,
    handleDeleteChat,
    handleChatClick,
    handleUnsendMessage,
    handleChatInteraction,
    handleEditMessage,
    archiveChat,
    unarchiveChat,
  };
};

export const createOrGetChat = async (userId) => {
  try {
    const API_URL = process.env.REACT_APP_API_URL;

    const response = await fetch(`${API_URL}/api/chat/create-or-get`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error("Failed to create or get chat");
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating/getting chat:", error);
    throw error;
  }
};

export const updateChatTitle = async (chatId, title) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/chat/chats/${chatId}/title`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update chat title");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating chat title:", error);
    throw error;
  }
};
