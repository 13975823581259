import React from "react";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

const PaginationContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  
  gap: 12px;
  position: sticky;
  z-index: 10;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background: ${({ theme }) => theme.colors.surface};
  padding: 6px;
  border-radius: 16px;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  box-shadow: ${({ theme }) => theme.colors.shadow};
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const PaginationButton = styled(motion.button)`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 12px;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  &.edge-button {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &::after {
    content: "";
    position: absolute;
    top: 25%;
    height: 50%;
    width: 1px;
    background: ${({ theme }) => theme.colors.border};
  }
`;

const Numbers = styled.div`
  display: flex;
  gap: 2px;
  padding: 0 4px;
  position: relative;
  justify-content: center;
  overflow: hidden;
`;

const NumbersInner = styled(motion.div)`
  display: flex;
  gap: 2px;
  position: relative;
`;

const PageButton = styled(motion.button)`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "SF Mono", "Roboto Mono", monospace;
  font-size: 13px;
  font-weight: 500;
  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover:not(.active) {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  &.active {
    background: ${({ theme }) => theme.colors.buttonGradient};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
    box-shadow: ${({ theme }) => theme.colors.shadow};
    border: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const PageNumber = styled.span`
  position: relative;
  display: inline-block;
  transition: transform 0.2s ease;

  ${PageButton}:hover & {
    transform: translateY(-1px);
  }
`;

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const maxItems = 15;
  const halfItems = Math.floor(maxItems / 2);

  let startPage = Math.max(currentPage - halfItems, 1);
  let endPage = startPage + maxItems - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxItems + 1, 1);
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const formatPageNumber = (page) => {
    if (page < 10) return `0${page}`;
    if (page < 100) return `${page}`;
    if (page < 1000) return `${page}`;
    return `${page}`;
  };

  return (
    <PaginationContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Controls>
        <PaginationButton
          as={motion.button}
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="First Page"
          className="edge-button"
        >
          <ChevronsLeft size={16} />
        </PaginationButton>
        <PaginationButton
          as={motion.button}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="Previous Page"
          className="nav-button"
        >
          <ChevronLeft size={16} />
        </PaginationButton>

        <Numbers>
          <NumbersInner layout>
            <AnimatePresence mode="popLayout" initial={false}>
              {pages.map((page) => (
                <PageButton
                  key={page}
                  as={motion.button}
                  className={currentPage === page ? "active" : ""}
                  onClick={() => onPageChange(page)}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      type: "spring",
                      stiffness: 500,
                      damping: 30,
                    },
                  }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <PageNumber>{formatPageNumber(page)}</PageNumber>
                </PageButton>
              ))}
            </AnimatePresence>
          </NumbersInner>
        </Numbers>

        <PaginationButton
          as={motion.button}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || totalPages === 0}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="Next Page"
          className="nav-button"
        >
          <ChevronRight size={16} />
        </PaginationButton>
        <PaginationButton
          as={motion.button}
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages || totalPages === 0}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="Last Page"
          className="edge-button"
        >
          <ChevronsRight size={16} />
        </PaginationButton>
      </Controls>
    </PaginationContainer>
  );
};

export default Pagination;
