// SwarmAlive.js
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useTheme } from "../../context/ThemeContext";
import SwarmManager from "../../services/SwarmManager";
// Styled Components
const SwarmContainer = styled(motion.div)`
  width: ${(props) => props.$size || "40px"};
  height: ${(props) => props.$size || "40px"};
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: auto;

  @media (max-width: 480px) {
    width: ${(props) => (props.$size ? `calc(${props.$size} * 0.8)` : "32px")};
    height: ${(props) => (props.$size ? `calc(${props.$size} * 0.8)` : "32px")};
  }
`;

const ParticleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: ${(props) => (props.$mode === "frenetic" ? "visible" : "hidden")};
  background: transparent;
  transform-style: preserve-3d;
  perspective: 1000px;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: ${(props) =>
    props.$mode === "frenetic"
      ? "transparent"
      : `radial-gradient(
          circle at 30% 30%,
          rgba(255, 255, 255, 0.08) 0%,
          rgba(255, 255, 255, 0.03) 20%,
          transparent 70%
        )`};
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 0 20px rgba(136, 83, 251, 0.15),
    inset 0 0 5px rgba(255, 255, 255, 0.1);
  pointer-events: none;
  z-index: 2;
  opacity: 0.4;
  will-change: auto;
`;

const Highlight = styled(motion.div)`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4),
    transparent 80%
  );
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
`;

const EnergyRing = styled(motion.div)`
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: 1px solid rgba(136, 83, 251, 0.2);
  background: radial-gradient(
    circle at center,
    rgba(136, 83, 251, 0.1) 0%,
    transparent 70%
  );
  pointer-events: none;
  z-index: 1;
  box-shadow: inset 0 0 15px rgba(136, 83, 251, 0.2),
    0 0 15px rgba(136, 83, 251, 0.2);
  opacity: 1;
`;

// SwarmAlive Component
const SwarmAlive = ({
  size = "40px",
  mode = "normal",
  enableHover = true,
  active = false,
  targetComponent = null,
  instanceId
}) => {
  const containerRef = useRef(null);
  const { isDarkMode } = useTheme();
  const [isReady, setIsReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const particleIdRef = useRef(
    `particles-${instanceId || Math.random().toString(36).substr(2, 9)}`
  );
  
  // Add size check
  const isSmallContainer = parseInt(size) <= 100;

  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 10;

    const checkParticles = () => {
      if (window.particlesJS) {
        setIsReady(true);
        return;
      }

      attempts++;
      if (attempts >= maxAttempts) {
        setHasError(true);
        return;
      }

      setTimeout(checkParticles, 100);
    };

    checkParticles();
  }, []);

  useEffect(() => {
    if (!containerRef.current || !isReady || !window.particlesJS || hasError) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          const isNowVisible = entry.isIntersecting;
          setIsVisible(isNowVisible);
          
          if (isNowVisible) {
            SwarmManager.initParticles(particleIdRef.current, mode, isSmallContainer);
            SwarmManager.startPerformanceMonitoring();
          } else {
            SwarmManager.pause();
            SwarmManager.stopPerformanceMonitoring();
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
      SwarmManager.stopPerformanceMonitoring();
    };
  }, [isReady, hasError, mode, isSmallContainer]);

  if (hasError) {
    return (
      <SwarmContainer
        $size={size}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <BubbleSkin
          $mode={mode}
          animate={{
            scale: active ? 1.02 : 1,
            opacity: active ? 0.9 : 0.6,
          }}
          transition={{
            type: "spring",
            stiffness: 400,
            damping: 20,
          }}
        />
        <Highlight
          animate={{
            opacity: active ? 0.5 : 0.3,
          }}
          transition={{
            duration: 0.3,
          }}
        />
        <EnergyRing
          animate={{
            opacity: 1,
            scale: active ? 1.1 : 1,
          }}
          transition={{
            type: "spring",
            stiffness: 400,
            damping: 25,
          }}
        />
      </SwarmContainer>
    );
  }

  return (
    <SwarmContainer
      $size={size}
      initial={{ opacity: 0 }}
      animate={{ opacity: isReady && isVisible ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      whileHover={enableHover ? { scale: 1.05 } : undefined}
    >
      <ParticleContainer
        ref={containerRef}
        id={particleIdRef.current}
        $mode={mode}
      />
      <BubbleSkin
        $mode={mode}
        animate={{
          scale: active ? 1.02 : 1,
          opacity: active ? 0.9 : 0.6,
        }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 20,
        }}
      />
      <Highlight
        animate={{
          opacity: active ? 0.5 : 0.3,
        }}
        transition={{
          duration: 0.3,
        }}
      />
      <EnergyRing
        animate={{
          opacity: 1,
          scale: active ? 1.1 : 1,
        }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 25,
        }}
      />
    </SwarmContainer>
  );
};

export default SwarmAlive;
