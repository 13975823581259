import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { motion } from "framer-motion";
import { keyframes } from "styled-components";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SwarmAlive from "./SwarmAlive";

gsap.registerPlugin(ScrollTrigger);

const ParticleWrapper = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const wobble = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(1.02) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.98) rotate(-1deg);
  }
  75% {
    transform: translate(-50%, -50%) scale(1.02) rotate(0.5deg);
  }
`;

const pulseNeon = keyframes`
  0%, 100% {
    box-shadow: 0 0 15px rgba(136, 83, 251, 0.7),
                0 0 30px rgba(136, 83, 251, 0.5),
                0 0 45px rgba(136, 83, 251, 0.3),
                inset 0 0 15px rgba(136, 83, 251, 0.7),
                inset 0 0 30px rgba(136, 83, 251, 0.5);
    border-color: rgba(136, 83, 251, 0.8);
  }
  50% {
    box-shadow: 0 0 25px rgba(136, 83, 251, 0.9),
                0 0 50px rgba(136, 83, 251, 0.7),
                0 0 75px rgba(136, 83, 251, 0.5),
                inset 0 0 25px rgba(136, 83, 251, 0.9),
                inset 0 0 50px rgba(136, 83, 251, 0.7);
    border-color: rgba(136, 83, 251, 1);
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 2px solid rgba(136, 83, 251, 0.8);
  box-shadow: 0 0 15px rgba(136, 83, 251, 0.7), 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(136, 83, 251, 0.7),
    inset 0 0 30px rgba(136, 83, 251, 0.5);
  animation: ${pulseNeon} 2s ease-in-out infinite,
    ${wobble} 6s ease-in-out infinite;
  background: rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(5px);
  will-change: transform, box-shadow, border-color;
`;

const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(136, 83, 251, 0.3) 0%,
    rgba(136, 83, 251, 0.1) 20%,
    transparent 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.5);
  box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
  opacity: 0.9;
  will-change: transform, opacity;
`;

const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `radial-gradient(
          circle at center,
          rgba(255, 255, 255, 0.4),
          transparent 80%
        )`
      : `radial-gradient(
          circle at center,
          rgba(136, 83, 251, 0.4),
          transparent 80%
        )`};
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: highlightFloat 4s ease-in-out infinite;

  @keyframes highlightFloat {
    0%,
    100% {
      transform: translate(0, 0);
      opacity: 0.3;
    }
    50% {
      transform: translate(1px, 1px);
      opacity: 0.4;
    }
  }
`;

const SectionContainer = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const SectionTitle = styled.h2`
  font-size: clamp(2rem, 4vw, 3rem);
  text-align: center;
  margin-bottom: 4rem;
  background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0.8) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  letter-spacing: -0.02em;
  opacity: 0;
  transform: translateY(20px);
`;

const Timeline = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 0;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(136, 83, 251, 0.3),
      rgba(136, 83, 251, 0.3),
      transparent
    );
    transform: translateX(-50%);
  }
`;

const TimelineItem = styled.div`
  display: flex;
  justify-content: ${(props) => (props.$isEven ? "flex-start" : "flex-end")};
  padding: 40px 0;
  width: 100%;
  opacity: 0;
  transform: translateY(20px);

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-left: 80px;
  }
`;

const ItemContent = styled.div`
  width: 42%;
  background: radial-gradient(
    circle at 50% 0%,
    rgba(136, 83, 251, 0.35),
    rgba(136, 83, 251, 0.25) 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.4);
  border-radius: 16px;
  padding: 24px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);

  &:hover {
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.45),
      rgba(136, 83, 251, 0.35) 70%
    );
    border-color: rgba(136, 83, 251, 0.5);
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    ${(props) => (props.$isEven ? "right: -85px" : "left: -85px")};
    width: 85px;
    height: 2px;
    background: rgba(136, 83, 251, 0.5);
    transform: translateY(-50%);
  }

  @media (max-width: 768px) {
    width: 100%;
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.35),
      rgba(136, 83, 251, 0.25) 70%
    );
    border: 1px solid rgba(136, 83, 251, 0.4);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);

    &:hover {
      background: radial-gradient(
        circle at 50% 0%,
        rgba(136, 83, 251, 0.45),
        rgba(136, 83, 251, 0.35) 70%
      );
      border-color: rgba(136, 83, 251, 0.5);
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
    }
  }
`;

const LogoContainer = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  ${(props) => (props.$isEven ? "right: -115px" : "left: -115px")};
  transform: translateY(-50%);
  z-index: 2;

  @media (max-width: 768px) {
    left: -75px;
    width: 50px;
    height: 50px;
  }
`;

const StepTitle = styled.h3`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 480px) {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.6;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  @media (max-width: 480px) {
    font-size: 0.9rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const MobileTimelineItem = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.35),
      rgba(136, 83, 251, 0.25) 70%
    );
    border: 1px solid rgba(136, 83, 251, 0.4);
    border-radius: 16px;
    margin: 16px 0;
    position: relative;
    opacity: 0;
    transform: translateY(20px);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    width: 100%;
    box-sizing: border-box;

    &:hover {
      transform: translateY(-5px);
      background: radial-gradient(
        circle at 50% 0%,
        rgba(136, 83, 251, 0.45),
        rgba(136, 83, 251, 0.35) 70%
      );
      border-color: rgba(136, 83, 251, 0.5);
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
    }
  }
`;

const MobileTimeline = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    width: calc(100% - 32px);
    max-width: 100%;
    margin: 40px auto 0;
  }
`;

const MobileOrbContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }
`;

const MobileOrb = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(136, 83, 251, 0.2);
    border: 2px solid rgba(136, 83, 251, 0.8);
    box-shadow: 0 0 20px rgba(136, 83, 251, 0.8), 0 0 40px rgba(136, 83, 251, 0.6);
    position: relative;
    overflow: hidden;
    animation: ${pulseNeon} 2s ease-in-out infinite;
    flex-shrink: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
        circle at 30% 30%,
        rgba(136, 83, 251, 0.3) 0%,
        rgba(136, 83, 251, 0.1) 20%,
        transparent 70%
      );
      border: 1px solid rgba(136, 83, 251, 0.5);
      box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
        inset 0 0 15px rgba(255, 255, 255, 0.3);
    }
  }
`;

const MobileStepNumber = styled.span`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const steps = [
  {
    title: "Discover Properties",
    description:
      "Start your journey by exploring our comprehensive database of properties.",
  },
  {
    title: "Apply Smart Filters",
    description:
      "Use our intelligent filtering system to narrow down properties that match your criteria.",
  },
  {
    title: "Analyze Data",
    description:
      "Dive deep into property insights with our advanced analytics tools.",
  },
  {
    title: "Take Notes",
    description:
      "Document your findings and thoughts directly within the platform.",
  },
  {
    title: "Collaborate",
    description:
      "Share insights and work together with team members in real-time.",
  },
  {
    title: "Track Progress",
    description:
      "Monitor your property research journey with our tracking tools.",
  },
  {
    title: "Make Decisions",
    description: "Use comprehensive data to make informed property decisions.",
  },
];

const JourneySection = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const itemsRef = useRef([]);
  const mobileItemsRef = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;
    const items = itemsRef.current;
    const mobileItems = mobileItemsRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    tl.to(title, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power3.out",
    }).to(
      items,
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        stagger: 0.2,
        ease: "power3.out",
      },
      "-=0.5"
    );

    if (isMobile) {
      // Mobile animations with individual triggers for each item
      mobileItems.forEach((item, index) => {
        gsap.set(item, {
          opacity: 0,
          y: 50,
          scale: 0.95,
        });

        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
            start: "top 85%",
            end: "bottom 15%",
            toggleActions: "play none none reverse",
          },
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 0.8,
          ease: "power3.out",
          delay: index * 0.1, // Slight stagger effect
        });

        // Animate the orb with a slight delay
        const orb = item.querySelector('[class*="MobileOrb"]');
        if (orb) {
          gsap.from(orb, {
            scrollTrigger: {
              trigger: item,
              start: "top 85%",
              end: "bottom 15%",
              toggleActions: "play none none reverse",
            },
            scale: 0,
            opacity: 0,
            duration: 0.6,
            ease: "back.out(1.7)",
            delay: index * 0.1 + 0.2,
          });
        }
      });
    }

    return () => {
      const triggers = ScrollTrigger.getAll().filter(
        (trigger) => trigger.vars.trigger === section
      );
      triggers.forEach((trigger) => trigger.kill());
    };
  }, [isMobile]);

  return (
    <SectionContainer ref={sectionRef} data-scroll-section>
      <ContentWrapper>
        <SectionTitle ref={titleRef}>
          Your Property Research Journey
        </SectionTitle>

        {/* Desktop Timeline */}
        {!isMobile && (
          <Timeline>
            {steps.map((step, index) => (
              <TimelineItem
                key={step.title}
                $isEven={index % 2 === 0}
                ref={(el) => (itemsRef.current[index] = el)}
              >
                <ItemContent $isEven={index % 2 === 0}>
                  <LogoContainer $isEven={index % 2 === 0}>
                    <ParticleWrapper>
                      <SwarmAlive
                        size="60px"
                        enableHover={true}
                        active={true}
                        mode="normal"
                        instanceId={`journey-step-${index}-swarm`}
                      />
                      <BubbleContainer>
                        <BubbleSkin
                          animate={{
                            scale: 1.02,
                            opacity: 0.9,
                          }}
                          transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 20,
                          }}
                        />
                        <Highlight />
                      </BubbleContainer>
                    </ParticleWrapper>
                  </LogoContainer>
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </ItemContent>
              </TimelineItem>
            ))}
          </Timeline>
        )}

        {/* Mobile Timeline */}
        {isMobile && (
          <MobileTimeline>
            {steps.map((step, index) => (
              <MobileTimelineItem
                key={step.title}
                ref={(el) => (mobileItemsRef.current[index] = el)}
              >
                <MobileOrbContainer>
                  <MobileOrb />
                  <MobileStepNumber>Step {index + 1}</MobileStepNumber>
                </MobileOrbContainer>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </MobileTimelineItem>
            ))}
          </MobileTimeline>
        )}
      </ContentWrapper>
    </SectionContainer>
  );
};

export default JourneySection;
