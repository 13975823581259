import styled from "styled-components";
import { motion } from "framer-motion";

export const FilterOrb = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 20px
      ${({ theme }) => `${theme.colors.accent.purple.base}30`},
    0 0 0 1px ${({ theme }) => theme.colors.border} inset,
    0 0 0 1px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;
  pointer-events: auto;

  &::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), transparent);
    border-radius: inherit;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.05) translateY(-2px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    color: ${({ theme }) => theme.colors.text};
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

export const ModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) =>
    theme.isDarkMode ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.4)"};
  backdrop-filter: blur(8px);
  z-index: 9999;
  overflow: hidden;
  touch-action: none;
  pointer-events: all;
  user-select: none;
`;

export const FilterPanel = styled(motion.div)`
  position: fixed;
  inset: 0;
  margin: auto;
  width: 90%;
  height: 85%;
  max-width: 1200px;
  max-height: 750px;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border-radius: 32px;
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => `
    0 0 0 1px ${theme.colors.glass.border},
    ${theme.colors.shadow},
    ${theme.colors.glow}
  `};
  pointer-events: auto;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => `${theme.colors.accent.purple.light}`},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const FilterCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 24px;
  padding: 24px;
  overflow-y: auto;
  flex: 1;
  margin-bottom: 80px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme.isDarkMode ? "rgba(18, 18, 24, 0.3)" : "rgba(136, 83, 251, 0.05)"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 4px;
    border: 2px solid
      ${({ theme }) =>
        theme.isDarkMode
          ? "rgba(18, 18, 24, 0.3)"
          : "rgba(248, 249, 252, 0.3)"};
  }
`;

export const FilterCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 20px;
  padding: 24px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }
`;

export const FilterCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  h3 {
    margin: 0;
    color: ${({ theme }) =>
      theme.isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(28, 28, 35, 0.7)"};
    font-size: 16px;
  }
`;

export const FilterInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 4px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  .filter-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .filter-input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-bottom: 0;

    label {
      display: inline-block;
      margin-bottom: 0;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text};
      font-size: 14px;
    }
  }
`;

export const ModernTextInput = styled.input`
  background: ${({ theme }) => theme.colors.glass.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ActiveFilterPill = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border-radius: 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ApplyButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background: ${({ theme }) => `linear-gradient(
    to top,
    ${theme.colors.glass.background},
    transparent
  )`};
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export const ApplyButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  svg {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const ModernInput = styled.input`
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  max-width: 100%;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary}20`};
  }

  &:disabled {
    background: ${({ theme }) => `${theme.colors.surface}80`};
    color: ${({ theme }) => theme.colors.secondary};
    font-style: italic;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const RangeInputContainer = styled.div`
  .range-input {
    background: ${({ theme }) => theme.colors.glass.background};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 12px;
    padding: 12px 16px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    transition: all 0.2s ease;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.accent.purple.base};
      box-shadow: ${({ theme }) => theme.colors.glow};
    }
  }

  .range-dragger {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border: 1px solid ${({ theme }) => theme.colors.border};
    box-shadow: ${({ theme }) => theme.colors.shadow};

    &:hover {
      background: ${({ theme }) => theme.colors.buttonGradientHover};
    }

    &:active {
      background: ${({ theme }) => theme.colors.accent.purple.base};
    }
  }

  .range-track {
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  .range-track-bg {
    background: ${({ theme }) =>
      theme.isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(136, 83, 251, 0.1)"};
  }
`;

export const FieldTitle = styled.h3`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 16px 0;
`;
