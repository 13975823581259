import React, { useState, useEffect, useRef, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { X, Trash2, Check, Copy, Edit, Clock, XCircle } from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import { useToast } from "../../context/ToastContext";

const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: ${(props) => (props.$sent ? "flex-end" : "flex-start")};
  max-width: 70%;
`;

const SenderName = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: 12px;
  margin-bottom: 4px;
`;

const MessageContent = styled(motion.div)`
  padding: 12px 16px;
  border-radius: 16px;
  border-bottom-right-radius: ${(props) => (props.$sent ? "4px" : "16px")};
  border-bottom-left-radius: ${(props) => (!props.$sent ? "4px" : "16px")};
  ${({ theme, $sent }) => {
    const sentBg = $sent 
      ? theme.colors.buttonGradient
      : theme.colors.surfaceHover;
    const borderColor = $sent
      ? theme.colors.accent.purple.light
      : theme.colors.border;
    const textColor = theme.colors.text;

    return `
      background: ${sentBg};
      border: 1px solid ${borderColor};
      color: ${textColor};
    `;
  }}
  font-size: 13px;
  line-height: 1.4;
  position: relative;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    ${({ theme, $sent }) => {
      const hoverBg = $sent
        ? theme.colors.buttonGradientHover
        : theme.colors.surface;
      return `background: ${hoverBg};`;
    }}
  }
`;

const MessageTimestamp = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 4px;
  display: block;
`;

const MessageOptionsMenu = styled(motion.div).attrs({ tabIndex: -1 })`
  position: absolute;
  top: 0;
  right: ${(props) => (props.$sent ? "100%" : "auto")};
  left: ${(props) => (props.$sent ? "auto" : "100%")};
  margin: ${(props) => (props.$sent ? "0 8px 0 0" : "0 0 0 8px")};
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(25px);
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  overflow: hidden;
  z-index: 100;
  min-width: 180px;
  padding: 6px;
  outline: none;
`;

const MessageOptionItem = styled(motion.button)`
  width: 100%;
  padding: 10px 16px;
  background: none;
  border: none;
  color: ${({ theme, $danger }) => 
    $danger ? theme.colors.accent.error.base : theme.colors.text};
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme, $danger }) => 
      $danger ? theme.colors.accent.error.light : theme.colors.buttonGradient};
  }

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.9;
  }
`;

const MessageEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 500px;
`;

const MessageEditInput = styled.input`
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surfaceHover};
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }
`;

const MessageEditActions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const EditActionButton = styled(motion.button)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  backdrop-filter: blur(20px);
  transition: all 0.3s ease;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    transform: translateY(-1px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const MessageEditedBadge = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 11px;
  cursor: pointer;
  margin-left: 4px;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

const MessageStatus = styled.div`
  font-size: 11px;
  color: ${({ theme, $status }) => 
    $status === "not_delivered" ? theme.colors.error : theme.colors.secondary};
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Message = ({ msg, user, onDelete, onUnsend, onEdit, onViewHistory }) => {
  const theme = useTheme();
  const [showOptions, setShowOptions] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(msg.content);
  const optionsRef = useRef(null);
  const messageRef = useRef(null);
  const isSentByMe = msg.sender?._id === user?._id;
  const { showToast } = useToast();
  const [canUnsendMessage, setCanUnsendMessage] = useState(false);
  const unsendTimeoutRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showOptions &&
        optionsRef.current &&
        messageRef.current &&
        !optionsRef.current.contains(event.target) &&
        !messageRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  const canEdit = useMemo(() => {
    return !msg.isUnsent && isSentByMe;
  }, [msg.isUnsent, isSentByMe]);

  const handleEdit = () => {
    if (!canEdit) return;
    onEdit(msg._id, editContent);
    setIsEditing(false);
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowOptions(false);
      showToast("Message copied to clipboard", "success");
    } catch (err) {
      console.error("Failed to copy text:", err);
      showToast("Failed to copy message", "error");
    }
  };

  const getMessageContent = () => {
    if (msg.isUnsent) {
      return (
        <span style={{ fontStyle: "italic", opacity: 0.7 }}>
          <Clock size={12} /> Message was unsent
        </span>
      );
    }
    return (
      <>
        {msg.content}
        {msg.isEdited && (
          <MessageEditedBadge
            onClick={(e) => {
              e.stopPropagation();
              onViewHistory(msg);
            }}
          >
            (edited)
          </MessageEditedBadge>
        )}
      </>
    );
  };

  useEffect(() => {
    if (msg.sender?._id === user?._id && !msg.isUnsent) {
      const messageTime = new Date(msg.createdAt).getTime();
      const now = new Date().getTime();
      const timeDiff = now - messageTime;
      const twoMinutesInMs = 120000;

      if (timeDiff < twoMinutesInMs) {
        setCanUnsendMessage(true);
        const remainingTime = twoMinutesInMs - timeDiff;

        unsendTimeoutRef.current = setTimeout(async () => {
          setCanUnsendMessage(false);

          try {
            await fetch(
              `${process.env.REACT_APP_API_URL}/api/chat/messages/${msg._id}/expire-unsend`,
              {
                method: "POST",
                credentials: "include",
              }
            );
          } catch (error) {
            console.error("Error updating message unsend status:", error);
          }
        }, remainingTime);
      } else {
        setCanUnsendMessage(false);
      }
    }

    return () => {
      if (unsendTimeoutRef.current) {
        clearTimeout(unsendTimeoutRef.current);
      }
    };
  }, [msg._id, msg.createdAt, msg.sender?._id, msg.isUnsent, user?._id]);

  const handleShowOptions = () => {
    setShowOptions(true);
  };

  return (
    <MessageGroup $sent={isSentByMe}>
      {!isSentByMe && msg.sender && (
        <SenderName>{msg.sender.name || "Unknown"}</SenderName>
      )}
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexDirection: isSentByMe ? "row-reverse" : "row",
        }}
      >
        {isEditing ? (
          <MessageEditContainer>
            <MessageEditInput
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleEdit()}
              autoFocus
            />
            <MessageEditActions>
              <EditActionButton onClick={() => setIsEditing(false)}>
                <X size={14} />
              </EditActionButton>
              <EditActionButton onClick={handleEdit}>
                <Check size={14} />
              </EditActionButton>
            </MessageEditActions>
          </MessageEditContainer>
        ) : (
          <MessageContent
            ref={messageRef}
            $sent={isSentByMe}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onClick={handleShowOptions}
          >
            {getMessageContent()}
            <MessageTimestamp>
              {new Date(msg.updatedAt || msg.createdAt).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </MessageTimestamp>
            {msg.status && (
              <MessageStatus theme={theme} $status={msg.status}>
                {msg.status === "sending" && "Sending..."}
                {msg.status === "not_delivered" && (
                  <>
                    <XCircle size={12} />
                    {msg.error || "Not delivered"}
                  </>
                )}
              </MessageStatus>
            )}
          </MessageContent>
        )}

        <AnimatePresence>
          {showOptions && !isEditing && !msg.isUnsent && (
            <MessageOptionsMenu
              ref={optionsRef}
              $sent={isSentByMe}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ type: "spring", damping: 20, stiffness: 300 }}
            >
              {isSentByMe && (
                <>
                  {canUnsendMessage && (
                    <MessageOptionItem onClick={() => onUnsend(msg._id)}>
                      <X size={14} />
                      Unsend
                    </MessageOptionItem>
                  )}
                  {canEdit && (
                    <MessageOptionItem onClick={() => setIsEditing(true)}>
                      <Edit size={14} />
                      Edit
                    </MessageOptionItem>
                  )}
                  <MessageOptionItem onClick={() => onDelete(msg._id)} $danger>
                    <Trash2 size={14} />
                    Delete for me
                  </MessageOptionItem>
                </>
              )}
              {msg.isEdited && (
                <MessageOptionItem onClick={() => onViewHistory(msg)}>
                  <Clock size={14} />
                  Show edit history
                </MessageOptionItem>
              )}
              <MessageOptionItem onClick={() => handleCopy(msg.content)}>
                <Copy size={14} />
                Copy message
              </MessageOptionItem>
            </MessageOptionsMenu>
          )}
        </AnimatePresence>
      </div>
    </MessageGroup>
  );
};

export default Message;
