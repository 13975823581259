import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ChevronDown, BarChart, Globe, DollarSign, Home } from "react-feather";
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { format } from "date-fns";
import { useTheme } from "../../context/ThemeContext";

const UsageGraphSection = styled(motion.div)`
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `linear-gradient(165deg, rgba(18,18,24,0.95), rgba(12,12,16,0.98))`
      : `linear-gradient(165deg, rgba(255,255,255,0.95), rgba(245,245,250,0.98))`};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px 28px;
  margin: 24px 30px;
  height: 450px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    pointer-events: none;
    z-index: 0;
  }

  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line {
    stroke: ${({ theme }) =>
      theme.isDarkMode
        ? "rgba(136, 83, 251, 0.15)"
        : "rgba(136, 83, 251, 0.1)"};
  }

  .recharts-text {
    fill: ${({ theme }) => theme.colors.secondary};
    font-size: 12px;
  }

  .recharts-area {
    filter: drop-shadow(0 0 8px rgba(136, 83, 251, 0.3));
  }

  .recharts-tooltip-wrapper {
    filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.2));
  }
`;

const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const GraphTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const GraphControls = styled.div`
  display: flex;
  gap: 8px;
`;

const TimeframeButton = styled.button`
  padding: 6px 12px;
  background: ${(props) =>
    props.$active
      ? props.theme.isDarkMode
        ? "rgba(136, 83, 251, 0.25)"
        : `${props.theme.colors.primary}25`
      : props.theme.isDarkMode
      ? "rgba(28, 28, 35, 0.5)"
      : props.theme.colors.surfaceHover};
  border: 1px solid
    ${(props) =>
      props.$active
        ? props.theme.isDarkMode
          ? "rgba(136, 83, 251, 0.4)"
          : `${props.theme.colors.primary}40`
        : props.theme.colors.border};
  border-radius: 8px;
  color: ${(props) =>
    props.$active ? props.theme.colors.text : props.theme.colors.secondary};
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: ${(props) =>
      props.theme.isDarkMode
        ? "rgba(136, 83, 251, 0.25)"
        : `${props.theme.colors.primary}25`};
    border-color: ${(props) =>
      props.theme.isDarkMode
        ? "rgba(136, 83, 251, 0.4)"
        : `${props.theme.colors.primary}40`};
    color: ${(props) => props.theme.colors.text};
  }
`;

const CustomTooltipContainer = styled.div`
  background: ${({ theme }) =>
    theme.isDarkMode ? `rgba(28,28,35,0.95)` : `rgba(255,255,255,0.95)`};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 12px 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);

  .tooltip-label {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 12px;
    margin-bottom: 4px;
  }

  .tooltip-value {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 500;
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 16px;
  position: relative;
  z-index: 2;

  .recharts-wrapper {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    z-index: 2;
  }

  .recharts-surface {
    z-index: 2;
  }

  .recharts-area-area {
    animation: fadeInPath 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  .recharts-area-curve {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawLine 2s ease-out forwards;
  }

  @keyframes drawLine {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fadeInPath {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const UsageGraphFilters = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  padding: 10px;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `linear-gradient(165deg, rgba(38,38,45,0.3), rgba(28,28,35,0.3))`
      : `linear-gradient(165deg, rgba(240,240,250,0.3), rgba(235,235,245,0.3))`};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
  z-index: 3;
`;

const FilterDivider = styled.div`
  width: 1px;
  height: 24px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(136, 83, 251, 0.2),
    rgba(136, 83, 251, 0.3),
    rgba(136, 83, 251, 0.2),
    transparent
  );
  margin: 0 4px;
`;

const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 100;
`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: ${(props) =>
    props.$active ? props.theme.colors.primary : props.theme.colors.secondary};
  &:hover {
    background: ${(props) =>
      props.theme.isDarkMode
        ? "rgba(136, 83, 251, 0.1)"
        : `${props.theme.colors.primary}10`};
  }
`;

const RegionsDropdown = styled(motion.div)`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: ${({ theme }) =>
    theme.isDarkMode ? `rgba(28,28,35,0.98)` : `rgba(255,255,255,0.98)`};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  overflow: hidden;
  z-index: 10;
  min-width: 180px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 2px;
  }
`;

const RegionOption = styled.button`
  width: 100%;
  padding: 10px 16px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s ease;

  &:hover {
    background: ${(props) =>
      props.theme.isDarkMode
        ? "rgba(136, 83, 251, 0.15)"
        : `${props.theme.colors.primary}10`};
  }

  ${({ $active, theme }) =>
    $active &&
    `
    background: ${theme.colors.primary}15;
    color: ${theme.colors.text};
  `}
`;

const GraphViewWallet = ({ expanded }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [timeframe, setTimeframe] = useState("7d");
  const [regionFilter, setRegionFilter] = useState("all");
  const [propertyTypeFilter, setPropertyTypeFilter] = useState("all");
  const [valueRangeFilter, setValueRangeFilter] = useState("all");
  const [showMoreRegions, setShowMoreRegions] = useState(false);
  const [showPropertyTypes, setShowPropertyTypes] = useState(false);
  const [showValueRanges, setShowValueRanges] = useState(false);
  const [viewsData, setViewsData] = useState([]);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [availableFilters, setAvailableFilters] = useState({
    regions: [],
    propertyTypes: [],
    valueRanges: [],
  });

  const regionsDropdownRef = useRef(null);
  const propertyTypesDropdownRef = useRef(null);
  const valueRangesDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        regionsDropdownRef.current &&
        !regionsDropdownRef.current.contains(event.target)
      ) {
        setShowMoreRegions(false);
      }

      if (
        propertyTypesDropdownRef.current &&
        !propertyTypesDropdownRef.current.contains(event.target)
      ) {
        setShowPropertyTypes(false);
      }

      if (
        valueRangesDropdownRef.current &&
        !valueRangesDropdownRef.current.contains(event.target)
      ) {
        setShowValueRanges(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchViewsData = async () => {
    setIsLoadingChart(true);
    try {
      const queryParams = new URLSearchParams({
        timeframe,
        state: regionFilter,
        propertyType: propertyTypeFilter,
        valueRange: valueRangeFilter,
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/views/analytics?${queryParams}`,
        {
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to fetch analytics data");

      const data = await response.json();

      setViewsData(
        data.timeseriesData.map((item) => ({
          date: format(new Date(item._id + "T00:00:00"), "MM/dd"),
          views: item.count,
        }))
      );
      setAvailableFilters(data.availableFilters);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    } finally {
      setIsLoadingChart(false);
    }
  };

  useEffect(() => {
    fetchViewsData();
  }, [timeframe, regionFilter, propertyTypeFilter, valueRangeFilter]);

  const handleRegionChange = (region) => {
    setRegionFilter(region);
    setShowMoreRegions(false);
  };

  const handlePropertyTypeChange = (type) => {
    setPropertyTypeFilter(type);
    setShowPropertyTypes(false);
  };

  const handleValueRangeChange = (range) => {
    setValueRangeFilter(range);
    setShowValueRanges(false);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipContainer>
          <div className="tooltip-label">
            {format(
              new Date(label + "/" + new Date().getFullYear()),
              "MMM d, yyyy"
            )}
          </div>
          <div className="tooltip-value">
            {payload[0].value} {t("views")}
          </div>
        </CustomTooltipContainer>
      );
    }
    return null;
  };

  return (
    <UsageGraphSection
      $expanded={expanded}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <GraphHeader>
        <GraphTitle>
          <BarChart size={16} />
          {t("usageAnalytics")}
        </GraphTitle>
        <GraphControls>
          {["7D", "30D", "90D"].map((period) => (
            <Tippy
              key={period}
              content={`Last ${period}`}
              placement="top"
              animation="shift-away"
              arrow={true}
              duration={[300, 250]}
              delay={[400, 0]}
              theme="modern"
            >
              <TimeframeButton
                $active={timeframe === period.toLowerCase()}
                onClick={() => setTimeframe(period.toLowerCase())}
              >
                {period}
              </TimeframeButton>
            </Tippy>
          ))}
        </GraphControls>
      </GraphHeader>

      <UsageGraphFilters>
        <FilterGroup ref={regionsDropdownRef}>
          <Tippy
            content={t("filterByRegion")}
            placement="top"
            animation="shift-away"
            arrow={true}
            duration={[300, 250]}
            delay={[400, 0]}
            theme="modern"
          >
            <FilterOption
              onClick={() => setShowMoreRegions(!showMoreRegions)}
              $active={regionFilter !== "all"}
            >
              <Globe size={12} />
              {regionFilter === "all"
                ? t("allRegions")
                : availableFilters.regions.find((r) => r.id === regionFilter)
                    ?.label}
              <ChevronDown
                size={12}
                style={{
                  transform: showMoreRegions
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
              <AnimatePresence>
                {showMoreRegions && availableFilters.regions.length > 0 && (
                  <RegionsDropdown
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2 }}
                  >
                    <RegionOption
                      key="all"
                      $active={regionFilter === "all"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRegionChange("all");
                      }}
                    >
                      <Globe size={12} />
                      {t("allRegions")}
                    </RegionOption>
                    {availableFilters.regions.map((region) => (
                      <RegionOption
                        key={region.id}
                        $active={regionFilter === region.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRegionChange(region.id);
                        }}
                      >
                        <Globe size={12} />
                        {region.label}
                      </RegionOption>
                    ))}
                  </RegionsDropdown>
                )}
              </AnimatePresence>
            </FilterOption>
          </Tippy>
        </FilterGroup>

        <FilterDivider />

        <FilterGroup ref={propertyTypesDropdownRef}>
          <Tippy
            content={t("filterByType")}
            placement="top"
            animation="shift-away"
            arrow={true}
            duration={[300, 250]}
            delay={[400, 0]}
            theme="modern"
          >
            <FilterOption
              onClick={() => setShowPropertyTypes(!showPropertyTypes)}
              $active={propertyTypeFilter !== "all"}
            >
              <Home size={12} />
              {propertyTypeFilter === "all"
                ? t("allTypes")
                : availableFilters.propertyTypes.find(
                    (t) => t.id === propertyTypeFilter
                  )?.label}
              <ChevronDown
                size={12}
                style={{
                  transform: showPropertyTypes
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
              <AnimatePresence>
                {showPropertyTypes &&
                  availableFilters.propertyTypes.length > 0 && (
                    <RegionsDropdown
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <RegionOption
                        key="all"
                        $active={propertyTypeFilter === "all"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePropertyTypeChange("all");
                        }}
                      >
                        <Home size={12} />
                        {t("allTypes")}
                      </RegionOption>
                      {availableFilters.propertyTypes.map((type) => (
                        <RegionOption
                          key={type.id}
                          $active={propertyTypeFilter === type.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePropertyTypeChange(type.id);
                          }}
                        >
                          <Home size={12} />
                          {type.label}
                        </RegionOption>
                      ))}
                    </RegionsDropdown>
                  )}
              </AnimatePresence>
            </FilterOption>
          </Tippy>
        </FilterGroup>

        <FilterDivider />

        <FilterGroup ref={valueRangesDropdownRef}>
          <Tippy
            content={t("filterByValue")}
            placement="top"
            animation="shift-away"
            arrow={true}
            duration={[300, 250]}
            delay={[400, 0]}
            theme="modern"
          >
            <FilterOption
              onClick={() => setShowValueRanges(!showValueRanges)}
              $active={valueRangeFilter !== "all"}
            >
              <DollarSign size={12} />
              {valueRangeFilter === "all"
                ? t("allValues")
                : availableFilters.valueRanges.find(
                    (v) => v.id === valueRangeFilter
                  )?.label}
              <ChevronDown
                size={12}
                style={{
                  transform: showValueRanges
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
              <AnimatePresence>
                {showValueRanges && availableFilters.valueRanges.length > 0 && (
                  <RegionsDropdown
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2 }}
                  >
                    <RegionOption
                      key="all"
                      $active={valueRangeFilter === "all"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleValueRangeChange("all");
                      }}
                    >
                      <DollarSign size={12} />
                      {t("allValues")}
                    </RegionOption>
                    {availableFilters.valueRanges.map((range) => (
                      <RegionOption
                        key={range.id}
                        $active={valueRangeFilter === range.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleValueRangeChange(range.id);
                        }}
                      >
                        <DollarSign size={12} />
                        {range.label}
                      </RegionOption>
                    ))}
                  </RegionsDropdown>
                )}
              </AnimatePresence>
            </FilterOption>
          </Tippy>
        </FilterGroup>
      </UsageGraphFilters>

      <ChartContainer>
        <AreaChart
          data={viewsData}
          margin={{ top: 10, right: 10, left: -15, bottom: 0 }}
          width={window.innerWidth - 200}
          height={300}
        >
          <defs>
            <linearGradient id="colorViews" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={
                  theme.isDarkMode
                    ? "rgba(136,83,251,0.8)"
                    : "rgba(136,83,251,1)"
                }
                stopOpacity={theme.isDarkMode ? 0.3 : 0.4}
              />
              <stop
                offset="95%"
                stopColor="rgba(136,83,251,0.1)"
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke={
              theme.isDarkMode
                ? "rgba(136,83,251,0.15)"
                : "rgba(136,83,251,0.1)"
            }
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: theme.colors.secondary,
              fontSize: 12,
            }}
            dy={10}
            height={50}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{
              fill: theme.colors.secondary,
              fontSize: 12,
            }}
            dx={-10}
            width={60}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="views"
            stroke={
              theme.isDarkMode ? "rgba(136,83,251,0.9)" : "rgba(136,83,251,1)"
            }
            strokeWidth={2}
            fill="url(#colorViews)"
            isAnimationActive={true}
            animationBegin={0}
            animationDuration={1500}
            animationEasing="ease-in-out"
            connectNulls={true}
          />
        </AreaChart>
      </ChartContainer>
    </UsageGraphSection>
  );
};

export default GraphViewWallet;
