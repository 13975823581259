import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";
import Sidebar from "../layout/navigation/Sidebar";
import MainContent from "../layout/home/MainContent";
import Buy from "../layout/buy/Buy";
import SellContent from "../layout/sell/SellContent";
import HireContent from "../layout/hire/HireContent";
import WalletContent from "./WalletPage";
import AccountSettings from "./AccountSettings";
import LoadingOverlay from "../common/LoadingOverlay";
import NewPricingPage from "./NewPricingPage";
import "../../assets/styles/HomePage.css";
import ChatPage from "./ChatPage";
import FriendsPage from "./FriendsPage";
import styled, {
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

const HomePageContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  position: relative;

  &::before {
    content: "";
    position: fixed;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${(props) =>
        props.theme.isDarkMode
          ? "rgba(136, 83, 251, 0.08)"
          : "rgba(136, 83, 251, 0.06)"},
      transparent 70%
    );
    pointer-events: none;
  }

  &::after {
    content: "";
    position: fixed;
    inset: 0;
    background: radial-gradient(
      circle at bottom left,
      ${(props) =>
        props.theme.isDarkMode
          ? "rgba(136, 83, 251, 0.05)"
          : "rgba(136, 83, 251, 0.03)"},
      transparent 60%
    );
    pointer-events: none;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: transparent;
  backdrop-filter: blur(${(props) => props.theme.colors.glass.blur});
`;

const MainSection = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  background: transparent;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const HomePage = () => {
  const { loading, error } = useContext(AuthContext);
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  const handleCollapseSidebar = () => {
    setIsCollapsed(true);
  };

  if (loading) return <LoadingOverlay />;
  if (error) return <p>Error: {error}</p>;

  if (!isReady) {
    return <LoadingOverlay />;
  }

  return (
    <StyledThemeProvider theme={theme}>
      <HomePageContainer>
        <Sidebar
          isCollapsed={isCollapsed}
          onCollapseSidebar={handleCollapseSidebar}
        />
        <ContentContainer>
          <MainSection>
            <Routes>
              <Route path="/" element={<Navigate to="overview" replace />} />
              <Route path="overview" element={<MainContent />} />
              <Route path="buy" element={<Buy />} />
              <Route path="sell" element={<SellContent />} />
              <Route path="hire" element={<HireContent />} />
              <Route path="account-settings" element={<AccountSettings />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="friends" element={<FriendsPage />} />
              <Route path="wallet/*" element={<NewPricingPage />} />
            </Routes>
          </MainSection>
        </ContentContainer>
      </HomePageContainer>
    </StyledThemeProvider>
  );
};

export default HomePage;
