import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import SwarmLogo from "./SwarmLogo";

const OverlayContainer = styled(motion.div)`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.isDarkMode ?
    `linear-gradient(
      165deg,
      rgba(28, 28, 35, 0.98),
      rgba(18, 18, 24, 0.98)
    )` :
    `linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.98),
      rgba(245, 245, 247, 0.98)
    )`
  };
  backdrop-filter: blur(10px);
  z-index: 1000;
`;

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  transform-style: preserve-3d;
  perspective: 1000px;
`;

const StyledSwarmWrapper = styled(motion.div)`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  animation: swarmFloat 4s ease-in-out infinite;

  @keyframes swarmFloat {
    0%, 100% {
      transform: translateY(0px) translateZ(0) rotate(0deg);
    }
    50% {
      transform: translateY(-10px) translateZ(20px) rotate(5deg);
    }
  }
`;

const LoadingText = styled(motion.p)`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.isDarkMode ?
    'rgba(255, 255, 255, 0.9)' :
    'rgba(0, 0, 0, 0.9)'
  };
  max-width: 280px;
  line-height: 1.6;
  letter-spacing: 0.3px;
  transform-style: preserve-3d;
  text-shadow: ${({ theme }) => theme.isDarkMode ?
    '0 2px 4px rgba(0, 0, 0, 0.2)' :
    'none'
  };
  background: ${({ theme }) => theme.isDarkMode ?
    `linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.8)
    )` :
    `linear-gradient(
      165deg,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8)
    )`
  };
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

const LoadingOverlay = ({ messageLayers = [] }) => {
  const [currentMessages, setCurrentMessages] = useState([]);
  const [currentLayerIndex, setCurrentLayerIndex] = useState(0);

  useEffect(() => {
    if (messageLayers.length === 0) return;

    const selectRandomMessages = () => {
      const newMessages = messageLayers.map(
        (layer) => layer[Math.floor(Math.random() * layer.length)]
      );
      setCurrentMessages(newMessages);
    };

    selectRandomMessages();
    const interval = setInterval(() => {
      setCurrentLayerIndex(
        (prevIndex) => (prevIndex + 1) % messageLayers.length
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [messageLayers]);

  return (
    <OverlayContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayContent>
        <StyledSwarmWrapper>
          <SwarmLogo size="120px" enableHover={false} active={true} />
        </StyledSwarmWrapper>

        <AnimatePresence mode="wait">
          <LoadingText
            key={currentLayerIndex}
            initial={{ opacity: 0, y: 10, z: -50 }}
            animate={{ opacity: 1, y: 0, z: 0 }}
            exit={{ opacity: 0, y: -10, z: -50 }}
            transition={{ duration: 0.5 }}
          >
            {currentMessages[currentLayerIndex]}
          </LoadingText>
        </AnimatePresence>
      </OverlayContent>
    </OverlayContainer>
  );
};

export default LoadingOverlay;
