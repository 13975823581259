import React, { useState, useEffect, useRef } from "react";
import useUserData from "../../../hooks/useUserData";
import "../../../assets/styles/MainContent.css";
import Dropdown from "../../common/Dropdown";
import Pagination from "../../common/Pagination";
import DataTable from "../../common/DataTable";
import LoadingOverlay from "../../common/LoadingOverlay"; // Add this import

const MainContent = () => {
  const [selectedOption, setSelectedOption] = useState("Favorites");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const containerRef = useRef(null);
  const [isContainerReady, setIsContainerReady] = useState(false);

  const options = ["Favorites", "Purchased", "Processing", "Discarded", "Sold"];
  const { favorites, purchased, processing, discarded, sold, loading, error } =
    useUserData(selectedOption.toLowerCase());

  const calculateItemsPerPage = () => {
    if (!containerRef.current) return;
    
    const containerHeight = containerRef.current.clientHeight;
    const itemHeight = 100; // Approximate height of each item
    const calculatedItems = Math.floor(containerHeight / itemHeight);
    
    setItemsPerPage(Math.max(calculatedItems, 1)); // Ensure at least 1 item
  };

  useEffect(() => {
    // Wait for the container to be mounted
    const timer = setTimeout(() => {
      setIsContainerReady(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isContainerReady) return;

    calculateItemsPerPage();
    
    const handleResize = () => {
      calculateItemsPerPage();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isContainerReady]);

  if (!isContainerReady) {
    return <div ref={containerRef}>Loading...</div>;
  }

  const getCurrentItems = () => {
    switch (selectedOption.toLowerCase()) {
      case "favorites":
        return favorites || [];
      case "purchased":
        return purchased || [];
      case "processing":
        return processing || [];
      case "discarded":
        return discarded || [];
      case "sold":
        return sold || [];
      default:
        return [];
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
    setExpandedRow(null);
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const formatCurrency = (value) => {
    return value ? `$${value.toLocaleString()}` : "N/A";
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getAdditionalInfo = (item) => {
    return (
      <div className="expanded-content">
        {item.Owner1FullName && (
          <div className="info-pair">
            <strong>Owner Name:</strong> {item.Owner1FullName}
          </div>
        )}
        {item.ClosePrice && (
          <div className="info-pair">
            <strong>Last Sale Price:</strong> {formatCurrency(item.ClosePrice)}
          </div>
        )}
        {item.LivingArea && (
          <div className="info-pair">
            <strong>Living Area:</strong> {item.LivingArea.toLocaleString()} sq
            ft
          </div>
        )}
        {item.LotSizeAcres && (
          <div className="info-pair">
            <strong>Lot Size:</strong> {item.LotSizeAcres.toFixed(2)} acres
          </div>
        )}
        {item.LastSaleRecordingDate && (
          <div className="info-pair">
            <strong>Last Sale Date:</strong>{" "}
            {formatDate(item.LastSaleRecordingDate)}
          </div>
        )}
        {item.BedroomsTotal && (
          <div className="info-pair">
            <strong>Bedrooms:</strong> {item.BedroomsTotal}
          </div>
        )}
        {item.BathroomsTotalInteger && (
          <div className="info-pair">
            <strong>Bathrooms:</strong> {item.BathroomsTotalInteger}
          </div>
        )}
        {item.Zoning && (
          <div className="info-pair">
            <strong>Zoning:</strong> {item.Zoning}
          </div>
        )}
        {item.LandUseCode && (
          <div className="info-pair">
            <strong>Land Use Code:</strong> {item.LandUseCode}
          </div>
        )}
        {item.TaxAnnualAmount && (
          <div className="info-pair">
            <strong>Annual Tax:</strong> {formatCurrency(item.TaxAnnualAmount)}
          </div>
        )}
        {item.MarketValue && (
          <div className="info-pair">
            <strong>Market Value:</strong> {formatCurrency(item.MarketValue)}
          </div>
        )}
        {item.AssessorsMapReference && (
          <div className="info-pair">
            <strong>Assessors Map Reference:</strong>{" "}
            {item.AssessorsMapReference}
          </div>
        )}
        {item.LegalSubdivisionName && (
          <div className="info-pair">
            <strong>Legal Subdivision:</strong> {item.LegalSubdivisionName}
          </div>
        )}
      </div>
    );
  };

  const sortedItems = () => {
    const items = getCurrentItems();
    if (!sortConfig.key) return items;

    return [...items].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  const totalPages = Math.ceil(sortedItems().length / itemsPerPage);

  const headers = [
    { key: "ParcelNumber", label: "Parcel Number" },
    { key: "County", label: "County" },
    { key: "City", label: "City" },
    { key: "StateOrProvince", label: "State" },
    { key: "AssessedValue", label: "Assessed Value" },
    { key: "PropertyType", label: "Property Type" },
  ];

  if (loading) return <LoadingOverlay />; // Replace loading text with LoadingOverlay
  if (error) return <p>Error: {error}</p>;

  return (
    <div ref={containerRef} className="main-content">
      <Dropdown
        options={options}
        selectedOption={selectedOption}
        onSelect={setSelectedOption}
      />

      <DataTable
        headers={headers}
        items={sortedItems()}
        sortConfig={sortConfig}
        onSort={handleSort}
        expandedRow={expandedRow}
        onRowClick={handleRowClick}
        getAdditionalInfo={getAdditionalInfo}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />

      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default MainContent;
