import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ThemeContext = createContext();
const THEME_STORAGE_KEY = "deedfinders-theme-preference";

// Routes that should always be in dark mode
const FORCE_DARK_MODE_ROUTES = [
  "/",
  "/pricing",
  "/pricing#privacy-policy",
  "/pricing#terms-of-service"
];

// Modal query parameters that should force dark mode
const FORCE_DARK_MODE_MODALS = [
  "privacy-policy",
  "terms-of-service"
];

export const ThemeProvider = ({ children }) => {
  const location = useLocation();
  
  // Initialize state from localStorage, fallback to true (dark mode) if not set
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem(THEME_STORAGE_KEY);
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });

  // Check if current route should force dark mode
  const shouldForceDarkMode = React.useMemo(() => {
    // Check route paths
    const routeMatch = FORCE_DARK_MODE_ROUTES.some(route => 
      location.pathname + (location.hash || "") === route
    );
    
    // Check modal query parameters
    const searchParams = new URLSearchParams(location.search);
    const modalParam = searchParams.get("modal");
    const modalMatch = modalParam && FORCE_DARK_MODE_MODALS.includes(modalParam);
    
    return routeMatch || modalMatch;
  }, [location]);

  useEffect(() => {
    // Only update localStorage when theme changes and we're not in a forced dark mode route
    if (!shouldForceDarkMode) {
      localStorage.setItem(THEME_STORAGE_KEY, JSON.stringify(isDarkMode));
    }
    
    // Always update the document theme
    document.documentElement.setAttribute(
      "data-theme",
      shouldForceDarkMode || isDarkMode ? "dark" : "light"
    );
  }, [isDarkMode, shouldForceDarkMode, location]);

  const toggleTheme = () => {
    // Only allow theme toggle if not in a forced dark mode route
    if (!shouldForceDarkMode) {
      setIsDarkMode((prev) => !prev);
    }
  };

  const theme = {
    isDarkMode: shouldForceDarkMode || isDarkMode,
    toggleTheme,
    colors: {
      // Core colors
      background: shouldForceDarkMode || isDarkMode
        ? "linear-gradient(180deg, #16161a 0%, #121216 100%)"
        : "linear-gradient(180deg, #f8f9fc 0%, #eef1f8 100%)",
      text: shouldForceDarkMode || isDarkMode ? "rgba(255, 255, 255, 0.95)" : "rgba(28, 28, 35, 0.95)",
      primary: "#8853fb",
      secondary: shouldForceDarkMode || isDarkMode
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(28, 28, 35, 0.7)",

      // Surface treatments
      surface: shouldForceDarkMode || isDarkMode
        ? "linear-gradient(165deg, rgba(28, 28, 35, 0.85), rgba(18, 18, 24, 0.95))"
        : "linear-gradient(165deg, rgba(255, 255, 255, 0.95), rgba(252, 253, 255, 0.98))",
      surfaceHover: shouldForceDarkMode || isDarkMode
        ? "linear-gradient(165deg, rgba(38, 38, 45, 0.9), rgba(28, 28, 35, 0.95))"
        : "linear-gradient(165deg, rgba(255, 255, 255, 0.98), rgba(248, 249, 252, 0.95))",

      // Borders and dividers
      border: shouldForceDarkMode || isDarkMode
        ? "rgba(255, 255, 255, 0.07)"
        : "rgba(136, 83, 251, 0.08)",
      divider: shouldForceDarkMode || isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(136, 83, 251, 0.1)",

      // Shadows and glows
      shadow: shouldForceDarkMode || isDarkMode
        ? "0 8px 32px rgba(0, 0, 0, 0.4), 0 2px 8px rgba(0, 0, 0, 0.2)"
        : "0 8px 32px rgba(136, 83, 251, 0.08), 0 2px 8px rgba(136, 83, 251, 0.04)",
      glow: shouldForceDarkMode || isDarkMode
        ? "0 0 20px rgba(136, 83, 251, 0.2)"
        : "0 0 20px rgba(136, 83, 251, 0.1)",

      // Interactive elements
      buttonGradient: shouldForceDarkMode || isDarkMode
        ? "linear-gradient(165deg, rgba(136, 83, 251, 0.25), rgba(96, 57, 176, 0.25))"
        : "linear-gradient(165deg, rgba(136, 83, 251, 0.12), rgba(96, 57, 176, 0.12))",
      buttonGradientHover: shouldForceDarkMode || isDarkMode
        ? "linear-gradient(165deg, rgba(136, 83, 251, 0.35), rgba(96, 57, 176, 0.35))"
        : "linear-gradient(165deg, rgba(136, 83, 251, 0.18), rgba(96, 57, 176, 0.18))",

      // Accent colors
      accent: {
        purple: {
          base: "#8853fb",
          light: shouldForceDarkMode || isDarkMode
            ? "rgba(136, 83, 251, 0.2)"
            : "rgba(136, 83, 251, 0.1)",
          dark: "#6039b0",
        },
        success: {
          base: "#34C759",
          light: shouldForceDarkMode || isDarkMode
            ? "rgba(52, 199, 89, 0.2)"
            : "rgba(52, 199, 89, 0.1)",
          dark: "#248A3D",
        },
        warning: {
          base: "#FF9500",
          light: shouldForceDarkMode || isDarkMode
            ? "rgba(255, 149, 0, 0.2)"
            : "rgba(255, 149, 0, 0.1)",
          dark: "#C93400",
        },
        error: {
          base: "#FF3B30",
          light: shouldForceDarkMode || isDarkMode
            ? "rgba(255, 59, 48, 0.2)"
            : "rgba(255, 59, 48, 0.1)",
          dark: "#C9302B",
        },
      },

      // Glass effects
      glass: {
        background: shouldForceDarkMode || isDarkMode
          ? "rgba(28, 28, 35, 0.85)"
          : "rgba(255, 255, 255, 0.92)",
        border: shouldForceDarkMode || isDarkMode
          ? "rgba(255, 255, 255, 0.07)"
          : "rgba(136, 83, 251, 0.08)",
        blur: "40px",
      },
    },
  };

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
