import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Check, ChevronDown, ChevronLeft } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import SwarmLogo from "../common/SwarmLogo";
import BackgroundAnimation from "../common/BackgroundAnimation";
import PolicyModal from "../modals/PolicyModal";
import SignUpModal from "../modals/SignUpModal";
import SignInModal from "../modals/SignInModal";
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../utils/policyContent";
import {
  PageContainer,
  ContentWrapper,
  Navbar,
  Nav,
  Logo,
  LogoText,
  IconButton,
  MainSection,
  PricingHeader,
  IntervalSelectionContainer,
  IntervalSelection,
  IntervalButton,
  IntervalSlider,
  SavingsLabel,
  CardsContainer,
  PricingCard,
  CardContent,
  PlanHeader,
  PlanName,
  PlanPrice,
  CurrentPrice,
  OriginalPrice,
  AccessCount,
  AccessNumber,
  AccessPeriod,
  FeatureList,
  FeatureItem,
  GetStartedButton,
  Divider,
  PayAsYouGoBar,
  PayAsYouGoContent,
  PayAsYouGoTitle,
  PayAsYouGoPrice,
  PriceSubtext,
  DropdownWrapper,
  PayAsYouGoDropdown,
  DropdownHeader,
  DropdownOptions,
  DropdownOption,
  CheckMark,
  LandingFooter,
  FooterLinks,
  LanguageButtons,
  LanguageButton,
  priceAnimationVariants,
  BackButton,
} from "./PublicPricingPage.style";

const pageVariants = {
  initial: { opacity: 0, scale: 0.95 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: { duration: 0.4, ease: [0.16, 1, 0.3, 1] },
  },
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, ease: [0.16, 1, 0.3, 1] },
  },
};

const PublicPricingPage = () => {
  const { t, i18n } = useTranslation();
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [selectedAmount, setSelectedAmount] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const rechargeAmounts = [
    { value: 10, label: "$10" },
    { value: 25, label: "$25" },
    { value: 50, label: "$50" },
    { value: 100, label: "$100" },
    { value: 200, label: "$200" },
  ];

  const plans = [
    {
      name: "Unlimited",
      monthlyPrice: 99.99,
      yearlyPrice: 999.9,
      access: {
        count: "Unlimited",
        period: "views",
      },
      features: [
        "Full platform capabilities",
        "AI-powered insights",
        "Priority support",
      ],
    },
  ];

  const calculateYearlyPrice = (monthlyPrice) => {
    const yearlyPrice = (monthlyPrice * 10).toFixed(2);
    return { price: yearlyPrice };
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleOpenModal = (modalType) => {
    if (modalType === "privacyPolicy" || modalType === "termsOfService") {
      const hash = modalType.replace(/([A-Z])/g, "-$1").toLowerCase();
      navigate(`#${hash}`);
    }
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    navigate("");
    setActiveModal(null);
  };

  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault();
    handleOpenModal("privacyPolicy");
  };

  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    handleOpenModal("termsOfService");
  };

  const handleSignUpClick = () => {
    setIsSignUpModalOpen(true);
  };

  const handleSignInClick = () => {
    setIsSignInModalOpen(true);
  };

  const handleSwitchToSignUp = () => {
    setIsSignInModalOpen(false);
    setIsSignUpModalOpen(true);
  };

  const handleSwitchToSignIn = () => {
    setIsSignUpModalOpen(false);
    setIsSignInModalOpen(true);
  };

  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash === "privacy-policy") {
      setActiveModal("privacyPolicy");
    } else if (hash === "terms-of-service") {
      setActiveModal("termsOfService");
    } else {
      setActiveModal(null);
    }
  }, [location]);

  return (
    <PageContainer
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <BackButton
        onClick={() => navigate("/")}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <ChevronLeft /> Back
      </BackButton>
      <BackgroundAnimation />
      <Navbar>
        <Logo to="/">
          <SwarmLogo size="48px" />
          <LogoText>{t("companyName")}</LogoText>
        </Logo>
        <Nav>
          <IconButton onClick={handleSignInClick}>{t("signin")}</IconButton>
          <IconButton onClick={handleSignUpClick}>{t("signup")}</IconButton>
        </Nav>
      </Navbar>

      <ContentWrapper
        variants={contentVariants}
        initial="initial"
        animate="animate"
      >
        <MainSection>
          <PricingHeader
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <h1>{t("choosePlan")}</h1>
            <IntervalSelectionContainer>
              <IntervalSelection>
                <IntervalButton
                  $active={selectedInterval === "monthly"}
                  onClick={() => setSelectedInterval("monthly")}
                >
                  {t("monthly")}
                </IntervalButton>
                <IntervalButton
                  $active={selectedInterval === "yearly"}
                  onClick={() => setSelectedInterval("yearly")}
                >
                  {t("annual")}
                </IntervalButton>
                <IntervalSlider
                  $position={selectedInterval === "yearly" ? "right" : "left"}
                />
              </IntervalSelection>
              <AnimatePresence>
                {selectedInterval === "monthly" && (
                  <SavingsLabel
                    initial={{ opacity: 0, scale: 0.8, y: -20 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.8, y: -20 }}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                  >
                    Save 17%
                  </SavingsLabel>
                )}
              </AnimatePresence>
            </IntervalSelectionContainer>
          </PricingHeader>

          <CardsContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            {plans.map((plan, index) => {
              const yearlyCalculation = calculateYearlyPrice(plan.monthlyPrice);
              const displayPrice =
                selectedInterval === "yearly"
                  ? yearlyCalculation.price
                  : plan.monthlyPrice.toFixed(2);
              return (
                <PricingCard
                  key={plan.name}
                  $featured={plan.featured}
                  initial={{ opacity: 0, y: 20, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 30,
                    delay: index * 0.1,
                  }}
                >
                  <CardContent>
                    <PlanHeader>
                      <PlanName>{plan.name}</PlanName>
                    </PlanHeader>
                    <PlanPrice>
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={`${plan.name}-${selectedInterval}`}
                          variants={priceAnimationVariants}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <CurrentPrice>
                            <span>$</span>
                            {displayPrice}
                            <span className="period">
                              /{selectedInterval === "yearly" ? "yr" : "mo"}
                            </span>
                          </CurrentPrice>
                          {selectedInterval === "yearly" && (
                            <OriginalPrice
                              initial={{ opacity: 0 }}
                              animate={{
                                opacity: 0.6,
                                transition: { duration: 0.2, delay: 0.1 },
                              }}
                              exit={{ opacity: 0 }}
                            >
                              ${(plan.monthlyPrice * 12).toFixed(2)}/yr
                            </OriginalPrice>
                          )}
                        </motion.div>
                      </AnimatePresence>
                    </PlanPrice>
                    <AccessCount>
                      <AccessNumber>{plan.access.count}</AccessNumber>
                      <AccessPeriod>{plan.access.period}</AccessPeriod>
                    </AccessCount>
                    <FeatureList>
                      {plan.features.map((feature, idx) => (
                        <FeatureItem key={idx}>
                          <Check size={16} />
                          {feature}
                        </FeatureItem>
                      ))}
                    </FeatureList>
                    <GetStartedButton
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={handleSignInClick}
                    >
                      {t("getStarted")}
                    </GetStartedButton>
                  </CardContent>
                </PricingCard>
              );
            })}

            <Divider />

            <PayAsYouGoBar
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 30,
                delay: plans.length * 0.1,
              }}
            >
              <PayAsYouGoContent>
                <PlanHeader>
                  <PayAsYouGoTitle>Pay As You Go</PayAsYouGoTitle>
                </PlanHeader>
                <PayAsYouGoPrice>${selectedAmount}</PayAsYouGoPrice>
                <PriceSubtext>
                  {selectedAmount / 0.2} property views
                </PriceSubtext>
                <DropdownWrapper>
                  <PayAsYouGoDropdown>
                    <DropdownHeader
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <span>${selectedAmount}</span>
                      <ChevronDown
                        size={16}
                        style={{
                          transform: isDropdownOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </DropdownHeader>
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <DropdownOptions
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                        >
                          {rechargeAmounts.map((amount) => (
                            <DropdownOption
                              key={amount.value}
                              onClick={() => {
                                setSelectedAmount(amount.value);
                                setIsDropdownOpen(false);
                              }}
                              $isSelected={selectedAmount === amount.value}
                            >
                              {amount.label}
                              {selectedAmount === amount.value && (
                                <CheckMark>
                                  <Check size={16} />
                                </CheckMark>
                              )}
                            </DropdownOption>
                          ))}
                        </DropdownOptions>
                      )}
                    </AnimatePresence>
                  </PayAsYouGoDropdown>
                </DropdownWrapper>
                <FeatureList>
                  <FeatureItem>
                    <Check size={16} />
                    Pay only for what you use
                  </FeatureItem>
                  <FeatureItem>
                    <Check size={16} />
                    No monthly commitment
                  </FeatureItem>
                  <FeatureItem>
                    <Check size={16} />
                    Instant activation
                  </FeatureItem>
                </FeatureList>
                <GetStartedButton
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleSignInClick}
                >
                  {t("getStarted")}
                </GetStartedButton>
              </PayAsYouGoContent>
            </PayAsYouGoBar>
          </CardsContainer>
        </MainSection>
      </ContentWrapper>

      <LandingFooter
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <p>&copy; {new Date().getFullYear()} DeedFinders</p>
        <FooterLinks>
          <a href="#" onClick={handlePrivacyPolicyClick}>
            {t("privacyPolicy")}
          </a>
          <a href="#" onClick={handleTermsOfServiceClick}>
            {t("termsOfService")}
          </a>
        </FooterLinks>
        <LanguageButtons>
          <LanguageButton
            className={i18n.language === "en" ? "active" : ""}
            onClick={() => changeLanguage("en")}
          >
            EN
          </LanguageButton>
          <LanguageButton
            className={i18n.language === "ptbr" ? "active" : ""}
            onClick={() => changeLanguage("ptbr")}
          >
            PT
          </LanguageButton>
        </LanguageButtons>
      </LandingFooter>

      <SignUpModal
        open={isSignUpModalOpen}
        onClose={() => setIsSignUpModalOpen(false)}
        onSwitchToSignIn={handleSwitchToSignIn}
        onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
        onOpenTermsOfService={() => handleOpenModal("termsOfService")}
      />

      <SignInModal
        open={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
        onSuccess={() => {
          setIsSignInModalOpen(false);
          navigate("/home/buy");
        }}
        onSwitchToSignUp={handleSwitchToSignUp}
        onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
        onOpenTermsOfService={() => handleOpenModal("termsOfService")}
      />

      <PolicyModal
        open={
          activeModal === "privacyPolicy" || activeModal === "termsOfService"
        }
        onClose={handleCloseModal}
        title={
          activeModal === "privacyPolicy"
            ? "Privacy Policy"
            : "Terms of Service"
        }
        content={
          activeModal === "privacyPolicy"
            ? privacyPolicyContent
            : termsOfServiceContent
        }
      />
    </PageContainer>
  );
};

export default PublicPricingPage;
