// DetailedPropertyInfo.jsx

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";
import GoogleMaps from "../../common/GoogleMaps";
import FavoriteButton from "./FavoriteButton";
import { Share2, Globe, RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import Toast from "../../common/Toast";
import PropertyDetails from "./PropertyDetails";
import { motion, AnimatePresence } from "framer-motion";
import { useWallet } from "../../../context/WalletContext";
import { useNavigate } from "react-router-dom";

// Keyframe Animations
const slideIn = keyframes`
  0% {
    transform: translate(100%, -50%);
    opacity: 0;
  }
  60% {
    transform: translate(0, -50%);
    opacity: 0.3;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// Styled Components

// Overlay for the modal background
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.5)"};

  z-index: 1000;
  cursor: pointer;
  opacity: ${(props) => (props.$isFadingOut ? 0 : 1)};
  transition: opacity 0.5s ease;
`;

// Modal Wrapper
const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 90vh; /* Matches .property-modal-overlay height */
  transform: translate(-50%, -50%);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* Matches .property-modal-overlay border-radius */
  opacity: ${(props) => (props.$isFadingOut ? 0 : 1)};
  animation: ${(props) => (props.$isFadingOut ? fadeOut : slideIn)} 0.5s
    ease-out forwards;
`;

// Modal Content
const ModalContent = styled.div`
  background: ${({ theme }) =>
    theme.isDarkMode ? "rgba(38, 38, 45, 0.98)" : "rgba(255, 255, 255, 0.98)"};
  padding: 20px;
  border-radius: 10px; /* Matches .property-modal-content border-radius */
  height: 100%;
  width: 98%; /* Matches .property-modal-content width */
  overflow-y: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
`;

// Details Container
const DetailsContainer = styled.div`
  width: 40%;
  height: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  /* Remove scrollbar styles since container shouldn't scroll */
  &::-webkit-scrollbar {
    display: none;
  }
`;

// Options Box containing buttons
const OptionsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 42px;
  padding: 0;
  margin-top: 10px;
`;

// Left Options containing Favorite, Share, and Translate buttons
const LeftOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 42px;
`;

// New styled components for the translation controls
const TranslationControls = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  height: 42px;
`;

// First, define IconButton before it's used by other components
const IconButton = styled.button`
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  height: 42px;
  width: 44px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.2s ease;
  color: ${({ theme }) => theme.colors.primary};

  svg {
    width: 20px;
    height: 20px;
  }
`;

// Then define components that depend on IconButton
const TranslationButton = styled(IconButton)`
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
const TranslatingText = styled(motion.span)`
  height: 42px;
  padding: 0 16px;
  border-radius: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.border};
  backdrop-filter: blur(10px);
  font-size: 14px;
  letter-spacing: 0.3px;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  animation: pulse 2s infinite cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes pulse {
    0% {
      opacity: 0.7;
      transform: scale(0.98);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0.7;
      transform: scale(0.98);
    }
  }
`;

// Close Button styled-component
const CloseButton = styled.button`
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
  }
`;

// Map Container styled-component
const MapContainer = styled.div`
  width: 60%; /* Matches .property-map-container width */
  background: ${({ theme }) => theme.colors.surface};
  box-sizing: border-box;
  border-radius: 10px; /* Matches .property-map-container border-radius */
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

// Property Map Section styled-component
const PropertyMapSection = styled.div`
  border-radius: 10px; /* Matches .property-map-section border-radius */
  width: 100%;
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.$expanded ? "100%" : "300px")};
  position: relative;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

// Toggle Buttons Container styled-component
const ToggleButtonsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 10px;
`;

// Toggle Button styled-component
const ToggleButton = styled.button`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;

  &.active {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    color: ${({ theme }) => theme.colors.text};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
  }
`;

// DetailedPropertyInfo Component
const DetailedPropertyInfo = ({
  isOpen,
  onClose,
  mapCenter,
  activeMap,
  setActiveMap,
  expandedData,
  setExpandedData,
  tabIndex,
  setTabIndex,
  isPublic = false,
  creditLimitReached = false,
  children,
}) => {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [femaTabRef, setFemaTabRef] = useState(null);
  const tippyInstance = useRef();

  // Translation States
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedData, setTranslatedData] = useState(null);
  const [toast, setToast] = useState(null);

  // New state to determine which data to use
  const [currentData, setCurrentData] = useState(expandedData || {});

  const { walletData, trackPropertyView } = useWallet();
  const navigate = useNavigate();
  const [viewError, setViewError] = useState(null);

  // Add a check for English language
  const shouldShowTranslation = i18n.language !== "en";

  useEffect(() => {
    if (isOpen) {
      setIsFadingOut(false);
      setTimeout(() => setIsExpanded(true), 10);
    } else {
      handleClose();
    }
  }, [isOpen]);

  // Separate cleanup function
  const handleClose = () => {
    setIsFadingOut(true);
    // Wait for fade out animation to complete before cleaning up
    setTimeout(() => {
      if (!isOpen) {
        // Additional check to prevent state updates if modal was reopened
        setIsExpanded(false);
        setIsFadingOut(false);
        onClose();
        setTabIndex(0);
        setTranslatedData(null);
        setToast(null);
      }
    }, 500);
  };

  const handleOverlayClick = () => {
    onClose(); // Just trigger the close prop, let the effect handle cleanup
  };

  const handleOpenFEMAMap = () => {
    const offset = 0.001;
    const femaUrl = `https://hazards-fema.maps.arcgis.com/apps/webappviewer/index.html?id=8b0adb51996444d4879338b5529aa9cd&extent=${
      mapCenter.lng - offset
    },${mapCenter.lat - offset},${mapCenter.lng + offset},${
      mapCenter.lat + offset
    }`;

    if (femaTabRef && !femaTabRef.closed) {
      femaTabRef.location.href = femaUrl;
      femaTabRef.focus();
    } else {
      const newTab = window.open(femaUrl, "_blank");
      setFemaTabRef(newTab);
    }
  };

  const handleShare = () => {
    if (!expandedData || !expandedData.overview) {
      console.error("Expanded data or overview is missing");
      return;
    }

    const propertyId = expandedData.overview.uid;
    // Construct the base URL using window.location.origin and pathname
    const baseUrl = `${window.location.origin}/home/buy`;
    const url = new URL(baseUrl);
    url.searchParams.set("property", propertyId);

    const shareUrl = url.toString();

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        if (tippyInstance.current) {
          tippyInstance.current.setContent(t("linkCopied"));
          tippyInstance.current.show();
        }
        setTimeout(() => {
          if (tippyInstance.current) {
            tippyInstance.current.setContent(t("shareProperty"));
          }
        }, 2000);
      })
      .catch(console.error);
  };

  // Translation Handler
  const handleTranslate = async () => {
    if (!expandedData || !expandedData.overview) {
      console.error("Expanded data or overview is missing");
      return;
    }

    setIsTranslating(true);

    try {
      const endpoint = isPublic
        ? `${process.env.REACT_APP_API_URL}/api/public-properties/${expandedData.overview.uid}/translate`
        : `${process.env.REACT_APP_API_URL}/api/properties/${expandedData.overview.uid}/translate`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ targetLanguage: "ptbr" }),
      });

      if (response.ok) {
        const data = await response.json();
        setTranslatedData(data.translatedDetails);
        setCurrentData(data.translatedDetails);
        setExpandedData({
          ...expandedData,
          overview: {
            ...expandedData.overview,
            needsTranslation: false,
          },
        });
        setToast({
          message: data.message,
          type: "success",
          duration: 3000,
        });
      } else {
        const errorData = await response.json();
        setToast({
          message: errorData.error || t("translationFailed"),
          type: "error",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Translation error:", error);
      setToast({
        message: t("translationFailed"),
        type: "error",
        duration: 3000,
      });
    } finally {
      setIsTranslating(false);
    }
  };

  const handleRevertTranslation = () => {
    setTranslatedData(null);
    setCurrentData(expandedData);
  };

  const isTranslated = Boolean(translatedData);

  // Update currentData when expandedData or translatedData changes
  useEffect(() => {
    if (translatedData) {
      setCurrentData(translatedData);
    } else if (expandedData) {
      setCurrentData(expandedData);
    }
  }, [expandedData, translatedData]);

  useEffect(() => {
    // Only set the tab index when the modal first opens AND we're coming from notes
    if (isOpen && !isExpanded && tabIndex === 5) {
      setTabIndex(5);
    }
  }, [isOpen]); // Only depend on isOpen

  // Update the tab handling logic
  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  // Update handlePropertyDetails

  // Add view limit warning if close to limit
  useEffect(() => {
    if (walletData?.subscription?.usage) {
      const { current, limit } = walletData.subscription.usage;
      if (limit && current >= limit * 0.9) {
        // 90% of limit
        setToast({
          message: `You're approaching your view limit (${current}/${limit}). Consider upgrading your plan.`,
          type: "warning",
          duration: 5000,
        });
      }
    }
  }, [walletData?.subscription?.usage]);

  // Add credit limit protection
  useEffect(() => {
    if (creditLimitReached) {
      // If credit limit is reached, force navigation to pricing on any interaction
      const handleInteraction = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate("/home/wallet/plans");
      };

      const modalContent = document.querySelector(".property-details");
      if (modalContent) {
        modalContent.addEventListener("click", handleInteraction, true);
        modalContent.addEventListener("keydown", handleInteraction, true);
        modalContent.addEventListener("scroll", handleInteraction, true);
      }

      return () => {
        if (modalContent) {
          modalContent.removeEventListener("click", handleInteraction, true);
          modalContent.removeEventListener("keydown", handleInteraction, true);
          modalContent.removeEventListener("scroll", handleInteraction, true);
        }
      };
    }
  }, [creditLimitReached, navigate]);

  if (!isOpen && !isExpanded) return null;
  return ReactDOM.createPortal(
    <>
      <Overlay $isFadingOut={isFadingOut} onClick={handleOverlayClick} />
      <ModalWrapper $isFadingOut={isFadingOut}>
        <ModalContent>
          <DetailsContainer
            $isLoading={isTranslating}
            style={{
              pointerEvents: creditLimitReached ? "none" : "auto",
              opacity: creditLimitReached ? 0.5 : 1,
            }}
          >
            <PropertyDetails
              property={currentData}
              tabIndex={tabIndex}
              setTabIndex={handleTabChange}
              isTranslating={isTranslating}
              isPublic={isPublic}
            />

            <OptionsBox>
              <LeftOptions>
                {!isPublic && currentData && currentData.overview && (
                  <FavoriteButton uid={currentData.overview.uid} />
                )}
                <Tippy
                  content={t("shareProperty")}
                  animation="shift-away"
                  placement="top"
                  duration={[300, 250]}
                  delay={[400, 0]}
                  theme="modern"
                  arrow={true}
                  onCreate={(instance) => (tippyInstance.current = instance)}
                >
                  <IconButton
                    onClick={handleShare}
                    aria-label={t("shareProperty")}
                  >
                    <Share2 size={20} />
                  </IconButton>
                </Tippy>
                <TranslationControls>
                  {shouldShowTranslation && (
                    <>
                      {isTranslated ? (
                        <Tippy
                          content={t("revertTranslation")}
                          animation="shift-away"
                          placement="top"
                          duration={[300, 250]}
                          delay={[400, 0]}
                          theme="modern"
                          arrow={true}
                        >
                          <TranslationButton
                            onClick={handleRevertTranslation}
                            disabled={isTranslating}
                            aria-label={t("revertTranslation")}
                          >
                            <RefreshCw size={20} />
                          </TranslationButton>
                        </Tippy>
                      ) : (
                        <Tippy
                          content={t("translate")}
                          animation="shift-away"
                          placement="top"
                          duration={[300, 250]}
                          delay={[400, 0]}
                          theme="modern"
                          arrow={true}
                        >
                          <TranslationButton
                            onClick={handleTranslate}
                            disabled={isTranslating}
                            aria-label={t("translate")}
                          >
                            <Globe size={20} />
                          </TranslationButton>
                        </Tippy>
                      )}
                      <AnimatePresence>
                        {isTranslating && (
                          <TranslatingText
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                          >
                            {t("translating")}
                          </TranslatingText>
                        )}
                      </AnimatePresence>
                    </>
                  )}
                </TranslationControls>
              </LeftOptions>
              <CloseButton onClick={handleOverlayClick}>
                {t("close")}
              </CloseButton>
            </OptionsBox>
          </DetailsContainer>

          <MapContainer>
            <PropertyMapSection $expanded={isExpanded}>
              <GoogleMaps mapCenter={mapCenter} />
              <ToggleButtonsContainer>
                <ToggleButton
                  className={activeMap === "Google" ? "active" : ""}
                  onClick={() => setActiveMap("Google")}
                >
                  Google Maps
                </ToggleButton>
                <ToggleButton
                  className={activeMap === "FEMA" ? "active" : ""}
                  onClick={handleOpenFEMAMap}
                >
                  FEMA
                </ToggleButton>
              </ToggleButtonsContainer>
            </PropertyMapSection>
          </MapContainer>
        </ModalContent>
      </ModalWrapper>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={() => setToast(null)}
        />
      )}
      {viewError && <div className="view-error-message">{viewError}</div>}
    </>,
    document.getElementById("modal-root")
  );
};

export default DetailedPropertyInfo;
