import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { ChevronDown } from "react-feather";
import SwarmAlive from "./SwarmAlive";
import gsap from "gsap";
import SplitType from "split-type";

// Define keyframes first
const wobble = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(1.02) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.98) rotate(-1deg);
  }
  75% {
    transform: translate(-50%, -50%) scale(1.02) rotate(0.5deg);
  }
`;

const pulseNeon = keyframes`
  0%, 100% {
    box-shadow: 0 0 15px rgba(136, 83, 251, 0.7),
                0 0 30px rgba(136, 83, 251, 0.5),
                0 0 45px rgba(136, 83, 251, 0.3),
                inset 0 0 15px rgba(136, 83, 251, 0.7),
                inset 0 0 30px rgba(136, 83, 251, 0.5);
    border-color: rgba(136, 83, 251, 0.8);
  }
  50% {
    box-shadow: 0 0 25px rgba(136, 83, 251, 0.9),
                0 0 50px rgba(136, 83, 251, 0.7),
                0 0 75px rgba(136, 83, 251, 0.5),
                inset 0 0 25px rgba(136, 83, 251, 0.9),
                inset 0 0 50px rgba(136, 83, 251, 0.7);
    border-color: rgba(136, 83, 251, 1);
  }
`;

const highlightFloat = keyframes`
  0%, 100% {
    transform: translate(0, 0);
    opacity: 0.3;
  }
  50% {
    transform: translate(1px, 1px);
    opacity: 0.4;
  }
`;

const IntroContainer = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  flex: 1;
  padding-right: 5vw;
  padding-top: 10vh;
  @media (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 15vh;
  }
`;

const TextContainer = styled.div`
  position: relative;
  padding-left: 2vw;
  max-width: 100%;
`;

const BrandName = styled.h1`
  font-family: "Space Grotesk", sans-serif;
  font-size: clamp(4rem, 10vw, 7rem);
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  margin-bottom: 1.5rem;
  opacity: 0;
  color: transparent;
  white-space: nowrap;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 20px rgba(136, 83, 251, 0.3),
    0 0 40px rgba(136, 83, 251, 0.2), 0 0 60px rgba(136, 83, 251, 0.1);

  @media (max-width: 480px) {
    font-size: clamp(2.5rem, 8vw, 4rem);
  }

  .char {
    display: inline-block;
    transform-origin: center;
    transition: color 0.3s ease, text-shadow 0.3s ease;
  }
`;

const Slogan = styled.div`
  font-size: clamp(1.5rem, 3vw, 2rem);
  line-height: 1.5;
  font-weight: 300;
  font-family: "Inter", sans-serif;
  opacity: 0;
  display: flex;
  margin-top: 0;
  padding-left: 0.25rem;

  @media (max-width: 768px) {
    font-size: clamp(1.2rem, 4vw, 1.5rem);
    line-height: 1.4;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .word {
    display: inline-block;
    margin-right: 0.25em;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.95) 0%,
      rgba(255, 255, 255, 0.75) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }
`;

const VisualSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  position: absolute;
  right: -100vh;
  top: 30vh;

  @media (max-width: 768px) {
    right: -70vh;
    top: 20vh;
    opacity: 0.5;
    pointer-events: none;
    transform: scale(0.8);
  }

  @media (max-width: 480px) {
    // right: -50vh;
    // top: 15vh;
    transform: scale(0.6);
  }
`;

const ParticleWrapper = styled.div`
  position: relative;
  width: 1000px;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${wobble} 10s ease-in-out infinite;

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 480px) {
    width: 400px;
    height: 400px;
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 2px solid rgba(136, 83, 251, 0.8);
  box-shadow: 0 0 15px rgba(136, 83, 251, 0.7), 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(136, 83, 251, 0.7),
    inset 0 0 30px rgba(136, 83, 251, 0.5);
  animation: ${pulseNeon} 2s ease-in-out infinite;
  background: rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(5px);
  will-change: transform, box-shadow, border-color;

  @media (max-width: 768px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 480px) {
    width: 300px;
    height: 300px;
  }
`;

const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(136, 83, 251, 0.3) 0%,
    rgba(136, 83, 251, 0.1) 20%,
    transparent 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.5);
  box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
  opacity: 0.9;
  will-change: transform, opacity;
`;

const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4),
    transparent 80%
  );
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: ${highlightFloat} 4s ease-in-out infinite;
`;

const ScrollArrow = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease;
  z-index: 10;

  &:hover {
    color: rgba(136, 83, 251, 0.9);
  }

  svg {
    width: 32px;
    height: 32px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const IntroSection = () => {
  const containerRef = useRef(null);
  const brandRef = useRef(null);
  const sloganRef = useRef(null);
  const scrollArrowRef = useRef(null);
  const brandTextRef = useRef(null);
  const sloganTextRef = useRef(null);
  const tlRef = useRef(null);

  useEffect(() => {
    // Initialize split text
    brandTextRef.current = new SplitType(brandRef.current, { types: "chars" });
    sloganTextRef.current = new SplitType(sloganRef.current, {
      types: "words",
    });

    // Create timeline
    tlRef.current = gsap.timeline({
      defaults: { ease: "power4.out" },
    });

    // Animate brand name
    tlRef.current
      .set(brandRef.current, { opacity: 1 })
      .from(brandTextRef.current.chars, {
        duration: 1.5,
        y: 100,
        rotateX: -90,
        stagger: 0.02,
        opacity: 0,
      })
      .to(
        brandTextRef.current.chars,
        {
          duration: 0.6,
          scale: 1.1,
          stagger: 0.02,
        },
        "-=1"
      )
      .to(brandTextRef.current.chars, {
        duration: 0.6,
        scale: 1,
        stagger: 0.02,
      });

    // Animate slogan - Modified to fade in and stay visible
    tlRef.current
      .set(sloganRef.current, { opacity: 0 }) // Start fully transparent
      .to(
        sloganRef.current,
        {
          duration: 1.2,
          opacity: 1,
          ease: "power3.out",
        },
        "-=0.8"
      );

    // Animate scroll arrow
    tlRef.current.to(
      scrollArrowRef.current,
      {
        duration: 1,
        opacity: 1,
        y: [20, 0],
        ease: "power2.out",
      },
      "-=0.5"
    );

    // Cleanup function
    return () => {
      if (tlRef.current) {
        tlRef.current.kill();
      }
      if (brandTextRef.current) {
        brandTextRef.current.revert();
      }
      if (sloganTextRef.current) {
        sloganTextRef.current.revert();
      }
    };
  }, []);

  const scrollToNext = () => {
    const nextSection = containerRef.current?.nextElementSibling;
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <IntroContainer ref={containerRef} data-scroll-section>
      <ContentWrapper data-scroll data-scroll-speed="-0.3">
        <TextContainer>
          <BrandName ref={brandRef}>DeedFinders</BrandName>
          <Slogan ref={sloganRef}>
            <div>
              All your tax deed and lien insights—unified, simplified, and ready
              to explore.
            </div>
          </Slogan>
        </TextContainer>
      </ContentWrapper>

      <VisualSection>
        <ParticleWrapper>
          <BubbleContainer>
            <BubbleSkin
              animate={{
                scale: 1.02,
                opacity: 0.9,
              }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 20,
              }}
            />
            <Highlight />
          </BubbleContainer>
          <SwarmAlive
            size={
              window.innerWidth <= 480
                ? "300px"
                : window.innerWidth <= 768
                ? "400px"
                : "1000px"
            }
            enableHover={false}
            active={true}
            mode="normal"
            instanceId="intro-section-swarm"
          />
        </ParticleWrapper>
      </VisualSection>

      <ScrollArrow
        ref={scrollArrowRef}
        onClick={scrollToNext}
        animate={{ y: [0, 10, 0] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <ChevronDown size={32} />
      </ScrollArrow>
    </IntroContainer>
  );
};

export default IntroSection;
