// frontend/src/components/modals/SignInModal.js

import React, { useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { X as CloseIcon, AlertTriangle } from "react-feather";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import debounce from "lodash/debounce";

const SigninModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const SigninModalContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  margin: 20px;
  width: 90%;
  max-width: 440px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }

  @media (max-width: 480px) {
    padding: 20px;
    width: 95%;
    margin: 10px;
  }
`;

const SigninModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #fff;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const SigninErrorMessage = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.1),
    rgba(255, 59, 48, 0.05)
  );
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 59, 48, 0.2);
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 20px;
  font-size: 13px;
  color: #ff3b30;
  display: flex;
  align-items: center;
  gap: 12px;
  transform-origin: top;
  box-shadow: 0 4px 12px rgba(255, 59, 48, 0.1),
    0 0 0 1px rgba(255, 59, 48, 0.1);

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
`;

const SigninFormGroup = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;
`;

const SigninInput = styled.input`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid
    ${(props) =>
      props.$hasError ? "rgba(255, 59, 48, 0.4)" : "rgba(136, 83, 251, 0.2)"};
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  max-width: 100%;

  &:focus {
    outline: none;
    border-color: ${(props) =>
      props.$hasError ? "rgba(255, 59, 48, 0.6)" : "rgba(136, 83, 251, 0.4)"};
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: ${(props) =>
      props.$hasError
        ? "0 4px 20px rgba(255, 59, 48, 0.1), 0 0 0 1px rgba(255, 59, 48, 0.2)"
        : "0 4px 20px rgba(0, 0, 0, 0.2), 0 0 20px rgba(136, 83, 251, 0.1), 0 0 0 1px rgba(136, 83, 251, 0.2)"};
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  @media (max-width: 480px) {
    height: 44px;
    font-size: 13px;
  }
`;

const SigninForgotPasswordLink = styled.button`
  background: none;
  border: none;
  color: rgba(136, 83, 251, 0.8);
  font-size: 14px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;

  &:hover {
    color: #8853fb;
    background: rgba(136, 83, 251, 0.1);
  }
`;

const SigninSubmitButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

const SigninModalFooter = styled.div`
  margin-top: 24px;
  text-align: center;

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 1.6;
    margin: 8px 0;
  }

  button {
    background: none;
    border: none;
    color: #8853fb;
    font-size: inherit;
    padding: 4px 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 6px;

    &:hover {
      background: rgba(136, 83, 251, 0.1);
    }
  }

  @media (max-width: 480px) {
    margin-top: 16px;

    p {
      font-size: 13px;
    }
  }
`;

const SigninModalClose = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  color: rgba(255, 59, 48, 0.9);
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const SignInModal = ({
  open,
  onClose,
  onSwitchToSignUp,
  onOpenPrivacyPolicy,
  onOpenTermsOfService,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  // State variables for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);

  // Email validation function
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Debounced validation
  const debouncedValidateEmail = useCallback(
    debounce((value) => {
      if (isEmailTouched && value && !validateEmail(value)) {
        setShowEmailError(true);
      } else {
        setShowEmailError(false);
      }
    }, 800),
    [isEmailTouched]
  );

  // Clear debounce on unmount
  useEffect(() => {
    return () => {
      debouncedValidateEmail.cancel();
    };
  }, [debouncedValidateEmail]);

  const handleSignIn = async (event) => {
    event.preventDefault();
    setError(null);

    // Validate email format before proceeding
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const result = await login({ email: email.toLowerCase(), password });

    if (result.success) {
      onClose(); // Close the modal upon successful login
      navigate("/home/buy");
    } else {
      setError(result.error || "An unexpected error occurred during sign-in");
    }
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    onSwitchToSignUp();
  };

  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault();
    onOpenPrivacyPolicy();
  };

  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    onOpenTermsOfService();
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    onClose(); // Close the sign-in modal
    navigate("/forgot-password"); // Navigate to the forgot password page
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    debouncedValidateEmail(value);
  };

  const handleEmailBlur = () => {
    setIsEmailTouched(true);
    if (email) {
      debouncedValidateEmail(email);
    }
  };

  if (!open) return null;

  return (
    <AnimatePresence>
      {open && (
        <SigninModalOverlay
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SigninModalContent
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <SigninModalClose onClick={onClose}>
              <CloseIcon size={24} />
            </SigninModalClose>
            <SigninModalTitle>{t("signin")}</SigninModalTitle>
            <AnimatePresence mode="wait">
              {error && (
                <SigninErrorMessage
                  initial={{ opacity: 0, y: -20, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: -20, scale: 0.95 }}
                  transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
                >
                  <AlertTriangle />
                  {error}
                </SigninErrorMessage>
              )}
            </AnimatePresence>

            <form onSubmit={handleSignIn}>
              <SigninFormGroup>
                <SigninInput
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("email")}
                  required
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  $hasError={showEmailError}
                />
                <AnimatePresence>
                  {showEmailError && (
                    <ErrorMessage
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <AlertTriangle />
                      {t("pleaseEnterValidEmail")}
                    </ErrorMessage>
                  )}
                </AnimatePresence>
              </SigninFormGroup>
              <SigninFormGroup>
                <SigninInput
                  type="password"
                  id="password"
                  name="password"
                  placeholder={t("password")}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </SigninFormGroup>
              <SigninFormGroup>
                <SigninForgotPasswordLink
                  type="button"
                  onClick={handleForgotPasswordClick}
                >
                  {t("forgotPassword")}
                </SigninForgotPasswordLink>
              </SigninFormGroup>
              <SigninSubmitButton type="submit">
                {t("signin")}
              </SigninSubmitButton>
            </form>
            <SigninModalFooter>
              <p>
                {t("dontHaveAccount")}{" "}
                <button type="button" onClick={handleSignUpClick}>
                  {t("signup")}
                </button>
              </p>
              <p>
                <button type="button" onClick={handlePrivacyPolicyClick}>
                  {t("privacyPolicy")}
                </button>{" "}
                {t("and")}{" "}
                <button type="button" onClick={handleTermsOfServiceClick}>
                  {t("termsOfService")}
                </button>{" "}
                {t("apply")}
              </p>
            </SigninModalFooter>
          </SigninModalContent>
        </SigninModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default SignInModal;
