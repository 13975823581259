const fetchExpandedData = async (uid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/properties/${uid}/details`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (!data || !data.overview) {
      throw new Error('Invalid data received from server');
    }

    return data;
  } catch (error) {
    console.error("Failed to fetch expanded data:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

export default fetchExpandedData;
