import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      companyName: "DeedFinders",
      signin: "Sign In",
      signout: "Sign Out",
      signup: "Sign Up",
      termsOfService: "Terms of Service",
      and: "and",
      privacyPolicy: "Privacy Policy",
      translate: "Translate",
      translating: "Translating...",
      translated: "Translated",
      revertTranslation: "Revert Translation",
      translationFailed: "Translation failed. Please try again.",
      linkCopied: "Link copied!",
      shareProperty: "Share Property",
      close: "Close",
      confirmPassword: "Confirm Password",
      or: "OR",
      workEmail: "Work Email",
      password: "Password",
      agreeToTerms: "I agree to the Terms of Service",
      receiveUpdates: "I want to receive updates and marketing communications",
      alreadyRegistered: "Already registered?",
      dontHaveAccount: "Don't have an account?",
      apply: "apply",
      welcome: "Welcome to DeedFinders",
      oneStopPlatform:
        "Your one-stop platform for property purchase and services",
      ourFeatures: "Our Features",
      findProperties: "Find properties with tax liens and tax deeds",
      connect:
        "Connect with attorneys, real estate agents, and service providers",
      invest: "Invest in properties and services",
      accessAuctions: "Access property auctions and bidding systems",
      copyright: "© 2024 DeedFinders™. All rights reserved.",
      passwordRequirement: {
        length: "Be at least 8 characters long",
        uppercaseLowercase: "Contain both uppercase and lowercase letters",
        numberOrSpecial: "Contain numbers or special characters",
      },
      passwordsMustMatch: "Passwords must match",
      forgotPassword: "Forgot Password",
      forgotPasswordDescription:
        "Enter your email address and we'll send you instructions to reset your password.",
      resetPassword: "Reset Password",
      email: "Email",
      emailVerification: "Email Verification",
      verifyingEmail: "Verifying your email...",
      emailVerificationSuccess: "Your email has been successfully verified!",
      emailVerificationError:
        "There was an error verifying your email. Please try again or contact support.",
      proceedToLogin: "Proceed to Login",
      verificationExpires: "This verification link expires on {{time}}",
      accountSettings: "Account Settings",
      personalInformation: "Personal Information",
      name: "Name",
      location: "Location",
      bio: "Bio",
      security: "Security",
      changePassword: "Change Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
      passwordMust: "Password must",
      beAtLeast8CharactersLong: "Be at least 8 characters long",
      containAtLeastOneUppercaseLetter: "Contain at least one uppercase letter",
      containAtLeastOneLowercaseLetter: "Contain at least one lowercase letter",
      containAtLeastOneNumber: "Contain at least one number",
      containAtLeastOneSpecialCharacter:
        "Contain at least one special character (!@#$%^&*)",
      cancel: "Cancel",
      accountActions: "Account Actions",
      languagePreferences: "Language Preferences",
      deactivateAccount: "Deactivate Account",
      deactivateAccountDescription:
        "Temporarily disable your account. You can reactivate at any time by logging back in.",
      deleteAccount: "Delete Account",
      deleteAccountDescription:
        "Permanently remove your account and all associated data within 30 days. You can cancel the deletion process by logging back in during this period.",
      logout: "Logout",
      saveChanges: "Save Changes",
      currentPasswordIncorrect: "Current password is incorrect",
      languageUpdateSuccess: "Language preference updated successfully!",
      languageUpdateError:
        "Failed to update language preference. Please try again.",
      areYouSureYouWantToLogOut: "Are you sure you want to log out?",
      areYouSureYouWantToDeactivateYourAccount:
        "Are you sure you want to deactivate your account?",
      areYouSureYouWantToDeleteYourAccount:
        "Are you sure you want to delete your account? This action cannot be undone.",
      language: {
        en: "English",
        ptbr: "Portuguese (Brazil)",
      },
      advancedActions: "Advanced Actions",

      // Wallet translations
      myWallet: "My Wallet",
      enhancedPropertySearch: "Enhanced Property Search",
      walletDescription: "Manage your credits and transactions.",
      currentSubscription: "Current Subscription",
      yourActivePlan: "Your active plan and its benefits",
      active: "Active",
      inactive: "Inactive",
      nextBilling: "Next billing",
      currentPlanFeatures: "Plan Features",
      upgradeYourPlan: "Enhance Your Experience",
      youreOnTopPlan: "You're on our Premium Plan",
      maximizeInvestment: "Maximize your investment potential.",
      upgradeNow: "Upgrade Now",
      topPlanMessage: "Enjoy all the premium features available:",
      securityInfo: "Your payment information is securely protected by Stripe",
      free: "Free",
      pro: "Pro",
      premium: "Premium",
      month: "month",
      year: "year",
      mo: "mo",
      yr: "yr",
      premiumMember: "Premium Member",
      enjoyingAllFeatures:
        "Enjoying all premium features and exclusive benefits",
      premiumThankYouTitle: "Thank you for being Premium",
      premiumThankYouMessage:
        "You're enjoying all the best features DeedFinders has to offer. We appreciate your support!",
      upcomingPlanChange: "Upcoming Plan Change",
      upcomingChangeTitle: "Plan Change Scheduled",
      upcomingChangeMessage:
        "Your subscription will change from {{currentPlan}} ({{currentInterval}}) to {{newPlan}} ({{newInterval}}) on {{date}}",
      newPlanFeatures: "New Plan Features",
      nextPlanBenefits: "{{plan}} Benefits",
      upgradeNowTo: "Upgrade to {{plan}}",
      changePlan: "Change Plan",
      subscribe: "Subscribe",
      reactivateSubscription: "Reactivate Subscription",
      noFeaturesAvailable: "No features available for this plan",
      noBenefitsAvailable: "No additional benefits available",
      confirmPlanChange: "Confirm Plan Change",
      confirmReactivation: "Confirm Reactivation",
      currentPlan: "Current plan",
      newPlan: "New plan",
      notAvailableUntilPlanEnds: "Not available until plan ends",
      reactivationImpact:
        "Your subscription will be reactivated immediately. You'll continue with your {{plan}} plan.",
      upgradeImpact:
        "You'll be upgraded to the {{plan}} plan immediately. You'll be charged ${{amount}}/{{interval}}.",
      downgradeImpact:
        "Your new {{plan}} plan will start at the end of your current billing cycle at ${{newAmount}}/{{newInterval}}.",
      downgradeToFreeImpact:
        "Your subscription will switch to the Free plan at the end of your current billing cycle.",
      noChangeImpact: "No changes will be made to your current subscription.",
      subscriptionUpdateSuccess:
        "Your subscription has been successfully updated",
      subscriptionUpdateError: "There was an error updating your subscription",

      // Subscription Modal translations
      chooseYourPlan: "Choose Your Plan",
      monthly: "Monthly",
      annual: "Annual",
      back: "Back",
      confirmChange: "Confirm Change",
      reactivate: "Reactivate",

      // Buy page translations
      manageBillingAndPayment: "Manage billing and payment methods",
      propertyIdentifier: "Property Identifier",
      city: "City",
      county: "County",
      state: "State",
      zipCode: "Zip Code",
      minimumBid: "Minimum Bid",
      adjudgedValue: "Adjudged Value",
      availableAfter: "Available after plan ends",
      status: "Status",
      saleType: "Sale Type",
      failedToFetchInitialData: "Failed to fetch initial data.",
      failedToFetchData: "Failed to fetch data.",
      failedToFetchFavoriteProperties: "Failed to fetch favorite properties.",
      failedToFetchExpandedData: "Failed to fetch expanded data",
      loadingPropertyDetails: "Loading property details...",
      propertyOverview: "Property Overview",
      propertyDetails: "Property Details",
      salesInformation: "Sales Information",
      frequentlyAskedQuestions: "Frequently Asked Questions",
      myPropertyNotes: "My Property Notes",

      // FilterSidebar translations
      legalAndFinancial: "Legal and Financial",
      myFavorites: "My Favorites",
      applyFilters: "Apply Filters",
      selectFavoriteFolders: "Select Favorite Folders",
      futureSale: "Future Sale",
      resale: "Resale",
      sale: "Sale",
      struckOff: "Struck Off",
      businessPersonalProperty: "Business Personal Property",
      improvementOnly: "Improvement Only",
      mineral: "Mineral",
      mobileHome: "Mobile Home",
      otherUnknown: "Other / Unknown",
      realProperty: "Real Property",
      availableForFutureSale: "Available for Future Sale",
      cancelled: "Cancelled",
      postponed: "Postponed",
      saleResultsPending: "Sale Results Pending",
      scheduledForAuction: "Scheduled for Auction",
      sold: "Sold",
      stayed: "Stayed",
      propertyType: "Property Type",
      adjudgedValueRange: "Adjudged Value Range",

      // Status translations
      availableforfuturesale: "Available for Future Sale",
      scheduledforauction: "Scheduled for Auction",

      // Sale type translations
      futuresale: "Future Sale",
      struckoff: "Struck Off",

      // Tab translations
      Overview: "Overview",
      Details: "Details",
      Sales: "Sales",
      Resources: "Resources",
      Notes: "Notes",
      FAQ: "FAQ",

      // New translations
      saveNote: "Save Note",
      struckOffDate: "Struck Off Date",
      additionalInfo: "Additional Info",
      saleDate: "Sale Date",
      struckOffAmount: "Struck Off Amount",
      caseStyle: "Case Style",
      legalDescription: "Legal Description",
      saleLocation: "Sale Location",
      judgmentDate: "Judgment Date",
      schoolDistrict: "School District",
      notAvailable: "Not Available",
      address: "Address",
      writeYourNotesHere: "Write your notes here...",

      // Cancel subscription translations
      cancelSubscription: "Cancel Subscription",
      cancelSubscriptionConfirmation: "Cancel Subscription Confirmation",
      cancelSubscriptionWarning:
        "Are you sure you want to cancel your subscription? You'll continue to have access until the end of your current billing period.",
      keepSubscription: "Keep Subscription",
      confirmCancel: "Confirm Cancellation",
      billingCycle: "Billing Cycle",
      canceling: "Canceling",
      subscriptionCanceledInfo: "Your subscription has been canceled.",
      accessUntil: "You will have access to your current plan until",

      // New translations for PricingPage
      pricing: "Pricing",
      choosePlan: "Choose Your Plan",
      getStarted: "Get Started",
      loading: "Loading...",

      // New translations for subscription plans
      basicAccess: "Basic access to the platform",
      limitedSearches: "Limited searches per month",
      standardSupport: "Standard support",
      unlimitedSearchResults: "Unlimited search results",
      enhancedSorting: "Enhanced sorting and filtering options",
      favoritesFeature: "Favorites feature to save and organize properties",
      unlimitedDailySearches: "Unlimited daily searches",
      documentTemplates: "Access to document templates and other resources",
      allProBenefits: "All Pro benefits",
      aiAssistant: "AI Assistant for personalized assistance",
      aiDrivenContent: "AI-driven content context to enhance property insights",
      exclusiveAccess: "Exclusive access to premium assets",
      advancedDocumentAccess: "Advanced document access",

      // New translation for activeFrom
      activeFrom: "Active from {{date}}",

      // New translations for DetailedPropertyInfo
      copyShareLink: "Copy property link",
      parcelNumber: "Parcel Number",

      // New translation for development warning
      developmentWarning:
        "Website under development. Some features may be incomplete or change without notice.",

      // New translation for registrationSuccessful
      registrationSuccessful:
        "Registration successful! Please check your email to verify your account.",

      // New translations for pagination
      page: "Page",
      of: "of",

      // New translations for PublicBuyContent
      createAccountToSeeMore: "Create an account to see more results",
      searchesRemaining: "Searches remaining today: {{count}}",
      dailySearchLimitReached: "Daily search limit reached",
      signInOrSignUpForMore: "Sign in or sign up to perform more searches",
      noItemsToDisplay: "No items to display",
      viewCredits: "View Credits",

      // New translations for FakeWindowsScreen
      freePlan: "Free Plan",
      proPlan: "Pro Plan",
      premiumPlan: "Premium Plan",
      start: "Start",

      // Updated translations for daily limit reached
      timeIsUp: "Time's up!",
      thankYouForTesting:
        "Thanks for test-driving our property search tool. What do you think? Ready to unlock its full potential?",
      readyToSignUp: "Sign Up Now",
      alreadyHaveAccount: "I Already Have an Account",

      // Loading overlay messages
      loadingMessages: {
        layer1: [
          "Summoning the property gnomes...",
          "Polishing our crystal ball...",
        ],
        layer2: [
          "Decoding ancient property scrolls...",
          "Brewing a potion of market insights...",
        ],
        layer3: [
          "Casting a wide net for hidden gems...",
          "Unleashing our pack of data-sniffing bloodhounds...",
        ],
        layer4: [
          "Convincing stubborn properties to reveal themselves...",
          "Battling the dragons of overpriced listings...",
        ],
      },

      // Resource tab translations
      resources: "Resources",
      countyResources: "County Resources",
      propertyResources: "Property Resources",
      clerkOfficeURL: "Clerk's Office URL",
      governmentLinks: "Government Links",
      propertyTaxInfo: "Property Tax Information",
      deedInfo: "Deed Information",
      lienInfo: "Lien Information",
      auctionInfo: "Auction Information",
      countyClerkOfficeURL: "County Clerk's Office URL",
      countyGovernmentLinks: "County Government Links",
      countyPropertyTaxInfo: "County Property Tax Information",
      countyDeedInfo: "County Deed Information",
      countyLienInfo: "County Lien Information",
      countyAuctionInfo: "County Auction Information",
      countyAdditionalInfo: "County Additional Information",
      loadingResources: "Loading resources",
      resourcesDisclaimer:
        "These resources are AI-generated and may not be up to date. Please verify information with official sources.",
      errorLoadingResources: "Error loading resources",

      // Add these new translations for the resources tab
      saleNotes: "Sale Notes",
      legalDescriptionShort: "Legal Description (Short)",
      courtNumber: "Court Number",
      causeNumber: "Cause Number",

      // Ensure all other necessary translations are included
      listingLinks: "Listing Links",
      taxAssessmentLinks: "Tax Assessment Links",
      marketInsights: "Market Insights",
      ownershipHistory: "Ownership History",
      floodRiskInfo: "Flood Risk Information",
      zoningInfo: "Zoning Information",
      nearbyAmenities: "Nearby Amenities",

      // Add these to your translation files
      noPropertyResourcesAvailable:
        "No property resources available at this time.",
      noCountyResourcesAvailable: "No county resources available at this time.",
      noPropertyResourcesFound: "No additional property resources found.",

      // Notes related translations
      "My Notes": "My Notes",
      "No Notes Found": "No notes found",
      "View Property": "View Property",
      "Note Saved Successfully": "Note saved successfully",
      "Failed to Save Note": "Failed to save note",
      "Note Deleted Successfully": "Note deleted successfully",
      "Failed to Delete Note": "Failed to delete note",
      "Are You Sure Delete Note": "Are you sure you want to delete this note?",
      "More Options": "More options",
      Delete: "Delete",
      "Last Updated": "Last updated",
      Note: "note",
      "Write Your Notes Here": "Write your notes here...",
      "Save Note": "Save Note",
      "Failed to Fetch Notes": "Failed to fetch notes",

      // Add to the English translations
      viewMyNotes: "View My Notes",
      clickOutsideToClose: "Click outside to close",

      // Report Link translations
      reportIncorrectLink: "Report incorrect link",
      reportLink: "Report Link",
      reportLinkTitle: "Report Incorrect Link",
      reportLinkDescription:
        "Please explain why you think this link is incorrect:",
      reportLinkPlaceholder: "Describe the issue...",
      reportLinkSubmit: "Submit Report",
      reportLinkSubmitting: "Submitting...",
      reportLinkSuccess: "Thank You!",
      reportLinkSuccessMessage:
        "We appreciate your report and will investigate this issue.",
      reportLinkClose: "Close",
      reportLinkError: "Failed to submit report",
      reportLinkMissingId: "Unable to submit report: Property ID is missing",
      reportLinkResourceType: "Resource Type",
      reportLinkResourceTitle: "Resource Title",
      reportLinkUrl: "URL",
      reportLinkTestMessage: "Test report received successfully.",
      reportLinkInvalidContent: "Invalid report content",
      reportLinkProcessingError:
        "An error occurred while processing your report",

      notesCount: "{{count}} notes",
      latestNotes: "Latest Notes",
      olderNotes: "Older Notes",
      stackedNotesHint: "Hover to expand",
      noNotesForProperty: "No notes for this property",
      addFirstNote: "Add your first note",
      noteCreatedAt: "Created {{date}}",
      noteUpdatedAt: "Updated {{date}}",
      propertyNotes: "Property Notes",
      billingPortal: "Billing Portal",
      totalNotes: "Total Notes: {{count}}",

      myNotes: "My Notes",
      viewProperty: "View Property",
      noteDeletedSuccessfully: "Note deleted successfully",
      failedToDeleteNote: "Failed to delete note",
      areYouSureDeleteNote:
        "Are you sure you want to delete this note? This action cannot be undone.",
      noNotesFound: "No notes found",

      // Add these new translations in the English section
      viewMyFavorites: "View My Favorites",
      deleteNote: "Delete Note",

      // Add these to your translation objects
      enterZipCode: "Enter ZIP code",
      enterParcelNumber: "Enter parcel number",

      // Add these new translations in the English section
      pressCtrlEnterToSave: "Press Ctrl+Enter to save",

      // Add these translations to your English and Portuguese resources
      unlockFullAccess: "Unlock Full Access",
      accessMorePropertiesMessage:
        "You're viewing properties beyond the first page. Sign up now to unlock full access to all property listings and advanced features!",
      signUpNow: "Sign Up Now",
      continueBrowsing: "Continue Browsing",

      // Add these new translations in the English section
      searchNotes: "Search in notes or addresses...",
      filterNotes: "Filter Notes",
      allTime: "All Time",
      today: "Today",
      pastWeek: "Past Week",
      pastMonth: "Past Month",
      allProperties: "All Properties",
      toggleFilters: "Toggle Filters",
      closeNotes: "Close Notes",
      clearFilters: "Clear All Filters",
      noNotesMatchFilters: "No notes match your filters",
      notesFound: "notes found",

      // Add this to both English and Portuguese translations
      clickToViewAllNotes: "Click to view all notes for this property",

      // Add to the English translations
      copyToClipboard: "Copy to clipboard",
      copied: "Copied!",

      // Email validation messages
      pleaseEnterValidEmail: "Please enter a valid email address",
      emailValidationMessage: "Enter a valid email to continue",

      // Notes related translations (updated)
      noteSavedSuccessfully: "Note saved successfully",
      failedToSaveNote: "Failed to save note",
      note: "note",
      notes: "notes",

      // Add the seePlans translation
      seePlans: "See Plans",
      deactivate: "Deactivate",

      // Password validation messages (updated with more elegant messaging)
      pleaseVerifyPassword: "Please verify your password to continue",
      passwordValidation: {
        title: "Password Requirements",
        length: "At least 8 characters",
        uppercase: "One uppercase letter",
        lowercase: "One lowercase letter",
        number: "One number",
        special: "One special character",
        match: "Passwords match",
      },
      passwordStrength: {
        weak: "Could be stronger",
        medium: "Getting better",
        strong: "Strong password",
      },

      // Add FloatingActionButtons translations
      favorites: "My Favorites",
      filterProperties: "Filter Properties",
      allFavorites: "All My Favorites",
      viewAllFavorites: "View all my favorites",
      viewAllNotes: "View all my notes",
      addToFavorites: "Add to my favorites",
      removeFromFavorites: "Remove from my favorites",
      addNote: "Add note",
      viewNotes: "View my notes",

      // New Pricing System Translations
      newPricing: "Usage Pricing",
      newPricingTooltip: "New usage-based pricing system (TEST)",
      pricingSubtitle: "Select a plan that fits your needs",
      select: "Select",
      addonsAndPackages: "Add-ons & Packages",

      // Plan Features
      propertyAccesses: {
        free: "75 property accesses per month",
        pro: "500 property accesses per month",
        premium: "1,000 property accesses per month",
        unlimited: "Unlimited property accesses",
      },

      // Plan Names
      planNames: {
        free: "Free",
        pro: "Pro",
        premium: "Premium",
        unlimited: "Unlimited",
      },

      // Feature Descriptions
      features: {
        basicSearch: "Basic search filters",
        emailSupport: "Email support",
        advancedSearch: "Advanced search filters",
        prioritySupport: "Priority email support",
        dataExport: "Data export",
        apiAccess: "API access",
        phoneSupport: "Phone support",
        dedicatedManager: "Dedicated account manager",
        customSolutions: "Custom solutions",
      },

      // Add-on Related
      addons: {
        payAsYouGo: "Pay-as-you-go Views",
        payAsYouGoDesc: "per property view",
        accessPackage500: "500 Property Views",
        accessPackage1000: "1,000 Property Views",
        oneTimePurchase: "One-time purchase",
      },

      // English
      oneTimePurchases: "One-Time Purchases",
      showOptions: "Show Options",
      hideOptions: "Hide Options",

      // Billing actions
      pauseBilling: "Pause Billing",
      cancelPlan: "Cancel Plan",
      keepActive: "Keep Active",
      keepPlan: "Keep Plan",
      pauseBillingConfirmation:
        "Are you sure you want to pause your billing? Your access will be limited until you resume.",
      cancelPlanConfirmation:
        "Are you sure you want to cancel your plan? You'll continue to have access until the end of your current billing period.",

      // English
      monthlyUsage: "Monthly Usage",
      viewsUsed: "views used",
      remaining: "remaining this month",
      resetsOn: "Resets on",

      // Add these to the English translations
      usageAnalytics: "Usage Analytics",
      filterByRegion: "Filter by Region",
      allRegions: "All Regions",
      allValues: "All Values",
      viewsPerProperty: "Views per Property",
      costPerView: "Cost per View",
      engagementRate: "Engagement Rate",
      vsLastPeriod: "vs last period",
      viewsToday: "Views Today",
      viewsThisMonth: "Views This Month",
      viewsRemaining: "Views Remaining",

      // New translations
      compareAndUpgradePlans: "Compare & Upgrade Plans",
      viewUsageAnalytics: "View Usage Analytics",

      // Filter translations
      filterByType: "Filter by Property Type",
      filterByValue: "Filter by Property Value",

      // Property type filters
      allTypes: "All Property Types",
      // Value range filters
      under100k: "Under $100K",
      "100k-500k": "$100K - $500K",
      "500k-1m": "$500K - $1M",
      over1m: "Over $1M",

      // View count display translations
      viewsTotal: "Total Views",
      viewsUpgrade: "Upgrade for More Views",
      viewsUnlimited: "Unlimited Views",
      viewsProLimit: "Up to {{count}} views/month",
      viewsPremiumLimit: "Unlimited views",
      viewsBasicLimit: "{{count}} views/month",
      viewsRefreshIn: "Refreshes in {{days}} days",
      viewsRefreshesToday: "Refreshes today",
      viewsRefreshesTomorrow: "Refreshes tomorrow",
      viewsUpgradeMessage: "Upgrade to Pro or Premium for more property views",
      viewsProFeatures: "Pro Features",
      viewsPremiumFeatures: "Premium Features",
      viewsProDescription: "Enhanced property viewing with detailed analytics",
      viewsPremiumDescription:
        "Unlimited property viewing with premium insights",

      // Pay-as-you-go views translations
      payAsYouGoViews: "Pay-as-you-go Views",
      frozenCredits: "Frozen Credits",
      payAsYouGoCredits: "{{count}} Credits",
      buyMoreCredits: "Buy More Credits",
      creditsRemaining: "Credits Remaining",
      creditsUsed: "Credits Used",
      oneViewOneCredit: "1 view = 1 credit",
      buyCreditsStartingAt: "Buy credits starting at ${{price}}",
      lowCreditsWarning: "Running low on credits?",
      outOfCredits: "Out of credits",

      // View limit modal translations
      viewLimitReached: "View Limit Reached",
      viewLimitMessage:
        "You've reached your daily property view limit. Upgrade your plan to continue exploring properties and unlock premium features.",
      viewPricingPlans: "View Pricing Plans",
      dailyViews: "Daily Views",
      unlimitedViews: "Unlimited Views",
      monthlyViews: "Monthly Views",
      creditsReserved: "credits reserved",
      unlimited: "Unlimited",
      restoredCredits: "Credits added",
      save17: "Save 17%",

      // New translations for NewPricingPage.js
      pleaseSelectAmount: "Please select an amount",
      failedToCreateCheckoutSession: "Failed to create checkout session",
      failedToProcessPayment: "Failed to process payment",
      activeUntilEndOfBilling: "Active until end of billing period",
      unlimitedViewsUntilPlanEnds: "Unlimited Views (Until Plan Ends)",
      unlimitedPropertyViews: "Unlimited Property Views",
      dailyPropertyViews: "Daily Property Views",
      monthlyPropertyViews: "Monthly Property Views",
      frozenCreditsCount: "{{count}} credits frozen",
      propertyViews: "property views",
      viewsCount: "Remaining Credits: {{count}}",
      planEnds: "Plan ends",

      // Additional translations for NewPricingPage.js
      lastPeriod: "Last {{period}}",
      views: "views",
      viewsValue: "{{value}} views",
      viewsAt: "{{count}} views at ${{price}} per view",
      viewsAtPtBr: "{{count}} visualizações a ${{price}} por visualização",
      current: "Current",
      switchToBilling: "Switch to {{interval}} billing",
      getPlan: "Get {{plan}}",
      payOnlyWhatYouUse: "Pay only for what you use",
      noMonthlyCommitment: "No monthly commitment",
      unavailableWithUnlimited: "Unavailable with Unlimited",
      addCredits: "Add {{count}} Credits",
      planWillBeCanceledOn: "Plan will be canceled on {{date}}",
      planHasBeenCanceled: "Plan has been canceled",
      nextBillingOn: "Next billing on {{date}}",
      subscriptionCanceledCreditsRemain:
        "Subscription canceled. Your purchased credits will remain available on the Free plan.",
      failedToCancelSubscription: "Failed to cancel subscription",
      failedToOpenBillingPortal: "Failed to open billing portal",
      invalidResponseFromServer: "Invalid response from server",
      everythingFromUnlimited: "Everything from Unlimited",
      availableViews: "Available Views",
      currentPlanLabel: "Current Plan",
      viewsTooltip:
        "Each property view consumes one credit. Free users get 15 credits per month.",
      usedCreditsPercentage: "{{percentage}}% of credits used",
      additionalCreditsAvailable: "Additional credits available",
      paymentFailed: "Payment Failed",
      cardWasDeclined:
        "Your card was declined. Please update your payment method to continue using the service.",
      paymentFailedMessage:
        "Your payment has failed {{attempts}} time(s). Please update your payment method to continue using the service.",
      planCanceledDueToPayment: "Plan Canceled - Payment Issue",
      planCanceledDueToPaymentMessage:
        "Your plan has been canceled due to payment issues. Please update your payment method to restore access.",
      // Dismissal options
      dontAskAgain: "Don't ask me again",
      remindLater: "Remind me later",
      waitFewHours: "Ask me in a few hours",

      // Add these new translations in the English section
      selectFavorites: "Select Favorites",

      viewCount: "You and {{count}} other{{plural}} have viewed this property",
      firstViewer: "You're the first to discover this property!",
      selectProperty: "Select property",
      failedToFetchNotes: "Failed to fetch notes",
      failedToFetchDetails: "Failed to fetch property details",

      // Add new translations for viewed properties
      viewedProperties: {
        title: "Viewed Properties",
        timeframes: {
          today: "Today",
          yesterday: "Yesterday",
          thisWeek: "This Week",
          lastWeek: "Last Week",
          thisMonth: "This Month",
          lastMonth: "Last Month",
          thisYear: "This Year",
          allTime: "All Time",
        },
        stats: {
          viewed: "Viewed",
          times: "times",
          lastViewed: "Last viewed",
          properties: "properties",
        },
        noProperties: "No viewed properties in this timeframe",
        loading: "Loading viewed properties...",
        addressNotAvailable: "Address not available",
        clickToView: "Click to view property details",
      },

      // Add these to your translation objects
      viewsTooltipUntrackedCard: "Click to see your viewed properties",
      viewsLabel: "Available Views",
      backToSearch: "Back to Search",
      loadingFavorites: "Loading favorites...",
      peopleFavorited: "{{count}} person favorited",
      peopleFavorited_plural: "{{count}} people favorited",

      // Add Friend Modal translations
      addFriendTitle: "Add Friend",
      searchUsersPlaceholder: "Search users by email...",

      // Chat Page translations
      messageEditHistory: "Message Edit History",
      currentVersion: "current",
      searchChats: "Search chats...",
      newChat: "New Chat",
      inbox: "Inbox",
      archived: "Archived",
      unnamedChat: "Unnamed Chat",
      doubleClickToEdit: "Double-click to edit",
      noMessagesYet: "No messages yet",
      participants: "participants",
      searchInConversation: "Search in conversation...",
      addUserToChat: "Add User to Chat",
      searchUsersByNameOrEmail: "Search users by name or email...",
      noUsersFound: "No Users Found",
      trySearchingDifferent: "Try searching with a different name or email",
      archiveChat: "Archive Chat",
      unarchiveChat: "Unarchive Chat",
      deleteChat: "Delete Chat",
      deleteMessage: "Delete Message",
      deleteMessageConfirmation:
        "This message will be permanently deleted for you. Other chat participants will still be able to see it.",
      deleteChatConfirmation:
        "This chat will be permanently deleted for all participants. This action cannot be undone.",
      chatParticipants: "Chat Participants",
      welcomeToMessages: "Welcome to Messages",
      selectChatToStart:
        "Select a chat from the sidebar or start a new conversation to begin messaging",
      loadingMoreMessages: "Loading more messages...",
      noMessagesFound: "No messages found",
      typeMessage: "Type a message...",
      thisMessageWasUnsent: "This message was unsent",
      failedToLoadChats: "Failed to load chats. Please try again later.",
      noChatsFound: "No chats found",
      delete: "Delete",
      unarchive: "Unarchive",
      archive: "Archive",
      areYouSureYouWantToUnarchiveThisChatItWillReappearInYourMainChatList:
        "Are you sure you want to unarchive this chat? It will reappear in your main chat list.",
      areYouSureYouWantToArchiveThisChatYouCanAlwaysUnarchiveItLater:
        "Are you sure you want to archive this chat? You can always unarchive it later.",
    },
  },
  ptbr: {
    translation: {
      // New translations from NewPricingPage.js
      loadingFavorites: "Carregando favoritos...",
      peopleFavorited: "{{count}} pessoa favoritou",
      peopleFavorited_plural: "{{count}} pessoas favoritaram",
      backToSearch: "Voltar para a Busca",
      usageAnalytics: "Análise de Uso",
      compareAndUpgradePlans: "Comparar e Atualizar Planos",
      viewUsageAnalytics: "Ver Análise de Uso",
      filterByRegion: "Filtrar por Região",
      allRegions: "Todas as Regiões",
      filterByType: "Filtrar por Tipo",
      filterByValue: "Filtrar por Valor",
      allValues: "Todos os Valores",
      viewsPerProperty: "Visualizações por Propriedade",
      vsLastPeriod: "vs Período Anterior",
      engagementRate: "Taxa de Engajamento",
      billingPortal: "Portal de Faturamento",
      addCredits: "Adicionar Créditos",
      viewCredits: "Créditos de Visualização",
      unlimited: "Ilimitado",
      propertyViews: "visualizações de propriedades",
      payAsYouGo: "Pague Conforme Usar",
      payOnlyForWhatYouUse: "Pague apenas pelo que usar",
      noMonthlyCommitment: "Sem compromisso mensal",
      unavailableWithUnlimited: "Indisponível com Plano Ilimitado",
      planWillBeCanceledOn: "O plano será cancelado em {{date}}",
      planHasBeenCanceled: "O plano foi cancelado",
      nextBillingOn: "Próxima cobrança em {{date}}",
      subscriptionCanceledCreditsRemain:
        "Assinatura cancelada. Seus créditos comprados permanecerão disponíveis no plano Grátis.",
      failedToCancelSubscription: "Falha ao cancelar a assinatura",
      failedToOpenBillingPortal: "Falha ao abrir o portal de faturamento",
      invalidResponseFromServer: "Resposta inválida do servidor",
      failedToCreateCheckoutSession: "Falha ao criar sessão de pagamento",
      pleaseSelectAmount: "Por favor, selecione um valor",
      failedToProcessPayment: "Falha ao processar o pagamento",
      everythingFromUnlimited: "Tudo do Plano Ilimitado",
      // New translations for NewPricingPage.js
      creditAmount: "${{amount}} ({{views}} visualizações)",
      activeUntilEndOfBilling: "Ativo até o fim do período de faturamento",
      unlimitedViews: "Visualizações ilimitadas",
      unlimitedViewsUntilPlanEnds:
        "Visualizações Ilimitadas (Até o Fim do Plano)",
      unlimitedPropertyViews: "Visualizações Ilimitadas de Propriedades",
      dailyPropertyViews: "Visualizações Diárias de Propriedades",
      monthlyPropertyViews: "Visualizações Mensais de Propriedades",
      frozenCreditsCount: "{{count}} créditos congelados",
      viewsCount: "Créditos Restantes: {{count}}",
      planEnds: "Fim do plano",

      // Add after the viewsCount translation
      availableViews: "Visualizações Disponíveis",
      viewsTooltip:
        "Cada visualização de propriedade consome um crédito. Usuários gratuitos recebem 15 créditos por mês.",
      usedCreditsPercentage: "{{percentage}}% dos créditos utilizados",
      additionalCreditsAvailable: "Créditos adicionais disponíveis",
      paymentFailed: "Pagamento Falhou",
      cardWasDeclined:
        "Seu cartão foi recusado. Por favor, atualize seu método de pagamento para continuar.",
      paymentFailedMessage:
        "Seu pagamento falhou {{attempts}} vez(es). Por favor, atualize seu método de pagamento para continuar.",
      planCanceledDueToPayment: "Plano Cancelado - Problema de Pagamento",
      planCanceledDueToPaymentMessage:
        "Seu plano foi cancelado devido a problemas de pagamento. Por favor, atualize seu método de pagamento para restaurar o acesso.",
      planCanceledByAdmin: "Plano Cancelado pelo Administrador",
      planCanceledByAdminMessage:
        "Sua assinatura foi cancelada por um administrador. Por favor, entre em contato com o suporte para mais informações.",
      contactSupport: "Contatar Suporte",
      subscriptionCanceled: "Assinatura Cancelada",
      subscriptionCanceledMessage:
        "Sua assinatura foi cancelada. Gostaria de reativá-la?",
      subscriptionReactivated: "Assinatura reativada com sucesso",
      failedToReactivateSubscription:
        "Falha ao reativar a assinatura. Por favor, tente novamente.",
      planDowngradedToFree: "Plano alterado para Gratuito com sucesso",
      failedToProcessRequest:
        "Falha ao processar solicitação. Por favor, tente novamente.",
      companyName: "DeedFinders",
      signin: "Entrar",
      signup: "Inscrever-se",
      signout: "Sair",
      termsOfService: "Termos de Serviço",
      and: "e",
      privacyPolicy: "Política de Privacidade",
      or: "OU",
      workEmail: "Email de Trabalho",
      password: "Senha",
      agreeToTerms: "Eu concordo com os Termos de Serviço",
      receiveUpdates:
        "Eu quero receber atualizações e comunicações de marketing",
      alreadyRegistered: "Já registrado?",
      dontHaveAccount: "Não tem uma conta?",
      apply: "aplicam-se",
      welcome: "Bem-vindo ao DeedFinders",
      oneStopPlatform: "Sua plataforma única para serviços de propriedades",
      ourFeatures: "Nossas Funcionalidades",
      findProperties:
        "Encontre propriedades com penhoras fiscais e certidões de imposto",
      connect:
        "Conecte-se com advogados, corretores de imóveis e prestadores de serviços",
      invest: "Invista em propriedades e serviços",
      accessAuctions: "Acesse leilões de propriedades e sistemas de licitação",
      copyright: "© 2024 DeedFinders™. Todos os direitos reservados.",
      passwordRequirement: {
        length: "Ter pelo menos 8 caracteres",
        uppercaseLowercase: "Conter letras maiúsculas e minúsculas",
        numberOrSpecial: "Conter números ou caracteres especiais",
      },
      passwordsMustMatch: "As senhas devem coincidir",
      name: "Nome",
      email: "E-mail",
      confirmPassword: "Confirmar Senha",
      forgotPassword: "Esqueceu a Senha",
      forgotPasswordDescription:
        "Digite seu endereço de e-mail e enviaremos instruções para redefinir sua senha.",
      resetPassword: "Redefinir Senha",
      emailVerification: "Verificação de E-mail",
      verifyingEmail: "Verificando seu e-mail...",
      emailVerificationSuccess: "Seu e-mail foi verificado com sucesso!",
      emailVerificationError:
        "Houve um erro ao verificar seu e-mail. Por favor, tente novamente ou entre em contato com o suporte.",
      proceedToLogin: "Prosseguir para o Login",
      verificationExpires: "Este link de verificação expira em {{time}}",
      accountSettings: "Configurações da Conta",
      personalInformation: "Informações Pessoais",
      bio: "Biografia",
      security: "Segurança",
      changePassword: "Alterar Senha",
      currentPassword: "Senha Atual",
      newPassword: "Nova Senha",
      confirmNewPassword: "Confirmar Nova Senha",
      passwordMust: "A senha deve",
      beAtLeast8CharactersLong: "Ter pelo menos 8 caracteres",
      containAtLeastOneUppercaseLetter: "Conter pelo menos uma letra maiúscula",
      containAtLeastOneLowercaseLetter: "Conter pelo menos uma letra minúscula",
      containAtLeastOneNumber: "Conter pelo menos um número",
      containAtLeastOneSpecialCharacter:
        "Conter pelo menos um caractere especial (!@#$%^&*)",
      cancel: "Cancelar",
      accountActions: "Ações da Conta",
      languagePreferences: "Preferências de Idioma",
      deactivateAccount: "Desativar Conta",
      deactivateAccountDescription:
        "Desative temporariamente sua conta. Você pode reativá-la a qualquer momento fazendo login novamente.",
      deleteAccount: "Excluir Conta",
      deleteAccountDescription:
        "Remova permanentemente sua conta e todos os dados associados em 30 dias. Você pode cancelar o processo de exclusão fazendo login novamente durante esse período.",
      logout: "Sair",
      saveChanges: "Salvar Alterações",
      currentPasswordIncorrect: "A senha atual está incorreta",
      languageUpdateSuccess: "Preferência de idioma atualizada com sucesso!",
      languageUpdateError:
        "Falha ao atualizar a preferência de idioma. Por favor, tente novamente.",
      areYouSureYouWantToLogOut: "Tem certeza de que deseja sair?",
      areYouSureYouWantToDeactivateYourAccount:
        "Tem certeza de que deseja desativar sua conta?",
      areYouSureYouWantToDeleteYourAccount:
        "Tem certeza de que deseja excluir sua conta? Esta ação não pode ser desfeita.",
      language: {
        en: "Inglês",
        ptbr: "Português (Brasil)",
      },
      advancedActions: "Ações Avançadas",

      // Wallet translations
      myWallet: "Minha Carteira",
      enhancedPropertySearch:
        "Busca e Gerenciamento Aprimorados de Propriedades",
      walletDescription: "Gerencie seu creditos e transações.",
      currentSubscription: "Assinatura Atual",
      yourActivePlan: "Seu plano ativo e seus benefícios",
      active: "Ativo",
      inactive: "Inativo",
      nextBilling: "Próxima cobrança",
      currentPlanFeatures: "Recursos do Plano Atual",
      upgradeYourPlan: "Atualize Seu Plano",
      youreOnTopPlan: "Você está no nosso melhor plano!",
      maximizeInvestment: "Maximize seu potencial de investimento.",
      upgradeNow: "Atualize Agora",
      topPlanMessage: "Aproveite todos os recursos premium disponíveis:",
      securityInfo:
        "Suas informações de pagamento estão protegidas com segurança pelo Stripe",
      free: "Grátis",
      pro: "Pro",
      premium: "Premium",
      month: "mês",
      year: "ano",

      translate: "Traduzir",
      translating: "Traduzindo...",
      translated: "Traduzido",
      revertTranslation: "Reverter Tradução",
      translationFailed: "Falha na tradução. Por favor, tente novamente.",
      linkCopied: "Link copiado!",
      shareProperty: "Compartilhar Imóvel",
      close: "Fechar",

      // Subscription Modal translations
      chooseYourPlan: "Escolha Seu Plano",
      monthly: "Mensal",
      annual: "Anual",
      mo: "mês",
      yr: "ano",
      changePlan: "Alterar Plano",
      subscribe: "Assinar",
      confirmPlanChange: "Confirmar Mudança de Plano",
      currentPlan: "Plano atual",
      newPlan: "Novo plano",
      reactivationImpact:
        "O cancelamento do plano ser anulado. Seu plano {{plan}} continuará funcionando normalmente.",
      upgradeImpact:
        "Seu novo plano {{plan}} será ativado imediatamente. Você será cobrado um valor proporcional pelo upgrade para o plano {{interval}}.",
      downgradeImpact:
        "Seu plano atual permanecerá ativo até o final do ciclo de faturamento. Depois disso, você será cobrado ${{newAmount}} por {{newInterval}} pelo novo plano {{plan}}.",
      downgradeToFreeImpact:
        "Seu plano atual permanecerá ativo até o final do ciclo de faturamento. Depois disso, sua assinatura mudará para o plano Gratuito sem cobranças adicionais.",
      noChangeImpact:
        "Não haverá alteração no seu plano atual ou ciclo de faturamento.",
      back: "Voltar",
      confirmChange: "Confirmar Mudança",
      confirmReactivation: "Confirmar Reativação",
      reactivate: "Reativar",

      // Success messages
      subscriptionUpdateSuccess: "Sua assinatura foi atualizada com sucesso.",
      subscriptionUpdateError:
        "Ocorreu um erro ao atualizar sua assinatura. Por favor, tente novamente ou entre em contato com o suporte.",

      // Buy page translations
      propertyIdentifier: "Identificador da Propriedade",
      city: "Cidade",
      county: "Condado",
      state: "Estado",
      zipCode: "CEP",
      minimumBid: "Lance Mínimo",
      adjudgedValue: "Valor Adjudicado",
      status: "Status",
      saleType: "Tipo de Venda",
      failedToFetchInitialData: "Falha ao buscar dados iniciais.",
      failedToFetchData: "Falha ao buscar dados.",
      failedToFetchFavoriteProperties:
        "Falha ao buscar propriedades favoritas.",
      failedToFetchExpandedData: "Falha ao buscar dados expandidos",
      loadingPropertyDetails: "Carregando detalhes da propriedade...",
      propertyOverview: "Visão Geral da Propriedade",
      propertyDetails: "Detalhes da Propriedade",
      salesInformation: "Informações de Venda",
      frequentlyAskedQuestions: "Perguntas Frequentes",
      myPropertyNotes: "Minhas Notas da Propriedade",

      // FilterSidebar translations
      legalAndFinancial: "Legal e Financeiro",
      myFavorites: "Meus Favoritos",
      applyFilters: "Aplicar Filtros",
      selectFavoriteFolders: "Selecionar Pastas Favoritas",
      futureSale: "Venda Futura",
      resale: "Revenda",
      sale: "Venda",
      struckOff: "Retirado",
      businessPersonalProperty: "Propriedade Pessoal de Negócios",
      improvementOnly: "Apenas Melhorias",
      mineral: "Mineral",
      mobileHome: "Casa Móvel",
      otherUnknown: "Outro / Desconhecido",
      realProperty: "Propriedade Real",
      availableForFutureSale: "Disponível para Venda Futura",
      cancelled: "Cancelado",
      postponed: "Adiado",
      saleResultsPending: "Resultados da Venda Pendentes",
      scheduledForAuction: "Agendado para Leilão",
      sold: "Vendido",
      stayed: "Suspenso",
      propertyType: "Tipo de Propriedade",
      adjudgedValueRange: "Faixa de Valor Adjudicado",

      // Status translations
      availableforfuturesale: "Disponível para Venda Futura",
      scheduledforauction: "Agendado para Leilão",

      // Sale type translations
      futuresale: "Venda Futura",
      struckoff: "Retirado",

      // Tab translations
      Overview: "Visão Geral",
      Details: "Detalhes",
      Sales: "Vendas",
      Resources: "Recursos",
      Notes: "Notas",
      FAQ: "Perguntas Frequentes",

      // New translations
      saveNote: "Salvar Nota",
      struckOffDate: "Data de Retirada",
      additionalInfo: "Informações Adicionais",
      saleDate: "Data de Venda",
      struckOffAmount: "Valor de Retirada",
      caseStyle: "Estilo do Caso",
      legalDescription: "Descrição Legal",
      saleLocation: "Local de Venda",
      judgmentDate: "Data do Julgamento",
      schoolDistrict: "Distrito Escolar",
      notAvailable: "Não Disponível",
      address: "Endereço",
      writeYourNotesHere: "Escreva suas notas aqui...",

      // Cancel subscription translations
      cancelSubscription: "Cancelar Assinatura",
      cancelSubscriptionConfirmation:
        "Confirmação de Cancelamento de Assinatura",
      cancelSubscriptionWarning:
        "Tem certeza de que deseja cancelar sua assinatura? Você continuará tendo acesso até o final do seu período de faturamento atual.",
      keepSubscription: "Manter Assinatura",
      confirmCancel: "Confirmar Cancelamento",
      billingCycle: "Ciclo de Faturamento",
      canceling: "Cancelando",
      subscriptionCanceledInfo: "Sua assinatura foi cancelada.",
      accessUntil: "Você terá acesso ao seu plano atual até",

      // New translations for upcoming plan change
      upcomingPlanChange: "Mudança de Plano Futura",
      upcomingChangeMessage:
        "Seu plano mudará de {{currentPlan}} ({{currentInterval}}) para {{newPlan}} ({{newInterval}}) em {{date}}.",
      newPlanFeatures: "Recursos do Seu Novo Plano",
      upcomingChangeTitle: "Mudança de Plano Agendada",

      // New translation for next plan benefits
      nextPlanBenefits: "Benefícios do Plano {{plan}}",
      upgradeNowTo: "Atualize Agora para {{plan}}",

      // New translations for Premium plan
      premiumThankYouTitle: "Obrigado por ser Premium!",
      premiumThankYouMessage:
        "Você está aproveitando todos os melhores recursos que o DeedFinders tem a oferecer. Agradecemos seu apoio!",

      // New translations for PricingPage
      pricing: "Preços",
      choosePlan: "Escolha Seu Plano",
      getStarted: "Começar",
      loading: "Carregando...",

      // New translations for subscription plans
      basicAccess: "Acesso básico à plataforma",
      limitedSearches: "Buscas limitadas por mês",
      standardSupport: "Suporte padrão",
      unlimitedSearchResults: "Resultados de busca ilimitados",
      enhancedSorting: "Opções avançadas de classificação e filtragem",
      favoritesFeature:
        "Recurso de favoritos para salvar e organizar propriedades",
      unlimitedDailySearches: "Buscas diárias ilimitadas",
      documentTemplates: "Acesso a modelos de documentos e outros recursos",
      allProBenefits: "Todos os benefícios do plano Pro",
      currentPlanLabel: "Plano Atual",
      aiAssistant: "Assistente de IA para ajuda personalizada",
      aiDrivenContent:
        "Contexto de conteúdo baseado em IA para melhorar insights sobre propriedades",
      exclusiveAccess: "Acesso exclusivo a ativos premium",
      advancedDocumentAccess: "Acesso avançado a documentos",

      // New translation for activeFrom
      activeFrom: "Ativo a partir de {{date}}",

      // New translations for DetailedPropertyInfo
      copyShareLink: "Copiar link da propriedade",
      parcelNumber: "Número da Parcela",

      // New translations
      noFeaturesAvailable: "Nenhum recurso disponível para este plano.",
      noBenefitsAvailable:
        "Nenhum benefício adicional disponível para este plano.",

      // New translation for development warning
      developmentWarning:
        "Site em desenvolvimento. Alguns recursos podem estar incompletos ou mudar sem aviso prévio.",

      // New translation for registrationSuccessful
      registrationSuccessful:
        "Registro bem-sucedido! Por favor, verifique seu e-mail para confirmar sua conta.",

      // New translations for pagination
      page: "Página",
      of: "de",

      // New translations for PublicBuyContent
      createAccountToSeeMore: "Crie uma conta para ver mais resultados",
      searchesRemaining: "Buscas restantes hoje: {{count}}",
      dailySearchLimitReached: "Limite diário de buscas atingido",
      signInOrSignUpForMore: "Entre ou cadastre-se para realizar mais buscas",
      noItemsToDisplay: "Nenhum item para exibir",

      // New translations for FakeWindowsScreen
      freePlan: "Plano Gratuito",
      proPlan: "Plano Pro",
      premiumPlan: "Plano Premium",
      start: "Iniciar",

      // Updated translations for daily limit reached
      timeIsUp: "Tempo esgotado!",
      thankYouForTesting:
        "Obrigado por testar nossa ferramenta de busca de propriedades. O que achou? Pronto para desbloquear todo o seu potencial?",
      readyToSignUp: "Cadastre-se Agora",
      alreadyHaveAccount: "Já Tenho uma Conta",

      // Loading overlay messages
      loadingMessages: {
        layer1: [
          "Convocando os gnomos da propriedade...",
          "Polindo nossa bola de cristal...",
        ],
        layer2: [
          "Decodificando pergaminhos antigos de propriedades...",
          "Preparando uma poção de insights do mercado...",
        ],
        layer3: [
          "Lançando uma rede ampla para encontrar joias escondidas...",
          "Soltando nossa matilha de cães farejadores de dados...",
        ],
        layer4: [
          "Convencendo propriedades teimosas a se revelarem...",
          "Batalhando contra os dragões das listagens superfaturadas...",
        ],
      },

      // Resource tab translations
      resources: "Recursos",
      countyResources: "Recursos do Condado",
      propertyResources: "Recursos da Propriedade",
      clerkOfficeURL: "URL do Escritório do Escrivão",
      governmentLinks: "Links Governamentais",
      propertyTaxInfo: "Informações sobre Impostos de Propriedade",
      deedInfo: "Informações sobre Escritura",
      lienInfo: "Informações sobre Penhora",
      auctionInfo: "Informações sobre Leilão",
      countyClerkOfficeURL: "URL do Escritório do Escrivão do Condado",
      countyGovernmentLinks: "Links Governamentais do Condado",
      countyPropertyTaxInfo:
        "Informações sobre Impostos de Propriedade do Condado",
      countyDeedInfo: "Informações sobre Escritura do Condado",
      countyLienInfo: "Informações sobre Penhora do Condado",
      countyAuctionInfo: "Informações sobre Leilão do Condado",
      countyAdditionalInfo: "Informações Adicionais do Condado",
      loadingResources: "Carregando recursos",
      resourcesDisclaimer:
        "Estes recursos são gerados por IA e podem não estar atualizados. Por favor, verifique as informações com fontes oficiais.",
      errorLoadingResources: "Erro ao carregar recursos",
      resourcesLoadingMessages: {
        layer1: [
          "Escaneando arquivos do condado...",
          "Decodificando registros de propriedades...",
          "Mapeando recursos locais...",
          "Analisando bancos de dados governamentais...",
          "Compilando informações do condado...",
        ],
        layer2: [
          "Extraindo dados fiscais...",
          "Localizando repositórios de escrituras...",
          "Identificando registros de penhora...",
          "Reunindo detalhes de leilões...",
          "Montando insights sobre propriedades...",
        ],
        layer3: [
          "Verificando dados do cartório...",
          "Cruzando links governamentais...",
          "Organizando informações de impostos sobre propriedades...",
          "Estruturando dados de escrituras e penhoras...",
          "Finalizando compilação de recursos...",
        ],
      },

      // Add these new translations
      saleNotes: "Notas de Venda",
      legalDescriptionShort: "Descrição Legal (Resumida)",
      courtNumber: "Número do Tribunal",
      causeNumber: "Número da Causa",

      // Ensure all other necessary translations are included
      listingLinks: "Links de Listagem",
      taxAssessmentLinks: "Links de Avaliação Fiscal",
      marketInsights: "Insights de Mercado",
      ownershipHistory: "Histórico de Propriedade",
      floodRiskInfo: "Informações de Risco de Inundação",
      zoningInfo: "Informações de Zoneamento",
      nearbyAmenities: "Comodidades Próximas",

      // Add these to your translation files
      noPropertyResourcesAvailable:
        "Nenhum recurso da propriedade disponível no momento.",
      noCountyResourcesAvailable:
        "Nenhum recurso do condado disponível no momento.",
      noPropertyResourcesFound:
        "Nenhum recurso adicional da propriedade encontrado.",
      Delete: "Excluir",
      Note: "nota",

      // Add to the Portuguese translations
      viewMyFavorites: "Ver Meus Favoritos",
      viewMyNotes: "Ver Minhas Notas",
      deleteNote: "Excluir Nota",

      // Report Link translations
      reportIncorrectLink: "Reportar link incorreto",
      reportLink: "Reportar Link",
      reportLinkTitle: "Reportar Link Incorreto",
      reportLinkDescription:
        "Por favor, explique por que você acha que este link está incorreto:",
      reportLinkPlaceholder: "Descreva o problema...",
      reportLinkSubmit: "Enviar Relatório",
      reportLinkSubmitting: "Enviando...",
      reportLinkSuccess: "Obrigado!",
      reportLinkSuccessMessage:
        "Agradecemos seu relatório e investigaremos este problema.",
      reportLinkClose: "Fechar",
      reportLinkError: "Falha ao enviar relatório",
      reportLinkMissingId:
        "Não foi possível enviar o relatório: ID da propriedade ausente",
      reportLinkResourceType: "Tipo de Recurso",
      reportLinkResourceTitle: "Título do Recurso",
      reportLinkUrl: "URL",
      reportLinkTestMessage: "Relatório de teste recebido com sucesso.",
      reportLinkInvalidContent: "Conteúdo do relatório inválido",
      reportLinkProcessingError: "Ocorreu um erro ao processar seu relatório",

      notesCount: "{{count}} notas",
      latestNotes: "Últimas Notas",
      olderNotes: "Notas Antigas",
      stackedNotesHint: "Passe o mouse para expandir",
      noNotesForProperty: "Sem notas para esta propriedade",
      addFirstNote: "Adicione sua primeira nota",
      noteCreatedAt: "Criado em {{date}}",
      noteUpdatedAt: "Atualizado em {{date}}",
      propertyNotes: "Notas da Propriedade",
      totalNotes: "Total de Notas: {{count}}",

      myNotes: "Minhas Notas",
      viewProperty: "Ver Propriedade",
      noteDeletedSuccessfully: "Nota excluída com sucesso",
      failedToDeleteNote: "Falha ao excluir nota",
      areYouSureDeleteNote:
        "Tem certeza que deseja excluir esta nota? Esta ação não pode ser desfeita.",
      clickOutsideToClose: "Clique fora para fechar",
      noNotesFound: "Nenhuma nota encontrada",

      // Add these new translations in the Portuguese section
      selectFavorites: "Selecionar Favoritos",

      // Add these new translations in the Portuguese section
      pressCtrlEnterToSave: "Pressione Ctrl+Enter para salvar",
      // Add these new translations in the English section
      searchNotes: "Pesquisar em notas ou endereços...",
      filterNotes: "Filtrar Notas",
      allTime: "Todo o Tempo",
      today: "Hoje",
      pastWeek: "Semana Passada",
      pastMonth: "Mês Passado",
      allProperties: "Todas as Propriedades",
      toggleFilters: "Alternar Filtros",
      closeNotes: "Fechar Notas",
      clearFilters: "Limpar Todos os Filtros",
      noNotesMatchFilters: "Nenhuma nota corresponde aos seus filtros",
      notesFound: "notas encontradas",

      // Add this to both English and Portuguese translations
      clickToViewAllNotes: "Click to view all notes for this property",

      // Add to the English translations
      copyToClipboard: "Copy to clipboard",
      copied: "Copied!",

      // Email validation messages
      pleaseEnterValidEmail: "Por favor, insira um endereço de e-mail válido",
      emailValidationMessage: "Digite um e-mail válido para continuar",

      // Notes related translations (updated)
      noteSavedSuccessfully: "Nota salva com sucesso",
      failedToSaveNote: "Falha ao salvar nota",
      note: "nota",
      notes: "notas",

      // Add the seePlans translation
      seePlans: "Ver Planos",
      deactivate: "Desativar",

      // Password validation messages (updated with more elegant messaging)
      pleaseVerifyPassword: "Por favor, verifique sua senha para continuar",
      passwordValidation: {
        title: "Requisitos da Senha",
        length: "Mínimo de 8 caracteres",
        uppercase: "Uma letra maiúscula",
        lowercase: "Uma letra minúscula",
        number: "Um número",
        special: "Um caractere especial",
        match: "Senhas coincidem",
      },
      passwordStrength: {
        weak: "Poderia ser mais forte",
        medium: "Está melhorando",
        strong: "Senha forte",
      },

      // Add FloatingActionButtons translations
      favorites: "Meus Favoritos",
      filterProperties: "Filtrar Propriedades",
      allFavorites: "Todos Meus Favoritos",
      viewAllFavorites: "Ver todos meus favoritos",
      viewAllNotes: "Ver todas minhas notas",
      addToFavorites: "Adicionar aos meus favoritos",
      removeFromFavorites: "Remover dos meus favoritos",
      addNote: "Adicionar nota",
      viewNotes: "Ver minhas notas",

      // New Pricing System Translations in Portuguese
      newPricing: "Preços por Uso",
      newPricingTooltip: "Novo sistema de preços baseado em uso (TESTE)",
      pricingSubtitle: "Selecione um plano que atenda suas necessidades",
      select: "Selecionar",
      addonsAndPackages: "Complementos & Pacotes",

      // Plan Features in Portuguese
      propertyAccesses: {
        free: "75 acessos a imóveis por mês",
        pro: "500 acessos a imóveis por mês",
        premium: "1.000 acessos a imóveis por mês",
        unlimited: "Acessos ilimitados a imóveis",
      },

      // Plan Names in Portuguese
      planNames: {
        free: "Gratuito",
        pro: "Pro",
        premium: "Premium",
        unlimited: "Ilimitado",
      },

      // Feature Descriptions in Portuguese
      features: {
        basicSearch: "Filtros básicos de busca",
        emailSupport: "Suporte por email",
        advancedSearch: "Filtros avançados de busca",
        prioritySupport: "Suporte prioritário por email",
        dataExport: "Exportação de dados",
        apiAccess: "Acesso à API",
        phoneSupport: "Suporte por telefone",
        dedicatedManager: "Gerente dedicado",
        customSolutions: "Soluções personalizadas",
      },

      // Add-on Related in Portuguese
      addons: {
        payAsYouGo: "Visualizações Pré-pagas",
        payAsYouGoDesc: "por visualização",
        accessPackage500: "500 Visualizações",
        accessPackage1000: "1.000 Visualizações",
        oneTimePurchase: "Compra única",
      },

      // English
      oneTimePurchases: "Compras Únicas",
      showOptions: "Mostrar Opções",
      hideOptions: "Ocultar Opções",

      // Billing actions
      pauseBilling: "Pausar Cobrança",
      cancelPlan: "Cancelar Plano",
      keepActive: "Manter Ativo",
      keepPlan: "Manter Plano",
      pauseBillingConfirmation:
        "Tem certeza que deseja pausar sua cobrança? Seu acesso será limitado até que você retome.",
      cancelPlanConfirmation:
        "Tem certeza que deseja cancelar seu plano? Você continuará tendo acesso até o final do período de cobrança atual.",

      // Add these to the English translations
      allTypes: "Todos os Tipos",
      costPerView: "Custo por Visualização",
      viewsToday: "Visualizações Hoje",
      viewsThisMonth: "Visualizações Este Mês",
      viewsRemaining: "Visualizações Restantes",

      // Value range filters
      under100k: "Abaixo de $100K",
      "100k-500k": "$100K - $500K",
      "500k-1m": "$500K - $1M",
      over1m: "Acima de $1M",
      // Dismissal options
      dontAskAgain: "Não perguntar novamente",
      remindLater: "Lembre-me mais tarde",
      waitFewHours: "Perguntar em algumas horas",
      // Add property views translations
      firstViewer: "Você foi o primeiro a descobrir esta propriedade!",
      viewCount: "Você e {{count}} outro{{plural}} já viram esta propriedade",

      // Notes Modal translations
      selectProperty: "Selecionar propriedade",
      failedToFetchNotes: "Falha ao buscar anotações",
      failedToFetchDetails: "Falha ao buscar detalhes da propriedade",

      // Add new translations for viewed properties
      viewedProperties: {
        title: "Propriedades Visualizadas",
        timeframes: {
          today: "Hoje",
          yesterday: "Ontem",
          thisWeek: "Esta Semana",
          lastWeek: "Semana Passada",
          thisMonth: "Este Mês",
          lastMonth: "Mês Passado",
          thisYear: "Este Ano",
          allTime: "Todo o Período",
        },
        stats: {
          viewed: "Visualizada",
          times: "vezes",
          lastViewed: "Última visualização",
          properties: "propriedades",
        },
        noProperties: "Nenhuma propriedade visualizada neste período",
        loading: "Carregando propriedades visualizadas...",
        addressNotAvailable: "Endereço não disponível",
        clickToView: "Clique para ver detalhes da propriedade",
      },

      // Add these to your translation objects
      viewsTooltipUntrackedCard:
        "Clique para ver suas propriedades visualizadas",
      viewsLabel: "Visualizações Disponíveis",
      creditsRemaining: "créditos restantes",
      creditsReserved: "créditos reservados",

      monthlyViews: "Visualizações Mensais",

      // Friends page translations
      friends: "Amigos",
      friendRequests: "Solicitações de Amizade",
      sent: "Enviadas",
      blocked: "Bloqueados",
      sendFriendRequest: "Enviar Solicitação",
      searchFriends: "Pesquisar amigos...",
      noFriendsYet: "Nenhum Amigo Ainda",
      startAddingFriends:
        "Comece a adicionar amigos para conectar e conversar!",
      noFriendsFound: "Nenhum amigo encontrado com '{{searchTerm}}'",
      noFriendRequests: "Nenhuma Solicitação de Amizade",
      noBlockedUsers: "Nenhum Usuário Bloqueado",
      noBlockedUsersYet: "Você ainda não bloqueou nenhum usuário",
      friendRemoved: "Amigo removido com sucesso",
      failedToRemoveFriend: "Falha ao remover amigo",
      removeFriend: "Remover Amigo",
      removeFriendConfirmation: "Tem certeza que deseja remover este amigo?",
      blockUser: "Bloquear Usuário",
      blockUserConfirmation: "Tem certeza que deseja bloquear este usuário?",
      blockUserWarning:
        "Usuários bloqueados não poderão enviar mensagens ou solicitações de amizade",
      blockReason: "Motivo do bloqueio (opcional)",
      unblockUser: "Desbloquear Usuário",
      userBlocked: "Usuário bloqueado com sucesso",
      failedToBlockUser: "Falha ao bloquear usuário",
      userUnblocked: "Usuário desbloqueado com sucesso",
      failedToUnblockUser: "Falha ao desbloquear usuário",
      chatWithFriend: "Conversar com {{name}}",
      userDeleted: "Usuário excluído com sucesso",
      failedToDeleteUser: "Falha ao excluir usuário",
      cancelFriendRequest: "Cancelar Solicitação",
      friendStatus: {
        pending: "Pendente",
        accepted: "Amigos",
        blocked: "Bloqueado",
      },
      acceptFriendRequest: "Aceitar Solicitação",
      declineFriendRequest: "Recusar Solicitação",

      // Add Friend Modal translations
      addFriend: "Adicionar Amigo",
      searchUsers: "Buscar Usuários",
      searchByEmail: "Buscar usuários por email...",
      searchByEmailPlaceholder: "Buscar usuários por email...",
      noUsersFound: "Nenhum usuário encontrado",
      enterEmailToSearch: "Digite um endereço de email para buscar usuários",
      sendingRequest: "Enviando solicitação...",
      requestSent: "Solicitação de amizade enviada!",
      alreadyFriends: "Vocês já são amigos",
      cantAddYourself: "Você não pode adicionar a si mesmo como amigo",
      userNotFound: "Usuário não encontrado",
      addMessage: "Adicionar uma mensagem (opcional)",
      messagePlaceholder: "Olá! Gostaria de me conectar com você...",
      send: "Enviar Solicitação",
      searchingUsers: "Buscando usuários...",
      tryAnotherEmail: "Tente outro endereço de email",
      requestAlreadySent: "Solicitação de amizade já enviada",
      addFriendTitle: "Adicionar Amigo",
      searchUsersPlaceholder: "Buscar usuários por email...",

      // Chat Page translations
      messageEditHistory: "Histórico de Edições",
      currentVersion: "atual",
      searchChats: "Buscar conversas...",
      newChat: "Nova Conversa",
      inbox: "Caixa de Entrada",
      archived: "Arquivadas",
      unnamedChat: "Conversa sem Nome",
      doubleClickToEdit: "Clique duas vezes para editar",
      noMessagesYet: "Nenhuma mensagem ainda",
      noChatsFound: "Nenhuma conversa encontrada",
      participants: "participantes",
      searchInConversation: "Pesquisar na conversa...",
      loadingMoreMessages: "Carregando mais mensagens...",
      noMessagesFound: "Nenhuma mensagem encontrada",
      typeMessage: "Digite uma mensagem...",
      welcomeToMessages: "Bem-vindo às Mensagens",
      selectChatToStart:
        "Selecione uma conversa da barra lateral ou inicie uma nova conversa para começar a enviar mensagens",
      addUserToChat: "Adicionar Usuário à Conversa",
      searchUsersByNameOrEmail: "Pesquisar usuários por nome ou email...",
      trySearchingDifferent: "Tente pesquisar com um nome ou email diferente",
      unarchiveChat: "Desarquivar Conversa",
      archiveChat: "Arquivar Conversa",
      deleteChat: "Excluir Conversa",
      deleteMessage: "Excluir Mensagem",
      deleteMessageConfirmation:
        "Esta mensagem será permanentemente excluída para você. Outros participantes da conversa ainda poderão vê-la.",
      delete: "Excluir",
      deleteChatConfirmation:
        "Esta conversa será permanentemente excluída para todos os participantes. Esta ação não pode ser desfeita.",
      chatParticipants: "Participantes da Conversa",
      unarchive: "Desarquivar",
      archive: "Arquivar",
      areYouSureYouWantToUnarchiveThisChatItWillReappearInYourMainChatList:
        "Tem certeza que deseja desarquivar esta conversa? Ela reaparecerá na sua lista principal de conversas.",
      areYouSureYouWantToArchiveThisChatYouCanAlwaysUnarchiveItLater:
        "Tem certeza que deseja arquivar esta conversa? Você pode desarquivá-la depois.",
    },
  },
};

const initI18n = (language = "en") => {
  return i18n.use(initReactI18next).init({
    resources,
    lng: language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};

export { initI18n };
export default i18n;
