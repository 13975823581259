class SwarmManager {
  constructor() {
    this.instances = new Map();
    this.configs = new Map();
    this.isTransitioning = false;
    this.animationLoop = null;
    this.performanceMonitoring = {
      enabled: false,
      frameCount: 0,
      lastTime: 0,
      history: []
    };
    this.originalConfigs = new Map();
  }

  getConfig(mode, isSmallContainer) {
    const key = `${mode}-${isSmallContainer}`;
    
    if (this.configs.has(key)) {
      return this.configs.get(key);
    }

    const config = this.createConfig(mode, isSmallContainer);
    this.configs.set(key, config);
    this.originalConfigs.set(key, JSON.parse(JSON.stringify(config)));
    return config;
  }

  createConfig(mode, isSmallContainer) {
    const baseConfig = {
      normal: {
        particles: {
          number: {
            value: isSmallContainer ? 40 : 120,
            density: {
              enable: true,
              value_area: isSmallContainer ? 400 : 800,
            },
          },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
          },
          opacity: {
            value: 0.6,
            random: false,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.4,
              sync: false,
            },
          },
          size: {
            value: isSmallContainer ? 2 : 3,
            random: true,
            anim: {
              enable: true,
              speed: 2,
              size_min: isSmallContainer ? 0.5 : 1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: isSmallContainer ? 80 : 150,
            color: "#8853fb",
            opacity: 0.4,
            width: isSmallContainer ? 0.5 : 1,
          },
          move: {
            enable: true,
            speed: isSmallContainer ? 1 : 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "bounce",
            bounce: true,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
            onclick: { enable: false },
            resize: true,
          },
          modes: {
            grab: {
              distance: isSmallContainer ? 140 : 200,
              line_linked: { opacity: 1 },
            },
          },
        },
        retina_detect: true,
      },
      frenetic: {
        particles: {
          number: {
            value: isSmallContainer ? 50 : 200,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: ["#ffffff", "#8853fb", "#6039b0", "#4c2d8c", "#3a2268"],
          },
          shape: { type: "circle" },
          opacity: {
            value: 0.5,
            random: true,
            anim: {
              enable: true,
              speed: 3,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              size_min: 0.5,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 40,
            color: "#8853fb",
            opacity: 1,
            width: isSmallContainer ? 0.5 : 1,
          },
          move: {
            enable: true,
            speed: isSmallContainer ? 5 : 10,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "bounce",
            bounce: true,
            attract: {
              enable: true,
              rotateX: 600,
              rotateY: 600,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "bubble",
            },
            onclick: { enable: false },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 200,
              size: 4,
              duration: 0.4,
              opacity: 0.8,
              speed: 3,
            },
          },
        },
        retina_detect: true,
      },
    };

    return baseConfig[mode] || baseConfig.normal;
  }

  async initParticles(containerId, mode, isSmallContainer) {
    if (!window.particlesJS || this.isTransitioning) return null;
    
    const container = document.getElementById(containerId);
    if (!container) return null;

    // If we already have an instance, destroy it first
    if (this.instances.has(containerId)) {
      this.destroy(containerId);
    }

    // Create new instance
    const config = this.getConfig(mode, isSmallContainer);
    window.particlesJS(containerId, config);
    
    if (window.pJSDom && window.pJSDom.length > 0) {
      const instance = window.pJSDom[window.pJSDom.length - 1];
      
      if (instance && instance.pJS) {
        // Store the instance with its original configuration
        this.instances.set(containerId, {
          instance,
          originalConfig: JSON.parse(JSON.stringify(config))
        });
        
        instance.pJS.fn.particlesCreate();
        instance.pJS.fn.particlesDraw();
        
        this.ensureAnimationLoop();
      }
      
      return instance;
    }
    
    return null;
  }

  async updateConfiguration(containerId, mode, isSmallContainer) {
    const instance = this.instances.get(containerId);
    if (!instance || !instance.pJS) return;

    const config = this.getConfig(mode, isSmallContainer);
    const pJS = instance.pJS;

    // Pause the animation loop
    this.stopAnimationLoop();

    try {
      // Update configuration
      pJS.particles.array = [];
      Object.assign(pJS.particles, config.particles);
      Object.assign(pJS.interactivity, config.interactivity);

      // Recreate particles
      pJS.fn.particlesEmpty();
      pJS.fn.particlesCreate();
      pJS.fn.particlesDraw();
      
      // Restart animation loop
      this.ensureAnimationLoop();
    } catch (error) {
      console.error('Error updating particles configuration:', error);
      // Attempt recovery by reinitializing
      this.initParticles(containerId, mode, isSmallContainer);
    }
  }

  destroy(containerId) {
    const instance = this.instances.get(containerId);
    if (instance) {
      try {
        if (instance.pJS) {
          // Clear particles array
          if (instance.pJS.particles) {
            instance.pJS.particles.array = [];
          }
          // Remove canvas
          if (instance.pJS.canvas && instance.pJS.canvas.el) {
            instance.pJS.canvas.el.remove();
          }
        }
      } catch (error) {
        console.error('Error during cleanup:', error);
      }
      
      this.instances.delete(containerId);
    }

    if (this.instances.size === 0) {
      this.stopAnimationLoop();
    }
  }

  destroyAll() {
    for (const containerId of this.instances.keys()) {
      this.destroy(containerId);
    }
    this.stopAnimationLoop();
  }

  ensureAnimationLoop() {
    if (!this.animationLoop) {
      this.startAnimationLoop();
    }
  }

  startAnimationLoop() {
    const animate = () => {
      let hasActiveInstances = false;

      this.instances.forEach((instance) => {
        if (instance && instance.pJS && instance.pJS.particles) {
          try {
            if (instance.pJS.particles.array && instance.pJS.particles.array.length > 0) {
              instance.pJS.fn.particlesDraw();
              hasActiveInstances = true;
            }
          } catch (error) {
            console.error('Error in animation loop:', error);
            this.instances.delete(instance.pJS.canvas.el.id);
          }
        }
      });

      // Check performance if monitoring is enabled
      if (this.performanceMonitoring.enabled) {
        this.checkPerformance();
      }

      if (hasActiveInstances) {
        this.animationLoop = requestAnimationFrame(animate);
      } else {
        this.stopAnimationLoop();
      }
    };

    this.animationLoop = requestAnimationFrame(animate);
  }

  stopAnimationLoop() {
    if (this.animationLoop) {
      cancelAnimationFrame(this.animationLoop);
      this.animationLoop = null;
    }
  }

  pause(containerId) {
    const instance = this.instances.get(containerId);
    if (instance && instance.pJS) {
      instance.pJS.particles.move.enable = false;
    }
  }

  resume(containerId) {
    const instance = this.instances.get(containerId);
    if (instance && instance.pJS) {
      instance.pJS.particles.move.enable = true;
    }
  }

  startPerformanceMonitoring() {
    this.performanceMonitoring = {
      enabled: true,
      frameCount: 0,
      lastTime: performance.now(),
      history: []
    };
  }

  stopPerformanceMonitoring() {
    this.performanceMonitoring = {
      enabled: false,
      frameCount: 0,
      lastTime: 0,
      history: []
    };
  }

  checkPerformance() {
    if (!this.performanceMonitoring.enabled) return;

    const now = performance.now();
    this.performanceMonitoring.frameCount++;

    if (now - this.performanceMonitoring.lastTime >= 1000) {
      const fps = this.performanceMonitoring.frameCount;
      this.performanceMonitoring.history.push(fps);
      
      if (this.performanceMonitoring.history.length > 5) {
        this.performanceMonitoring.history.shift();
      }

      const avgFps = this.performanceMonitoring.history.reduce((a, b) => a + b, 0) / 
                    this.performanceMonitoring.history.length;

      if (avgFps < 20) {
        this.instances.forEach(({instance, originalConfig}) => {
          if (instance && instance.pJS) {
            const pJS = instance.pJS;
            const currentValue = pJS.particles.number.value;
            
            // Don't reduce below 50% of original
            const minParticles = Math.floor(originalConfig.particles.number.value * 0.5);
            const newValue = Math.max(minParticles, Math.floor(currentValue * 0.9));
            
            if (newValue !== currentValue) {
              pJS.particles.number.value = newValue;
              pJS.fn.particlesEmpty();
              pJS.fn.particlesCreate();
              pJS.fn.particlesDraw();
            }
          }
        });
      }

      this.performanceMonitoring.frameCount = 0;
      this.performanceMonitoring.lastTime = now;
    }
  }
}

export default new SwarmManager(); 