import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  ExternalLink,
  FileText,
  DollarSign,
  AlertTriangle,
  Tool,
  Info,
  HelpCircle,
  AlertCircle,
} from "react-feather";
import styled from "styled-components";
import { TabDetails } from "./TabStyles";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import ReportLinkModal from "../../modals/ReportLinkModal";
import { LoadingText } from "../../common/LoadingAnimation";
import { useTheme } from "../../../context/ThemeContext";

const ResourcesWrapper = styled.div`
  position: relative;
  height: 100%;
  // padding: 5px;
  display: flex;
  flex-direction: column;
  // background: ${({ theme }) => theme.colors.surface};
`;

const SubTabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const SubTab = styled.button`
  background: ${(props) =>
    props.$active
      ? props.theme.colors.primary
      : props.theme.colors.buttonGradient};
  color: ${({ theme, $active }) =>
    $active
      ? "#FFFFFF"
      : theme.isDarkMode
      ? "rgba(255, 255, 255, 0.9)"
      : "rgba(255, 255, 255, 0.9)"};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 12px;
`;

const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ResourceSection = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 16px;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ResourceTitle = styled.h3`
  font-size: 0.95rem;
  color: ${({ theme }) => theme.colors.primary};
  margin: 8px 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }
`;

const ResourceList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ResourceItem = styled.li`
  margin-bottom: 6px;
`;

const ResourceLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;

const ReportButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 4px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s, color 0.2s;
  margin-left: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${ResourceItem}:hover & {
    opacity: 1;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ResourceDescription = styled.p`
  margin: 2px 0 8px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 1.4;
`;

const DisclaimerContainer = styled.div`
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 10;
`;

const DisclaimerIcon = styled(HelpCircle)`
  color: ${({ theme }) => theme.colors.primary};
  width: 18px;
  height: 18px;
  cursor: help;
  margin-top: 10px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  height: 100%;
`;

const EmptyStateIcon = styled(Info)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 12px;
`;

const EmptyStateText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text};
`;

const ResourcesTab = ({ county, state, isTranslating, isActive, property }) => {
  const { t } = useTranslation();
  const scrollableContentRef = useRef(null);
  const [activeSubTab, setActiveSubTab] = useState("property");

  const propertyResources = property?.resources?.propertyResources;
  const countyResources = property?.resources?.countyResources;
  const displayedSources = new Set();

  // Modified hasValidData function
  const hasValidData = (value, label, allResources) => {
    if (!value) return false;

    if (Array.isArray(value)) {
      if (value.length > 0 && value[0]?.url) {
        // Get all URLs from other sections
        const allOtherUrls = new Set();

        // Collect URLs from both property and county resources
        [
          ...(allResources?.propertyResourceDetails || []),
          ...(allResources?.countyResourceDetails || []),
        ].forEach((detail) => {
          if (detail.label !== label && Array.isArray(detail.value)) {
            detail.value.forEach((item) => {
              if (typeof item === "string") {
                allOtherUrls.add(item);
              } else if (item?.url) {
                allOtherUrls.add(item.url);
              }
            });
          } else if (
            detail.label !== label &&
            typeof detail.value === "string"
          ) {
            allOtherUrls.add(detail.value);
          }
        });

        // Check if this section has any unique URLs
        return value.some((item) => {
          const url = typeof item === "string" ? item : item.url;
          return url && !allOtherUrls.has(url);
        });
      }

      // New check for arrays of objects (like ownership_history)
      if (value.length > 0 && typeof value[0] === "object" && !value[0]?.url) {
        return value.some((item) =>
          Object.values(item).some(
            (val) =>
              val !== null &&
              val !== undefined &&
              val !== "" &&
              val !== "Unknown"
          )
        );
      }

      return value.some(
        (item) =>
          item &&
          !displayedSources.has(typeof item === "string" ? item : item.url)
      );
    }

    if (typeof value === "string") {
      return value.trim() !== "" && !displayedSources.has(value);
    }

    if (typeof value === "object" && value !== null) {
      // Handle objects (like flood_risk_info, market_insights)
      return Object.values(value).some(
        (val) =>
          val !== null &&
          val !== undefined &&
          val !== "" &&
          val !== "Unknown" &&
          val !== "medium" // Skip confidence values
      );
    }

    return false;
  };

  // Move these declarations before the console.log
  const propertyResourceDetails = [
    {
      icon: <Link size={14} />,
      label: t("listingLinks"),
      value: propertyResources?.listing_links,
    },
    {
      icon: <ExternalLink size={14} />,
      label: t("taxAssessmentLinks"),
      value: propertyResources?.tax_assessment_links,
    },
    {
      icon: <DollarSign size={14} />,
      label: t("marketInsights"),
      value: propertyResources?.market_insights,
    },
    {
      icon: <FileText size={14} />,
      label: t("ownershipHistory"),
      value: propertyResources?.ownership_history,
    },
    {
      icon: <AlertTriangle size={14} />,
      label: t("floodRiskInfo"),
      value: propertyResources?.flood_risk_info,
    },
    {
      icon: <Tool size={14} />,
      label: t("zoningInfo"),
      value: propertyResources?.zoning_info,
    },
    {
      icon: <Info size={14} />,
      label: t("nearbyAmenities"),
      value: propertyResources?.nearby_amenities,
    },
  ];

  const countyResourceDetails = [
    {
      icon: <Link size={14} />,
      label: t("clerkOfficeURL"),
      value: countyResources?.ClerkOfficeURL,
    },
    {
      icon: <ExternalLink size={14} />,
      label: t("governmentLinks"),
      value: countyResources?.GovernmentLinks,
    },
    {
      icon: <DollarSign size={14} />,
      label: t("propertyTaxInfo"),
      value: countyResources?.PropertyTaxInfo,
    },
    {
      icon: <FileText size={14} />,
      label: t("deedInfo"),
      value: countyResources?.DeedInfo,
    },
    {
      icon: <AlertTriangle size={14} />,
      label: t("lienInfo"),
      value: countyResources?.LienInfo,
    },
    {
      icon: <Tool size={14} />,
      label: t("auctionInfo"),
      value: countyResources?.AuctionInfo,
    },
    {
      icon: <Info size={14} />,
      label: t("additionalInfo"),
      value: countyResources?.AdditionalInfo,
    },
  ];

  useEffect(() => {
    if (!isActive && scrollableContentRef.current) {
      scrollableContentRef.current.scrollTop = 0;
    }
  }, [isActive]);

  const renderResourceLinks = (resourceLinks, displayedSources) => {
    // Handle non-array data
    if (!Array.isArray(resourceLinks)) {
      if (
        typeof resourceLinks === "string" &&
        !displayedSources.has(resourceLinks)
      ) {
        displayedSources.add(resourceLinks);
        return (
          <ResourceList>
            <ResourceItem>
              <ResourceLink
                href={resourceLinks}
                target="_blank"
                rel="noopener noreferrer"
              >
                {resourceLinks}
              </ResourceLink>
            </ResourceItem>
          </ResourceList>
        );
      }
      return null;
    }

    // Handle arrays of objects without URLs (like ownership_history)
    if (
      resourceLinks.length > 0 &&
      typeof resourceLinks[0] === "object" &&
      !resourceLinks[0]?.url
    ) {
      const hasValidContent = resourceLinks.some((item) =>
        Object.values(item).some(
          (val) =>
            val !== null &&
            val !== undefined &&
            val !== "" &&
            val !== "Unknown" &&
            val !== "medium"
        )
      );

      if (!hasValidContent) {
        return null;
      }
    }

    // Handle arrays with URLs
    const uniqueLinks = resourceLinks.filter((item) => {
      const url = typeof item === "string" ? item : item.url;
      if (url && !displayedSources.has(url)) {
        displayedSources.add(url);
        return true;
      }
      return false;
    });

    if (uniqueLinks.length === 0) return null;

    return (
      <ResourceList>
        {uniqueLinks.map((item, index) => {
          const url = typeof item === "string" ? item : item.url;
          const title = typeof item === "string" ? item : item.title;

          return (
            <ResourceItem key={index}>
              <LinkWrapper>
                <ResourceLink
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isTranslating ? <LoadingText>{title}</LoadingText> : title}
                </ResourceLink>
                <Tippy
                  content={t("reportIncorrectLink")}
                  animation="shift-away"
                  placement="left"
                  arrow={true}
                  duration={[300, 250]}
                  delay={[400, 0]}
                  theme="modern"
                >
                  <ReportButton
                    onClick={(e) => {
                      e.preventDefault();
                      const propertyId =
                        property?.overview?.uid || property?.uid;
                      if (!propertyId) {
                        console.error("Property UID not found:", property);
                        return;
                      }
                      setReportModalData({
                        isOpen: true,
                        url,
                        resourceType: activeSubTab,
                        resourceTitle: title,
                        propertyId,
                      });
                    }}
                  >
                    <AlertCircle size={14} />
                  </ReportButton>
                </Tippy>
              </LinkWrapper>
              {item.description && (
                <ResourceDescription>
                  {isTranslating ? (
                    <LoadingText>{item.description}</LoadingText>
                  ) : (
                    item.description
                  )}
                </ResourceDescription>
              )}
            </ResourceItem>
          );
        })}
      </ResourceList>
    );
  };

  // Modify the renderResourceSection to pass the label
  const renderResourceSection = (
    title,
    icon,
    data,
    renderFunction,
    displayedSources,
    label
  ) => {
    if (!hasValidData(data, label)) {
      return null;
    }

    const content = renderFunction(data, displayedSources);
    if (!content || React.Children.count(content.props.children) === 0) {
      return null;
    }

    return (
      <ResourceSection>
        <ResourceTitle>
          {icon} {title}
        </ResourceTitle>
        {content}
      </ResourceSection>
    );
  };

  // Add state for report modal
  const [reportModalData, setReportModalData] = useState({
    isOpen: false,
    url: "",
    resourceType: "",
    resourceTitle: "",
    propertyId: property?.overview?.uid || property?.uid, // Get UID from either location
  });

  return (
    <TabDetails>
      <ResourcesWrapper>
        <DisclaimerContainer>
          <Tippy
            content={t("resourcesDisclaimer")}
            animation="shift-away"
            arrow={true}
            placement="left"
            theme="modern"
            duration={[300, 250]}
            delay={[400, 0]}
          >
            <DisclaimerIcon />
          </Tippy>
        </DisclaimerContainer>
        <SubTabContainer>
          <SubTab
            $active={activeSubTab === "property"}
            onClick={() => setActiveSubTab("property")}
          >
            {isTranslating ? (
              <LoadingText>{t("propertyResources")}</LoadingText>
            ) : (
              t("propertyResources")
            )}
          </SubTab>
          <SubTab
            $active={activeSubTab === "county"}
            onClick={() => setActiveSubTab("county")}
          >
            {isTranslating ? (
              <LoadingText>{t("countyResources")}</LoadingText>
            ) : (
              t("countyResources")
            )}
          </SubTab>
        </SubTabContainer>
        <ScrollableContent ref={scrollableContentRef}>
          <ResourcesContainer>
            {activeSubTab === "property" &&
              propertyResources &&
              (propertyResourceDetails.some((detail) =>
                hasValidData(detail.value, detail.label, {
                  propertyResourceDetails,
                  countyResourceDetails,
                })
              ) ? (
                propertyResourceDetails.map(
                  (detail) =>
                    hasValidData(detail.value, detail.label, {
                      propertyResourceDetails,
                      countyResourceDetails,
                    }) && (
                      <React.Fragment key={`property-${detail.label}`}>
                        {renderResourceSection(
                          detail.label,
                          detail.icon,
                          detail.value,
                          renderResourceLinks,
                          displayedSources,
                          detail.label
                        )}
                      </React.Fragment>
                    )
                )
              ) : (
                <EmptyStateContainer>
                  <EmptyStateIcon />
                  <EmptyStateText>
                    {t("noPropertyResourcesFound")}
                  </EmptyStateText>
                </EmptyStateContainer>
              ))}
            {activeSubTab === "county" &&
              countyResources &&
              (countyResourceDetails.some((detail) =>
                hasValidData(detail.value, detail.label, {
                  propertyResourceDetails,
                  countyResourceDetails,
                })
              ) ? (
                countyResourceDetails.map(
                  (detail) =>
                    hasValidData(detail.value, detail.label, {
                      propertyResourceDetails,
                      countyResourceDetails,
                    }) && (
                      <React.Fragment key={`county-${detail.label}`}>
                        {renderResourceSection(
                          detail.label,
                          detail.icon,
                          detail.value,
                          renderResourceLinks,
                          displayedSources,
                          detail.label
                        )}
                      </React.Fragment>
                    )
                )
              ) : (
                <EmptyStateContainer>
                  <EmptyStateIcon />
                  <EmptyStateText>
                    {t("noCountyResourcesAvailable")}
                  </EmptyStateText>
                </EmptyStateContainer>
              ))}
          </ResourcesContainer>
        </ScrollableContent>
      </ResourcesWrapper>
      <ReportLinkModal
        isOpen={reportModalData.isOpen}
        onClose={() =>
          setReportModalData((prev) => ({ ...prev, isOpen: false }))
        }
        propertyId={reportModalData.propertyId}
        reportedUrl={reportModalData.url}
        resourceType={reportModalData.resourceType}
        resourceTitle={reportModalData.resourceTitle}
      />
    </TabDetails>
  );
};

export default React.memo(ResourcesTab);
