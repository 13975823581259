import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../common/DataTable";
import Pagination from "../../common/Pagination";
import DetailedPropertyInfo from "./DetailedPropertyInfo";
import PropertyDetails from "./PropertyDetails";
import fetchFilteredData from "../../../hooks/fetchFilteredData";
import fetchExpandedData from "../../../hooks/fetchExpandedData";
import { FavoritesContext } from "../../../context/FavoritesContext";
import { motion, AnimatePresence } from "framer-motion";
import LoadingOverlay from "../../common/LoadingOverlay";
import { useTranslation } from "react-i18next";
import Toast from "../../common/Toast";
import NotesModal from "../../modals/NotesModal";
import { NotesContext } from "../../../context/NotesContext";
import ModernFilterSystem from "./ModernFilterSystem";
import FloatingActionButtons from "./FloatingActionButtons";
import { useWallet } from "../../../context/WalletContext";
import styled from "styled-components";
import { Eye } from "react-feather";
import ViewLimitModal from "../../modals/ViewLimitModal";
import Tippy from "@tippyjs/react";

const ViewCountDisplay = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 12px 16px;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  box-shadow: ${({ theme }) => theme.colors.shadow};
  width: fit-content;
  max-width: 300px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-2px);
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ViewInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ViewLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const ViewCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  gap: 8px;

  .highlight {
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  .divider {
    opacity: 0.4;
    margin: 0 4px;
  }
`;

const CreditsCount = styled.span`
  transition: all 0.3s ease;
  font-weight: ${(props) => (props.$credits <= 10 ? "600" : "500")};
  color: ${(props) => {
    const credits = props.$credits;
    const theme = props.theme;
    const isDarkMode = theme.isDarkMode;

    if (credits === 0) {
      return isDarkMode
        ? `rgba(255, 59, 48, 0.95)` // Bright red with high opacity for dark mode
        : theme.colors.accent.error.dark; // Deeper red for light mode
    }
    if (credits <= 5) {
      return isDarkMode
        ? `rgba(255, 149, 0, 0.95)` // Bright orange with high opacity for dark mode
        : theme.colors.accent.warning.dark; // Deeper orange for light mode
    }
    if (credits <= 10) {
      return isDarkMode
        ? `rgba(255, 149, 0, 0.8)` // Softer orange with medium opacity for dark mode
        : theme.colors.accent.warning.base; // Standard orange for light mode
    }
    return theme.colors.text;
  }};
  text-shadow: ${(props) => {
    const credits = props.$credits;
    const theme = props.theme;
    const isDarkMode = theme.isDarkMode;

    if (credits <= 5 && isDarkMode) {
      return "0 0 10px rgba(255, 59, 48, 0.3)"; // Subtle glow for emphasis in dark mode
    }
    return "none";
  }};
`;

const CreditsBadge = styled.div`
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.accent.purple.base};
  font-weight: 500;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
`;

const ViewedPropertiesDropup = styled(motion.div)`
  position: absolute;
  bottom: calc(100% + 12px);
  left: 0;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 16px;
  width: 240px;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  z-index: 101;
`;

const TimeframeButton = styled.button`
  background: ${(props) =>
    props.$active ? props.theme.colors.buttonGradient : "transparent"};
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${(props) =>
    props.$active
      ? props.theme.colors.accent.purple.base
      : props.theme.colors.text};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  .count {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.secondary};
    margin-left: 8px;
  }
`;

const ViewedPropertiesBackButton = styled(motion.button)`
  position: relative;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  box-shadow: ${({ theme }) => theme.colors.shadow};
  height: 44px;
  width: 44px;

  &:hover {
    transform: translateY(-2px);
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

const ResultsContainer = styled.div`
  flex: 1;
  overflow: auto;
  min-height: calc(100vh - 320px);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DataTableWrapper = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  height: 100%;
`;

const PaginationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 12px;
  z-index: 10;
`;

const ResultsArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ViewedPropertiesHeader = styled.div`
  margin-bottom: 20px;
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  button {
    padding: 8px 16px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.buttonGradient};
    border: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      background: ${({ theme }) => theme.colors.buttonGradientHover};
      transform: translateY(-1px);
    }
  }
`;

const BuyContent = styled(motion.div)`
  height: 96vh;
  width: calc(100% - 36px);
  padding: 13px;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text};
`;

const BuyLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.shared-property {
    display: block;
  }
`;

const FilterArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  pointer-events: none;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.accent.error.base};
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.accent.error.light};
  margin: 12px 0;
`;

const PageInfo = styled(motion.div)`
  font-family: "SF Mono", "Roboto Mono", monospace;
  background: ${({ theme }) => theme.colors.surface};
  padding: 6px 12px;
  border-radius: 12px;
  margin-left: 12px;
  display: grid;
  grid-template-columns: 32px 8px 32px;
  align-items: center;
  justify-content: center;
  font-feature-settings: "tnum" on, "lnum" on;
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  box-shadow: ${({ theme }) => theme.colors.shadow};
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const NumberContainer = styled.div`
  position: relative;
  width: 28px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`;

const TotalContainer = styled(NumberContainer)`
  justify-content: flex-start;
`;

const PageNumber = styled(motion.div)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  font-size: 13px;
  width: 32px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

const PageDivider = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 300;
  font-size: 13px;
  width: 8px;
  text-align: center;
  opacity: 0.4;
`;

const TotalPages = styled(motion.div)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 13px;
  width: 32px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
`;

const BottomControlsWrapper = styled.div`
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 0 24px;
  z-index: 100;
`;

const LeftControls = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CenterControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RightSpacer = styled.div`
  flex: 1;
`;

const ViewsDisplay = ({
  timeframes,
  selectedTimeframe,
  timeframeStats,
  onTimeframeChange,
  isDropupOpen,
  setIsDropupOpen,
  walletData,
  isViewedPropertiesMode,
  onExitViewedPropertiesMode,
}) => {
  const { t } = useTranslation();
  const dropupRef = useRef(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropupRef.current && !dropupRef.current.contains(event.target)) {
        setIsDropupOpen(false);
      }
    };

    if (isDropupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropupOpen, setIsDropupOpen]);

  const getViewsLabel = () => {
    const plan = walletData?.subscription?.plan;
    const credits = walletData?.subscription?.credits;

    if (plan === "UNLIMITED") return t("unlimitedViews");
    if (credits >= 0) return t("availableViews");
    return t("monthlyViews");
  };

  const getViewsCount = () => {
    const plan = walletData?.subscription?.plan;
    const credits = walletData?.subscription?.credits;
    const current = walletData?.subscription?.usage?.current || 0;

    if (plan === "UNLIMITED") return t("unlimited");
    if (credits >= 0)
      return (
        <CreditsCount $credits={credits}>
          {credits} {t("creditsRemaining")}
        </CreditsCount>
      );
    return `${current} / ${credits || 0}`;
  };

  const getCreditsInfo = () => {
    const plan = walletData?.subscription?.plan;
    const credits = walletData?.subscription?.credits;

    if (plan === "UNLIMITED" && credits > 0) {
      return `${credits} ${t("creditsReserved")}`;
    }
    return null;
  };

  const handleTimeframeClick = (timeframe) => {
    if (timeframe === selectedTimeframe && isViewedPropertiesMode) {
      onTimeframeChange(null);
      onExitViewedPropertiesMode();
    } else {
      onTimeframeChange(timeframe);
    }
    setIsDropupOpen(false);
  };

  return (
    <Tippy
      content={t("viewsTooltipUntrackedCard")}
      placement="top"
      animation="shift-away"
      arrow={true}
      duration={[300, 250]}
      delay={[400, 0]}
      theme="modern"
    >
      <ViewCountDisplay
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        onClick={() => setIsDropupOpen(!isDropupOpen)}
        style={{ position: "relative", cursor: "pointer" }}
        ref={dropupRef}
      >
        <div>
          <Eye size={20} color="rgba(136, 83, 251, 0.9)" />
        </div>
        <ViewInfo>
          <ViewLabel>{getViewsLabel()}</ViewLabel>
          <ViewCount>
            {getViewsCount()}
            {getCreditsInfo() && (
              <>
                <span className="divider">•</span>
                <CreditsBadge>{getCreditsInfo()}</CreditsBadge>
              </>
            )}
          </ViewCount>
        </ViewInfo>

        <AnimatePresence>
          {isDropupOpen && (
            <ViewedPropertiesDropup
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {timeframes.map((timeframe) => (
                  <TimeframeButton
                    key={timeframe.id}
                    $active={
                      selectedTimeframe === timeframe.id &&
                      isViewedPropertiesMode
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTimeframeClick(timeframe.id);
                    }}
                  >
                    <span>{timeframe.label}</span>
                    <span className="count">
                      ({timeframeStats[timeframe.id]?.uniqueProperties || 0})
                    </span>
                  </TimeframeButton>
                ))}
              </div>
            </ViewedPropertiesDropup>
          )}
        </AnimatePresence>
      </ViewCountDisplay>
    </Tippy>
  );
};

const Buy = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchPropertiesByUIDs, folders } = useContext(FavoritesContext);
  const { fetchAllUserNotes } = useContext(NotesContext);
  const { trackPropertyView, walletData } = useWallet();

  const [displayData, setDisplayData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "account_nbr",
    direction: "ascending",
  });
  const [expandedData, setExpandedData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [activeMap, setActiveMap] = useState("Google");
  const [filters, setFilters] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const savedFilters = JSON.parse(searchParams.get("filters") || "{}");

    return {
      state: Array.isArray(savedFilters?.StateOrProvince)
        ? savedFilters.StateOrProvince
        : [],
      county: Array.isArray(savedFilters?.County) ? savedFilters.County : [],
      city: Array.isArray(savedFilters?.City) ? savedFilters.City : [],
      saleType: Array.isArray(savedFilters?.SaleType)
        ? savedFilters.SaleType
        : [],
      propType: Array.isArray(savedFilters?.PropType)
        ? savedFilters.PropType
        : [],
      status: Array.isArray(savedFilters?.Status) ? savedFilters.Status : [],
      adjudgedValueRange: Array.isArray(savedFilters?.AdjudgedValue)
        ? savedFilters.AdjudgedValue
        : [1, 50000000],
      minimumBidRange: Array.isArray(savedFilters?.MinimumBid)
        ? savedFilters.MinimumBid
        : [1, 10000000],
      zipCode: savedFilters?.zipCode || "",
      parcelNumber: savedFilters?.parcelNumber || "",
    };
  });
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [isFavoritesSelected, setIsFavoritesSelected] = useState(false);
  const [favoriteProperties, setFavoriteProperties] = useState([]);

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [toast, setToast] = useState(null);

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const [wasNotesModalOpen, setWasNotesModalOpen] = useState(false);

  const [currentFilters, setCurrentFilters] = useState({});

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [isFilterOpen, setIsFilterOpen] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const propertyId = searchParams.get("property");
    return !propertyId; // Filter should be closed if we have a property ID
  });

  const [isSharedProperty, setIsSharedProperty] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return Boolean(searchParams.get("property"));
  });

  const [isAnyModalOpen, setIsAnyModalOpen] = useState(false);

  const loadingMessageLayers = [
    t("loadingMessages.layer1", { returnObjects: true }),
    t("loadingMessages.layer2", { returnObjects: true }),
    t("loadingMessages.layer3", { returnObjects: true }),
    t("loadingMessages.layer4", { returnObjects: true }),
  ];

  const [searchState, setSearchState] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      filters: JSON.parse(searchParams.get("filters") || "{}"),
      page: parseInt(searchParams.get("page") || "1", 10),
      sort: JSON.parse(searchParams.get("sort") || "null"),
    };
  });

  const [viewedItems, setViewedItems] = useState(new Set());

  const [viewLimitModalOpen, setViewLimitModalOpen] = useState(false);
  const [viewLimitMessage, setViewLimitMessage] = useState("");

  const [isViewedPropertiesMode, setIsViewedPropertiesMode] = useState(false);
  const [viewedProperties, setViewedProperties] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState("today");
  const [viewedPropertiesError, setViewedPropertiesError] = useState(null);
  const [isDropupOpen, setIsDropupOpen] = useState(false);
  const [timeframeStats, setTimeframeStats] = useState({});

  const timeframes = [
    { id: "today", label: t("viewedProperties.timeframes.today") },
    { id: "yesterday", label: t("viewedProperties.timeframes.yesterday") },
    { id: "thisWeek", label: t("viewedProperties.timeframes.thisWeek") },
    { id: "lastWeek", label: t("viewedProperties.timeframes.lastWeek") },
    { id: "thisMonth", label: t("viewedProperties.timeframes.thisMonth") },
    { id: "lastMonth", label: t("viewedProperties.timeframes.lastMonth") },
    { id: "thisYear", label: t("viewedProperties.timeframes.thisYear") },
    { id: "all", label: t("viewedProperties.timeframes.allTime") },
  ];

  const fetchTimeframeStats = async () => {
    try {
      const statsPromises = timeframes.map(async (timeframe) => {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/properties/viewed?timeframe=${timeframe.id}`,
          { credentials: "include" }
        );
        if (!response.ok) throw new Error("Failed to fetch stats");
        const data = await response.json();
        return { timeframe: timeframe.id, stats: data };
      });

      const results = await Promise.all(statsPromises);
      const statsMap = results.reduce((acc, { timeframe, stats }) => {
        acc[timeframe] = stats;
        return acc;
      }, {});

      setTimeframeStats(statsMap);
    } catch (error) {
      console.error("Error fetching timeframe stats:", error);
      setViewedPropertiesError(t("viewedProperties.fetchError"));
    }
  };

  const fetchViewedProperties = async (timeframe) => {
    try {
      setLoading(true);
      setViewedPropertiesError(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/properties/viewed?timeframe=${timeframe}&page=${currentPage}&itemsPerPage=${itemsPerPage}&sortKey=${sortConfig.key}&sortDirection=${sortConfig.direction}`,
        { credentials: "include" }
      );

      if (!response.ok) throw new Error("Failed to fetch viewed properties");

      const data = await response.json();
      setViewedProperties(data.properties || []);
      setTotalItems(data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching viewed properties:", error);
      setViewedPropertiesError(t("viewedProperties.fetchError"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isViewedPropertiesMode && selectedTimeframe) {
      fetchViewedProperties(selectedTimeframe);
    }
  }, [currentPage, sortConfig, selectedTimeframe, isViewedPropertiesMode]);

  const handleTimeframeChange = async (timeframe) => {
    if (timeframe === null) {
      setIsViewedPropertiesMode(false);
      setSelectedTimeframe(null);
      
      // If we were in favorites mode, restore favorites view
      if (isFavoritesSelected) {
        const paginatedFavorites = paginateProperties(
          favoriteProperties,
          1,
          itemsPerPage
        );
        setDisplayData(paginatedFavorites);
        setTotalItems(favoriteProperties.length);
        setCurrentPage(1);
      } else {
        // Otherwise restore main search view
        await exitViewedPropertiesMode();
      }
    } else {
      // If coming from favorites, clear that state
      if (isFavoritesSelected) {
        setIsFavoritesSelected(false);
      }
      
      setSelectedTimeframe(timeframe);
      setIsViewedPropertiesMode(true);
      setCurrentPage(1); // Reset to first page when changing timeframe
      await fetchViewedProperties(timeframe);
    }
  };

  const exitViewedPropertiesMode = async () => {
    setIsViewedPropertiesMode(false);
    setViewedProperties([]);
    setSelectedTimeframe(null);
    
    // Reset pagination to initial state for main view
    setCurrentPage(searchState.page || 1);
    
    // Restore the previous sort config if needed
    if (searchState.sort) {
      setSortConfig(searchState.sort);
    }

    // Fetch the main view data to ensure correct pagination
    try {
      const filtersToUse = Object.keys(filters).length === 0 ? {} : filters;
      const data = await fetchFilteredData(
        filtersToUse,
        searchState.page || 1,
        itemsPerPage,
        searchState.sort || sortConfig
      );
      
      setDisplayData(data.properties || []);
      setTotalItems(data.totalCount || 0);
    } catch (err) {
      console.error("Error fetching main view data:", err);
      setError(t("failedToFetchData"));
    }
  };

  const fetchPageData = useCallback(
    async (page, currentFilters, currentSort) => {
      if (loading) return;

      // Only show loading state for filter changes, not pagination
      const isFilterChange =
        JSON.stringify(currentFilters) !== JSON.stringify(filters);
      if (isFilterChange) {
        setLoading(true);
      }

      try {
        const filtersToUse =
          Object.keys(currentFilters).length === 0 ? {} : currentFilters;

        const data = await fetchFilteredData(
          filtersToUse,
          page,
          itemsPerPage,
          currentSort
        );

        const newViewedItems = new Set(
          data.properties
            .filter((property) => property.hasBeenViewed)
            .map((property) => property.uid)
        );
        setViewedItems(newViewedItems);
        setDisplayData(data.properties);
        setTotalItems(data.totalCount);
      } catch (err) {
        setError(t("failedToFetchData"));
        console.error(err);
      } finally {
        if (isFilterChange) {
          setLoading(false);
        }
      }
    },
    [itemsPerPage, t, loading, filters]
  );

  const paginateProperties = (properties, page, itemsPerPage) => {
    const startIndex = (page - 1) * itemsPerPage;
    return properties.slice(startIndex, startIndex + itemsPerPage);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const savedFilters = JSON.parse(searchParams.get("filters") || "{}");
        const savedPage = parseInt(searchParams.get("page") || "1", 10);
        const savedSort = JSON.parse(searchParams.get("sort") || "null");
        const propertyId = searchParams.get("property");

        setFilters(savedFilters);
        setCurrentPage(savedPage);
        if (savedSort) setSortConfig(savedSort);

        if (propertyId) {
          // If we have a property ID, try to load it directly first
          try {
            const expandedData = await fetchExpandedData(propertyId);
            if (expandedData) {
              setExpandedData(expandedData);
              // Handle coordinates from expanded data
              if (expandedData.overview?.geometry?.coordinates) {
                const [lng, lat] = expandedData.overview.geometry.coordinates;
                setSelectedCoordinates({ lat, lng });
              } else if (expandedData.overview?.coordinates) {
                const [lng, lat] = expandedData.overview.coordinates;
                setSelectedCoordinates({ lat, lng });
              }
              setActiveMap("Google");
              setIsModalOpen(true);
            }
          } catch (err) {
            console.error("Failed to fetch property details:", err);
            setToast({ message: t("failedToFetchDetails"), type: "error" });
          }
        }

        // Load the filtered data in the background
        const filtersToUse =
          Object.keys(savedFilters).length === 0 ? {} : savedFilters;
        const initialData = await fetchFilteredData(
          filtersToUse,
          savedPage,
          itemsPerPage,
          savedSort || sortConfig
        );

        const newViewedItems = new Set(
          initialData.properties
            .filter((property) => property.hasBeenViewed)
            .map((property) => property.uid)
        );
        setViewedItems(newViewedItems);
        setDisplayData(initialData.properties || []);
        setTotalItems(initialData.totalCount || 0);
      } catch (err) {
        console.error("Error fetching initial data:", err);
        setError(t("failedToFetchData"));
      } finally {
        setLoading(false);
        setInitialFetchDone(true);
      }
    };

    fetchInitialData();
  }, [location.search]);

  useEffect(() => {
    if (initialFetchDone && isFavoritesSelected) {
      if (favoriteProperties.length > 0) {
        const paginatedFavorites = paginateProperties(
          favoriteProperties,
          currentPage,
          itemsPerPage
        );
        setDisplayData(paginatedFavorites);
        setTotalItems(favoriteProperties.length);
      } else {
        setDisplayData([]);
        setTotalItems(0);
      }
    }
  }, [
    favoriteProperties,
    currentPage,
    itemsPerPage,
    initialFetchDone,
    isFavoritesSelected,
  ]);

  const updateURL = (newFilters, newPage, newSort) => {
    const searchParams = new URLSearchParams();
    searchParams.set("filters", JSON.stringify(newFilters));
    searchParams.set("page", newPage.toString());
    if (newSort) searchParams.set("sort", JSON.stringify(newSort));

    setSearchState({
      filters: newFilters,
      page: newPage,
      sort: newSort,
    });

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleApplyFilters = async (newFilters) => {
    if (JSON.stringify(filters) === JSON.stringify(newFilters)) return;

    setLoading(true);
    setError(null);
    setIsFavoritesSelected(false);

    try {
      const data = await fetchFilteredData(
        newFilters,
        1,
        itemsPerPage,
        sortConfig
      );

      setDisplayData(data.properties || []);
      setTotalItems(data.totalCount || 0);
      setCurrentPage(1);
      setFilters(newFilters);
      updateURL(newFilters, 1, sortConfig);
    } catch (err) {
      console.error("Filter error:", err);
      setError(t("failedToFetchData"));
    } finally {
      setLoading(false);
    }
  };

  const handleFavoriteSelection = async (selectedFolders = []) => {
    if (!initialFetchDone) {
      return;
    }

    setError(null);

    // If we're in viewed properties mode, exit it first
    if (isViewedPropertiesMode) {
      await exitViewedPropertiesMode();
    }

    if (selectedFolders.length === 0) {
      setIsFavoritesSelected(false);
      // Fetch normal filtered data
      await fetchPageData(currentPage, filters, sortConfig);
      return;
    }

    setLoading(true);
    try {
      const propertyIds = selectedFolders.flatMap(
        (folder) => folder.properties || []
      );
      const uniquePropertyIds = [...new Set(propertyIds)];

      if (uniquePropertyIds.length === 0) {
        setFavoriteProperties([]);
        setDisplayData([]);
        setTotalItems(0);
        setIsFavoritesSelected(true);
        return;
      }

      const favoritePropertiesData = await fetchPropertiesByUIDs(uniquePropertyIds);
      setFavoriteProperties(favoritePropertiesData);
      const paginatedFavorites = paginateProperties(
        favoritePropertiesData,
        1,
        itemsPerPage
      );
      setDisplayData(paginatedFavorites);
      setTotalItems(favoritePropertiesData.length);
      setCurrentPage(1);
      setIsFavoritesSelected(true);
    } catch (err) {
      console.error("Error fetching favorite properties:", err);
      setError(t("failedToFetchFavorites"));
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const newSortConfig = { key, direction };
    setSortConfig(newSortConfig);
    setCurrentPage(1);
    updateURL(filters, 1, newSortConfig);

    if (isFavoritesSelected) {
      const sortedFavorites = [...favoriteProperties].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      setFavoriteProperties(sortedFavorites);
    }
  };

  const handlePropertyDetails = async (item) => {
    try {
      // Start fetching the data immediately
      const expandedDataPromise = fetchExpandedData(item.uid);

      // Show the modal immediately with a loading state
      setIsModalOpen(true);
      setLoadingDetails(true);

      // Now wait for the data
      const expandedData = await expandedDataPromise;

      setExpandedData(expandedData);
      // Handle coordinates from expanded data
      if (expandedData.overview?.geometry?.coordinates) {
        const [lng, lat] = expandedData.overview.geometry.coordinates;
        setSelectedCoordinates({ lat, lng });
      } else if (expandedData.overview?.coordinates) {
        const [lng, lat] = expandedData.overview.coordinates;
        setSelectedCoordinates({ lat, lng });
      }
      setActiveMap("Google");
    } catch (err) {
      console.error(t("failedToFetchExpandedData"), err);
      setToast({ message: t("failedToFetchDetails"), type: "error" });
      handleModalClose();
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleRowClick = async (relativeIndex) => {
    const item = displayData[relativeIndex];
    if (item) {
      // Start loading details immediately
      handlePropertyDetails(item);

      // Track the view in the background
      trackPropertyView(item.uid, "quick")
        .then(() => {
          setViewedItems((prev) => new Set([...prev, item.uid]));
        })
        .catch((err) => {
          console.error("Error tracking view:", err);
          if (err.message.includes("upgrade")) {
            // If credit limit is reached, show the modal and force navigation on any interaction
            setViewLimitMessage(err.message);
            setViewLimitModalOpen(true);

            // Set a flag to indicate credit limit was reached
            setCreditLimitReached(true);
            return;
          }
          setToast({
            message: err.message || t("failedToTrackView"),
            type: "error",
          });
        });
    } else {
      console.warn("Clicked item is undefined:", relativeIndex);
      setToast({ message: t("invalidProperty"), type: "error" });
    }
  };

  // Add state for tracking credit limit
  const [creditLimitReached, setCreditLimitReached] = useState(false);

  // Modal handling functions
  const handleModalOpen = () => {
    setIsAnyModalOpen(true);
  };

  // Update modal close handler to check for credit limit
  const handleModalClose = () => {
    if (creditLimitReached) {
      // If credits are exhausted, force navigation to pricing
      navigate("/home/wallet/plans");
      return;
    }

    setIsAnyModalOpen(false);
    setIsModalOpen(false);
    setExpandedData(null);
    setSelectedCoordinates(null);
    setTabIndex(0);
    if (isSharedProperty) {
      // Clear the property parameter from URL when closing a shared property
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("property");
      navigate(`?${searchParams.toString()}`);
      setIsSharedProperty(false);
    }
  };

  // Update NotesModal close handler
  const handleNotesModalClose = () => {
    handleModalClose();
    setIsNotesModalOpen(false);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage < 1 || newPage > totalPages || loading) return;

      updateURL(filters, newPage, sortConfig);
      setCurrentPage(newPage);

      fetchPageData(newPage, filters, sortConfig);
    },
    [totalPages, loading, filters, sortConfig, fetchPageData]
  );

  const headers = [
    { key: "account_nbr", label: t("propertyIdentifier") },
    { key: "city", label: t("city") },
    { key: "county", label: t("county") },
    { key: "state", label: t("state") },
    { key: "zipCode", label: t("zipCode") },
    { key: "minimum_bid", label: t("minimumBid"), format: "USD" },
    { key: "value", label: t("adjudgedValue"), format: "USD" },
    {
      key: "status",
      label: t("status"),
      format: (value) => t(value.toLowerCase().replace(/\s+/g, "")),
    },
    {
      key: "sale_type",
      label: t("saleType"),
      format: (value) => t(value.toLowerCase().replace(/\s+/g, "")),
    },
  ];

  const handleFetchAllNotes = async () => {
    await fetchAllUserNotes();
    setIsNotesModalOpen(true);
    setWasNotesModalOpen(true);
  };

  const handleViewPropertyFromNotes = async (propertyId, tabIndex = 0) => {
    console.log(
      "handleViewPropertyFromNotes called with:",
      propertyId,
      tabIndex
    );

    setLoadingDetails(true);
    try {
      console.log("Fetching expanded data...");
      const expandedData = await fetchExpandedData(propertyId);
      console.log("Expanded data received:", expandedData);

      // Check if we got valid data back
      if (!expandedData || !expandedData.overview) {
        throw new Error(t("failedToFetchDetails"));
      }

      // First set the data
      setExpandedData(expandedData);

      // Then set coordinates if available
      if (expandedData.overview?.geometry?.coordinates) {
        setSelectedCoordinates({
          lat: expandedData.overview.geometry.coordinates[1],
          lng: expandedData.overview.geometry.coordinates[0],
        });
      }

      // Set other states
      setActiveMap("Google");
      setTabIndex(tabIndex);
      setSelectedTabIndex(tabIndex);

      // Close notes modal
      setIsNotesModalOpen(false);
      setWasNotesModalOpen(true);

      // Finally open the details modal
      setIsModalOpen(true);
    } catch (err) {
      console.error("Error in handleViewPropertyFromNotes:", err);
      setToast({ message: t("failedToFetchDetails"), type: "error" });
    } finally {
      setLoadingDetails(false);
    }
  };

  const hasFavorites = folders?.some((folder) => folder.properties?.length > 0);

  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleViewPricingPlans = () => {
    navigate("/home/wallet/plans");
    setViewLimitModalOpen(false);
    // Reset the credit limit reached state
    setCreditLimitReached(false);
    // Close the property details modal
    handleModalClose();
  };

  useEffect(() => {
    if (isDropupOpen) {
      fetchTimeframeStats();
    }
  }, [isDropupOpen]);

  return (
    <BuyContent
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {loading && !initialFetchDone ? (
        <LoadingOverlay messageLayers={loadingMessageLayers} />
      ) : (
        <>
          <BuyLayout className={isSharedProperty ? "shared-property" : ""}>
            {!isSharedProperty && (
              <FilterArea>
                <ModernFilterSystem
                  onApplyFilters={handleApplyFilters}
                  initialFilters={currentFilters}
                  onSelectFavorites={handleFavoriteSelection}
                  onFetchNotes={handleFetchAllNotes}
                  hasFavorites={hasFavorites}
                  isOpen={isFilterOpen}
                  setIsOpen={setIsFilterOpen}
                />
              </FilterArea>
            )}

            <ResultsArea className={isSharedProperty ? "full-width" : ""}>
              <ResultsContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <DataTableWrapper>
                  <DataTable
                    headers={headers}
                    items={
                      isViewedPropertiesMode ? viewedProperties : displayData
                    }
                    sortConfig={sortConfig}
                    onSort={handleSort}
                    onRowClick={handleRowClick}
                    loading={loading && !initialFetchDone}
                    viewedItems={viewedItems}
                    isViewedPropertiesMode={isViewedPropertiesMode}
                    key={`${currentPage}-${sortConfig.key}-${sortConfig.direction}`}
                  />
                </DataTableWrapper>
              </ResultsContainer>
              <BottomControlsWrapper>
                <LeftControls>
                  <ViewsDisplay
                    timeframes={timeframes}
                    selectedTimeframe={selectedTimeframe}
                    timeframeStats={timeframeStats}
                    onTimeframeChange={handleTimeframeChange}
                    isDropupOpen={isDropupOpen}
                    setIsDropupOpen={setIsDropupOpen}
                    walletData={walletData}
                    isViewedPropertiesMode={isViewedPropertiesMode}
                    onExitViewedPropertiesMode={exitViewedPropertiesMode}
                  />
                  {isViewedPropertiesMode && (
                    <Tippy
                      content={t("backToSearch")}
                      placement="top"
                      animation="shift-away"
                      arrow={true}
                      duration={[300, 250]}
                      delay={[400, 0]}
                      theme="modern"
                    >
                      <ViewedPropertiesBackButton
                        onClick={exitViewedPropertiesMode}
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M19 12H5M12 19l-7-7 7-7" />
                        </svg>
                      </ViewedPropertiesBackButton>
                    </Tippy>
                  )}
                </LeftControls>

                <CenterControls>
                  <PaginationWrapper>
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                    <PageInfo>
                      <PageNumber
                        key={`page-${currentPage}`}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.15 }}
                      >
                        {currentPage}
                      </PageNumber>
                      <PageDivider>/</PageDivider>
                      <TotalPages
                        key={`total-${totalPages}`}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.15 }}
                      >
                        {totalPages}
                      </TotalPages>
                    </PageInfo>
                  </PaginationWrapper>
                </CenterControls>

                <RightSpacer />
              </BottomControlsWrapper>
            </ResultsArea>
          </BuyLayout>
        </>
      )}

      <DetailedPropertyInfo
        isOpen={isModalOpen || isSharedProperty}
        onOpen={handleModalOpen}
        onClose={handleModalClose}
        mapCenter={selectedCoordinates}
        activeMap={activeMap}
        expandedData={expandedData}
        setExpandedData={setExpandedData}
        setActiveMap={setActiveMap}
        tabIndex={tabIndex}
        setTabIndex={handleTabChange}
        creditLimitReached={creditLimitReached}
      >
        {expandedData && (
          <PropertyDetails
            property={expandedData}
            tabIndex={tabIndex}
            setTabIndex={handleTabChange}
          />
        )}
      </DetailedPropertyInfo>
      <NotesModal
        isOpen={isNotesModalOpen}
        onOpen={handleModalOpen}
        onClose={handleNotesModalClose}
        onViewProperty={(propertyId, tabIndex) => {
          console.log(
            "onViewProperty called from NotesModal:",
            propertyId,
            tabIndex
          );
          handleViewPropertyFromNotes(propertyId, tabIndex);
        }}
      />
      {!isAnyModalOpen && (!isSharedProperty || !expandedData) && (
        <FloatingActionButtons
          onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
          onFavoritesClick={handleFavoriteSelection}
          onNotesClick={handleFetchAllNotes}
          onApplyFilters={handleApplyFilters}
          isFilterOpen={isFilterOpen}
          isNotesOpen={isNotesModalOpen}
          hasFavorites={hasFavorites}
          folders={folders}
          isDetailedOpen={isModalOpen}
        />
      )}
      {(loading && !initialFetchDone) || loadingDetails ? (
        <LoadingOverlay messageLayers={loadingMessageLayers} />
      ) : null}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={3000}
          onClose={() => setToast(null)}
        />
      )}
      <ViewLimitModal
        isOpen={viewLimitModalOpen}
        message={viewLimitMessage}
        onConfirm={handleViewPricingPlans}
      />
      {isViewedPropertiesMode && (
        <ViewedPropertiesHeader>
          <h2>
            {t("viewedProperties.title")} -{" "}
            {timeframes.find((t) => t.id === selectedTimeframe)?.label}
          </h2>
          <button onClick={exitViewedPropertiesMode}>{t("back")}</button>
        </ViewedPropertiesHeader>
      )}
    </BuyContent>
  );
};

export default Buy;
