import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Search as SearchIcon, X as CloseIcon } from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import debounce from "lodash/debounce";
import { searchUsers } from "./friendsApi";
import { useTheme } from "../../context/ThemeContext";
import FriendSearchResults from "./FriendSearchResults";

const SearchModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const SearchContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border-radius: 24px;
  padding: 24px;
  width: 90%;
  max-width: 440px;
  min-width: 280px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => `
    0 0 0 1px ${theme.colors.glass.border},
    ${theme.colors.shadow},
    ${theme.colors.glow}
  `};
  position: relative;
  overflow: hidden;
`;

const SearchHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-size: 1.8rem;
    background: linear-gradient(
      45deg,
      ${({ theme }) => theme.colors.accent.purple.base},
      ${({ theme }) => theme.colors.accent.purple.dark}
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0;
  }
`;

const CloseButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
    color: ${({ theme }) => theme.colors.text};
  }
`;

const SearchInputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  .input-container {
    position: relative;
    width: 80%;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  padding: 0 48px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surface};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
    text-overflow: ellipsis;
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.accent.purple.base};
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.accent.error.base};
  font-size: 14px;
  padding: 8px 12px;
  margin-bottom: 12px;
  background: ${({ theme }) => theme.colors.accent.error.light};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.accent.error.base};
`;

const FriendsSearch = ({ show, onClose, onFriendAdd }) => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (!term || term.length < 2) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      setError(null);

      try {
        const results = await searchUsers(term);

        setSearchResults(results);
      } catch (error) {
        console.error("Search error:", error);
        setError(error.message);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length >= 2) {
      debouncedSearch(value);
    } else {
      setSearchResults([]);
    }
  };

  // Clear search on close
  const handleClose = () => {
    setSearchTerm("");
    setSearchResults([]);
    setError(null);
    onClose();
  };

  if (!show) return null;

  return (
    <AnimatePresence>
      <SearchModal
        theme={theme}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleClose}
      >
        <SearchContent
          theme={theme}
          onClick={(e) => e.stopPropagation()}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", damping: 25, stiffness: 500 }}
        >
          <SearchHeader theme={theme}>
            <h2>Add Friend</h2>
            <CloseButton
              theme={theme}
              onClick={handleClose}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <CloseIcon size={24} />
            </CloseButton>
          </SearchHeader>

          <SearchInputWrapper>
            <div className="input-container">
              <SearchIconWrapper theme={theme}>
                <SearchIcon size={20} />
              </SearchIconWrapper>
              <SearchInput
                theme={theme}
                type="text"
                placeholder="Search users by email..."
                value={searchTerm}
                onChange={handleSearchChange}
                autoFocus
              />
            </div>
          </SearchInputWrapper>

          {error && <ErrorMessage theme={theme}>{error}</ErrorMessage>}

          <FriendSearchResults
            results={searchResults}
            isSearching={isSearching}
            searchTerm={searchTerm}
            onFriendAdd={(friend) => {
              onFriendAdd(friend);
              handleClose();
            }}
          />
        </SearchContent>
      </SearchModal>
    </AnimatePresence>
  );
};

export default FriendsSearch;
