// src/pages/LandingPage.js

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import SignInModal from "../modals/SignInModal";
import SignUpModal from "../modals/SignUpModal";
import PolicyModal from "../modals/PolicyModal";
import LoadingOverlay from "../common/LoadingOverlay";
import BackgroundAnimation from "../common/BackgroundAnimation";
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../utils/policyContent";
import { Globe, LogOut, LogIn, UserPlus, Menu, X } from "react-feather";
import SwarmLogo, {
  SwarmContainer,
  BubbleSkin,
  Highlight,
  EnergyRing,
} from "../common/SwarmLogo";
import IntroSection from "../common/IntroSection";
import ProblemSection from "../common/ProblemSection";
import SolutionSection from "../common/SolutionSection";
import FeaturesSection from "../common/FeaturesSection";
import JourneySection from "../common/JourneySection";
import CTASection from "../common/CTASection";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const LandingPageContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: transparent;
  justify-content: space-between;
  z-index: 1;
`;

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  will-change: transform;
  transform-style: preserve-3d;
  perspective: 1000px;

  backface-visibility: hidden;
  z-index: 2;
  &::before {
    content: "";
    position: fixed;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    content: "";
    position: fixed;
    inset: 0;
    background: radial-gradient(
      circle at bottom left,
      rgba(136, 83, 251, 0.05),
      transparent 70%
    );
    pointer-events: none;
    z-index: 1;
  }
`;

const Navbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  ${SwarmContainer} {
    width: 48px;
    height: 48px;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    ${SwarmContainer} {
      transform: scale(1.05);
    }

    ${BubbleSkin} {
      scale: 1.02;
      opacity: 0.9;
    }

    ${Highlight} {
      opacity: 0.5;
      scale: 1.1;
    }

    ${EnergyRing} {
      scale: 1.1;
      opacity: 1;
    }
  }
`;

const LogoText = styled.span`
  position: relative;
  background: linear-gradient(45deg, #3498db, #8853fb, #3498db, #8853fb);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: waveAnimation 8s ease-in-out infinite;

  @keyframes waveAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const IconButton = styled.button`
  width: 140px;
  height: 44px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.12),
    rgba(96, 57, 176, 0.12)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(10px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.05)
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.15)
    );
    border-color: rgba(136, 83, 251, 0.3);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
    margin: 4px 0;
  }
`;

const CTABUTTON = styled(IconButton)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.2),
    rgba(96, 57, 176, 0.2)
  );
  border-color: rgba(136, 83, 251, 0.3);
  font-weight: 600;
  color: white;

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.25),
      rgba(96, 57, 176, 0.25)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 12px 36px rgba(136, 83, 251, 0.25),
      0 0 0 1px rgba(136, 83, 251, 0.3);
  }
`;

const LandingMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

const LandingFooter = styled.footer`
  flex-shrink: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.98)
  );
  backdrop-filter: blur(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }
`;

const CopyrightText = styled.p`
  color: rgba(255, 255, 255, 0.95);
  margin: 0;
`;

const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 1rem;

  a {
    color: rgba(136, 83, 251, 0.8);
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;

    &:hover {
      color: #8853fb;
    }
  }
`;

const WindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => (props.$isVisible ? "1000" : "-1")};
  pointer-events: ${(props) => (props.$isMinimized ? "none" : "auto")};
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
  transition: opacity 0.3s ease-out, z-index 0.3s step-end;
`;

const pageVariants = {
  initial: { opacity: 0, scale: 0.95 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1],
    },
  },
};

const MobileMenuButton = styled(motion.button)`
  display: none;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  isolation: isolate;
  overflow: hidden;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at center,
      rgba(136, 83, 251, 0.1),
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(136, 83, 251, 0.2),
      0 0 20px rgba(136, 83, 251, 0.15);

    &::before {
      opacity: 1;
    }

    svg {
      color: rgba(136, 83, 251, 1);
      filter: drop-shadow(0 0 8px rgba(136, 83, 251, 0.5));
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 24px;
    height: 24px;
    color: rgba(136, 83, 251, 0.8);
    filter: drop-shadow(0 0 4px rgba(136, 83, 251, 0.3));
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

const MobileMenuContent = styled(motion.div)`
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const NavContent = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background: linear-gradient(
      165deg,
      rgba(28, 28, 35, 0.98),
      rgba(18, 18, 24, 0.98)
    );
    backdrop-filter: blur(40px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1), 0 0 100px rgba(136, 83, 251, 0.1);
    padding: 0;
    overflow: hidden;
    transform-origin: top;
  }
`;

const LanguageButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LanguageButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover,
  &.active {
    color: rgba(136, 83, 251, 0.8);
    background: rgba(136, 83, 251, 0.1);
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const AnimationContainer = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NetworkBackground = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: radial-gradient(
    circle at 0% 0%,
    rgba(136, 83, 251, 0.05),
    transparent 50%
  );
  pointer-events: none;
  overflow: hidden;
`;

const NetworkCanvas = styled.canvas`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const NetworkAnimation = () => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let nodes = [];
    let connections = [];

    const resize = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);
    };

    class Node {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.vx = (Math.random() - 0.5) * 0.2;
        this.vy = (Math.random() - 0.5) * 0.2;
        this.radius = Math.random() * 1.5 + 1;
        this.lastUpdate = Date.now();
        this.pulsePhase = Math.random() * Math.PI * 2;
      }

      update(width, height) {
        const now = Date.now();
        const delta = (now - this.lastUpdate) / 16;
        this.lastUpdate = now;

        this.x += this.vx * delta;
        this.y += this.vy * delta;

        if (this.x < 0 || this.x > width) this.vx *= -1;
        if (this.y < 0 || this.y > height) this.vy *= -1;

        this.pulsePhase += 0.02;
        this.currentRadius =
          this.radius * (1 + 0.2 * Math.sin(this.pulsePhase));
      }

      draw(ctx) {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.currentRadius, 0, Math.PI * 2);
        ctx.fillStyle = "rgba(136, 83, 251, 0.4)";
        ctx.fill();

        ctx.shadowColor = "rgba(136, 83, 251, 0.3)";
        ctx.shadowBlur = 5;
      }
    }

    class Connection {
      constructor(nodeA, nodeB) {
        this.nodeA = nodeA;
        this.nodeB = nodeB;
        this.progress = 0;
        this.speed = 0.01 + Math.random() * 0.01;
        this.active = true;
        this.maxDistance = 250;
      }

      update() {
        this.progress += this.speed;
        if (this.progress >= 1) {
          this.active = false;
        }
      }

      draw(ctx) {
        const dx = this.nodeB.x - this.nodeA.x;
        const dy = this.nodeB.y - this.nodeA.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < this.maxDistance) {
          const progress = Math.min(this.progress, 1);
          const x = this.nodeA.x + dx * progress;
          const y = this.nodeA.y + dy * progress;

          ctx.beginPath();
          ctx.moveTo(this.nodeA.x, this.nodeA.y);
          ctx.lineTo(x, y);

          const alpha = (1 - progress) * 0.5;
          ctx.strokeStyle = `rgba(136, 83, 251, ${alpha})`;
          ctx.lineWidth = 0.5;
          ctx.stroke();

          ctx.beginPath();
          ctx.moveTo(this.nodeA.x, this.nodeA.y);
          ctx.lineTo(x, y);
          ctx.strokeStyle = `rgba(136, 83, 251, ${alpha * 0.3})`;
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }
    }

    const init = () => {
      resize();
      nodes = [];
      connections = [];

      const nodeCount = Math.floor((canvas.width * canvas.height) / 15000);
      for (let i = 0; i < nodeCount; i++) {
        nodes.push(
          new Node(Math.random() * canvas.width, Math.random() * canvas.height)
        );
      }
    };

    const createConnections = () => {
      if (Math.random() < 0.3) {
        const nodeA = nodes[Math.floor(Math.random() * nodes.length)];
        const nodeB = nodes[Math.floor(Math.random() * nodes.length)];
        if (nodeA !== nodeB) {
          connections.push(new Connection(nodeA, nodeB));
        }
      }

      if (connections.length < nodes.length * 2) {
        for (let i = 0; i < 3; i++) {
          const nodeA = nodes[Math.floor(Math.random() * nodes.length)];
          const nodeB = nodes[Math.floor(Math.random() * nodes.length)];
          if (nodeA !== nodeB) {
            connections.push(new Connection(nodeA, nodeB));
          }
        }
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      nodes.forEach((nodeA, i) => {
        nodes.slice(i + 1).forEach((nodeB) => {
          const dx = nodeB.x - nodeA.x;
          const dy = nodeB.y - nodeA.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 200) {
            ctx.beginPath();
            ctx.moveTo(nodeA.x, nodeA.y);
            ctx.lineTo(nodeB.x, nodeB.y);
            const alpha = Math.max(0, (1 - distance / 200) * 0.15);
            ctx.strokeStyle = `rgba(136, 83, 251, ${alpha})`;
            ctx.lineWidth = 0.5;
            ctx.stroke();
          }
        });
      });

      nodes.forEach((node) => {
        node.update(canvas.width, canvas.height);
        node.draw(ctx);
      });

      createConnections();

      connections = connections.filter((conn) => conn.active);
      connections.forEach((conn) => {
        conn.update();
        conn.draw(ctx);
      });

      animationRef.current = requestAnimationFrame(animate);
    };

    window.addEventListener("resize", init);
    init();
    animate();

    return () => {
      window.removeEventListener("resize", init);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <NetworkBackground
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <NetworkCanvas ref={canvasRef} />
    </NetworkBackground>
  );
};

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeModal, setActiveModal] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState(null);
  const [policyContent, setPolicyContent] = useState("");
  const [policyTitle, setPolicyTitle] = useState("");

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    if (!scrollRef.current) return;

    const locomotiveScroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      multiplier: 0.8,
      class: "is-revealed",
      lerp: 0.05,
      reloadOnContextChange: true,
      touchMultiplier: 2.5,
      smoothMobile: true,
      resetNativeScroll: true,
      smartphone: {
        smooth: true,
        multiplier: 0.8,
        lerp: 0.05,
        breakpoint: 767,
      },
      tablet: {
        smooth: true,
        multiplier: 0.8,
        lerp: 0.05,
        breakpoint: 1024,
      },
      getDirection: true,
      getSpeed: true,
      inertia: 0.8,
      gestureDirection: "vertical",
    });

    setScroll(locomotiveScroll);

    let resizeTimer;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        locomotiveScroll.update();
      }, 250);
    };

    window.addEventListener("resize", handleResize);

    window.addEventListener("load", () => {
      locomotiveScroll.update();
    });

    const images = document.querySelectorAll("img");
    let loadedImages = 0;
    const totalImages = images.length;

    const updateOnAllImagesLoaded = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        locomotiveScroll.update();
      }
    };

    images.forEach((img) => {
      if (img.complete) {
        updateOnAllImagesLoaded();
      } else {
        img.addEventListener("load", updateOnAllImagesLoaded);
      }
    });

    document.fonts.ready.then(() => {
      locomotiveScroll.update();
    });

    const observer = new MutationObserver(() => {
      locomotiveScroll.update();
    });

    observer.observe(scrollRef.current, {
      childList: true,
      subtree: true,
    });

    const sections = document.querySelectorAll("[data-scroll-section]");
    sections.forEach((section) => {
      gsap.fromTo(
        section,
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out",
          scrollTrigger: {
            trigger: section,
            start: "top 85%",
            end: "top 15%",
            toggleActions: "play none none reverse",
            markers: false,
          },
        }
      );
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(resizeTimer);
      observer.disconnect();
      locomotiveScroll.destroy();
    };
  }, []);

  const checkAuthentication = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/verify`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        setIsAuthenticated(true);
        navigate("/home/buy");
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Failed to verify authentication:", error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const modalParam = params.get("modal");
    const hash = location.hash.slice(1);

    if (modalParam === "privacy-policy" || hash === "privacy-policy") {
      setActiveModal("privacyPolicy");
    } else if (
      modalParam === "terms-of-service" ||
      hash === "terms-of-service"
    ) {
      setActiveModal("termsOfService");
    } else {
      setActiveModal(null);
    }
  }, [location]);

  if (isLoading) return <LoadingOverlay />;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSignOut = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setIsAuthenticated(false);
        } else {
          console.error("Logout failed");
        }
      })
      .catch((err) => console.error("Sign out error:", err));
  };

  const handleOpenModal = (modalType) => {
    if (modalType === "privacyPolicy" || modalType === "termsOfService") {
      const queryParam = modalType.replace(/([A-Z])/g, "-$1").toLowerCase();
      navigate(`?modal=${queryParam}`);
    }
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    navigate("");
    setActiveModal(null);
  };

  const handleSignInSuccess = () => {
    setIsAuthenticated(true);
    handleCloseModal();
    navigate("/home/buy");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault();
    handleOpenModal("privacyPolicy");
  };

  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    handleOpenModal("termsOfService");
  };

  return (
    <>
      <LandingPageContainer
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Navbar>
            <Logo to="#">
              <SwarmLogo size="48px" />
              <LogoText>{t("companyName")}</LogoText>
            </Logo>

            <MobileMenuButton
              onClick={toggleMobileMenu}
              className={isMobileMenuOpen ? "active" : ""}
              whileTap={{ scale: 0.95 }}
            >
              <AnimatePresence mode="wait">
                {isMobileMenuOpen ? (
                  <motion.div
                    key="close"
                    initial={{ rotate: -45, opacity: 0, scale: 0.8 }}
                    animate={{ rotate: 0, opacity: 1, scale: 1 }}
                    exit={{ rotate: 45, opacity: 0, scale: 0.8 }}
                    transition={{
                      duration: 0.3,
                      ease: [0.16, 1, 0.3, 1],
                    }}
                  >
                    <X />
                  </motion.div>
                ) : (
                  <motion.div
                    key="menu"
                    initial={{ rotate: 45, opacity: 0, scale: 0.8 }}
                    animate={{ rotate: 0, opacity: 1, scale: 1 }}
                    exit={{ rotate: -45, opacity: 0, scale: 0.8 }}
                    transition={{
                      duration: 0.3,
                      ease: [0.16, 1, 0.3, 1],
                    }}
                  >
                    <Menu />
                  </motion.div>
                )}
              </AnimatePresence>
            </MobileMenuButton>

            <AnimatePresence>
              {(isMobileMenuOpen || window.innerWidth > 768) && (
                <NavContent
                  initial={{ height: 0, opacity: 0, scale: 0.98 }}
                  animate={{
                    height: "auto",
                    opacity: 1,
                    scale: 1,
                    transition: {
                      height: {
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                      opacity: {
                        duration: 0.3,
                        delay: 0.1,
                      },
                      scale: {
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                    },
                  }}
                  exit={{
                    height: 0,
                    opacity: 0,
                    scale: 0.98,
                    transition: {
                      height: {
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.3 },
                    },
                  }}
                >
                  <MobileMenuContent
                    initial={{ y: -20, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.2,
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                    }}
                    exit={{
                      y: -20,
                      opacity: 0,
                      transition: {
                        duration: 0.3,
                        ease: [0.16, 1, 0.3, 1],
                      },
                    }}
                  >
                    <Nav>
                      {isAuthenticated ? (
                        <IconButton onClick={handleSignOut}>
                          <LogOut size={18} /> {t("signout")}
                        </IconButton>
                      ) : (
                        <>
                          <IconButton onClick={() => handleOpenModal("signIn")}>
                            <LogIn size={18} /> {t("signin")}
                          </IconButton>
                          <CTABUTTON onClick={() => handleOpenModal("signUp")}>
                            <UserPlus size={18} /> {t("signup")}
                          </CTABUTTON>
                        </>
                      )}
                    </Nav>
                  </MobileMenuContent>
                </NavContent>
              )}
            </AnimatePresence>
          </Navbar>
        </motion.div>

        <ScrollContainer data-scroll-container ref={scrollRef}>
          <LandingMain>
            <IntroSection />
            <ProblemSection />
            <SolutionSection />
            <FeaturesSection />
            <JourneySection />
            <CTASection onGetStarted={() => handleOpenModal("signUp")} />
          </LandingMain>
        </ScrollContainer>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <LandingFooter>
            <CopyrightText>
              &copy; {new Date().getFullYear()} DeedFinders
            </CopyrightText>

            <FooterLinks>
              <Link to="/pricing">Pricing</Link>
              <a href="#" onClick={handlePrivacyPolicyClick}>
                Privacy Policy
              </a>
              <a href="#" onClick={handleTermsOfServiceClick}>
                Terms of Service
              </a>
            </FooterLinks>

            <LanguageButtons>
              <LanguageButton
                onClick={() => changeLanguage("en")}
                className={i18n.language === "en" ? "active" : ""}
              >
                <Globe size={12} />
                EN
              </LanguageButton>
              <LanguageButton
                onClick={() => changeLanguage("ptbr")}
                className={i18n.language === "ptbr" ? "active" : ""}
              >
                <Globe size={12} />
                PT
              </LanguageButton>
            </LanguageButtons>
          </LandingFooter>
        </motion.div>
        <SignInModal
          open={activeModal === "signIn"}
          onClose={handleCloseModal}
          onSuccess={handleSignInSuccess}
          onSwitchToSignUp={() => handleOpenModal("signUp")}
          onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
          onOpenTermsOfService={() => handleOpenModal("termsOfService")}
        />
        <SignUpModal
          open={activeModal === "signUp"}
          onClose={handleCloseModal}
          onSwitchToSignIn={() => {
            handleCloseModal();
            handleOpenModal("signIn");
          }}
          onOpenPrivacyPolicy={() => {
            setPolicyContent(privacyPolicyContent);
            setPolicyTitle("Privacy Policy");
            handleOpenModal("privacyPolicy");
          }}
          onOpenTermsOfService={() => {
            setPolicyContent(termsOfServiceContent);
            setPolicyTitle("Terms of Service");
            handleOpenModal("termsOfService");
          }}
        />
        <PolicyModal
          open={
            activeModal === "privacyPolicy" || activeModal === "termsOfService"
          }
          onClose={handleCloseModal}
          title={
            activeModal === "privacyPolicy"
              ? "Privacy Policy"
              : "Terms of Service"
          }
          content={
            activeModal === "privacyPolicy"
              ? privacyPolicyContent
              : termsOfServiceContent
          }
        />

        {/* <NetworkAnimation /> */}
      </LandingPageContainer>
    </>
  );
};

export default LandingPage;
