import React, { useState, useEffect } from "react";
import { ToastProvider } from "./context/ToastContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import HomePage from "./components/pages/HomePage";
import { FavoritesProvider } from "./context/FavoritesContext";
import PrivateRoute from "./components/common/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { NotesProvider } from "./context/NotesContext";
import { WalletProvider } from "./context/WalletContext";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import "./assets/styles/App.css";
import EmailVerification from "./components/EmailVerification";
import { initI18n } from "./i18n";

import DevelopmentWarning from "./components/common/DevelopmentWarning";
import { PublicBuyProvider } from "./context/PublicBuyContext";
import { LinkReportProvider } from "./context/LinkReportContext";
import NotFoundPage from "./components/pages/NotFoundPage";
import ChatPage from "./components/pages/ChatPage";
import FriendsPage from "./components/pages/FriendsPage";
import PublicPricingPage from "./components/pages/PublicPricingPage";
import GlobalStyles from "./styles/GlobalStyles";
import { ThemeProvider } from "./context/ThemeContext";

function App() {
  const [i18nInitialized, setI18nInitialized] = useState(false);

  useEffect(() => {
    initI18n().then(() => {
      setI18nInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (i18nInitialized) {
      const loadingScreen = document.getElementById("loading-screen");
      if (loadingScreen) {
        loadingScreen.style.opacity = "0";
        loadingScreen.style.transition = "opacity 0.5s ease-out";
        setTimeout(() => {
          loadingScreen.style.display = "none";
        }, 500);
      }
    }
  }, [i18nInitialized]);

  if (!i18nInitialized) {
    return null;
  }

  return (
    <LinkReportProvider>
      <AuthProvider>
        <ToastProvider>
          <PublicBuyProvider>
            <GlobalStyles />
            <Router>
              <ThemeProvider>
                <DevelopmentWarning />
                <div className="App" data-theme="dark">
                  <Routes>
                    <Route path="/" element={<LandingPage />}>
                      <Route path="#privacy-policy" element={<LandingPage />} />
                      <Route
                        path="#terms-of-service"
                        element={<LandingPage />}
                      />
                    </Route>
                    <Route path="/pricing" element={<PublicPricingPage />}>
                      <Route
                        path="#privacy-policy"
                        element={<PublicPricingPage />}
                      />
                      <Route
                        path="#terms-of-service"
                        element={<PublicPricingPage />}
                      />
                    </Route>
                    <Route
                      path="/home/*"
                      element={
                        <PrivateRoute>
                          <NotesProvider>
                            <FavoritesProvider>
                              <WalletProvider>
                                <HomePage />
                              </WalletProvider>
                            </FavoritesProvider>
                          </NotesProvider>
                        </PrivateRoute>
                      }
                    >
                      <Route path="chat" element={<ChatPage />} />
                      <Route path="friends" element={<FriendsPage />} />
                    </Route>
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/reset-password/:token"
                      element={<ResetPassword />}
                    />
                    <Route
                      path="/verify-email/:token"
                      element={<EmailVerification />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </div>
              </ThemeProvider>
            </Router>
            <div id="modal-root"></div>
            <div id="limit-modal-root"></div>
            <div id="toast-root"></div>
          </PublicBuyProvider>
        </ToastProvider>
      </AuthProvider>
    </LinkReportProvider>
  );
}

export default App;
