import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Filter, Heart, FileText, Check, MessageCircle } from "react-feather";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { useTranslation } from "react-i18next";
import MiniChat from "../../common/MiniChat";
import { io } from "socket.io-client";
import { AuthContext } from "../../../context/AuthContext";

// Styled Components with Jony Ive-inspired design
const FloatingButtonsContainer = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10001;
  pointer-events: none;
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  transform: ${(props) =>
    props.$isDetailedOpen ? "translateX(calc(100% + 30px))" : "translateX(0)"};

  & > * {
    pointer-events: auto;
  }
`;

const FloatingButton = styled(motion.button)`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.glass.background};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  backdrop-filter: blur(20px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  isolation: isolate;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  svg {
    color: ${({ theme }) => theme.colors.text};
    width: 22px;
    height: 22px;
    stroke-width: 2px;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    position: relative;
    z-index: 2;
  }

  &:hover {
    transform: translateY(-2px);
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};

    &::before {
      opacity: 1;
    }

    svg {
      color: ${({ theme }) => theme.colors.accent.purple.base};
      transform: scale(1.1);
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &.active {
    background: ${({ theme }) => theme.colors.accent.purple.base};
    border: 1px solid ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => `
      0 8px 32px ${theme.colors.accent.purple.transparent20},
      0 0 0 1px ${theme.colors.accent.purple.base}
    `};

    &::before {
      opacity: 1;
      background: radial-gradient(
        circle at top right,
        rgba(255, 255, 255, 0.2),
        transparent 70%
      );
    }

    &::after {
      content: '';
      position: absolute;
      inset: -1px;
      border-radius: 28px;
      padding: 1px;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), transparent);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }

    svg {
      color: rgba(255, 255, 255, 0.95);
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
      stroke-width: 2px;
      opacity: 0.95;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.accent.purple.dark};
      transform: translateY(-2px);
      box-shadow: ${({ theme }) => `
        0 8px 32px ${theme.colors.accent.purple.transparent30},
        0 0 0 1px ${theme.colors.accent.purple.base}
      `};

      svg {
        color: rgba(255, 255, 255, 1);
        transform: scale(1.1);
        opacity: 1;
      }
    }
  }
`;

const FavoritesDropdown = styled(motion.div)`
  position: absolute;
  right: calc(100% + 16px);
  bottom: 0;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(40px);
  border-radius: 16px;
  padding: 12px;
  width: 240px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  transform-origin: bottom right;
`;

const FolderItem = styled(motion.button)`
  width: 100%;
  padding: 12px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  text-align: left;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  justify-content: space-between;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
  }
`;

const FolderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.text};
  transition: color 0.3s ease;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.text};
    transition: color 0.3s ease;
  }

  ${FolderItem}:hover & {
    color: ${({ theme }) => theme.colors.accent.purple.base};

    svg {
      color: ${({ theme }) => theme.colors.accent.purple.base};
    }
  }
`;

const PropertyCount = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  transition: color 0.3s ease;

  ${FolderItem}:hover & {
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

const UnreadBadge = styled(motion.div)`
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 18px;
  height: 18px;
  padding: 0 6px;
  background: ${({ theme }) => theme.colors.accent.purple.base};
  color: ${({ theme }) => theme.colors.white};
  font-size: 11px;
  font-weight: 600;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const FloatingActionButtons = ({
  onFilterClick,
  onFavoritesClick,
  onNotesClick,
  isFilterOpen,
  isNotesOpen,
  hasFavorites,
  folders = [],
  isPublic = false,
  isDetailedOpen = false,
}) => {
  const { t } = useTranslation();
  const [showFavoritesDropdown, setShowFavoritesDropdown] = useState(false);
  const [isMiniChatOpen, setIsMiniChatOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const socket = useRef(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat/unread-count`,
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const { totalUnread } = await response.json();
          setUnreadCount(totalUnread);
        }
      } catch (error) {
        console.error("Error fetching unread count:", error);
      }
    };

    fetchUnreadCount();

    socket.current = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket"],
      path: "/socket.io/",
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      auth: {
        token: document.cookie,
      },
      extraHeaders: {
        Cookie: document.cookie,
      },
      autoConnect: false,
    });

    socket.current.on("connect_error", (error) => {
      console.error("Socket connection error details:", error.message);
    });

    socket.current.connect();

    socket.current.on("connect", () => {
      if (user?._id) {
        socket.current.emit("user connected", user._id);
      }
    });

    socket.current.on("message received", () => {
      setUnreadCount((prev) => prev + 1);
    });

    socket.current.on("update_unread_count", ({ totalUnread }) => {
      setUnreadCount(totalUnread);
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [user]);

  useEffect(() => {
    const handleSocketError = (error) => {
      console.error("Socket error:", error);
    };

    const handleSocketConnect = () => {};

    if (socket.current) {
      socket.current.on("error", handleSocketError);
      socket.current.on("connect", handleSocketConnect);

      return () => {
        socket.current.off("error", handleSocketError);
        socket.current.off("connect", handleSocketConnect);
        socket.current.disconnect();
      };
    }
  }, []);

  const handleFavoritesClick = (e) => {
    e.stopPropagation();
    if (isFilterOpen) onFilterClick();
    if (isNotesOpen) onNotesClick();
    setShowFavoritesDropdown(!showFavoritesDropdown);
  };

  const handleFolderSelect = (folder = null) => {
    if (folder) {
      onFavoritesClick([folder]);
    } else {
      onFavoritesClick(folders);
    }
    setShowFavoritesDropdown(false);
  };

  const handleNotesClick = () => {
    if (isFilterOpen) onFilterClick();
    if (showFavoritesDropdown) setShowFavoritesDropdown(false);
    onNotesClick(!isNotesOpen);
  };

  const handleFilterClick = () => {
    if (isNotesOpen) onNotesClick();
    if (showFavoritesDropdown) setShowFavoritesDropdown(false);
    onFilterClick();
  };

  React.useEffect(() => {
    const handleClickOutside = () => {
      if (showFavoritesDropdown) {
        setShowFavoritesDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showFavoritesDropdown]);

  const tippyProps = {
    animation: "shift-away",
    placement: "left",
    arrow: true,
    duration: [300, 250],
    delay: [400, 0],
    theme: "modern",
  };

  if (isPublic) {
    return (
      <FloatingButtonsContainer $isDetailedOpen={isDetailedOpen}>
        <Tippy content={t("filterProperties")} {...tippyProps}>
          <FloatingButton
            onClick={handleFilterClick}
            className={isFilterOpen ? "active" : ""}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Filter size={24} />
          </FloatingButton>
        </Tippy>
      </FloatingButtonsContainer>
    );
  }

  return (
    <FloatingButtonsContainer $isDetailedOpen={isDetailedOpen}>
      <div style={{ position: "relative" }}>
        <Tippy content={t("chat")} {...tippyProps}>
          <FloatingButton
            onClick={() => {
              setIsMiniChatOpen(!isMiniChatOpen);
              if (!isMiniChatOpen) {
                setUnreadCount(0);
              }
            }}
            className={isMiniChatOpen ? "active" : ""}
          >
            <MessageCircle size={24} />
            <AnimatePresence>
              {!isMiniChatOpen && unreadCount > 0 && (
                <UnreadBadge
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 25,
                  }}
                >
                  {unreadCount}
                </UnreadBadge>
              )}
            </AnimatePresence>
          </FloatingButton>
        </Tippy>
        <MiniChat
          isOpen={isMiniChatOpen}
          onClose={() => setIsMiniChatOpen(false)}
        />
      </div>

      <div style={{ position: "relative" }}>
        <Tippy content={t("favorites")} {...tippyProps}>
          <FloatingButton
            onClick={handleFavoritesClick}
            className={showFavoritesDropdown ? "active" : ""}
          >
            <Heart size={24} />
          </FloatingButton>
        </Tippy>

        <AnimatePresence>
          {showFavoritesDropdown && (
            <FavoritesDropdown
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              onClick={(e) => e.stopPropagation()}
            >
              <FolderItem
                onClick={() => handleFolderSelect()}
                whileHover={{ x: 4 }}
              >
                <FolderInfo>
                  <Heart size={16} />
                  {t("allFavorites")}
                </FolderInfo>
                <PropertyCount>
                  (
                  {folders.reduce(
                    (total, folder) => total + (folder.properties || []).length,
                    0
                  )}
                  )
                </PropertyCount>
              </FolderItem>
              {folders.map((folder) => (
                <FolderItem
                  key={folder.id}
                  onClick={() => handleFolderSelect(folder)}
                  whileHover={{ x: 4 }}
                >
                  <FolderInfo>
                    <Heart size={16} />
                    {folder.name}
                  </FolderInfo>
                  <PropertyCount>
                    ({(folder.properties || []).length})
                  </PropertyCount>
                </FolderItem>
              ))}
            </FavoritesDropdown>
          )}
        </AnimatePresence>
      </div>

      <Tippy content={t("notes")} {...tippyProps}>
        <FloatingButton
          onClick={handleNotesClick}
          className={isNotesOpen ? "active" : ""}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FileText size={24} />
        </FloatingButton>
      </Tippy>

      <Tippy content={t("filterProperties")} {...tippyProps}>
        <FloatingButton
          onClick={handleFilterClick}
          className={isFilterOpen ? "active" : ""}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Filter size={24} />
        </FloatingButton>
      </Tippy>
    </FloatingButtonsContainer>
  );
};

export default FloatingActionButtons;
