import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const mapOptions = {
  mapTypeId: "satellite",
  styles: [
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff", // Changes the railroad tracks to a different color
        },
        {
          weight: 1.5, // Adjusts the thickness
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#2e2b45", // Subtle terrain color to match dark theme
        },
        {
          lightness: -20,
        },
      ],
    },

    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 50,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#1b1a2e",
        },
        {
          lightness: -40,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#2d2c45",
        },
        {
          lightness: -20,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff", // Make road labels brighter for better visibility
        },
        {
          lightness: 100, // Maximize lightness for contrast on satellite view
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off", // Remove text stroke to prevent dark outlines
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#1b1a2e",
        },
        {
          lightness: -10,
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#ff0000", // Bright red color to highlight property lines
        },
        {
          weight: 2.5, // Thicker lines for better visibility
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "off", // Keep POIs minimal or off
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

const GoogleMaps = ({ mapCenter }) => {
  const [center, setCenter] = useState(mapCenter);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (mapCenter) {
      setCenter(mapCenter);
    }
  }, [mapCenter]);

  const onLoad = React.useCallback((map) => {
    if (center) {
      // Set zoom directly instead of using bounds
      map.setZoom(19);
      map.setCenter(center);
    }
  }, [center]);

  return isLoaded ? (
    <GoogleMap
      options={mapOptions}
      mapContainerStyle={containerStyle}
      center={center}
      tilt={0}
      zoom={19}
      onLoad={onLoad}
    >
      {center && <Marker position={center} zIndex={1000} />}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMaps;
