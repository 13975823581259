import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "react-feather";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

const PolicyOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const PolicyContent = styled(motion.div)`
  background: ${({ theme }) =>
    theme.isDarkMode
      ? "linear-gradient(165deg, rgba(28, 28, 35, 0.95), rgba(18, 18, 24, 0.98))"
      : "linear-gradient(165deg, rgba(255, 255, 255, 0.95), rgba(252, 253, 255, 0.98))"};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  width: 95%;
  max-width: 800px;
  max-height: 85vh;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode
      ? "0 8px 32px rgba(0, 0, 0, 0.4), 0 2px 8px rgba(0, 0, 0, 0.2)"
      : "0 8px 32px rgba(136, 83, 251, 0.1), 0 2px 8px rgba(136, 83, 251, 0.05)"};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 20px;

  @media (max-width: 768px) {
    width: 95%;
    margin: 10px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) =>
        theme.isDarkMode
          ? "rgba(136, 83, 251, 0.08)"
          : "rgba(136, 83, 251, 0.05)"},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const PolicyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) =>
    theme.isDarkMode
      ? "linear-gradient(165deg, rgba(38, 38, 45, 0.5), rgba(28, 28, 35, 0.5))"
      : "linear-gradient(165deg, rgba(255, 255, 255, 0.8), rgba(252, 253, 255, 0.9))"};
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const PolicyTitle = styled.h2`
  margin: 0;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const PolicyCloseButton = styled.button`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const PolicyBody = styled.div`
  padding: 2rem;
  overflow-y: auto;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.text};

  h3 {
    color: ${({ theme }) => theme.colors.text};
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 0.95rem;
    color: ${({ theme }) => theme.colors.secondary};
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 1.5rem;

    li {
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;

      &::before {
        content: "•";
        color: ${({ theme }) => theme.colors.accent.purple.base};
        position: absolute;
        left: -1rem;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme.isDarkMode ? "rgba(18, 18, 24, 0.3)" : "rgba(136, 83, 251, 0.05)"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-radius: 4px;
    border: 2px solid
      ${({ theme }) =>
        theme.isDarkMode
          ? "rgba(18, 18, 24, 0.3)"
          : "rgba(248, 249, 252, 0.3)"};

    &:hover {
      background: ${({ theme }) => theme.colors.buttonGradientHover};
    }
  }

  @media (max-width: 768px) {
    padding: 1.5rem;

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const PolicyModal = ({ open, onClose, title, content }) => {
  const theme = useTheme();

  if (!open) return null;

  return ReactDOM.createPortal(
    <ThemeProvider theme={theme}>
      <AnimatePresence>
        {open && (
          <PolicyOverlay
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PolicyContent
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
            >
              <PolicyHeader>
                <PolicyTitle>{title}</PolicyTitle>
                <PolicyCloseButton onClick={onClose}>
                  <X size={24} />
                </PolicyCloseButton>
              </PolicyHeader>
              <PolicyBody>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </PolicyBody>
            </PolicyContent>
          </PolicyOverlay>
        )}
      </AnimatePresence>
    </ThemeProvider>,
    document.getElementById("modal-root")
  );
};

export default PolicyModal;
