import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { AlertTriangle, ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

const ModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.isDarkMode ? 
    'rgba(0, 0, 0, 0.85)' : 
    'rgba(0, 0, 0, 0.65)'
  };
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => theme.isDarkMode ? 
    `linear-gradient(
      165deg,
      rgba(28, 28, 35, 0.95),
      rgba(18, 18, 24, 0.98)
    )` : 
    `linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.95),
      rgba(245, 245, 247, 0.98)
    )`
  };
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 440px;
  border: 1px solid ${({ theme }) => theme.isDarkMode ? 
    'rgba(255, 255, 255, 0.07)' : 
    'rgba(0, 0, 0, 0.07)'
  };
  box-shadow: ${({ theme }) => theme.isDarkMode ?
    `0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1)` :
    `0 0 0 1px rgba(0, 0, 0, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.25),
    0 0 100px rgba(136, 83, 251, 0.07)`
  };
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: ${({ theme }) => theme.isDarkMode ?
      `radial-gradient(
        circle at top right,
        rgba(136, 83, 251, 0.08),
        transparent 70%
      )` :
      `radial-gradient(
        circle at top right,
        rgba(136, 83, 251, 0.05),
        transparent 70%
      )`
    };
    border-radius: inherit;
    pointer-events: none;
  }
`;

const WarningIcon = styled(motion.div)`
  width: 64px;
  height: 64px;
  background: ${({ theme }) => theme.isDarkMode ?
    `linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.15),
      rgba(255, 59, 48, 0.05)
    )` :
    `linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.1),
      rgba(255, 59, 48, 0.03)
    )`
  };
  border: 1px solid ${({ theme }) => theme.isDarkMode ?
    'rgba(255, 59, 48, 0.3)' :
    'rgba(255, 59, 48, 0.2)'
  };
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: ${({ theme }) => theme.isDarkMode ?
    'rgba(255, 59, 48, 0.9)' :
    'rgba(255, 59, 48, 0.8)'
  };
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 16px;
  font-size: 1.8rem;
  background: ${({ theme }) => theme.isDarkMode ?
    'linear-gradient(45deg, #ff3b30, #ff9500)' :
    'linear-gradient(45deg, #ff3b30, #ff9f0a)'
  };
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: ${({ theme }) => theme.isDarkMode ?
    '2px 2px 4px rgba(0, 0, 0, 0.1)' :
    'none'
  };
`;

const Message = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.isDarkMode ?
    'rgba(255, 255, 255, 0.8)' :
    'rgba(0, 0, 0, 0.8)'
  };
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 32px;
`;

const Button = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.isDarkMode ?
    `linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.15)
    )` :
    `linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    )`
  };
  border: 1px solid ${({ theme }) => theme.isDarkMode ?
    'rgba(136, 83, 251, 0.3)' :
    'rgba(136, 83, 251, 0.2)'
  };
  border-radius: 12px;
  color: ${({ theme }) => theme.isDarkMode ? 'white' : theme.colors.accent.purple.base};
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: ${({ theme }) => theme.isDarkMode ?
      `linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.2),
        rgba(96, 57, 176, 0.2)
      )` :
      `linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.15),
        rgba(96, 57, 176, 0.15)
      )`
    };
    border-color: ${({ theme }) => theme.isDarkMode ?
      'rgba(136, 83, 251, 0.4)' :
      'rgba(136, 83, 251, 0.3)'
    };
    box-shadow: ${({ theme }) => theme.isDarkMode ?
      `0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2)` :
      `0 8px 32px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.15)`
    };
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

const ViewLimitModal = ({ isOpen, message, onConfirm }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={(e) => e.stopPropagation()}
        >
          <ModalContent
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
            onClick={(e) => e.stopPropagation()}
          >
            <WarningIcon
              initial={{ scale: 0.8, rotate: -10 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{
                type: "spring",
                damping: 15,
                stiffness: 300,
                delay: 0.2,
              }}
            >
              <AlertTriangle size={32} />
            </WarningIcon>
            <Title>{t('viewLimitReached')}</Title>
            <Message>{message}</Message>
            <Button
              onClick={onConfirm}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {t('viewPricingPlans')} <ArrowRight size={18} />
            </Button>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>,
    document.getElementById("modal-root")
  );
};

export default ViewLimitModal; 