// WalletProvider.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-hot-toast";
import PaymentFailureModal from "../components/modals/PaymentFailureModal";
import io from "socket.io-client";

// Initialize Stripe outside the component to avoid multiple instances
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [walletData, setWalletData] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const [socket, setSocket] = useState(null);
  const { i18n } = useTranslation();

  // Add state for payment failure modal
  const [paymentFailure, setPaymentFailure] = useState({
    isOpen: false,
    error: null,
    nextAttempt: null,
    retryCount: 0,
    maxRetries: 3,
  });

  // Initialize WebSocket connection
  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_WS_URL, {
      withCredentials: true,
      transports: ["websocket"],
    });

    newSocket.on("connect", () => {
      console.log("WebSocket connected");
    });

    newSocket.on("payment_failed", (data) => {
      handlePaymentFailureNotification(data);
    });

    newSocket.on("payment_retry_scheduled", (data) => {
      handlePaymentRetryScheduled(data);
    });

    newSocket.on("payment_retry_succeeded", (data) => {
      handlePaymentRetrySuccess(data);
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  // Handle payment failure notification
  const handlePaymentFailureNotification = (data) => {
    const { error, nextAttempt, retryCount } = data;

    setPaymentFailure((prev) => ({
      isOpen: true,
      error,
      nextAttempt: nextAttempt ? new Date(nextAttempt) : null,
      retryCount,
      maxRetries: prev.maxRetries,
    }));

    // Show toast notification
    toast.error(
      <div>
        <strong>Payment Failed</strong>
        <p>{error.message}</p>
        {nextAttempt && (
          <p>Next attempt: {new Date(nextAttempt).toLocaleString()}</p>
        )}
      </div>,
      {
        duration: 6000,
        position: "bottom-center",
      }
    );

    // Refresh wallet data to get latest status
    fetchWalletData();
  };

  // Handle payment retry scheduled notification
  const handlePaymentRetryScheduled = (data) => {
    const { nextAttempt, retryCount } = data;

    toast.info(
      <div>
        <strong>Payment Retry Scheduled</strong>
        <p>
          Attempt {retryCount} of {paymentFailure.maxRetries}
        </p>
        <p>Scheduled for: {new Date(nextAttempt).toLocaleString()}</p>
      </div>,
      {
        duration: 5000,
        position: "bottom-center",
      }
    );

    setPaymentFailure((prev) => ({
      ...prev,
      nextAttempt: new Date(nextAttempt),
      retryCount,
    }));
  };

  // Handle payment retry success
  const handlePaymentRetrySuccess = () => {
    toast.success(
      <div>
        <strong>Payment Successful</strong>
        <p>Your subscription has been restored</p>
      </div>,
      {
        duration: 5000,
        position: "bottom-center",
      }
    );

    setPaymentFailure((prev) => ({
      ...prev,
      isOpen: false,
      error: null,
      nextAttempt: null,
      retryCount: 0,
    }));

    // Refresh wallet data to get latest status
    fetchWalletData();
  };

  // Function to open Stripe Customer Portal
  const openCustomerPortal = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/customer-portal`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            returnUrl: window.location.href,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create customer portal session");
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error("Error opening customer portal:", error);
      toast.error("Failed to open payment settings. Please try again.");
    }
  };

  // Function to handle payment method update
  const handleUpdatePaymentMethod = async () => {
    try {
      // First try to use the customer portal
      await openCustomerPortal();
    } catch (portalError) {
      console.error(
        "Failed to open customer portal, falling back to payment update:",
        portalError
      );

      // Fallback to direct payment update if portal fails
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/wallet/create-payment-update-session`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              returnUrl: window.location.href,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to create payment update session");
        }

        const { url } = await response.json();
        window.location.href = url;
      } catch (error) {
        console.error("Error updating payment method:", error);
        toast.error("Failed to update payment method. Please try again.");
      }
    }
  };

  // Function to manually trigger payment retry
  const retryPayment = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/retry-payment`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retry payment");
      }

      toast.success("Payment retry initiated");
    } catch (error) {
      console.error("Error retrying payment:", error);
      toast.error("Failed to retry payment. Please try again.");
    }
  };

  // Add useEffect to check subscription status on mount and after updates
  useEffect(() => {
    if (walletData?.subscription) {
      const { status, lastPaymentError } = walletData.subscription;

      if (status === "payment_failed" && lastPaymentError) {
        setPaymentFailure((prev) => ({
          ...prev,
          isOpen: true,
          error: {
            message: lastPaymentError.message,
          },
          nextAttempt: lastPaymentError.nextAttemptAt,
          retryCount: lastPaymentError.attemptCount || 0,
        }));
      }
    }
  }, [walletData]);

  const fetchWalletData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet`,
        {
          credentials: "include",
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setWalletData(data);

        // Check for payment failure status
        if (data.subscription?.status === "payment_failed") {
          setPaymentFailure({
            isOpen: true,
            error: {
              message:
                data.subscription.lastPaymentError?.message ||
                "Your payment has failed",
            },
            nextAttempt: data.subscription.lastPaymentError?.nextAttemptAt,
          });
        }
      } else {
        console.error("Failed to fetch wallet data");
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  }, [i18n.language]);

  const fetchSubscriptionPlans = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/subscription-plans`,
        {
          credentials: "include",
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSubscriptionPlans(data);
      } else {
        console.error("Failed to fetch subscription plans");
      }
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchWalletData();
    fetchSubscriptionPlans();
  }, [fetchWalletData, fetchSubscriptionPlans, i18n.language]);

  const updateSubscription = useCallback(async (plan, interval) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/update-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ plan, interval }),
        }
      );
      if (response.ok) {
        const updatedWalletData = await response.json();
        setWalletData(updatedWalletData);
        return updatedWalletData;
      } else {
        const errorData = await response.json();
        console.error("Failed to update subscription:", errorData);
        return null;
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      return null;
    }
  }, []);

  const createCheckoutSession = useCallback(
    async (plan, interval, amount = null) => {
      try {
        console.log("Creating checkout session:", { plan, interval, amount });

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/wallet/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              plan,
              interval,
              amount: amount ? parseInt(amount, 10) : null,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.error || "Failed to create checkout session"
          );
        }

        const data = await response.json();
        console.log("Got checkout data:", data);

        if (data.url) {
          window.location.href = data.url;
          return data;
        } else if (data.sessionId) {
          const stripe = await stripePromise;
          if (!stripe) {
            throw new Error("Failed to load Stripe");
          }

          console.log("Redirecting to Stripe checkout...");
          const { error } = await stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
          if (error) {
            console.error("Stripe redirect error:", error);
            throw error;
          }
          return data.sessionId;
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
        throw error;
      }
    },
    []
  );

  const reactivateSubscription = useCallback(async (plan, interval) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/reactivate-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ plan, interval }),
        }
      );
      if (response.ok) {
        const updatedWalletData = await response.json();
        setWalletData(updatedWalletData);
        return updatedWalletData;
      } else {
        console.error("Failed to reactivate subscription");
        return null;
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      return null;
    }
  }, []);

  const cancelSubscription = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/cancel-subscription`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }
      await fetchWalletData();
    } catch (error) {
      console.error("Error canceling subscription:", error);
      throw error;
    }
  }, [fetchWalletData]);

  const trackPropertyView = useCallback(
    async (propertyId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/properties/${propertyId}/track-view`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          const error = await response.json();
          if (error.requiresUpgrade) {
            throw new Error(error.message);
          }
          throw new Error("Failed to track view");
        }

        const data = await response.json();

        // Show low credit warnings if applicable
        if (data.creditInfo?.isLowCredits) {
          const plan = data.creditInfo.plan;
          const credits = data.creditInfo.remainingCredits;
          const nextRefresh = new Date(data.creditInfo.nextRefresh);

          let warningMessage = "";
          if (plan === "FREE") {
            warningMessage = `You have ${credits} daily views remaining. Credits reset at midnight.`;
          } else if (plan === "PRO" || plan === "PREMIUM") {
            warningMessage = `You have ${credits} monthly views remaining. Credits refresh on ${nextRefresh.toLocaleDateString()}.`;
          }

          if (warningMessage) {
            toast.warning(warningMessage, {
              duration: 5000,
              position: "bottom-center",
            });
          }
        }

        // Fetch updated wallet data after successful view tracking
        await fetchWalletData();

        return data;
      } catch (error) {
        throw error;
      }
    },
    [fetchWalletData]
  );

  const addCredits = useCallback(async (amount) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/create-credit-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ amount: parseInt(amount, 10) }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to create credit checkout session"
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error in credit purchase flow:", error);
      throw error;
    }
  }, []);

  // Helper function to check if credits are low
  const checkLowCredits = useCallback(() => {
    if (!walletData?.subscription) return false;

    const { plan, credits } = walletData.subscription;
    if (plan === "UNLIMITED") return false;

    const threshold =
      plan === "FREE"
        ? 100 * 0.2 // 20% of daily limit
        : 0;

    return credits <= threshold;
  }, [walletData]);

  return (
    <WalletContext.Provider
      value={{
        walletData,
        setWalletData,
        fetchWalletData,
        subscriptionPlans,
        updateSubscription,
        createCheckoutSession,
        reactivateSubscription,
        trackPropertyView,
        addCredits,
        checkLowCredits,
        cancelSubscription,
        openCustomerPortal,
        handleUpdatePaymentMethod,
      }}
    >
      {children}
      <PaymentFailureModal
        open={paymentFailure.isOpen}
        onClose={() =>
          setPaymentFailure((prev) => ({ ...prev, isOpen: false }))
        }
        error={paymentFailure.error}
        nextAttempt={paymentFailure.nextAttempt}
        onUpdatePaymentMethod={handleUpdatePaymentMethod}
        onRetryPayment={retryPayment}
        retryCount={paymentFailure.retryCount}
        maxRetries={paymentFailure.maxRetries}
      />
    </WalletContext.Provider>
  );
};
