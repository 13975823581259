import styled from "styled-components";

export const TabDetails = styled.div`
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${({ theme }) => `linear-gradient(
    165deg,
    ${theme.isDarkMode ? "rgba(28, 28, 35, 0.2)" : "rgba(255, 255, 255, 0.2)"},
    ${theme.isDarkMode ? "rgba(18, 18, 24, 0.2)" : "rgba(248, 249, 252, 0.2)"}
  )`};
  backdrop-filter: blur(40px);
  border-radius: 20px;
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;

  height: calc(100% - 60px);
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) =>
      theme.isDarkMode ? "rgba(28, 28, 35, 0.2)" : "rgba(248, 249, 252, 0.2)"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.isDarkMode
        ? "rgba(136, 83, 251, 0.2)"
        : "rgba(136, 83, 251, 0.15)"};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.glass.border};

    &:hover {
      background: ${({ theme }) =>
        theme.isDarkMode
          ? "rgba(136, 83, 251, 0.3)"
          : "rgba(136, 83, 251, 0.25)"};
    }
  }
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  gap: 10px;
  width: 100%;
  margin-bottom: 16px;
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  min-width: 0;
  background: ${({ theme }) =>
    theme.isDarkMode ? "rgba(38, 38, 45, 0.95)" : "rgba(255, 255, 255, 0.95)"};
  padding: 12px;
  border-radius: 12px;
  border: 1px solid
    ${({ theme }) =>
      theme.isDarkMode ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)"};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode
      ? "0 2px 8px rgba(0, 0, 0, 0.2)"
      : "0 2px 8px rgba(136, 83, 251, 0.08)"};
`;

export const DetailIcon = styled.div`
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.primary};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`;

export const DetailLabel = styled.span`
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 12px;
  color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)"};
`;

export const DetailValue = styled.span`
  color: ${({ theme }) =>
    theme.isDarkMode ? "rgba(255, 255, 255, 0.9)" : "rgba(0, 0, 0, 0.9)"};
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.4;
`;
