import React, { useState, useContext, useRef, useEffect } from "react";
import { Heart, Trash2 } from "react-feather";
import { FavoritesContext } from "../../../context/FavoritesContext";
import ConfirmationModal from "../../common/ConfirmationModal";
import Toast from "../../common/Toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

// Styled Components
const FavoriteContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 20px;
`;

const FavoriteButton = styled.button`
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.accent.purple.base};
    transition: all 0.3s ease;
  }
`;

const DropdownContainer = styled(motion.div)`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 240px;
  z-index: 1000;
  margin-bottom: 5px;
  padding: 8px;
  background: ${({ theme }) => theme.isDarkMode ? 
    'linear-gradient(165deg, rgba(28, 28, 35, 0.95), rgba(18, 18, 24, 0.98))' : 
    'linear-gradient(165deg, rgba(255, 255, 255, 0.95), rgba(252, 253, 255, 0.98))'};
  backdrop-filter: blur(40px);
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  font-size: 14px;
  box-shadow: ${({ theme }) => theme.isDarkMode ?
    '0 8px 32px rgba(0, 0, 0, 0.4), 0 2px 8px rgba(0, 0, 0, 0.2)' :
    '0 8px 32px rgba(136, 83, 251, 0.1), 0 2px 8px rgba(136, 83, 251, 0.05)'};
`;

const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  color: ${({ theme }) => theme.colors.text};

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.selected {
    background: ${({ theme }) => theme.isDarkMode ? 
      'linear-gradient(165deg, rgba(136, 83, 251, 0.2), rgba(96, 57, 176, 0.2))' : 
      'linear-gradient(165deg, rgba(136, 83, 251, 0.1), rgba(96, 57, 176, 0.1))'};
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  &:hover {
    background: ${({ theme }) => theme.isDarkMode ? 
      'linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))' : 
      'linear-gradient(165deg, rgba(136, 83, 251, 0.08), rgba(96, 57, 176, 0.08))'};
  }
`;

const DeleteIcon = styled(Trash2)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  transition: all 0.3s ease;
  opacity: 0.7;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    opacity: 1;
  }
`;

const CreateFolderContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CreateFolderInput = styled.input`
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.isDarkMode ? 
    'rgba(18, 18, 24, 0.5)' : 
    'rgba(255, 255, 255, 0.8)'};
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.accent.purple.transparent10};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const SaveButton = styled.button`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
  }
`;

const CreateOneButton = styled(DropdownItem)`
  color: ${({ theme }) => theme.colors.accent.purple.base};
  font-weight: 500;
  
  &:hover {
    background: ${({ theme }) => theme.isDarkMode ? 
      'linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))' : 
      'linear-gradient(165deg, rgba(136, 83, 251, 0.08), rgba(96, 57, 176, 0.08))'};
  }
`;

const FavoriteButtonComponent = ({ uid }) => {
  const { t } = useTranslation();
  const {
    folders,
    addFavorite,
    removeFavorite,
    createFolder,
    deleteFolder,
    selectedFolder,
    setSelectedFolder,
  } = useContext(FavoritesContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [toast, setToast] = useState(null);
  const [favoritesCount, setFavoritesCount] = useState(null);
  const [isLoadingCount, setIsLoadingCount] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const fetchFavoritesCount = async () => {
    if (isLoadingCount) return;
    setIsLoadingCount(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/properties/${uid}/favorites-count`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setFavoritesCount(data.favoritesCount);
      }
    } catch (error) {
      console.error("Error fetching favorites count:", error);
    } finally {
      setIsLoadingCount(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hasFavoritedThisProperty = () => {
    return folders.some(({ properties }) => properties.includes(uid));
  };

  const isFavoritedInFolder = (folder) => {
    return folder.properties.includes(uid);
  };

  const handleFavoriteClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFolderSelect = async (folder) => {
    if (isFavoritedInFolder(folder)) {
      await removeFavorite(uid, folder.id);
      if (favoritesCount !== null) {
        setFavoritesCount(Math.max(0, favoritesCount - 1));
      }
      setToast({
        message: `Property removed from "${folder.name}" folder`,
        type: "info",
      });
    } else {
      setSelectedFolder(folder.id);
      await addFavorite(uid, folder.id);
      if (favoritesCount !== null) {
        setFavoritesCount(favoritesCount + 1);
      }
      setToast({
        message: `Property added to "${folder.name}" folder`,
        type: "success",
      });
    }
    setShowDropdown(false);
  };

  const handleCreateFolder = () => {
    setIsCreatingFolder(true);
  };

  const handleSaveNewFolder = async () => {
    if (newFolderName.trim()) {
      await createFolder(newFolderName);
      setIsCreatingFolder(false);
      setNewFolderName("");
      setToast({
        message: `New folder "${truncateMessage(newFolderName, 20)}" created`,
        type: "success",
      });
      setShowDropdown(false);
    }
  };

  const handleDeleteClick = (folder) => {
    setFolderToDelete(folder);
    setIsModalOpen(true);
  };

  const confirmDeleteFolder = () => {
    setIsModalOpen(false);
    if (folderToDelete) {
      if (selectedFolder === folderToDelete.id) {
        removeFavorite(uid, folderToDelete.id);
      }
      deleteFolder(folderToDelete.id);
      setSelectedFolder(null);
      setToast({
        message: `Folder "${truncateMessage(
          folderToDelete.name,
          20
        )}" and all favorites inside have been deleted`,
        type: "warning",
      });
      setFolderToDelete(null);
    }
  };

  const cancelDeleteFolder = () => {
    setIsModalOpen(false);
    setFolderToDelete(null);
  };

  const truncateFolderName = (name, maxLength = 15) => {
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };

  const truncateMessage = (message, maxLength = 30) => {
    return message.length > maxLength
      ? message.substring(0, maxLength) + "..."
      : message;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveNewFolder();
    }
  };

  const getFavoriteTooltipText = () => {
    if (isLoadingCount) return t("loadingFavorites");
    const count = favoritesCount || 0;
    return t(count === 1 ? "peopleFavorited" : "peopleFavorited_plural", { count });
  };

  return (
    <FavoriteContainer>
      <Tippy
        content={getFavoriteTooltipText()}
        animation="shift-away"
        placement="top"
        duration={[300, 250]}
        delay={[400, 0]}
        theme="modern"
        arrow={true}
        onShow={() => {
          if (favoritesCount === null) {
            fetchFavoritesCount();
          }
        }}
      >
        <FavoriteButton
          onClick={handleFavoriteClick}
          ref={buttonRef}
        >
          <Heart
            fill={hasFavoritedThisProperty() ? "#8853fb" : "none"}
          />
        </FavoriteButton>
      </Tippy>

      <AnimatePresence>
        {showDropdown && (
          <DropdownContainer
            ref={dropdownRef}
            initial={{ opacity: 0, y: -20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -20, scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            {folders.map((folder) => (
              <DropdownItem
                key={folder.id}
                className={isFavoritedInFolder(folder) ? "selected" : ""}
                onClick={() => handleFolderSelect(folder)}
              >
                <Tippy
                  content={
                    truncateFolderName(folder.name).endsWith("...")
                      ? `${folder.name} (${folder.properties.length} items)`
                      : null
                  }
                  animation="shift-away"
                  placement="right"
                  duration={[300, 250]}
                  delay={[400, 0]}
                  theme="modern"
                  arrow={true}
                  disabled={!truncateFolderName(folder.name).endsWith("...")}
                >
                  <span>
                    {truncateFolderName(folder.name)} ({folder.properties.length})
                  </span>
                </Tippy>
                <Tippy
                  content="Delete folder"
                  animation="shift-away"
                  placement="right"
                  duration={[300, 250]}
                  delay={[400, 0]}
                  theme="modern"
                  arrow={true}
                >
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(folder);
                    }}
                  />
                </Tippy>
              </DropdownItem>
            ))}
            {isCreatingFolder ? (
              <CreateFolderContainer>
                <CreateFolderInput
                  type="text"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="New folder name"
                  autoFocus
                />
                <SaveButton onClick={handleSaveNewFolder}>
                  Save
                </SaveButton>
              </CreateFolderContainer>
            ) : (
              <CreateOneButton onClick={handleCreateFolder}>
                + Create One
              </CreateOneButton>
            )}
          </DropdownContainer>
        )}
      </AnimatePresence>
      <ConfirmationModal
        isOpen={isModalOpen}
        message={`Are you sure you want to delete the folder "${folderToDelete?.name}" and all favorites inside?`}
        onConfirm={confirmDeleteFolder}
        onClose={cancelDeleteFolder}
      />
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={3000}
          onClose={() => setToast(null)}
        />
      )}
    </FavoriteContainer>
  );
};

export default FavoriteButtonComponent;
