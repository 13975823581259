import React from "react";
import { motion } from "framer-motion";
import Friends from "../friends/Friends";
import { useTheme } from "../../context/ThemeContext";

const FriendsPage = () => {
  const theme = useTheme();

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
      style={{
        height: "100%",
        width: "100%",
        padding: "24px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <motion.div
        className="friends-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        style={{
          width: "100%",
          height: "100%",
          background: theme.colors.surface,
          backdropFilter: `blur(${theme.colors.glass.blur})`,
          borderRadius: "24px",
          border: `1px solid ${theme.colors.border}`,
          boxShadow: `
            0 0 0 1px ${theme.colors.glass.border},
            ${theme.colors.shadow},
            ${theme.colors.glow}
          `,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Friends />
      </motion.div>
    </motion.div>
  );
};

export default FriendsPage;
