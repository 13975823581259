import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const LinkReportContext = createContext();

export const useLinkReport = () => useContext(LinkReportContext);

export const LinkReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitReport = async (reportData) => {
    setLoading(true);
    setError(null);

    if (!reportData.uid) {
      setError("Property ID is missing");
      setLoading(false);
      throw new Error("Property ID is missing");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/properties/${reportData.uid}/report-link`,
        {
          url: reportData.url,
          reason: reportData.reason,
          resourceType: reportData.resourceType,
          resourceTitle: reportData.resourceTitle,
        },
        { withCredentials: true }
      );

      return response.data;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Failed to submit report";
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LinkReportContext.Provider value={{ submitReport, loading, error }}>
      {children}
    </LinkReportContext.Provider>
  );
};
