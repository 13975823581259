import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { AlertTriangle } from "react-feather";
import { useTheme } from "../../context/ThemeContext";

const ModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
  perspective: 1000px;
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  position: relative;
  overflow: hidden;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const Title = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 8px 0;
  font-weight: 500;
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  margin: 0 0 24px 0;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

const Button = styled(motion.button)`
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  align-items: center;
  gap: 8px;
  backdrop-filter: blur(10px);

  ${(props) =>
    props.$confirm
      ? `
    background: ${props.theme.colors.accent.error.light};
    border: 1px solid ${props.theme.colors.accent.error.base};
    color: ${props.theme.colors.accent.error.base};

    &:hover {
      background: ${props.theme.colors.accent.error.base};
      border-color: ${props.theme.colors.accent.error.dark};
      color: white;
      transform: translateY(-1px);
      box-shadow: ${props.theme.colors.shadow};
    }
  `
      : `
    background: ${props.theme.colors.buttonGradient};
    border: 1px solid ${props.theme.colors.border};
    color: ${props.theme.colors.text};

    &:hover {
      background: ${props.theme.colors.buttonGradientHover};
      border-color: ${props.theme.colors.accent.purple.light};
      transform: translateY(-1px);
      box-shadow: ${props.theme.colors.shadow};
    }
  `}

  &:active {
    transform: translateY(1px);
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.accent.error.base};
  display: flex;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
    filter: drop-shadow(
      0 2px 8px ${({ theme }) => theme.colors.accent.error.light}
    );
  }
`;

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  const theme = useTheme();

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <ModalOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
      >
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          initial={{ scale: 0.95, opacity: 0, rotateX: -10 }}
          animate={{ scale: 1, opacity: 1, rotateX: 0 }}
          exit={{ scale: 0.95, opacity: 0, rotateX: 10 }}
          transition={{ type: "spring", damping: 25, stiffness: 300 }}
        >
          <IconWrapper>
            <AlertTriangle />
          </IconWrapper>
          <Title>{title}</Title>
          <Message>{message}</Message>
          <ButtonGroup>
            <Button
              onClick={onClose}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {cancelText}
            </Button>
            <Button
              $confirm
              onClick={onConfirm}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {confirmText}
            </Button>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>
    </AnimatePresence>
  );
};

export default ConfirmationModal;
