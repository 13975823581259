import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  UserX,
  Shield,
  Search,
  Users,
  MoreVertical,
  MessageCircle,
  Trash2,
} from "react-feather";
import { removeFriend, blockUser, unblockUser, deleteUser } from "./friendsApi";
import { useToast } from "../../context/ToastContext";
import { useTheme } from "../../context/ThemeContext";
import ConfirmationModal from "../common/ConfirmationModal";
import { createOrGetChat } from "../chat/chatApi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
`;

const FriendCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  padding: 16px;
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.colors.glow};
  }
`;

const FriendInfo = styled.div`
  flex: 1;
  min-width: 0;
  margin-right: 16px;

  h3 {
    margin: 0 0 4px 0;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin: 0 0 8px 0;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`;

const IconButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${({ theme, $danger, $message }) =>
    $danger
      ? theme.colors.accent.error.base
      : $message
      ? theme.colors.accent.purple.base
      : theme.colors.secondary};
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme, $danger, $message }) =>
      $danger
        ? theme.colors.accent.error.light
        : $message
        ? theme.colors.accent.purple.light
        : theme.colors.buttonGradient};
    transform: scale(1.1);
  }
`;

const DropdownMenu = styled(motion.div)`
  position: absolute;
  top: 100%;
  right: 0;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 8px;
  min-width: 180px;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  z-index: 100;
`;

const DropdownItem = styled(motion.button)`
  width: 100%;
  padding: 10px 12px;
  background: none;
  border: none;
  color: ${({ theme, $danger }) =>
    $danger ? theme.colors.accent.error.base : theme.colors.text};
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme, $danger }) =>
      $danger ? theme.colors.accent.error.light : theme.colors.buttonGradient};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 32px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  .input-container {
    position: relative;
    width: 80%;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  padding: 0 40px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surface};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.accent.purple.base};
`;

const BlockedBadge = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  padding: 6px 10px;
  background: ${({ theme }) => theme.colors.accent.error.light};
  border: 1px solid ${({ theme }) => theme.colors.accent.error.base};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.accent.error.base};
  font-size: 12px;
  font-weight: 500;
  max-width: 100%;

  .badge-content {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
  }

  .block-reason {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.accent.error.base}aa;
    margin-top: 4px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
`;

const UnblockButton = styled(motion.button)`
  padding: 6px 12px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.light};
    transform: translateY(-1px);
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

const StyledTextArea = styled.textarea`
  width: 95%;
  height: 80px;
  padding: 12px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  resize: none;
  margin: 8px auto;
  display: block;
  outline: none;
  transition: all 0.2s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledTippy = styled(Tippy)`
  background-color: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  color: ${({ theme }) => theme.colors.text};
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 6px;
  backdrop-filter: blur(10px);

  .tippy-arrow {
    color: ${({ theme }) => theme.colors.accent.purple.light};
  }

  &[data-placement^="top"] .tippy-arrow::before {
    border-top-color: ${({ theme }) => theme.colors.accent.purple.light};
  }
`;

const FriendsList = ({ friends, onFriendRemove, onFriendUpdate }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const theme = useTheme();
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [blockReason, setBlockReason] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleRemoveClick = (friend) => {
    setSelectedFriend(friend);
    setShowConfirmation(true);
    setOpenDropdownId(null);
  };

  const handleConfirmRemove = async () => {
    try {
      await removeFriend(selectedFriend.friendshipId);
      onFriendRemove(selectedFriend._id);
      showToast(t("friendRemoved"), "success");
    } catch (error) {
      showToast(t("failedToRemoveFriend"), "error");
    } finally {
      setShowConfirmation(false);
      setSelectedFriend(null);
    }
  };

  const handleBlock = (friend) => {
    setSelectedFriend(friend);
    setShowBlockModal(true);
    setOpenDropdownId(null);
  };

  const handleConfirmBlock = async () => {
    try {
      await blockUser(selectedFriend._id, blockReason);
      onFriendUpdate(selectedFriend._id, {
        status: "blocked",
        blockReason,
      });
      showToast(t("userBlocked"), "success");
    } catch (error) {
      showToast(t("failedToBlockUser"), "error");
    } finally {
      setShowBlockModal(false);
      setSelectedFriend(null);
      setBlockReason("");
    }
  };

  const handleChatClick = async (friendId) => {
    try {
      const chatData = await createOrGetChat(friendId);
      navigate(`/home/chat?chatId=${chatData._id}`);
    } catch (error) {
      showToast(t("failedToOpenChat"), "error");
      console.error("Error opening chat:", error);
    }
  };

  const handleUnblock = async (friend) => {
    try {
      await unblockUser(friend._id);
      onFriendUpdate(friend._id, {
        status: "accepted",
        blockReason: null,
      });
      showToast(t("userUnblocked"), "success");
    } catch (error) {
      showToast(t("failedToUnblockUser"), "error");
    } finally {
      setOpenDropdownId(null);
    }
  };

  const handleDeleteUser = (friend) => {
    setSelectedFriend(friend);
    setShowDeleteConfirmation(true);
    setOpenDropdownId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteUser(selectedFriend._id);
      onFriendRemove(selectedFriend._id);
      showToast(t("userDeleted"), "success");
    } catch (error) {
      showToast(t("failedToDeleteUser"), "error");
    } finally {
      setShowDeleteConfirmation(false);
      setSelectedFriend(null);
    }
  };

  const filteredFriends = friends.filter(
    (friend) =>
      friend.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      friend.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SearchContainer theme={theme}>
        <div className="input-container">
          <SearchIcon>
            <Search size={16} />
          </SearchIcon>
          <SearchInput
            type="text"
            placeholder={t("searchFriends")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </SearchContainer>

      {!filteredFriends.length ? (
        <EmptyState theme={theme}>
          {searchTerm ? (
            <>
              <p>{t("noFriendsFound", { searchTerm })}</p>
            </>
          ) : (
            <>
              <Users />
              <h3>{t("noFriendsYet")}</h3>
              <p>{t("startAddingFriends")}</p>
            </>
          )}
        </EmptyState>
      ) : (
        <ListContainer theme={theme}>
          {filteredFriends.map((friend) => (
            <FriendCard
              key={friend._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <FriendInfo>
                <h3>{friend.name}</h3>
                <p>{friend.email}</p>
                {friend.status === "blocked" && (
                  <BlockedBadge>
                    <div style={{ width: "100%" }}>
                      <div className="badge-content">
                        <Shield size={12} />
                        {t("blocked")}
                      </div>
                      {friend.blockReason && (
                        <div className="block-reason">{friend.blockReason}</div>
                      )}
                    </div>
                  </BlockedBadge>
                )}
              </FriendInfo>
              <ActionButtons>
                {friend.status === "blocked" ? (
                  <>
                    <UnblockButton
                      onClick={() => handleUnblock(friend)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Shield size={14} />
                      {t("unblockUser")}
                    </UnblockButton>
                    <DropdownContainer>
                      <IconButton
                        onClick={() =>
                          setOpenDropdownId(
                            openDropdownId === friend._id ? null : friend._id
                          )
                        }
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <MoreVertical size={20} />
                      </IconButton>
                      <AnimatePresence>
                        {openDropdownId === friend._id && (
                          <DropdownMenu
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            <DropdownItem
                              onClick={() => handleDeleteUser(friend)}
                              $danger
                            >
                              <Trash2 size={16} />
                              {t("deleteUser")}
                            </DropdownItem>
                          </DropdownMenu>
                        )}
                      </AnimatePresence>
                    </DropdownContainer>
                  </>
                ) : (
                  <>
                    <StyledTippy
                      content={t("chatWithFriend", { name: friend.name })}
                      placement="top"
                      theme={theme}
                    >
                      <IconButton
                        $message
                        onClick={() => handleChatClick(friend._id)}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <MessageCircle size={20} />
                      </IconButton>
                    </StyledTippy>
                    <DropdownContainer>
                      <IconButton
                        onClick={() =>
                          setOpenDropdownId(
                            openDropdownId === friend._id ? null : friend._id
                          )
                        }
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <MoreVertical size={20} />
                      </IconButton>
                      <AnimatePresence>
                        {openDropdownId === friend._id && (
                          <DropdownMenu
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            <DropdownItem
                              onClick={() => handleRemoveClick(friend)}
                              $danger
                            >
                              <UserX size={16} />
                              {t("removeFriend")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleBlock(friend)}
                              $danger
                            >
                              <Shield size={16} />
                              {t("blockUser")}
                            </DropdownItem>
                          </DropdownMenu>
                        )}
                      </AnimatePresence>
                    </DropdownContainer>
                  </>
                )}
              </ActionButtons>
            </FriendCard>
          ))}
        </ListContainer>
      )}

      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => {
          setShowConfirmation(false);
          setSelectedFriend(null);
        }}
        onConfirm={handleConfirmRemove}
        title={t("removeFriend")}
        message={t("removeFriendConfirmation")}
        confirmText={t("removeFriend")}
      />

      <ConfirmationModal
        isOpen={showBlockModal}
        onClose={() => {
          setShowBlockModal(false);
          setSelectedFriend(null);
          setBlockReason("");
        }}
        onConfirm={handleConfirmBlock}
        title={t("blockUser")}
        message={
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <p>{t("blockUserConfirmation")}</p>
            <p style={{ fontSize: "12px", color: theme.colors.secondary }}>
              {t("blockUserWarning")}
            </p>
            <StyledTextArea
              placeholder={t("blockReason")}
              value={blockReason}
              onChange={(e) => setBlockReason(e.target.value)}
              theme={theme}
            />
          </div>
        }
        confirmText={t("blockUser")}
      />

      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
          setSelectedFriend(null);
        }}
        onConfirm={handleConfirmDelete}
        title={t("deleteUser")}
        message={
          <div>
            <p>{t("deleteUserConfirmation")}</p>
            <p
              style={{
                fontSize: "12px",
                color: "rgba(255, 255, 255, 0.6)",
                marginTop: "8px",
              }}
            >
              {t("deleteUserWarning")}
            </p>
          </div>
        }
        confirmText={t("deleteUser")}
      />
    </>
  );
};

export default FriendsList;
