import styled from "styled-components";
import { motion } from "framer-motion";
import { Search } from "react-feather";

export const ChatContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 98%;
  width: 98%;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const Sidebar = styled.div`
  background: ${({ theme }) => theme.colors.surfaceHover};
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  backdrop-filter: blur(10px);
`;

export const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 180px);
  padding-right: 8px;
  padding-left: 8px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.border};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent.purple.light};
    border-radius: 10px;

    &:hover {
      background: ${({ theme }) => theme.colors.accent.purple.base};
    }
  }
`;

export const ChatItem = styled(motion.div)`
  padding: 12px;
  margin: 5px;
  border-radius: 12px;
  cursor: pointer;
  background: ${({ theme, $active }) =>
    $active ? theme.colors.buttonGradient : "transparent"};
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.accent.purple.base : "transparent"};
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme, $active }) =>
      $active ? theme.colors.text : theme.colors.secondary};
    margin: 0 0 4px 0;
    transition: color 0.3s ease;
  }

  p {
    font-size: 12px;
    color: ${({ theme, $active }) =>
      $active ? theme.colors.secondary : theme.colors.secondary};
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.colors.shadow};

    h4 {
      color: ${({ theme }) => theme.colors.text};
    }

    p {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .editable-title {
    transition: all 0.2s ease;
    padding: 4px 8px;
    margin: -4px -8px;
    border-radius: 6px;
    position: relative;

    &:hover {
      background: ${({ theme }) => theme.colors.accent.purple.light};

      .edit-indicator {
        opacity: 1;
      }

      &::after {
        opacity: 1;
      }
    }

    input {
      &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.accent.purple.base};
        background: ${({ theme }) => theme.colors.surfaceHover};
        box-shadow: ${({ theme }) => theme.colors.shadow};
      }
    }
  }
`;

export const MainChat = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  min-height: 0;
`;

export const ChatHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  backdrop-filter: blur(20px);
`;

export const ChatHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  h3 {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 13px;
  }
`;

export const ChatHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-self: end;
`;

export const MessageContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  backdrop-filter: blur(10px);
  position: relative;
  scroll-behavior: smooth;
  height: calc(100vh - 250px);
  min-height: 0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.border};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent.purple.light};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.accent.purple.base};
    }
  }
`;

export const InputArea = styled.div`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  backdrop-filter: blur(20px);
`;

export const Input = styled.input`
  flex: 1;
  height: 44px;
  padding: 0 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  width: calc(100% - 56px);

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surfaceHover};
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.surfaceHover};
  }
`;

export const IconButton = styled(motion.button)`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.base};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  backdrop-filter: blur(20px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  svg {
    width: 18px;
    height: 18px;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-1px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.shadow};

    svg {
      transform: scale(1.1);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;

    svg {
      transform: none;
    }
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;
`;

export const SearchInput = styled(Input)`
  height: 40px;
  padding: 0 26px;
  font-size: 13px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};

  &:focus {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.accent.purple.base};
  width: 16px;
  height: 16px;
`;

export const NewChatButton = styled(IconButton)`
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.text};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const UserSearchModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

export const UserSearchContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  width: 90%;
  max-width: 440px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};

  h3 {
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
  }

  .search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .close-button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    padding: 4px;

    &:hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.border};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent.purple.light};
    border-radius: 3px;
  }
`;

export const UserItem = styled(motion.div)`
  padding: 12px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-color: ${({ theme }) => theme.colors.accent.purple.light};
    transform: translateY(-1px);
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h4 {
      color: ${({ theme }) => theme.colors.text};
      font-size: 14px;
      margin: 0;
    }

    p {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 12px;
      margin: 0;
    }
  }
`;

export const ChatOptionsMenu = styled(motion.div)`
  position: absolute;
  top: 60px;
  right: 20px;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(20px);
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  overflow: hidden;
  z-index: 100;
`;

export const ChatOptionItem = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: ${({ theme, $danger }) =>
    $danger ? theme.colors.accent.error.base : theme.colors.text};
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme, $danger }) =>
      $danger ? theme.colors.accent.error.light : theme.colors.buttonGradient};
  }
`;

export const CountBadge = styled.span`
  background: ${({ theme, $active }) =>
    $active
      ? `linear-gradient(45deg, ${theme.colors.accent.purple.base}, ${theme.colors.accent.purple.dark})`
      : theme.colors.buttonGradient};
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  min-width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.text : theme.colors.secondary};
`;

export const UnreadBadge = styled(CountBadge)`
  margin-left: auto;
  background: ${({ theme }) => theme.colors.accent.purple.base};
`;

export const ModalButton = styled(motion.button)`
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  align-items: center;
  gap: 8px;

  ${props => props.$danger ? `
    background: ${props.theme.colors.accent.error.light};
    border: 1px solid ${props.theme.colors.accent.error.base};
    color: ${props.theme.colors.accent.error.base};

    &:hover {
      background: ${props.theme.colors.accent.error.base};
      border-color: ${props.theme.colors.accent.error.dark};
      color: ${props.theme.colors.text};
      transform: translateY(-1px);
    }
  ` : props.$primary ? `
    background: ${props.theme.colors.buttonGradient};
    border: 1px solid ${props.theme.colors.accent.purple.light};
    color: ${props.theme.colors.text};

    &:hover {
      background: ${props.theme.colors.buttonGradientHover};
      border-color: ${props.theme.colors.accent.purple.base};
      transform: translateY(-1px);
    }
  ` : `
    background: ${props.theme.colors.surfaceHover};
    border: 1px solid ${props.theme.colors.border};
    color: ${props.theme.colors.secondary};

    &:hover {
      background: ${props.theme.colors.buttonGradient};
      border-color: ${props.theme.colors.accent.purple.light};
      color: ${props.theme.colors.text};
      transform: translateY(-1px);
    }
  `}

  &:active {
    transform: translateY(1px);
  }
`;

export const EmptyStateContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  gap: 16px;
  padding: 32px;
  text-align: center;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 24px;
  margin: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  backdrop-filter: blur(10px);

  svg {
    width: 48px;
    height: 48px;
    color: ${({ theme }) => theme.colors.accent.purple.light};
    margin-bottom: 16px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    background: linear-gradient(
      45deg,
      ${({ theme }) => theme.colors.accent.purple.base},
      ${({ theme }) => theme.colors.accent.purple.dark}
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 15px;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.secondary};
    max-width: 320px;
    margin: 0;
  }
`;

export const ChatSearchContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  justify-self: center;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

export const ChatSearchInput = styled(Input)`
  height: 36px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  padding-left: 36px;
  font-size: 13px;

  &:focus {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ChatSearchIcon = styled(Search)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.accent.purple.base};
  width: 16px;
  height: 16px;
  pointer-events: none;
  transition: color 0.2s ease;

  ${ChatSearchContainer}:hover & {
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }

  ${ChatSearchInput}:focus + & {
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

export const LoadingWaveContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WaveEffect = styled.div`
  position: absolute;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    ${({ theme }) => theme.colors.accent.purple.light},
    ${({ theme }) => theme.colors.accent.purple.base},
    ${({ theme }) => theme.colors.accent.purple.light},
    transparent
  );
  animation: waveAnimation 2s linear infinite;
  transform-origin: center;
  opacity: 0.5;

  @keyframes waveAnimation {
    0% {
      transform: translateX(-50%) perspective(500px) rotateX(60deg) scaleY(0.5);
    }
    100% {
      transform: translateX(0%) perspective(500px) rotateX(60deg) scaleY(0.5);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(8px);
  }
`;

export const LoadingText = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 13px;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const ParticipantsModal = styled(motion.div)`
  position: absolute;
  top: 70px;
  left: 420px;
  width: 300px;
  max-height: 400px;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  z-index: 10;
  overflow: hidden;
  transform-origin: top left;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const ModalHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.surfaceHover};

  h3 {
    color: ${({ theme }) => theme.colors.text};
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
`;

export const CloseButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.buttonGradient};
    transform: translateY(-1px);
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ParticipantList = styled(UserList)`
  padding: 12px;
`;

export const ParticipantItem = styled(UserItem)`
  .name {
    font-size: 14px;
    font-weight: 500;
  }
  
  .email {
    font-size: 13px;
  }
`;

export const ParticipantsButton = styled(motion.button)`
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.base};
  border-radius: 8px;
  padding: 4px 12px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }

  svg {
    width: 14px;
    height: 14px;
    color: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;
`;

export const Tab = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: ${({ theme, $active }) =>
    $active ? theme.colors.buttonGradient : "transparent"};
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.accent.purple.light : theme.colors.border};
  border-radius: 12px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.text : theme.colors.secondary};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  .tab-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.light};
    transform: translateY(-1px);
  }

  span {
    background: ${({ theme, $active }) =>
      $active
        ? `linear-gradient(45deg, ${theme.colors.accent.purple.base}, ${theme.colors.accent.purple.dark})`
        : theme.colors.buttonGradient};
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    min-width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ $active }) => ($active ? "#fff" : "inherit")};
  }
`;

export const ChatHistoryModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
  perspective: 1000px;
`;

export const ChatHistoryContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 600px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const ChatHistoryList = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  margin: 20px 0;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.border};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent.purple.light};
    border-radius: 3px;
  }
`;

export const ChatHistoryItem = styled(motion.div)`
  padding: 16px;
  margin: 8px 0;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};

  .timestamp {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 8px;
  }

  .content {
    font-size: 14px;
    line-height: 1.6;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.colors.shadow};
  }
`;

export const EmptyStateText = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  text-align: center;
  margin: 24px 0;
`;

export const ArchiveConfirmationModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.glass.background};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
  perspective: 1000px;
`;

export const ArchiveConfirmationContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  position: relative;
  overflow: hidden;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.accent.purple.light},
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const ArchiveTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 8px 0;
`;

export const ArchiveDescription = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  margin: 0 0 24px 0;
  line-height: 1.5;
`;

export const ArchiveActions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

export const UserSearchEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  color: ${({ theme }) => theme.colors.secondary};
  
  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
    stroke: ${({ theme }) => theme.colors.accent.purple.light};
  }

  h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    margin: 0 0 8px 0;
  }

  p {
    font-size: 14px;
    text-align: center;
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ChatItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ChatItemInfo = styled.div`
  flex: 1;
`;

export const EditableInput = styled(motion.input)`
  width: 100%;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1.5px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 8px;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;

  &:focus {
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    outline: none;
  }
`;

export const EditIndicator = styled(motion.span)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: ${({ theme }) => theme.colors.accent.purple.base};
  pointer-events: none;
`;

export const NoResultsText = styled.div`
  text-align: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
`;

export const SearchErrorText = styled.p`
  color: ${({ theme }) => theme.colors.accent.error.base};
  font-size: 13px;
  margin: 8px 0;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    margin: 0;
  }
`;

export const CloseIconButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.buttonGradient};
    transform: translateY(-1px);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
