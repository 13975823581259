import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SwarmLogo from "../common/SwarmLogo";

const NotFoundContainer = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: linear-gradient(
    165deg,
    rgba(18, 18, 24, 0.98),
    rgba(14, 14, 20, 0.99)
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  perspective: 1000px;
  overflow: hidden;
  z-index: 1000;
`;

const GlowingBackground = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(136, 83, 251, 0.08),
    transparent 70%
  );
  opacity: 0.6;
  z-index: -1;
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  transform-style: preserve-3d;
`;

const SwarmWrapper = styled(motion.div)`
  width: 180px;
  height: 180px;
  transform-style: preserve-3d;
  animation: swarmFloat 8s ease-in-out infinite;

  @keyframes swarmFloat {
    0%,
    100% {
      transform: translateY(0) rotateY(0) rotateX(0);
    }
    25% {
      transform: translateY(-15px) rotateY(15deg) rotateX(5deg);
    }
    75% {
      transform: translateY(15px) rotateY(-15deg) rotateX(-5deg);
    }
  }
`;

const ErrorText = styled(motion.h1)`
  font-size: 120px;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(
    135deg,
    rgba(136, 83, 251, 1),
    rgba(136, 83, 251, 0.5)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 0 40px rgba(136, 83, 251, 0.3);
  letter-spacing: -2px;
`;

const MessageText = styled(motion.p)`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
  margin: 0;
  font-weight: 500;
`;

const HomeButton = styled(motion.button)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  padding: 16px 32px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  margin-top: 20px;

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <NotFoundContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <GlowingBackground
        animate={{
          opacity: [0.4, 0.6, 0.4],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "linear",
        }}
      />

      <ContentWrapper>
        <SwarmWrapper>
          <SwarmLogo size="180px" active={true} enableHover={false} />
        </SwarmWrapper>

        <ErrorText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8, type: "spring" }}
        >
          404
        </ErrorText>

        <MessageText
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8, type: "spring" }}
        >
          The page you're looking for seems to have drifted into another
          dimension.
        </MessageText>

        <HomeButton
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8, type: "spring" }}
          onClick={() => navigate("/home/buy")}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
        >
          Return Home
        </HomeButton>
      </ContentWrapper>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
