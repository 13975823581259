import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SwarmAlive from "./SwarmAlive";
import { motion } from "framer-motion";
import { keyframes } from "styled-components";

gsap.registerPlugin(ScrollTrigger);

const wobble = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(1.02) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.98) rotate(-1deg);
  }
  75% {
    transform: translate(-50%, -50%) scale(1.02) rotate(0.5deg);
  }
`;

const pulseNeon = keyframes`
  0%, 100% {
    box-shadow: 0 0 15px rgba(136, 83, 251, 0.7),
                0 0 30px rgba(136, 83, 251, 0.5),
                0 0 45px rgba(136, 83, 251, 0.3),
                inset 0 0 15px rgba(136, 83, 251, 0.7),
                inset 0 0 30px rgba(136, 83, 251, 0.5);
    border-color: rgba(136, 83, 251, 0.8);
  }
  50% {
    box-shadow: 0 0 25px rgba(136, 83, 251, 0.9),
                0 0 50px rgba(136, 83, 251, 0.7),
                0 0 75px rgba(136, 83, 251, 0.5),
                inset 0 0 25px rgba(136, 83, 251, 0.9),
                inset 0 0 50px rgba(136, 83, 251, 0.7);
    border-color: rgba(136, 83, 251, 1);
  }
`;

const SectionContainer = styled.section`
  min-height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 40px 0px;
  background: linear-gradient(
    180deg,
    rgba(13, 13, 13, 0.8) 0%,
    rgba(13, 13, 13, 0) 100%
  );
  will-change: transform;
  transform-style: preserve-3d;
`;
const BubbleContainer = styled.div`
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 2px solid rgba(136, 83, 251, 0.8);
  box-shadow: 0 0 15px rgba(136, 83, 251, 0.7), 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(136, 83, 251, 0.7),
    inset 0 0 30px rgba(136, 83, 251, 0.5);
  animation: ${pulseNeon} 2s ease-in-out infinite;
  background: rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(5px);
  will-change: transform, box-shadow, border-color;
`;
const ParticleWrapper = styled.div`
  position: relative;
  width: 600px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${wobble} 10s ease-in-out infinite;
`;
const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-direction: row-reverse;
  padding: 0 40px;

  @media (max-width: 968px) {
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  opacity: 0;
  transform: translateY(30px);
`;

const Title = styled.h2`
  font-size: clamp(2rem, 4vw, 3rem);
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0.8) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.2;
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 500px;
`;

const VisualizationContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 300px;
  opacity: 0;
  transform: translateX(-30px);
  will-change: transform, opacity;

  @media (max-width: 968px) {
    min-height: 250px;
  }
`;

const VisualSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  height: 100%;
  margin-top: 70vh;
  margin-left: 20vw;
`;

const highlightFloat = keyframes`
  0%, 100% {
    transform: translate(0, 0);
    opacity: 0.3;
  }
  50% {
    transform: translate(1px, 1px);
    opacity: 0.4;
  }
`;
const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(136, 83, 251, 0.3) 0%,
    rgba(136, 83, 251, 0.1) 20%,
    transparent 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.5);
  box-shadow: inset 0 0 30px rgba(136, 83, 251, 0.5),
    inset 0 0 15px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
  opacity: 0.9;
  will-change: transform, opacity;
`;

const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4),
    transparent 80%
  );
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: ${highlightFloat} 4s ease-in-out infinite;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;

  @media (max-width: 768px) {
    gap: 16px;
    padding: 0 20px;
  }
`;

const Card = styled.div`
  background: radial-gradient(
    circle at 50% 0%,
    rgba(136, 83, 251, 0.35),
    rgba(136, 83, 251, 0.25) 70%
  );
  border: 1px solid rgba(136, 83, 251, 0.4);
  border-radius: 16px;
  padding: 30px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  transform: translateY(20px);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  margin: 0;

  @media (max-width: 768px) {
    margin: 8px 0;
  }

  &:hover {
    transform: translateY(-5px);
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.45),
      rgba(136, 83, 251, 0.35) 70%
    );
    border-color: rgba(136, 83, 251, 0.5);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
  }
`;

const SolutionSection = () => {
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const visualRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const textContent = section.querySelector(TextContent);
    const visualContent = section.querySelector(VisualizationContainer);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    tl.to(textContent, {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: "power3.out",
    }).to(
      visualContent,
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power3.out",
      },
      "-=0.5"
    );

    return () => {
      const triggers = ScrollTrigger.getAll().filter(
        (trigger) => trigger.vars.trigger === section
      );
      triggers.forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef} data-scroll-section>
      <ContentWrapper>
        <TextContent ref={textRef}>
          <Title>One platform, all your deed and lien data</Title>
          <Description>
            DeedFinders brings all your tax deed and lien insights into a
            single, easy-to-use platform. Explore, evaluate, and take action
            seamlessly—no more juggling multiple tools or losing track of
            crucial details.
          </Description>
        </TextContent>
        <VisualizationContainer ref={visualRef}>
          <VisualSection>
            <ParticleWrapper>
              <BubbleContainer>
                <BubbleSkin
                  animate={{
                    scale: 1.02,
                    opacity: 0.9,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 20,
                  }}
                />
                <Highlight />
              </BubbleContainer>
              <SwarmAlive
                size="350px"
                enableHover={true}
                active={true}
                mode="normal"
                instanceId="solution-section-swarm"
              />
            </ParticleWrapper>
          </VisualSection>
        </VisualizationContainer>
      </ContentWrapper>
    </SectionContainer>
  );
};

export default SolutionSection;
