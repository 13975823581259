import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  Plus,
  ChevronDown,
  Check,
  BarChart,
  Eye,
  Settings,
} from "react-feather";
import {
  Navigate,
  useNavigate,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import LoadingOverlay from "../common/LoadingOverlay";
import GraphViewWallet from "../common/GraphViewWallet";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { useWallet } from "../../context/WalletContext";
import { loadStripe } from "@stripe/stripe-js";
import { format } from "date-fns";
import { toast } from "react-hot-toast";

const CREDIT_LIMITS = {
  FREE: 15,
  UNLIMITED: Infinity,
};

const calculateViewsFromAmount = (amount) => {
  const costPerView = 0.2;
  return Math.floor(amount / costPerView);
};

const pageVariants = {
  initial: { opacity: 0, scale: 0.98 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.3,
    },
  },
};

const sectionVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1],
    },
  },
};

const PageContainer = styled(motion.div)`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ContentWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(40px);
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  padding: 24px 0 0px;
  overflow: visible;
`;

const CurrentPlanSection = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  margin: 0 30px;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  position: relative;
  overflow: hidden;
`;

const PlanTopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PlanMainRow = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  width: 100%;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
`;

const ManagePlanButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  svg {
    width: 16px;
    height: 16px;
    color: rgba(136, 83, 251, 0.9);
  }
  &:hover {
    transform: translateY(-1px);
  }
`;

const PlanTypeDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PlanTypeLabel = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
`;

const PlanTypeName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BillingInfo = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 4px;
`;

const UsageStatsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 16px;
  backdrop-filter: blur(10px);
  gap: 32px;

  .unlimited-views-text {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const UsageStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  min-width: 0;
`;

const UsageBar = styled.div`
  width: 100%;
  height: 6px;
  background: ${({ theme }) => `${theme.colors.primary}10`};
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 8px 0;
  box-shadow: inset 0 0 4px rgba(136, 83, 251, 0.2),
    0 0 8px rgba(136, 83, 251, 0.1);
`;

const UsageProgress = styled(motion.div)`
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  width: ${(props) => Math.min(props.$progress || 0, 100)}%;
  position: relative;
  box-shadow: 0 0 10px rgba(136, 83, 251, 0.4), 0 0 20px rgba(136, 83, 251, 0.2),
    0 0 30px rgba(136, 83, 251, 0.1);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    animation: shimmer 2s infinite;
  }
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const CreditStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: right;
  padding-left: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  .credit-label {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.secondary};
  }
  .credit-value {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
  }
  .frozen-credits {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
  }
  .frozen-label {
    font-size: 13px;
    color: rgba(255, 149, 0, 0.9);
  }
  .frozen-value {
    font-size: 14px;
    color: rgba(255, 149, 0, 0.9);
    font-weight: 500;
  }
  .available-after {
    font-size: 12px;
    color: rgba(255, 149, 0, 0.7);
    margin-top: 2px;
  }
`;

const PaymentButton = styled(ManagePlanButton)`
  background: ${({ theme }) => theme.colors.buttonGradientAlt};
  border: 1px solid rgba(136, 83, 251, 0.2);
  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradientHover};
  }
`;

const IntervalSelectionContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 40px auto 0;
`;

const IntervalSelection = styled.div`
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 4px;
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 auto 16px;
`;

const IntervalButton = styled.button`
  background: none;
  border: none;
  padding: 8px 24px;
  color: ${(props) =>
    props.$active ? props.theme.colors.text : props.theme.colors.secondary};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const IntervalSlider = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: calc(50% - 4px);
  background: ${({ theme }) => theme.colors.primary}15;
  border: 1px solid ${({ theme }) => theme.colors.primary}30;
  border-radius: 8px;
  transform: translateX(
    ${(props) => (props.$position === "right" ? "100%" : "0")}
  );
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 2px 8px ${({ theme }) => theme.colors.primary}15;
`;

const SavingsBadge = styled(motion.div)`
  position: absolute;
  top: -8px;
  right: -8px;
  background: ${({ theme }) => theme.colors.primary}15;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 2px 8px ${({ theme }) => theme.colors.primary}15;
  border: 1px solid ${({ theme }) => theme.colors.primary}30;
  backdrop-filter: blur(10px);
  transform-origin: center;
  animation: pulse 2s infinite;
  z-index: 100;
  pointer-events: none;
  @keyframes pulse {
    0% {
      box-shadow: 0 2px 8px ${({ theme }) => theme.colors.primary}15;
    }
    50% {
      box-shadow: 0 2px 12px ${({ theme }) => theme.colors.primary}30;
    }
    100% {
      box-shadow: 0 2px 8px ${({ theme }) => theme.colors.primary}15;
    }
  }
`;

const SavingsLabel = styled(motion.div)`
  position: absolute;
  top: -10px;
  right: -65px;
  background: ${({ theme }) => theme.colors.primary}15;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary}30;
  box-shadow: 0 2px 8px ${({ theme }) => theme.colors.primary}15;
  backdrop-filter: blur(10px);
  white-space: nowrap;
`;

const CardsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  padding: 16px 16px 24px;
  flex: 1;
  flex-wrap: nowrap;
  max-width: 1400px;
  margin: 12px auto 60px;
  width: 100%;
  height: calc(100vh - 560px);
  overflow: visible;
  position: relative;
  @media (max-width: 1200px) {
    overflow-x: auto;
    justify-content: flex-start;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    overflow-y: auto;
    padding-bottom: 60px;
  }
`;

const PlanCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  overflow: visible;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.colors.dividerGradient};
  margin: 0 34px;
  flex-shrink: 0;
  position: relative;
  &::before {
    content: "OR";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.colors.surface};
    padding: 12px;
    border-radius: 50%;
    color: rgba(136, 83, 251, 0.8);
    font-size: 12px;
    font-weight: 500;
    border: 1px solid rgba(136, 83, 251, 0.3);
    box-shadow: 0 0 20px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

const PricingCard = styled(motion.div)`
  position: relative;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  width: 280px;
  height: 100%;
  max-height: 440px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  backdrop-filter: blur(40px);
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ theme }) => theme.colors.radialGradient};
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  &:hover {
    transform: translateY(-5px);
    border-color: rgba(136, 83, 251, 0.3);
    &::before {
      opacity: 1;
    }
  }
  @media (min-width: 1400px) {
    width: 320px;
    padding: 28px;
  }
  @media (max-width: 1366px) {
    width: 260px;
    padding: 20px;
  }
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 4px;
  overflow: hidden;
  position: relative;
`;

const PlanName = styled.h3`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  padding-top: 8px;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.gradientLine};
  }
`;

const PlanPrice = styled.div`
  font-size: 2.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
  margin: 8px 0 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  min-height: 60px;
`;

const CurrentPrice = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  white-space: nowrap;
`;

const OriginalPrice = styled(motion.div)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: line-through;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const AccessCount = styled.div`
  margin: 12px 0;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  text-align: center;
`;

const AccessNumber = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4px;
`;

const AccessPeriod = styled.div`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.text};
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(136, 83, 251, 0.2) transparent;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 2px;
  }
  li {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-size: 0.85rem;
    line-height: 1.4;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SelectButton = styled(motion.button)`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 12px;
  background: ${(props) =>
    props.$isCurrentPlan
      ? "rgba(136, 83, 251, 0.1)"
      : props.$plan === "payAsYouGo" && props.$isUnlimitedPlan
      ? "linear-gradient(165deg, rgba(136,83,251,0.2), rgba(96,57,176,0.2))"
      : "linear-gradient(165deg, rgba(136,83,251,0.8), rgba(96,57,176,0.8))"};
  color: ${(props) =>
    props.$isCurrentPlan ? "rgba(136,83,251,0.6)" : "rgba(255,255,255,0.95)"};
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  border: 1px solid
    ${(props) =>
      props.$isCurrentPlan ? "rgba(136,83,251,0.2)" : "rgba(136,83,251,0.1)"};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  position: relative;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover:not(:disabled) {
    background: ${(props) =>
      props.$isCurrentPlan
        ? "rgba(136,83,251,0.15)"
        : props.$plan === "payAsYouGo" && props.$isUnlimitedPlan
        ? "linear-gradient(165deg, rgba(136,83,251,0.25), rgba(96,57,176,0.25))"
        : "linear-gradient(165deg, rgba(136,83,251,0.9), rgba(96,57,176,0.9))"};
    border-color: rgba(136, 83, 251, 0.3);
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

const PayAsYouGoBar = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  width: 280px;
  height: 100%;
  max-height: 440px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  backdrop-filter: blur(40px);
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  &:hover {
    transform: translateY(-5px);
    border-color: rgba(136, 83, 251, 0.3);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ theme }) => theme.colors.radialGradient};
    pointer-events: none;
  }
  @media (min-width: 1400px) {
    width: 300px;
    padding: 28px;
  }
`;

const PayAsYouGoContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PayAsYouGoPrice = styled.div`
  font-size: 2.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
  margin: 4px 0 0;
  display: flex;
  align-items: baseline;
  gap: 4px;
  height: 50px;
`;

const PriceSubtext = styled.div`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin: -4px 0 12px;
`;

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 4px 0;
  z-index: 100;
`;

const PayAsYouGoDropdown = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 10px;
  position: relative;
`;

const DropdownHeader = styled.div`
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  &:hover {
    background: ${({ theme }) => theme.colors.surfaceHover};
  }
`;

const DropdownOptions = styled(motion.div)`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-height: 180px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 2px;
  }
`;

const DropdownOption = styled.div`
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  transition: background 0.2s ease;
  &:hover {
    background: ${({ theme }) => theme.colors.surfaceHover};
  }
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
    background: ${theme.colors.primary}15;
  `}
`;

const CheckMark = styled(motion.div)`
  color: #8853fb;
  display: flex;
  align-items: center;
`;

const LoadingSpinner = styled(motion.div)`
  width: ${(props) => props.size || 20}px;
  height: ${(props) => props.size || 20}px;
  border: 2px solid rgba(136, 83, 251, 0.3);
  border-top: 2px solid rgba(136, 83, 251, 0.9);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  padding: 8px;
  background: rgba(255, 77, 77, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(255, 77, 77, 0.2);
`;

const NewPricingPage = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [selectedAmount, setSelectedAmount] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [timeframe, setTimeframe] = useState("7d");
  const [viewsData, setViewsData] = useState([]);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [metrics, setMetrics] = useState({
    averageViewsPerProperty: 0,
    viewsChange: 0,
    engagementRate: 0,
  });
  const optionsRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { walletData, createCheckoutSession, subscriptionPlans } = useWallet();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/wallet/payment-methods`,
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSavedPaymentMethods(data.paymentMethods);
        }
      } catch (error) {}
    };
    fetchPaymentMethods();
  }, []);

  const rechargeAmounts = [
    { value: 10, label: t("creditAmount", { amount: "10", views: "50" }) },
    { value: 25, label: t("creditAmount", { amount: "25", views: "125" }) },
    { value: 50, label: t("creditAmount", { amount: "50", views: "250" }) },
    { value: 100, label: t("creditAmount", { amount: "100", views: "500" }) },
    { value: 200, label: t("creditAmount", { amount: "200", views: "1000" }) },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");
    if (sessionId) {
      navigate("/home/wallet");
    }
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path.endsWith("/plans")) {
      setShowPlans(true);
      setShowAnalytics(false);
    } else if (path.endsWith("/analytics")) {
      setShowPlans(false);
      setShowAnalytics(true);
    } else {
      setShowPlans(false);
      setShowAnalytics(true);
      navigate("analytics", { replace: true });
    }
  }, [location.pathname, navigate]);

  const fetchViewsData = async () => {
    setIsLoadingChart(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/views/analytics?timeframe=${timeframe}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch analytics data");
      const data = await response.json();
      setMetrics(data.metrics);
      const formattedData = data.timeseriesData.map((item) => ({
        date: format(new Date(item._id), "MM/dd"),
        views: item.count,
      }));
      setViewsData(formattedData);
    } catch (error) {
    } finally {
      setIsLoadingChart(false);
    }
  };

  useEffect(() => {
    fetchViewsData();
  }, [timeframe]);

  if (loading) return <LoadingOverlay />;

  const handleSubscribe = async (planName, interval) => {
    try {
      setError(null);
      setIsProcessing(true);
      const hasPaymentMethod = walletData?.user?.hasPaymentMethod;
      const response = await createCheckoutSession(
        planName,
        interval,
        null,
        !hasPaymentMethod
      );
      if (response.url) {
        window.location.href = response.url;
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      setError(error.message);
      toast.error("Failed to create checkout session. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleViewSwitch = (view) => {
    if (view === "plans") {
      setShowPlans(true);
      setShowAnalytics(false);
      navigate("plans");
    } else if (view === "analytics") {
      setShowPlans(false);
      setShowAnalytics(true);
      navigate("analytics");
    }
  };

  const hasUnlimitedPlan =
    walletData?.subscription?.status === "active" &&
    walletData.subscription.plan === "UNLIMITED";

  const handleAddCredits = async () => {
    if (!selectedAmount) {
      toast.error(t("pleaseSelectAmount"));
      return;
    }
    setIsProcessing(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/create-credit-checkout-session`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            amount: parseInt(selectedAmount, 10),
            paymentMethodId: selectedPaymentMethod,
            locale: i18n.language,
          }),
        }
      );
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || t("failedToCreateCheckoutSession"));
      }
      const data = await response.json();
      if (data.sessionId) {
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        await stripe.redirectToCheckout({ sessionId: data.sessionId });
      }
    } catch (error) {
      toast.error(error.message || t("failedToProcessPayment"));
    } finally {
      setIsProcessing(false);
    }
  };

  const renderPlanStatus = () => {
    const subscription = walletData?.subscription;
    if (!subscription) return null;
    const { status, cancelAtPeriodEnd, nextBillingDate } = subscription;
    if (status === "canceled") {
      return <></>;
    }
    if (cancelAtPeriodEnd && nextBillingDate) {
      return (
        <BillingInfo style={{ color: "rgba(255,149,0,0.9)" }}>
          {t("planWillBeCanceledOn", {
            date: new Date(nextBillingDate).toLocaleDateString(
              i18n.language === "ptbr" ? "pt-BR" : "en-US"
            ),
          })}
        </BillingInfo>
      );
    }
    if (nextBillingDate) {
      return (
        <BillingInfo>
          {t("nextBillingOn", {
            date: new Date(nextBillingDate).toLocaleDateString(
              i18n.language === "ptbr" ? "pt-BR" : "en-US"
            ),
          })}
        </BillingInfo>
      );
    }
    return null;
  };

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <PageContainer
          as={motion.div}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageVariants}
        >
          <ContentWrapper>
            <motion.div variants={sectionVariants}>
              <CurrentPlanSection>
                <PlanTopSection>
                  <PlanMainRow>
                    <PlanInfo>
                      <PlanTypeDisplay>
                        <PlanTypeLabel>{t("currentPlanLabel")}</PlanTypeLabel>
                        <PlanTypeName>
                          {(() => {
                            const subscription = walletData?.subscription;
                            const plan = subscription?.plan || "FREE";
                            const status = subscription?.status;
                            const cancelAtPeriodEnd =
                              subscription?.cancelAtPeriodEnd;
                            let interval = t("free");
                            if (subscription?.stripeSubscriptionId) {
                              interval =
                                subscription.interval === "Monthly"
                                  ? t("monthly")
                                  : t("annual");
                            }
                            let planDisplay = plan;
                            if (plan === "UNLIMITED") {
                              planDisplay = (
                                <>
                                  {t("unlimited")}
                                  <span
                                    style={{ fontSize: "14px", opacity: 0.7 }}
                                  >
                                    •
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "rgba(136,83,251,0.9)",
                                    }}
                                  >
                                    {status === "canceled" || cancelAtPeriodEnd
                                      ? t("activeUntilEndOfBilling")
                                      : t("unlimitedViews")}
                                  </span>
                                </>
                              );
                            }
                            return (
                              <>
                                {planDisplay}
                                <span
                                  style={{ fontSize: "14px", opacity: 0.7 }}
                                >
                                  •
                                </span>
                                {interval}
                              </>
                            );
                          })()}
                        </PlanTypeName>
                      </PlanTypeDisplay>
                      {renderPlanStatus()}
                    </PlanInfo>
                    <ActionButtons>
                      {walletData?.subscription?.plan === "UNLIMITED" &&
                        walletData?.subscription?.status === "active" && (
                          <Tippy
                            content={t("manageBillingAndPayment")}
                            placement="top"
                            animation="shift-away"
                            arrow={true}
                            duration={[300, 250]}
                            delay={[400, 0]}
                            theme="modern"
                          >
                            <PaymentButton
                              onClick={() =>
                                handleSubscribe("UNLIMITED", selectedInterval)
                              }
                              disabled={isProcessing}
                            >
                              <Settings size={16} /> {t("billingPortal")}
                            </PaymentButton>
                          </Tippy>
                        )}
                      <Tippy
                        content={
                          !showPlans
                            ? t("compareAndUpgradePlans")
                            : t("viewUsageAnalytics")
                        }
                        placement="top"
                        animation="shift-away"
                        arrow={true}
                        duration={[300, 250]}
                        delay={[400, 0]}
                        theme="modern"
                        disabled={showPlans}
                      >
                        <ManagePlanButton
                          onClick={() => {
                            if (showPlans) {
                              handleViewSwitch("analytics");
                            } else {
                              handleViewSwitch("plans");
                            }
                          }}
                        >
                          {showPlans ? (
                            <>
                              <BarChart size={16} /> {t("viewUsageAnalytics")}
                            </>
                          ) : (
                            <>
                              <Plus size={16} /> {t("compareAndUpgradePlans")}
                            </>
                          )}
                        </ManagePlanButton>
                      </Tippy>
                    </ActionButtons>
                  </PlanMainRow>
                  <UsageStatsContainer>
                    <UsageStats>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <Tippy
                          content={t("viewsTooltip")}
                          placement="top"
                          animation="shift-away"
                          arrow={true}
                          duration={[300, 250]}
                          delay={[400, 0]}
                          theme="modern"
                        >
                          <div>
                            <Eye size={16} color="rgba(136,83,251,0.9)" />
                          </div>
                        </Tippy>
                        <div
                          className="unlimited-views-text"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {(() => {
                            const plan = walletData?.subscription?.plan;
                            const status = walletData?.subscription?.status;
                            const cancelAtPeriodEnd =
                              walletData?.subscription?.cancelAtPeriodEnd;
                            const isUnlimitedCanceling =
                              plan === "UNLIMITED" &&
                              (status === "canceled" || cancelAtPeriodEnd);
                            if (isUnlimitedCanceling)
                              return t("unlimitedViewsUntilPlanEnds");
                            if (plan === "UNLIMITED")
                              return t("unlimitedPropertyViews");
                            if (plan === "FREE")
                              return t("monthlyPropertyViews");
                            return t("monthlyPropertyViews");
                          })()}
                        </div>
                      </div>
                      <div
                        className="unlimited-views-text"
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          marginTop: "4px",
                        }}
                      >
                        {(() => {
                          const plan = walletData?.subscription?.plan;
                          const credits = walletData?.subscription?.credits;
                          const status = walletData?.subscription?.status;
                          const cancelAtPeriodEnd =
                            walletData?.subscription?.cancelAtPeriodEnd;
                          const isUnlimitedCanceling =
                            plan === "UNLIMITED" &&
                            (status === "canceled" || cancelAtPeriodEnd);
                          if (isUnlimitedCanceling) {
                            return (
                              <>
                                {t("unlimitedViews")}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    color: "rgba(255,149,0,0.9)",
                                    marginLeft: "8px",
                                  }}
                                >
                                  (
                                  {t("frozenCreditsCount", {
                                    count:
                                      walletData?.subscription?.frozenCredits ||
                                      0,
                                  })}
                                  )
                                </span>
                              </>
                            );
                          }
                          if (plan === "UNLIMITED") return t("unlimitedViews");
                          return t("viewsCount", {
                            count:
                              typeof credits === "number"
                                ? credits
                                : plan === "FREE"
                                ? 12
                                : 0,
                          });
                        })()}
                      </div>
                      {(() => {
                        const plan = walletData?.subscription?.plan;
                        const credits = walletData?.subscription?.credits || 0;
                        const status = walletData?.subscription?.status;
                        const cancelAtPeriodEnd =
                          walletData?.subscription?.cancelAtPeriodEnd;
                        const isUnlimitedCanceling =
                          plan === "UNLIMITED" &&
                          (status === "canceled" || cancelAtPeriodEnd);
                        if (plan === "UNLIMITED" && !isUnlimitedCanceling) {
                          return null;
                        }
                        if (plan === "FREE" && credits <= CREDIT_LIMITS.FREE) {
                          return (
                            <Tippy
                              content={t("usedCreditsPercentage", {
                                percentage: Math.round(
                                  100 - (credits / CREDIT_LIMITS.FREE) * 100
                                ),
                              })}
                              placement="top"
                              animation="shift-away"
                              arrow={true}
                              duration={[300, 250]}
                              delay={[400, 0]}
                              theme="modern"
                            >
                              <UsageBar>
                                <UsageProgress
                                  $progress={
                                    100 - (credits / CREDIT_LIMITS.FREE) * 100
                                  }
                                  initial={{ width: 0 }}
                                  animate={{
                                    width: `${
                                      100 - (credits / CREDIT_LIMITS.FREE) * 100
                                    }%`,
                                  }}
                                  transition={{
                                    duration: 1,
                                    ease: [0.16, 1, 0.3, 1],
                                  }}
                                />
                              </UsageBar>
                            </Tippy>
                          );
                        }
                        return null;
                      })()}
                    </UsageStats>
                    {(() => {
                      const plan = walletData?.subscription?.plan;
                      const status = walletData?.subscription?.status;
                      const cancelAtPeriodEnd =
                        walletData?.subscription?.cancelAtPeriodEnd;
                      const frozenCredits =
                        walletData?.subscription?.frozenCredits || 0;
                      const nextBillingDate =
                        walletData?.subscription?.nextBillingDate;
                      const isUnlimitedActive =
                        plan === "UNLIMITED" && status === "active";
                      const isUnlimitedCanceling =
                        plan === "UNLIMITED" &&
                        (status === "canceled" || cancelAtPeriodEnd);
                      if (isUnlimitedActive && frozenCredits > 0) {
                        return (
                          <CreditStats>
                            <div>
                              <div className="credit-label">
                                {t("currentPlanLabel")}
                              </div>
                              <div className="credit-value">
                                {t("unlimitedViews")}
                              </div>
                            </div>
                            <div className="frozen-credits">
                              <div className="frozen-label">
                                {t("frozenCredits")}
                              </div>
                              <div className="frozen-value">
                                {t("viewsCount", { count: frozenCredits })}
                              </div>
                              <div className="available-after">
                                {t("availableAfter")}
                              </div>
                            </div>
                          </CreditStats>
                        );
                      }
                      if (isUnlimitedCanceling && frozenCredits > 0) {
                        return (
                          <CreditStats>
                            <div>
                              <div className="frozen-label">
                                {t("frozenCredits")}
                              </div>
                              <div className="frozen-value">
                                {t("viewsCount", { count: frozenCredits })}
                              </div>
                            </div>
                            <div>
                              <div className="frozen-label">
                                {t("availableAfter")}
                              </div>
                              <div className="frozen-value">
                                {nextBillingDate
                                  ? new Date(
                                      nextBillingDate
                                    ).toLocaleDateString(i18n.language)
                                  : t("planEnds")}
                              </div>
                            </div>
                          </CreditStats>
                        );
                      }
                      return null;
                    })()}
                  </UsageStatsContainer>
                </PlanTopSection>
              </CurrentPlanSection>
            </motion.div>
            <Routes>
              <Route path="/" element={<Navigate to="analytics" replace />} />
              <Route
                path="analytics"
                element={
                  <motion.div
                    variants={sectionVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <GraphViewWallet expanded={true} />
                  </motion.div>
                }
              />
              <Route
                path="plans"
                element={
                  <motion.div
                    variants={sectionVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <IntervalSelectionContainer>
                      <IntervalSelection>
                        <IntervalButton
                          type="button"
                          $active={selectedInterval === "monthly"}
                          onClick={() => setSelectedInterval("monthly")}
                        >
                          {t("monthly")}
                        </IntervalButton>
                        <IntervalButton
                          type="button"
                          $active={selectedInterval === "annual"}
                          onClick={() => setSelectedInterval("annual")}
                        >
                          {t("annual")}
                        </IntervalButton>
                        <IntervalSlider
                          $position={
                            selectedInterval === "annual" ? "right" : "left"
                          }
                        />
                      </IntervalSelection>
                      <AnimatePresence>
                        {selectedInterval === "annual" && (
                          <SavingsLabel
                            initial={{ opacity: 0, scale: 0, y: -20 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0, y: -20 }}
                            transition={{
                              type: "spring",
                              stiffness: 500,
                              damping: 30,
                            }}
                          >
                            {t("save17")}
                          </SavingsLabel>
                        )}
                      </AnimatePresence>
                    </IntervalSelectionContainer>
                    <CardsContainer>
                      <PlanCardContainer>
                        {Object.entries(subscriptionPlans)
                          .filter(([key]) => key === "UNLIMITED")
                          .map(([key, plan]) => {
                            const displayPrice = plan?.prices?.[
                              selectedInterval
                            ]?.amount
                              ? plan.prices[selectedInterval].amount.toFixed(2)
                              : "0.00";
                            const isCurrentPlan =
                              walletData?.subscription?.plan === key;
                            const currentInterval =
                              walletData?.subscription?.interval;
                            const selectedIntervalFormatted =
                              selectedInterval === "annual"
                                ? "Annual"
                                : "Monthly";
                            const isExactMatch =
                              isCurrentPlan &&
                              currentInterval === selectedIntervalFormatted;
                            return (
                              <PricingCard
                                key={key}
                                $featured={true}
                                $interval={selectedInterval}
                                $plan={key}
                                $isCurrentPlan={isExactMatch}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                  delay: 0.3,
                                  duration: 0.5,
                                  ease: [0.16, 1, 0.3, 1],
                                }}
                              >
                                <AnimatePresence>
                                  {selectedInterval === "annual" && (
                                    <SavingsBadge>{t("save17")}</SavingsBadge>
                                  )}
                                </AnimatePresence>
                                <CardContent>
                                  <PlanName>
                                    {typeof plan.name === "string"
                                      ? plan.name
                                      : i18n.language === "ptbr"
                                      ? plan.name.ptbr
                                      : plan.name.en}
                                  </PlanName>
                                  <PlanPrice>
                                    <CurrentPrice>
                                      <AnimatePresence mode="wait">
                                        <motion.div
                                          key={`${displayPrice}-${selectedInterval}`}
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
                                          exit={{ opacity: 0 }}
                                          transition={{ duration: 0.2 }}
                                          style={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            gap: "4px",
                                          }}
                                        >
                                          <span>
                                            ${displayPrice}
                                            <span className="period">
                                              /
                                              {selectedInterval === "annual"
                                                ? t("yr")
                                                : t("mo")}
                                            </span>
                                          </span>
                                        </motion.div>
                                      </AnimatePresence>
                                    </CurrentPrice>
                                    {selectedInterval === "annual" && (
                                      <OriginalPrice
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                      >
                                        $
                                        {(
                                          plan.prices.monthly.amount * 12
                                        ).toFixed(2)}
                                        /{t("yr")}
                                      </OriginalPrice>
                                    )}
                                  </PlanPrice>
                                  <AccessCount>
                                    <AccessNumber>
                                      {t("unlimited")}
                                    </AccessNumber>
                                    <AccessPeriod>
                                      {t("propertyViews")}
                                    </AccessPeriod>
                                  </AccessCount>
                                  <FeaturesList>
                                    {plan.features &&
                                    Array.isArray(plan.features)
                                      ? plan.features.map((feature, index) => (
                                          <li key={index}>
                                            <span>{feature}</span>
                                          </li>
                                        ))
                                      : null}
                                  </FeaturesList>
                                </CardContent>
                                <SelectButton
                                  whileHover={{ scale: 1.02 }}
                                  whileTap={{ scale: 0.98 }}
                                  onClick={() =>
                                    handleSubscribe(key, selectedInterval)
                                  }
                                  $plan={key}
                                  $isCurrentPlan={isExactMatch}
                                  disabled={isProcessing || isExactMatch}
                                >
                                  {isProcessing ? (
                                    <LoadingSpinner size={20} />
                                  ) : (
                                    <span>
                                      {isExactMatch
                                        ? t("currentPlan")
                                        : isCurrentPlan
                                        ? t("switchToBilling", {
                                            interval: selectedInterval,
                                          })
                                        : t("getPlan", {
                                            plan:
                                              typeof plan.name === "string"
                                                ? plan.name
                                                : i18n.language === "ptbr"
                                                ? plan.name.ptbr
                                                : plan.name.en,
                                          })}
                                    </span>
                                  )}
                                </SelectButton>
                              </PricingCard>
                            );
                          })}
                      </PlanCardContainer>
                      <Divider />
                      <PayAsYouGoBar
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                      >
                        <PayAsYouGoContent>
                          <PlanName>{t("viewCredits")}</PlanName>
                          <PayAsYouGoPrice>${selectedAmount}</PayAsYouGoPrice>
                          <PriceSubtext>
                            {t("viewsAt", {
                              count: calculateViewsFromAmount(selectedAmount),
                              price: "0.20",
                            })}
                          </PriceSubtext>
                          <DropdownWrapper>
                            <PayAsYouGoDropdown>
                              <DropdownHeader
                                onClick={() => {
                                  const plan = walletData?.subscription?.plan;
                                  const status =
                                    walletData?.subscription?.status;
                                  const cancelAtPeriodEnd =
                                    walletData?.subscription?.cancelAtPeriodEnd;
                                  const isUnlimitedActive =
                                    plan === "UNLIMITED" &&
                                    (status === "active" ||
                                      (status === "canceled" &&
                                        !cancelAtPeriodEnd));

                                  if (!isUnlimitedActive) {
                                    setIsDropdownOpen((prev) => !prev);
                                  }
                                }}
                                style={{
                                  opacity:
                                    hasUnlimitedPlan ||
                                    (walletData?.subscription?.plan ===
                                      "UNLIMITED" &&
                                      walletData?.subscription?.status ===
                                        "canceled")
                                      ? 0.5
                                      : 1,
                                  cursor:
                                    hasUnlimitedPlan ||
                                    (walletData?.subscription?.plan ===
                                      "UNLIMITED" &&
                                      walletData?.subscription?.status ===
                                        "canceled")
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <span>${selectedAmount}</span>
                                <ChevronDown
                                  size={16}
                                  style={{
                                    transform: isDropdownOpen
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                    transition: "transform 0.3s ease",
                                    color:
                                      hasUnlimitedPlan ||
                                      (walletData?.subscription?.plan ===
                                        "UNLIMITED" &&
                                        walletData?.subscription?.status ===
                                          "canceled")
                                        ? "rgba(255,255,255,0.5)"
                                        : "rgba(136,83,251,0.8)",
                                    opacity:
                                      hasUnlimitedPlan ||
                                      (walletData?.subscription?.plan ===
                                        "UNLIMITED" &&
                                        walletData?.subscription?.status ===
                                          "canceled")
                                        ? 0.5
                                        : 1,
                                  }}
                                />
                              </DropdownHeader>

                              <AnimatePresence>
                                {isDropdownOpen && !hasUnlimitedPlan && (
                                  <DropdownOptions
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    {rechargeAmounts.map((amount) => (
                                      <DropdownOption
                                        key={amount.value}
                                        onClick={() => {
                                          setSelectedAmount(amount.value);
                                          setIsDropdownOpen(false);
                                        }}
                                        $isSelected={
                                          selectedAmount === amount.value
                                        }
                                        role="option"
                                        aria-selected={
                                          selectedAmount === amount.value
                                        }
                                      >
                                        <span>${amount.value}</span>
                                        {selectedAmount === amount.value && (
                                          <CheckMark
                                            initial={{ scale: 0 }}
                                            animate={{ scale: 1 }}
                                            exit={{ scale: 0 }}
                                          >
                                            <Check size={16} />
                                          </CheckMark>
                                        )}
                                      </DropdownOption>
                                    ))}
                                  </DropdownOptions>
                                )}
                              </AnimatePresence>
                            </PayAsYouGoDropdown>
                          </DropdownWrapper>
                          <FeaturesList>
                            <li>{t("everythingFromUnlimited")}</li>
                            <li>{t("noMonthlyCommitment")}</li>
                            {(() => {
                              const plan = walletData?.subscription?.plan;
                              const status = walletData?.subscription?.status;
                              const cancelAtPeriodEnd =
                                walletData?.subscription?.cancelAtPeriodEnd;
                              if (
                                plan === "UNLIMITED" &&
                                (status === "active" ||
                                  (status === "canceled" && !cancelAtPeriodEnd))
                              ) {
                                return (
                                  <li style={{ color: "rgba(255,149,0,1)" }}>
                                    {t("unavailableWithUnlimited")}
                                  </li>
                                );
                              }
                            })()}
                          </FeaturesList>
                          <SelectButton
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={() => handleAddCredits()}
                            $plan="payAsYouGo"
                            $isUnlimitedPlan={
                              walletData?.subscription?.plan === "UNLIMITED"
                            }
                            disabled={(() => {
                              const plan = walletData?.subscription?.plan;
                              const status = walletData?.subscription?.status;
                              const cancelAtPeriodEnd =
                                walletData?.subscription?.cancelAtPeriodEnd;
                              const isUnlimitedActive =
                                plan === "UNLIMITED" &&
                                (status === "active" ||
                                  (status === "canceled" &&
                                    !cancelAtPeriodEnd));
                              return isProcessing || isUnlimitedActive;
                            })()}
                          >
                            {isProcessing ? (
                              <LoadingSpinner size={20} />
                            ) : (
                              <span>
                                {(() => {
                                  const plan = walletData?.subscription?.plan;
                                  const status =
                                    walletData?.subscription?.status;
                                  const cancelAtPeriodEnd =
                                    walletData?.subscription?.cancelAtPeriodEnd;
                                  const isUnlimitedActive =
                                    plan === "UNLIMITED" &&
                                    (status === "active" ||
                                      (status === "canceled" &&
                                        !cancelAtPeriodEnd));
                                  if (isProcessing) {
                                    return <LoadingSpinner size={20} />;
                                  }
                                  if (isUnlimitedActive) {
                                    return t("notAvailableUntilPlanEnds");
                                  }
                                  return t("addCredits", {
                                    count:
                                      calculateViewsFromAmount(selectedAmount),
                                  });
                                })()}
                              </span>
                            )}
                          </SelectButton>
                        </PayAsYouGoContent>
                      </PayAsYouGoBar>
                    </CardsContainer>
                  </motion.div>
                }
              />
            </Routes>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ContentWrapper>
        </PageContainer>
      )}
    </AnimatePresence>
  );
};

export default NewPricingPage;
