import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {
  UserPlus,
  Loader,
  MessageCircle,
  Send,
  X as CloseIcon,
} from "react-feather";
import { sendFriendRequest } from "./friendsApi";
import { useToast } from "../../context/ToastContext";
import { useTheme } from "../../context/ThemeContext";

const ResultsContainer = styled(motion.div)`
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.colors.shadow};
`;

const UserItem = styled(motion.div)`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.buttonGradient};
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .name {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 500;
  }

  .email {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 12px;
  }

  .status-text {
    font-size: 12px;
    color: ${({ theme, $status }) => {
      switch ($status) {
        case "friend":
          return theme.colors.accent.success.base;
        case "request_sent":
          return theme.colors.accent.purple.base;
        case "request_received":
          return theme.colors.accent.purple.dark;
        default:
          return "inherit";
      }
    }};
  }
`;

const AddButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.accent.purple.base};
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.accent.purple.light};
    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.buttonGradient};
    color: ${({ theme }) => theme.colors.secondary};
    transform: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.secondary};

  svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 32px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  svg {
    color: ${({ theme }) => theme.colors.accent.purple.light};
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
`;

const HighlightedText = styled.span`
  color: ${({ theme, $highlight }) =>
    $highlight ? theme.colors.accent.purple.base : "inherit"};
  background: ${({ theme, $highlight }) =>
    $highlight ? theme.colors.accent.purple.light : "transparent"};
  padding: ${(props) => (props.$highlight ? "0 4px" : "0")};
  border-radius: 4px;
`;

const MessageOverlay = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: ${({ theme }) => theme.colors.surface};
  backdrop-filter: blur(${({ theme }) => theme.colors.glass.blur});
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
`;

const UserCard = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 10px;

  h3 {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    margin: 0;
    font-weight: 500;
  }

  p {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 12px;
    margin: 0;
  }
`;

const MessageInput = styled.textarea`
  height: 80px;
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  padding: 12px;
  resize: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  line-height: 1.5;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
    background: ${({ theme }) => theme.colors.surface};
    box-shadow: ${({ theme }) => theme.colors.glow};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const SendButton = styled(motion.button)`
  padding: 10px;
  background: ${({ theme }) => theme.colors.buttonGradient};
  border: 1px solid ${({ theme }) => theme.colors.accent.purple.light};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-1px);
    background: ${({ theme }) => theme.colors.buttonGradientHover};
    border-color: ${({ theme }) => theme.colors.accent.purple.base};
  }
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 6px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const FriendSearchResults = ({
  results,
  isSearching,
  searchTerm,
  onFriendAdd,
}) => {
  const theme = useTheme();
  const { showToast } = useToast();
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const highlightMatch = (text, term) => {
    if (!term) return text;

    const regex = new RegExp(`(${term})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? (
        <HighlightedText key={i} $highlight>
          {part}
        </HighlightedText>
      ) : (
        <span key={i}>{part}</span>
      )
    );
  };

  const handleSendRequest = async () => {
    if (!message.trim()) {
      showToast("Please add a message to your friend request", "error");
      return;
    }

    try {
      const response = await sendFriendRequest(selectedUser._id, message);
      onFriendAdd(response);
      showToast("Friend request sent successfully", "success");
      setSelectedUser(null);
      setMessage("");
    } catch (error) {
      showToast(error.message || "Failed to send friend request", "error");
    }
  };

  if (isSearching) {
    return (
      <ResultsContainer theme={theme}>
        <LoadingContainer theme={theme}>
          <Loader size={24} />
        </LoadingContainer>
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer theme={theme}>
      {!results.length && searchTerm.length >= 2 ? (
        <EmptyState theme={theme}>
          <UserPlus />
          <p>No users found matching "{searchTerm}"</p>
        </EmptyState>
      ) : (
        <>
          <AnimatePresence>
            {selectedUser && (
              <MessageOverlay
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.2 }}
              >
                <CloseButton
                  onClick={() => setSelectedUser(null)}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <CloseIcon size={16} />
                </CloseButton>

                <UserCard>
                  <div>
                    <h3>{selectedUser.name}</h3>
                    <p>{selectedUser.email}</p>
                  </div>
                </UserCard>

                <MessageInput
                  placeholder="Write a message to introduce yourself..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  autoFocus
                />

                <SendButton
                  onClick={handleSendRequest}
                  disabled={!message.trim()}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Send size={16} />
                  Send Friend Request
                </SendButton>
              </MessageOverlay>
            )}
          </AnimatePresence>

          {results.map((user) => (
            <UserItem
              key={user._id}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              $status={user.status}
              theme={theme}
            >
              <div className="user-info">
                <div className="name">
                  {highlightMatch(user.name, searchTerm)}
                </div>
                <div className="email">
                  {highlightMatch(user.email, searchTerm)}
                </div>
                {user.status !== "none" && (
                  <div className="status-text">
                    {user.status === "friend" && "✓ Already friends"}
                    {user.status === "request_sent" && "• Request sent"}
                    {user.status === "request_received" && "• Request received"}
                  </div>
                )}
              </div>
              <AddButton
                onClick={() => setSelectedUser(user)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                disabled={user.status !== "none"}
                theme={theme}
              >
                <UserPlus />
              </AddButton>
            </UserItem>
          ))}
        </>
      )}
    </ResultsContainer>
  );
};

export default FriendSearchResults;
